<template>
  <b-tabs content-class="mt-3" class="tabs-secciones">
    <b-tab title="Informacion General" @click="cambiarSeccion('general')">
      <template #title>
        <div class="title-tab">
          <inline-svg
            class="icono-titulo"
            src="../../assets/images/general.svg"
            title="My Image"
          />
          <p class="titulo text-center">Información General</p>
        </div>
      </template>
      <informacion-general
        v-if="secciones.general"
        :despacho="despacho"
        :postInformacionGeneral="postInformacionGeneral"
        :informacionGeneral="informacionGeneral"
        tipo="presentador"
      >
      </informacion-general>
    </b-tab>
    <b-tab title="Documentación" @click="cambiarSeccion('documentos')">
      <template #title>
        <div class="title-tab">
          <inline-svg
            class="icono-titulo"
            src="../../assets/images/documento.svg"
            title="My Image"
          />
          <p class="titulo text-center">Documentación</p>
        </div>
      </template>
      <documentos
        v-if="todosDocumentos.tipos_documentos && secciones.documentos"
        :agregarDocumento="true"
        :postDocumento="postDocumento"
        :updateDocumento="updateDocumento"
        :documentosTabla="documentosTabla"
        :documentos="todosDocumentos"
        :eliminarDocumento="eliminarDocumento"
        :validate_permission = "true"

        :eliminarDocumentos ="eliminarDocumentos"
        :showDeleteDocumentos = "showDeleteDocumentos"
        :actualizarDocumentos= "actualizarDocumentos"
        :confirmarDeleteDocumentos= "confirmarDeleteDocumentos"
        :cerrarModal="cerrarModal"
        :metodoThen= "metodoThen"
        :metodoCatch="metodoCatch"
      >
      </documentos>

      <!-- <DocumentosPrestador
        v-if="todosDocumentos.tipos_documentos && secciones.documentos"
        :agregarDocumento="true"
        :postDocumento="postDocumento"
        :updateDocumento="updateDocumento"
        :documentosTabla="documentosTabla"
        :documentos="todosDocumentos"
        :eliminarDocumento="eliminarDocumento"
        :validate_permission="true"
      >
      </DocumentosPrestador> -->
    </b-tab>
    <b-tab title="CDE" @click="cambiarSeccion('cde')">
      <template #title>
        <div class="title-tab">
          <inline-svg
            class="icono-titulo"
            src="../../assets/images/documento.svg"
            title="My Image"
          />
          <p class="titulo text-center">CDE</p>
        </div>
      </template>
      <admCde v-if="secciones.cde"> </admCde>
    </b-tab>
    <b-tab title="Pagos" @click="cambiarSeccion('pagos')">
                <template #title>
                    <div class="title-tab">
                        <inline-svg  class="icono-titulo" src="../../assets/images/dollar.svg" title="My Image"/>
                        <p class="titulo text-center" > Pagos</p>
                    </div>
                </template>
                <pagos
                    v-if="pagosTabla && secciones.pagos"
                    :agregarPago="true"
                    :despacho="despacho"
                    :pagosTabla="pagosTabla"
                    :montos="montos"
                    :postPago="postPago"
                    :updatePago="updatePago"
                    :getComprobante="getComprobante"
                >
                </pagos>

            </b-tab>
  </b-tabs>
</template>

<script>
import axios from "axios";
import FormAsignarUsuario from "../../../formulario/FormAsignarUsuario.vue";
import Usuarios from "../../Componentes/Usuarios.vue";
import { mapActions } from "vuex";
import FormDocumento from "../../../formulario/FormDocumento.vue";
import Documentos from "../../Componentes/Documentos.vue";
import Pagos from "../../Componentes/Pagos.vue";
import AdmCde from "@/components/Despachos/Componentes/cde/admCde.vue";
import InformacionGeneral from "../../Componentes/InformacionGeneral.vue";
export default {
  components: {
    FormAsignarUsuario,
    Usuarios,
    Documentos,
    Pagos,
    InformacionGeneral,
    AdmCde,
  },

  props: {
    despacho: Object,
    listaUsuarios: Object,

    submit: Function,
    changeForm: Function,
    changeValue: Function,
    estados: Object,
    usuarios: Array,

    changeState: Function,
    addUser: Function,
    deleteUser: Function,

    //DOCUMENTOS
    documentos: Object,
    documentosTabla: Array,
    postDocumento: Function,
    updateDocumento: Function,

    showDeleteDocumentos: Boolean,
    eliminarDocumentos: Function,
    actualizarDocumentos: Function,
    confirmarDeleteDocumentos: Function,
    cerrarModal: Function,
    metodoThen: Function,
    metodoCatch: Function,

    //PAGOS
    pagos: Object,
    pagosTabla: Array,
    postPago: Function,
    updatePago: Function,
    getComprobante: Function,

    //Informacion General
    postInformacionGeneral: Function,
    informacionGeneral: Object,

    eliminarDocumento: Function,
    eliminarPago: Function,
  },

  data() {
    return {
      usuariosPorRol: [],
      documentosPorRol: [],
      pagosPorRol: [],
      usuariosPorRol: [],

      todosDocumentos: {},
      todosPagos: {},

      tipoUsuariosPorRol: [
        { text: "Garantías y Correcciones", value: "administrativa" },
        { text: "Presentador", value: "prestador" },
        { text: "Tramitado de Servicios", value: "tramitador_servicios" },
      ],

      secciones: {
        general: true,
        carga: false,
        pagos: false,
        usuarios: false,
        documentos: false,
        cde: false,
      },
    };
  },
  mounted() {
    this.documentosPorRol = [];
    this.documentos.tipos_documentos.forEach((el) => {
      this.documentosPorRol.push(el);
    });
    this.todosDocumentos = {
            tipos_documentos: this.documentosPorRol,
            bl : this.documentos.bl,
            documento_transporte: this.documentos.documento_transporte,
            tipos_vistos_buenos: this.documentos.tipos_vistos_buenos
        }
  },
  watch: {
    documentos: function (newValue, oldValue) {
      if (this.documentos) {
        this.documentosPorRol = [];
        this.documentos.tipos_documentos.forEach((el) => {
          
            this.documentosPorRol.push(el);
        });
        this.todosDocumentos = {
            tipos_documentos: this.documentosPorRol,
            bl : this.documentos.bl,
            documento_transporte: this.documentos.documento_transporte,
            tipos_vistos_buenos: this.documentos.tipos_vistos_buenos
        }
      }
    },
  },

  methods: {
    //secciones
    cambiarSeccion(seccion) {
      Object.keys(this.secciones).forEach((key) => {
        if (key == seccion) this.secciones[key] = true;
        else this.secciones[key] = false;
      });
    },
  },
};
</script>

<style>
</style>