

export default {
  namespaced: true,

  state: {
    isNavOpen: false,
  },

  getters: {
    isNavOpen (state) {
      return state.isNavOpen
    }
  },

  mutations: {
    SET_ISNAVOPEN (state, value) {
      state.isNavOpen = value
    },

  },

  actions: {
    

    setIsNavOpen ({  commit }, tipo_filtro) {
      commit('SET_ISNAVOPEN', yesno)
    },
    toggleNav ({  commit }, isnavopen) {
      commit('SET_ISNAVOPEN', isnavopen)
    },

  

  

    
  }
}
