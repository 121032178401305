<template>
    <form class="form-input" @submit.prevent="submit" id="form-documentos" enctype="multipart/form-data">
        <b-row>
            <b-col sm="12" md="12" lg="4" class="mt-2 text-center">
                <h5>Tipo de Documento</h5>
                <h5>{{documentoEditar.tipo.nombre}}</h5>
            </b-col>
            <b-col sm="12" md="12" lg="4">
                <b-form-group
                    :content-cols="12"
                    label="FECHA RECEPCION"
                > 
                    <b-input type="date" v-model="fecha_recepcion">
                    </b-input>
                </b-form-group>
            </b-col>
            <b-col sm="12" md="12" lg="4">
                <b-form-group
                    :content-cols="12"
                    label="ADJUNTAR DOCUMENTO"
                > 
                     
                    <b-form-file 
                    browse-text="Buscar"
                        placeholder="Seleccione un archivo"
                        drop-placeholder="Coloque el archivo aquí..."
                        v-model="file" ref="file-input" class="mb-2" multiple></b-form-file>

                    
                    <b-button class="btn-archivo" v-b-tooltip.hover :title="file.name" type="button" v-for="(file,index) in files" :key="index" @click="eliminar(file)">

                             <inline-svg v-if="file.name.split('.').at(-1) == 'pdf'" src="../../assets/images/archivo-pdf.svg" />
                           <inline-svg 
                           v-if="file.name.split('.').at(-1) == 'png' || file.name.split('.').at(-1) == 'jpg' || file.name.split('.').at(-1) == 'jpeg'" 
                           src="../../assets/images/archivo-png.svg"/>
                        </b-button>
                </b-form-group>
            </b-col>
            
            <b-col sm="12" md="12" lg="12" v-if="tipoDocumentoTransporte == 'Documento de Transporte'">
                <b-row>
                    <b-col sm="12" md="12" lg="4" >
                        <b-form-group
                            :content-cols="12"
                            label="TIPO DE DOCUMENTO DE TRANSPORTE"
                        > 
                            <select class="form-control border border-secondary"  id="docuento-select"  v-model="tipo_transporte"  @change="changeTipoDocumentoTransporte(tipo_transporte)">
                                <option value="0">Seleccione el tipo de documento de transporte</option>
                                <option v-for="(tipoDocTrans,index) in documentos.documento_transporte" :key="index" :value="tipoDocTrans">{{tipoDocTrans}}</option>
                            </select>
                        </b-form-group>
                    </b-col>
                    <b-col sm="12" md="12" lg="4" >
                        <b-form-group
                            :content-cols="12"
                            label="Numero Documento de Transporte"
                        > 
                            <b-input v-model="numero_documento">

                            </b-input>
                        </b-form-group>
                    </b-col>
                    
                </b-row>
            </b-col>
            <b-col sm="12" md="12" lg="12" v-if="tipoDocumentoTransporte == 'Guía de Despacho'">
                <b-row>
                    <b-col sm="12" md="12" lg="4" >
                        <b-form-group
                            :content-cols="12"
                            label="Número Guía Despacho"
                        > 
                            <b-input v-model="numero_guia_despacho">

                            </b-input>
                        </b-form-group>
                    </b-col>
                    
                </b-row>
            </b-col>
            <b-col sm="12" md="12" lg="12" v-if="tipoDocumentoTransporte == 'Visto Bueno'">
                <b-row>
                    <b-col sm="12" md="12" lg="4" >
                        <b-form-group
                            :content-cols="12"
                            label="TIPO DE VB"
                        > 
                            <select class="form-control border border-secondary"  id="docuento-select"  v-model="tipo_vistos_buenos" >
                                <option value="0">Selecciona un tipo de vistos buenos</option>
                                <option v-for="(tipo,index) in documentos.tipos_vistos_buenos" :key="index" :value="tipo">{{tipo}}</option>
                            </select>
                        </b-form-group>
                    </b-col>
                </b-row>
            </b-col>
            <b-col sm="12" md="12" lg="12" v-if="tipoDocumentoTransporte == 'DIN'">
                <b-row>
                    <b-col sm="12" md="12" lg="4" >
                        <b-form-group
                            :content-cols="12"
                            label="Fecha Aceptación"
                        > 
                            <b-input type="date" v-model="fecha_aceptacion">

                            </b-input>
                        </b-form-group>
                    </b-col>
                </b-row>
            </b-col>
            <b-col sm="12" md="12" lg="12" v-if="tipoDocumentoTransporte == 'BL Corregido'">
                <b-row>
                    
                    <b-col sm="12" md="12" lg="4" >
                        <b-form-group
                            :content-cols="12"
                            label="Status BL"
                        > 
                            <select class="form-control border border-secondary"  id="status-select"  v-model="statusBl"  >
                                <option value="0">Selecciona el status del BL</option>
                                <option v-for="(tipo,index) in documentos.bl.status" :key="index" :value="tipo">{{tipo}}</option>
                            </select>
                        </b-form-group>
                    </b-col>
                </b-row>
            </b-col>
            <b-col sm="12" md="12" lg="12" v-if="tipoDocumentoTransporte == '1ER DUS'">
                    <b-row>
                        <b-col sm="12" md="12" lg="4" >
                            <b-form-group
                                class="text-left"
                                label="Fecha Presentación"
                            >
                                <b-form-datepicker 
                                    label-next-month="Siguiente mes"
                                    label-next-year="Siguiente año"
                                    label-current-month="Mes actual"
                                    label-prev-year="Año anterior"
                                    label-prev-month="Mes anterior"
                                    label-prev-decade="Decada anterior"
                                    label-next-decade="Decada siguiente"


                                    label-no-date-selected="No hay fecha seleccionada"
                                    label-help="Usa el cursos para navegar por las fechas"
                                v-model="fecha_presentacion_primer_dus" class="mb-2" ></b-form-datepicker>
                            </b-form-group>
                        </b-col>
                        <b-col sm="12" md="12" lg="4" >
                            <b-form-group
                                class="text-left"
                                cols="6"
                                label="Hora de Retiro"
                            >
                                <b-form-timepicker 
                                    label-no-time-selected="No hay hora seleccionada"
                                    label-close-button="Cerrar"
                                    label-hours="Horas"
                                    label-minutes="Minutes"
                                v-model="hora_presentacion_primer_dus" locale="es"></b-form-timepicker>
                            </b-form-group>
                        </b-col>
                        <b-col sm="12" md="12" lg="4" >
                            <b-form-group
                            class="text-left"
                                cols="6"
                                label="Número Primer Dus"
                            > 
                                <b-input type="text" v-model="numero_primer_dus" required>

                                </b-input>
                            </b-form-group>
                        </b-col>
                        <b-col sm="12" md="12" lg="4" >
                            <b-form-group
                            class="text-left"
                                cols="6"
                                label="Cantidad de días para alerta"
                            > 
                                <b-input type="text" v-model="orden_alerta" required>

                                </b-input>
                            </b-form-group>
                        </b-col>
                    </b-row>
                </b-col>
                <b-col sm="12" md="12" lg="12" v-if="tipoDocumentoTransporte == 'DUS LEGALIZADO'">
                    <b-row>
                        <b-col sm="12" md="12" lg="4" >
                            <b-form-group
                                class="text-left"
                                label="Fecha Dus Legalizado"
                            >
                                <b-form-datepicker 
                                    label-next-month="Siguiente mes"
                                    label-next-year="Siguiente año"
                                    label-current-month="Mes actual"
                                    label-prev-year="Año anterior"
                                    label-prev-month="Mes anterior"
                                    label-prev-decade="Decada anterior"
                                    label-next-decade="Decada siguiente"


                                    label-no-date-selected="No hay fecha seleccionada"
                                    label-help="Usa el cursor para navegar por las fechas"
                                v-model="fecha_dus_legalizado" class="mb-2" ></b-form-datepicker>
                            </b-form-group>
                        </b-col>
                    </b-row>
                </b-col>
                <b-col sm="12" md="12" lg="12" v-if="tipoDocumentoTransporte == 'Otro'">
                    <b-row>
                        <b-col sm="12" md="12" lg="4" >
                            <b-form-group
                            :content-cols="12"
                            label="Nombre Documento"
                        > 
                            <b-input type="text" v-model="nombre_documento">

                            </b-input>
                        </b-form-group>
                        </b-col>
                    </b-row>
                </b-col>
            <b-col cols="3">
                <b-button
                class="btn-principal btn-small"
                type="submit"
                >
                    Enviar
                </b-button>
                <slot name="cancelar"></slot>
            </b-col>
        </b-row>
    </form>
</template>

<script>
import axios from 'axios'
import moment from 'moment'
export default {
    props:{
        documentos: Object,

        postDocumento: Function,

        documentoEditar: Object,
        files_old: Array,
        cancelar: Function
    },
    data(){
        return{
            file: null,
            files: [],
            tipo_documento: 0,
            fecha_recepcion: '',

            idDocumento: '',
            //Archivos antiguos
            files_delete: [],

            ///BL
            statusBl: 0,

            //DIN
            fecha_aceptacion: '',

            //documento transporte
            tipo_transporte: 0,
            numero_documento: 0,

            //tipos vistos buenos
            tipo_vistos_buenos: 0,

            //numero_guia_despacho
            numero_guia_despacho: '',

            //1er dus
            fecha_presentacion_primer_dus: '',
            hora_presentacion_primer_dus: '',
            numero_primer_dus: '',
            orden_alerta:'',

            //dus legalizado
            fecha_dus_legalizado: '',

            //OTRO
            nombre_documento:'',

            

            tipoDocumentoTransporte: '',
            tipoDocumentoVB: false,

            tipoDocumentoBl: false,
        }
    },
    mounted(){
        this.files = this.files_old
        this.setDocumentoEditar()
    },
    watch:{
        file:function(e){
            this.$refs['file-input'].files.forEach(el =>{
                this.files.push(el)
            })
            this.$refs['file-input'].reset()
        },
        files_old:function(e){
            this.files = this.files_old
        },
        documentoEditar:function(e){
            this.setDocumentoEditar()
            
        }
    },
    methods:{

        setDocumentoEditar(){
            this.idDocumento = this.documentoEditar.id
            this.tipoDocumentoTransporte = this.documentoEditar.tipo.nombre
            console.log(this.tipoDocumentoTransporte)
            
            this.fecha_recepcion = moment(this.documentoEditar.fecha_recepcion).format('YYYY-MM-DD').toString()
            console.log(this.documentoEditar.tipo_documentos_id)
            if(this.documentoEditar.tipo_documentos_id == 1) {
                this.tipo_transporte = this.documentoEditar.base.tipo_transporte
                this.numero_documento = this.documentoEditar.base.numero_documento

                console.log( this.tipo_transporte,  this.numero_documento)
            }
            else if(this.documentoEditar.tipo_documentos_id == 2){
                this.tipo_vistos_buenos = this.documentoEditar.base.tipo_vistos_buenos 
            }
            else if(this.documentoEditar.tipo_documentos_id == 3) {
                this.statusBl = this.documentoEditar.base.status
            }
            else if(this.documentoEditar.tipo_documentos_id == 4) {
                this.fecha_aceptacion = moment(this.documentoEditar.base.fecha_aceptacion).format('YYYY-MM-DD').toString()
            }
            else if(this.documentoEditar.tipo_documentos_id == 10) {
                this.numero_guia_despacho = this.documentoEditar.base.numero_guia_despacho
            }
            else if(this.documentoEditar.tipo_documentos_id == 17) {

                this.fecha_presentacion_primer_dus = this.documentoEditar.base.fecha_presentacion
                this.hora_presentacion_primer_dus = this.documentoEditar.base.hora_presentacion
                this.numero_primer_dus = this.documentoEditar.base.numero_dus
                this.orden_alerta = this.documentoEditar.base.orden_alerta
            }
            else if(this.documentoEditar.tipo_documentos_id == 18) {
                this.fecha_dus_legalizado = this.documentoEditar.base.fecha_dus_legalizado
            }
            else if(this.documentoEditar.tipo_documentos_id == 20) {
                this.nombre_documento = this.documentoEditar.base.nombre_documento
            }
        },
        changeTipoDocumentoTransporte(nuevoTipoDocumentoTransporte){
            if(nuevoTipoDocumentoTransporte== 1) this.tipoDocumentoBl = true;
            else this.tipoDocumentoBl = false;
        },
        async eliminar(file){
            let variable = await this.$swal({
                    title: "¿Deseas eliminar este documento?",
                    text: "¿Está seguro? ¡No podrás revertir esta acción!",
                    type: "warning",
                    showCancelButton: true,
                    confirmButtonColor: '#3085d6',
                    cancelButtonColor: '#d33',
                    confirmButtonText: 'Si, Borrar!',
                    cancelButtonText: 'No, cancelar!',
                    buttonsStyling: true
                  })
            if(variable.isConfirmed == true){
                if(file.id){ 
                    this.files_delete.push(file.id)
                }
                
                let encontro = false
                this.files = this.files.filter(el => {
                    if(file.id){
                        if(el.id) {
                            if(file.id != el.id) {
                                return el
                            }
                        }
                    }
                    if(!encontro){
                        if (el.name == file.name) {
                            encontro = true;
                        }else{
                            return el
                        }
                    }else{
                        return el
                    }
                })
            }
            

        },
        async submit(){
            
            let formData = new FormData();
            
            this.id = this.$route.params.despachoId;
            formData.append('despachos_id', this.id);
            formData.append('fecha_recepcion', this.fecha_recepcion);

            for(var i = 0; i < this.files_delete.length; i++){
                formData.append('delete_files['+i+']', this.files_delete[i]);
            }
            for( var i = 0; i < this.files.length; i++ ){
                let file = this.files[i];
                if(!this.files[i].id)formData.append('files['+ i +']', file);
            }

            if(this.documentoEditar.tipo_documentos_id == 1){ 
                formData.append('tipo_transporte', this.tipo_transporte);
                formData.append('numero_documento', this.numero_documento);
            
            }
            else if(this.documentoEditar.tipo_documentos_id == 2) {
                formData.append('tipo_vistos_buenos',this.tipo_vistos_buenos)
            }
            else if(this.documentoEditar.tipo_documentos_id == 3) {

                formData.append('status',this.statusBl)

            
            }
            else if(this.documentoEditar.tipo_documentos_id == 4) {

                formData.append('fecha_aceptacion', this.fecha_aceptacion);
            }
             else if(this.documentoEditar.tipo_documentos_id == 10) {

                formData.append('numero_guia_despacho', this.numero_guia_despacho);
            }
            else if(this.documentoEditar.tipo_documentos_id == 17) {

                formData.append('fecha_presentacion',this.fecha_presentacion_primer_dus)
                formData.append('hora_presentacion',this.hora_presentacion_primer_dus)
                formData.append('numero_dus',this.numero_primer_dus)
                formData.append('orden_alerta',this.orden_alerta)
                //formData('prorroga',this.)
                //formData('usuarios_alerta',this.)
                //formData('fecha_vencimiento',this.)

            }
            else if(this.documentoEditar.tipo_documentos_id == 18) {
                formData.append('fecha_dus_legalizado',this.fecha_dus_legalizado)
            }
            else if(this.documentoEditar.tipo_documentos_id == 20) {
                formData.append('nombre_documento',this.nombre_documento)
            }
            
            formData.append('_method','put')
            if(this.files.length > 0 ){
                this.cancelar()
                    this.postDocumento(this.idDocumento,formData)
                    this.tipo_documento = ''
                    this.tipoDocumentoTransporte = '';
                    this.fecha_recepcion = '';
                    this.files = [];
                }
            else{
                this.$swal({
                    icon: 'warning',
                    title: '',
                    text: 'Se deben subir documentos para su comprobación',
                });
            }
            
        }
    }
}
</script>

<style>

</style>