<template>
    <b-form class="form-input">
        <b-row>
            <b-col cols="12">
                <b-row >
                    <b-col cols="12 text-left mt-3 ">
                        <h5 class="codigo-exportacion">Editar Contenedor</h5>
                    </b-col>
                    


                     <!--- CONTENEDOR -->
                    <b-col sm="12" md="12" lg="2" v-if="caracteristica_carga == 'CONTENEDOR'">
                        <b-form-group
                            class="text-left"
                            cols="6"
                            label="Variedad"
                        >
                            <select class="form-control border border-secondary"  :id='id +"-variedad-select"' v-model="editarContenedor.variedad"  required>
                                <option value="0" disabled>Selecciona el Tipo de Variedad</option>
                                <option :key="index" v-for="(variedad, index) in variedades" :value="variedad">{{variedad}}</option>
                            </select>
                        </b-form-group>
                    </b-col>
                    <b-col sm="12" md="12" lg="2" v-if="caracteristica_carga == 'CONTENEDOR.base'">
                        <b-form-group
                            class="text-left"
                            cols="6"
                            label="Identificador"
                        >
                            <b-input type="text"  v-model="editarContenedor.identificacion" :maxlength="255">

                            </b-input>
                        </b-form-group>
                    </b-col>
                    <!--- CONTENEDOR -->

                    <!--- CARGA SUELTA -->

                    <b-col sm="12" md="12" lg="2" v-if="caracteristica_carga == 'CARGA SUELTA'">
                        <b-form-group
                            class="text-left"
                            label="Bultos"
                        >
                            <b-input type="text"  v-model="editarContenedor.bultos" :maxlength="255" required>

                            </b-input>
                        </b-form-group>
                    </b-col>
                    <b-col sm="12" md="12" lg="2" v-if="caracteristica_carga == 'CARGA SUELTA'">
                        <b-form-group
                            class="text-left"
                            label="Kilos"
                        >
                            <b-input type="text"  v-model="editarContenedor.kilos" :maxlength="255" required>

                            </b-input>
                        </b-form-group>
                    </b-col>
                    <b-col sm="12" md="12" lg="2" v-if="caracteristica_carga == 'CARGA SUELTA'">
                        <b-form-group
                            class="text-left"
                            label="CBM"
                        >
                            <b-input type="text"  v-model="editarContenedor.CBM" :maxlength="255" required>

                            </b-input>
                        </b-form-group>
                    </b-col>

                    <!--- CARGA SUELTA -->

                    <!--- VEHÍCULOS -->
                    <b-col sm="12" md="12" lg="2" v-if="caracteristica_carga == 'VEHÍCULOS'">
                        <b-form-group
                            class="text-left"
                            label="Cantidad de Vehículos"
                        >
                            <b-input type="text"  v-model="editarContenedor.cantidad" :maxlength="255" required>

                            </b-input>
                        </b-form-group>
                    </b-col>
                    <!--- VEHÍCULOS -->

                    <!--- FIERROS -->
                    <b-col sm="12" md="12" lg="2" v-if="caracteristica_carga == 'FIERROS'">
                        <b-form-group
                            class="text-left"
                            label="Cantidad de Fierros"
                        >
                            <b-input type="text"  v-model="editarContenedor.cantidad" :maxlength="255" required>

                            </b-input>
                        </b-form-group>
                    </b-col>
                    <!--- FIERROS -->

                    <!--- GRANELES -->
                    <b-col sm="12" md="12" lg="2" v-if="caracteristica_carga == 'GRANELES'">
                        <b-form-group
                            class="text-left"
                            label="Volumen"
                        >
                            <b-input type="text"  v-model="editarContenedor.volumen" :maxlength="255" required>

                            </b-input>
                        </b-form-group>
                    </b-col>
                    <!--- GRANELES -->


                    <b-col sm="12" md="12" lg="4">
                        <b-form-group
                            class="text-left"
                            label="Fecha y Hora de Retiro"
                        >
                                <b-form-datepicker 
                                label-next-month="Siguiente mes"
                                label-next-year="Siguiente año"
                                label-current-month="Mes actual"
                                label-prev-year="Año anterior"
                                label-prev-month="Mes anterior"
                                label-prev-decade="Decada anterior"
                                label-next-decade="Decada siguiente"


                                label-no-date-selected="No hay fecha seleccionada"
                                label-help="Usa el cursos para navegar por las fechas"
                                v-model="editarContenedor.fecha_retiro" class="mb-2" ></b-form-datepicker>

                        </b-form-group>
                    </b-col>
                    <b-col sm="12" md="12" lg="3">
                        <b-form-group
                            class="text-left"
                            cols="6"
                            label="Hora de Retiro"
                        >
                              <b-form-timepicker 
                              label-no-time-selected="No hay hora seleccionada"
                                label-close-button="Cerrar"
                                label-hours="Horas"
                                label-minutes="Minutes"
                              v-model="editarContenedor.hora_retiro" locale="es"></b-form-timepicker>
                        </b-form-group>
                    </b-col>
                    <b-col sm="12" md="12" lg="2">
                        <b-form-group
                            class="text-left"
                            cols="6"
                            label="Patente Transporte"
                        >
                            <b-input type="text" v-model="editarContenedor.patente" :maxlength="255">

                            </b-input>
                        </b-form-group>
                    </b-col>
                    <b-col sm="12" md="12" lg="2">
                        <b-form-group
                            class="text-left"
                            label="Asociar Guía Despacho"
                        >
                            <select class="form-control border border-secondary"  id="agente_cargas-select" v-model="editarContenedor.documentos_id"  required>
                                <option value="0" disabled>Selecciona el Guia de Despacho Asociado</option>
                                <option :key="index" v-for="(documento, index) in documentos" :value="documento.id">{{documento.base ? documento.base.numero_guia_despacho: '' }} - {{documento.tipo.nombre}}</option>
                                
                            </select>
                        </b-form-group>
                    </b-col>
                    <b-col sm="12" md="12" lg="2">
                        <b-form-group
                            class="text-left"
                            label="Asociar Pago"
                        >
                            <select class="form-control border border-secondary"  id="agente_cargas-select" v-model="editarContenedor.pagos_id"  required>
                                <option value="0" disabled>Selecciona el Pago Asociado</option>
                                <option :key="index" v-for="(pago, index) in pagos" :value="pago.id">{{pago.tipo.nombre}}</option>
                                
                            </select>
                        </b-form-group>
                    </b-col>
                    <b-col sm="12" md="12" lg="2" class="text-right mb-2">
                        <slot name="cancelar"></slot>
                    </b-col>
                </b-row>
            </b-col>
            
        </b-row>
        <b-row>
            <b-col>
                <b-button

                    class="btn-principal btn-large"
                    type="button"

                    @click="postContenedor(new_files,editarContenedor,files_delete)"
                >
                    Enviar Formulario
                </b-button>
            </b-col>
        </b-row>
        
        
        
        
        
        
        
    </b-form>
</template>

<script>
import axios from 'axios'
export default {
    props:{
        caracteristica_carga: String,
        contenedor: Object,
        agregarContendor: Function,
        borrarContenedor:Function,
        postContenedor:Function,

        variedades:Array,
        pagos:Array,
        documentos:Array,
        files_old: Array,
    },
    data(){
        return{

            file: null,
            files: [],
            new_files: [],

            //Archivos antiguos
            files_delete: [],

            editarContenedor: {
                id:'',
                variedad: '',
                identificacion: '',
                fecha_retiro: '',
                patente: '',
                numero_guia_despacho: '',
                archivos:[],
                pagos_id:'',
                bultos:'',
                kilos:'',
                CBM:'',
                cantidad:'',
                volumen:'',
            },
            id:1,
            variedad: '',
            identificacion:'',
            fecha_retiro:'',
            patente:'',
            numero_guia_despacho:'',
            archivos:[],
            pagos_id:'',
            bultos:'',
            kilos:'',
            CBM:'',
            cantidad:'',
            volumen:'',

            
        }
    },
    watch: {
    // whenever question changes, this function will run
    file: function (newQuestion, oldQuestion) {
        this.$refs['file-input-contenedor'].files.forEach(el =>{
            this.new_files.push(el)
            this.files.push(el)
        })
    },
    files_old:function(e){
        this.files = []
        this.files = this.files_old
    },
    contenedor :function(e){
        this.setContenedor()
    },
  },
  mounted(){
      this.files = []
      this.files = this.files_old
      this.setContenedor()
  },
    methods:{
        setContenedor(){
            this.editarContenedor = this.contenedor
            this.id = this.contenedor.id
            this.fecha_retiro = this.contenedor.fecha
            this.hora_retiro = this.contenedor.hora
            this.patente = this.contenedor.patente
            this.numero_guia_despacho = this.contenedor.numero_guia_despacho
            this.variedad = this.contenedor.base.variedad ??this.contenedor.base.variedad
            this.identificacion = this.contenedor.base.identificacion ?? this.contenedor.base.identificacion
            this.cantidad=this.contenedor.base.cantidad ?? this.contenedor.base.cantidad
            this.bultos=this.contenedor.base.bultos ?? this.contenedor.base.bultos
            this.kilos=this.contenedor.base.kilos ?? this.contenedor.base.kilos
            this.CBM=this.contenedor.base.CBM ?? this.contenedor.base.CBM
            this.volumen=this.contenedor.base.volumen ?? this.contenedor.base.volumen
            this.archivos = this.contenedor.documento ? this.contenedor.documento.archivos : []
            this.pagos_id = this.contenedor.pagos_id
            this.documentos_id = this.contenedor.documentos_id ?? this.contenedor.documentos_id
            this.editarContenedor = {
                id:this.id,
                variedad:  this.variedad,
                identificacion:this.identificacion,
                fecha_retiro:this.fecha_retiro,
                hora_retiro:this.hora_retiro,
                patente:this.patente,
                numero_guia_despacho:this.numero_guia_despacho,
                pagos_id:this.pagos_id,
                documentos_id:this.documentos_id,
                cantidad:this.cantidad,
                bultos:this.bultos,
                kilos:this.kilos,
                CBM:this.CBM,
                volumen:this.volumen,
            }
        },
        eliminar(file){
            if(file.id){ 
                this.files_delete.push(file.id)
            }
            this.files = this.files.filter(el => {
                if(file.id){
                    if(el.id) {
                        if(file.id != el.id) {
                            return el
                        }
                    }
                }
                if (el.name != file.name) {
                    return el
                }
            })
            this.new_files = this.new_files.filter(el => {
                if (el.name != file.name) {
                    return el
                }
            })
        },
        
        documento(id){
            this.idContenedor = id
        }
    }
}
</script>

<style>

</style>