<template>
<b-row>
    <b-col sm="12" md="12" lg="4">
        <b-form-group
        class="text-left form-label"
        label="Usuario" 
        label-for="username-input" 
    >
        <b-form-input id="username-input" v-model="form.username" min="3" max="190" required>    
        </b-form-input>
        <div class="text-left">
            <span id="username"></span>     
        </div>
    </b-form-group>
    </b-col>
    <b-col  sm="12" md="12" lg="4">
        <b-form-group
        class="text-left"
        label="Nombre" 
        label-for="name-input" 
    >
        <b-form-input id="name-input" v-model="form.name" min="3" max="190"  required>    
        </b-form-input>
        <div class="text-left">
        <span id="name"></span>     
        </div>
    </b-form-group>
    </b-col>
    <b-col   sm="12" md="12" lg="4">
        <b-form-group
        class="text-left"
        label="Teléfono"
        label-for="phone-input"
    >
        <b-form-input id="phone-input" min="9" max="11" v-model="form.phone"  ></b-form-input>
        <div class="text-left">
            <span id="phone"></span>     
        </div>
    </b-form-group>
    </b-col>
    <b-col  sm="12" md="12" lg="4">
        <b-form-group
        class="text-left"
        label="Correo" 
        label-for="email-input" 
    >
        <b-form-input type="email" id="email-input" v-model="form.email"  required>    
        </b-form-input>
        <div class="text-left">
            <span id="email"></span>     
        </div>
    </b-form-group>
    </b-col>
    <b-col   sm="12" md="12" lg="4">
        <b-form-group
        class="text-left"
        label="Confirmar Correo" 
        label-for="email_confirmation-input" 
    >
        <b-form-input type="email" id="email_confirmation-input" v-model="form.email_confirmation"  required>    
        </b-form-input>
        <div class="text-left">
            <span id="email_confirmation"></span>     
        </div>
    </b-form-group>
    </b-col>
    
    <b-col  sm="12" md="12" lg="4">
        <b-form-group
                v-if="roles"
                class="text-left"
                label="Rol"
                label-for="rol-select"
            >
            
            <select class="form-control border border-secondary"  id="rol-select" v-model="form.rol"  required>
                <option value="">Seleccione un Rol</option>
                <option v-for="(rol,index) in roles" :key="index" :value="rol.value">{{rol.text}}</option>
            </select>
        </b-form-group>
    </b-col>
    <b-col   sm="12" md="12" lg="4">
        <b-form-group>
            <slot name="cambiarPassword"></slot>
        </b-form-group>
    </b-col>
    <b-col   sm="12" md="12" lg="4">
        <b-form-group
        v-if="password"
        class="text-left"
        label="Contraseña" 
        label-for="password-input" 
    >
        <b-form-input type="password" id="password-input" v-model="form.password" required>    
        </b-form-input>
        <div class="text-left">
            <span id="password"></span>     
        </div>
    </b-form-group>
    </b-col>
    <b-col  sm="12" md="12" lg="4">
        <b-form-group
        v-if="password"
        class="text-left"
        label="Confirmar Contraseña" 
        label-for="password-confirmation-input" 
    >
        <b-form-input type="password" id="password-confirmation-input" v-model="form.password_confirmation"  required>    
        </b-form-input>
        <div class="text-left">
            <span id="password_confirmation"></span>     
        </div>
    </b-form-group>
    </b-col>
    <b-col  sm="12" md="12" lg="4">
        <button
            class="btn-principal btn-large m-2"
            type="submit"
        >Guardar</button>
        <slot></slot>
    </b-col>
    
    
    
    
    
    

    

    <!-- <input-region
        :form="form"
        :cols="cols"
        :user="user"
    
    >
    </input-region> -->
    
    
    
    
    

</b-row>
</template>

<script>
import InputRut from './InputRut.vue';
import InputRegion from './InputRegion.vue';
export default {
    components: { InputRut, InputRegion },
    name:"FormUsuario",

    props: {
        form: Object,
        user: Object,
        roles: Array,
        rutDisponible: Boolean,
        password: Boolean,

    },
    data(){
        return{
            cols: 10,
        }
    },
    mounted(){
        if(this.password){
            this.cols = 9
        }
    },
    methods:{
      cambiarRut(value){
        this.form.rut = value;
      },
      //
    }
}
</script>

<style>

</style>