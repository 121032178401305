<template>
  <div class="container">
      <!-- <div class="row">
        <div class="col-12">
            <button @click="crear()">crear</button>
        </div>
      </div> -->

      <div v-if="!masterSpin" class="row">
          <div class="col-12">
                <div class="w-100 d-flex justify-content-between">
                  <h4>Administración CDE</h4>
                  <div>
                    <button :disabled="cde.estado !== 3" @click="showModalAbrir = !showModalAbrir" class="btn btn-principal btn-small mr-4">Abrir CDE</button>
                    <button :disabled="cde.estado === 3" @click="showModalCerrar = !showModalCerrar" class="btn btn-secundario btn-small">Cerrar CDE</button>
                  </div>
                  <b-modal v-model="showModalAbrir" centered id="modal-abrir-CDE" title="Abrir CDE">
                        <h4 class="mx-3">Seguro que desea abrir la CDE?</h4>
                        <template #modal-footer>
                        <div class="w-100 text-right">
                            <button class="btn btn-secondary mr-2 p-2" @click="showModalAbrir = false">Cancelar</button>
                            <button :disabled="abrirrBussy" @click="openCde()" class="btn btn-danger p-2">Abrir CDE
                                <b-spinner v-if="abrirBussy" small ></b-spinner>
                            </button>
                        </div>
                    </template>
                    </b-modal>
                    <b-modal v-model="showModalCerrar" centered id="modal-cerrar-CDE" title="Cerrar CDE">
                        <h4 class="mx-3">Seguro que desea cerrar la CDE?</h4>
                        <template #modal-footer>
                        <div class="w-100 text-right">
                            <button class="btn btn-secondary mr-2 p-2" @click="showModalCerrar = false">Cancelar</button>
                            <button :disabled="cerrarBussy" @click="cerrar()" class="btn btn-danger p-2">Cerrar CDE
                                <b-spinner v-if="cerrarBussy" small ></b-spinner>
                            </button>
                        </div>
                    </template>
                    </b-modal>
                </div>
                <hr>
          </div>
          <div class="col-12">
              <h4 class="text-danger text-center" v-if="cde.estado === 3">El documento esta cerrado. no se podrá modificar.</h4>
              <h5 v-if="cde.estado === 3 && url !== null" ><a target="_blank" :href="url">Descargar zip CDE.</a> <button @click="copyLink()" id="copylink" class="btn btn-principal p-2 ml-3">Copiar link</button></h5>
              
              <b-popover placement="top" triggers="click blur" target="copylink" title="Copiado!">
                <b>La url ha sido copiada en el portapapeles correctamente.</b>
            </b-popover>
              <div class="accordion" role="tablist">
                    <b-card no-body class="mb-1">
                        <b-card-header header-tag="header" class="p-1" role="tab">
                            <button :disabled="cde.estado === 3" v-b-toggle.accordion-1 class="w-100 p-2 btn btn-principal">Agregar Documentos</button>
                        </b-card-header>
                        <b-collapse v-model="toggle1" id="accordion-1" accordion="my-accordion" role="tabpanel">
                            <b-card-body>
                                <div class="w-100 d-flex justify-content-between py-3">
                                    <button v-if="selectedAllAdd" @click="selectAllDocumentsToAdd()" class="btn-secundario p-2">Seleccionar todos</button>
                                    <button v-else @click="unselectAllDocumentsToAdd()" class="btn-principal p-2">Deseleccionar todos</button>
                                    <div>
                                        <button @click="showModalSubir = !showModalSubir; contenido = ''" class="btn-principal p-2 mr-3">Subir documentos</button>
                                        <button :disabled="disableAddDocuments" @click="subir(0)" class="btn-principal p-2">Agregar documentos
                                            <b-spinner v-if="spinLoadAddDocument" small ></b-spinner>
                                        </button>
                                    </div>
                                </div>
                                <b-table show-empty :busy="DocumentosAddBusy" small :fields="fields" :items="documentosAdd" striped hover responsive>
                                    <template #empty >
                                        <h4 class="text-center">No hay documentos para listar</h4>
                                    </template>
                                    <template #table-busy>
                                        <div class="text-center my-2" style="color: #FFCE1E !important ">
                                        <b-spinner class="align-middle"></b-spinner>
                                        <strong> Cargando...</strong>
                                        </div>
                                    </template>

                                    <template #cell(fecha_recepcion)="row">
                                            <p>{{moment(row.item.fecha_recepcion).format('DD-MM-YYYY')}}</p> 
                                    </template>
                                    <template #cell(checkbox)="row">
                                        <b-form-checkbox
                                            :value="row.item.id"
                                            v-model="selectedDocuments"
                                            >
                                        </b-form-checkbox>
                        
                                    </template>
                                </b-table>
                            </b-card-body>
                        </b-collapse>
                    </b-card>
                    <b-card no-body class="mb-1">
                        <b-card-header header-tag="header" class="p-1" role="tab">
                            <button :disabled="cde.estado === 3" v-b-toggle.accordion-2 class="w-100 p-2 btn btn-principal">Modificar documentos</button>
                        </b-card-header>
                        <b-collapse v-model="toggle2" id="accordion-2" accordion="my-accordion" role="tabpanel">
                            <b-card-body>
                                <b-table show-empty :busy="DocumentosAddedBusy" small :fields="fieldsAdded" :items="documentosAdded" striped hover responsive>
                                    <template #empty >
                                        <h4 class="text-center">No hay documentos para listar</h4>
                                    </template>
                                    <template #table-busy>
                                        <div class="text-center my-2" style="color: #FFCE1E !important ">
                                        <b-spinner class="align-middle"></b-spinner>
                                        <strong> Cargando...</strong>
                                        </div>
                                    </template>
                                    <template #cell(formato)="row">
                                            <p v-if="row.item.formato === 0">Archivo XML</p> 
                                            <p v-if="row.item.formato === 1">Archivo</p> 
                                            <p v-if="row.item.formato === 2">Documento digitalizado</p> 
                                            <p v-if="row.item.formato === 3">Documento despacho</p> 
                                    </template>
                                    <template #cell(filename)="row">
                                        <p v-for="archivo in row.item.archivos">{{ archivo.filename }}</p>
                                    </template>
                                    <template #cell(created_at)="row">
                                            <p>{{moment(row.item.created_at).format('DD-MM-YYYY')}}</p> 
                                    </template>
                                    <template #cell(acciones)="row">
                                        <div>
                                            <button  class="btn btn-primary p-2" @click="editarModal(row.item.id)">Editar</button>
                                            <button v-if="!spinLoadModifyDocument" class="btn btn-danger p-2 ml-2" @click="modificar('borrar', row.item.id)">Eliminar</button>
                                            <button v-else class="btn btn-danger p-2 ml-2" :disabled="true">Eliminar</button>
                                        </div>
                                    </template>
                                </b-table>
                            </b-card-body>
                        </b-collapse>
                    </b-card>
              </div>
          </div>
      </div>
      <div v-else>
        <b-spinner ></b-spinner>
      </div>
      <!-- :no-close-on-backdrop="true" colocado para que el usuario no cierre el modal accidentalmente -->
      <b-modal :no-close-on-backdrop="true" v-model="showModalSubir" scrollable  centered title="Subir documento"> 
            <div class="form-group">
                <label for="">Formato del documento</label>
                <select @change="limpiarForm()" class="form-control" v-model="contenido">
                    <option value="" hidden disabled>Seleccione contenido del documento</option>
                    <!-- <option value="contenido_xml">Archivo XML</option> -->
                    <option value="archivo">Archivo</option>
                    <option value="documento_digitalizado">Documento digitalizado</option>
                </select>

            </div>
            <div class="form-group">
                <label for="">Tipo de Archivo</label>
                <!-- @change="tipo(nuevoTipo)" -->

                <select class="form-control border border-secondary border border-secondary"   v-model="documento.tipoArchivo">
                {{tiposArchivo}}
                <option value="" hidden disabled >Seleccione el tipo de archivo</option>
                <option v-for=" tipoArchivo in tiposArchivo" :value="tipoArchivo.id">{{tipoArchivo.tipo_archivo}}</option>
                </select>
            </div>

            <div v-if="contenido === 'contenido_xml'" class="form-group">
                <div class="form-group">
                    <label for="">Archivo</label>
                    <b-form-file
                        required
                        v-model="archivo"
                        placeholder="Subir un xml"
                        drop-placeholder="Suelta el xml aquí"
                        accept="text/xml"
                    ></b-form-file>
                    <small class="text-danger" v-if="xmlerror">{{xmlerror}}</small>
                </div>
            </div>
            <div v-if="contenido === 'archivo'" class="form-group">
                <div class="form-group">
                    <label for="">Archivo</label>
                    <b-form-file
                        required
                        v-model="archivo"
                        placeholder="Subir archivo"
                        drop-placeholder="Suelta el archivo aquí"
                        accept="image/png, image/gif, image/tiff, application/pdf"
                    ></b-form-file>
                </div>
                <!-- <div class="form-group">
                    <label for="">Nombre (opcional)</label>
                    <input placeholder="Escriba nombre del archivo aquí." type="text" class="form-control" v-model="documento.archivo.nombre">
                </div> -->
                <div class="form-group">
                    <label for="">Reverso
                        <fas size="sm" id="popover-archivo" style="cursor:pointer" :icon="['fas','exclamation-circle']"></fas>
                        <b-popover
                        target="popover-archivo"
                        title="Información"
                        triggers="hover focus"
                        :content="'No se cuentan permisos para este despacho. Solo se pueden visualizar los datos!'"
                        >
                            <p>
                                <b>"incluido"</b> indica que el documento tiene todas las páginas pares
                                dedicadas al reverso de cada hoja digitalizada
                            </p>
                            <p>
                                <b>"omitido"</b> indica que el documento no incorpora el reverso de las hojas
                            </p>
                            <p>
                                <b>"omitidoBlanco"</b> indica que el documento no incorpora el reverso de las hojas, y
                                se declara que el contenido de las hojas del documento original
                                está en blanco
                            </p>
                        </b-popover>
                    </label>
                    <select class="form-control border border-secondary" v-model="documento.archivo.reverso">
                        <option value="">Seleccione una opcion</option>
                        <option value="incluido">Incluido</option>
                        <option value="omitido">Omitido</option>
                        <option value="omitidoBlanco">Omitido blanco</option>
                    </select>
                </div>
            </div>
            <div v-if="contenido === 'documento_digitalizado'">
                <button type="button" class="btn btn-principal mb-2 p-2" @click="addPage()">Añadir pagina</button>
                <div v-for="(pagina, index) in documento.documento_digitalizado.paginas" :key="index">
                    <div class="form-group">
                        <label for="">Archivo</label>
                        <b-form-file
                            required
                            v-model="pagina.archivo"
                            placeholder="Subir un archivo digitalizado"
                            drop-placeholder="Suelta el archivo aquí"
                            accept="image/png, image/gif, image/tiff, application/pdf"
                        ></b-form-file>
                    </div>
                    <div class="form-group">
                        <label for="">Número de la pagina</label>
                        <input type="number" min="1" max="999" class="form-control" v-model="pagina.numeroPagina">
                    </div>
                    <div class="form-group">
                        <label for="">Cara</label>
                        <select class="form-control border border-secondary" v-model="pagina.cara">
                            <option disabled hidden value="">Seleccione cara</option>
                            <option value="anverso">Anverso</option>
                            <option value="reverso">Reverso</option>
                        </select>
                    </div>
                    <button @click="eliminarPagina(index)" class="btn btn-danger p-2">Eliminar Página</button>
                    <hr class="mb-3">
                </div>
                
            </div>
            <template #modal-footer>
            <div class="w-100 text-right">
                <button class="btn btn-secondary mr-2 p-2" @click="showModalSubir = false">Cancelar</button>
                <button v-if="!spinLoadAddDocument" :disabled="disableSubirDocument" @click="subir(1)" class="btn btn-principal p-2">
                    Subir
                </button>
                <button v-else class="btn btn-principal p-2" :disabled="true">
                    Subir
                    <b-spinner small ></b-spinner>                
                </button>
            </div>
            </template>
        </b-modal>
        <b-modal :no-close-on-backdrop="true" v-model="showModalEditar" scrollable  centered title="Editar documento"> 
            <div class="form-group">
                <label for="">Nombre del documento</label>
                <input class="form-control" type="text" value="" v-model="filename">
            </div>
            <template #modal-footer>
            <div class="w-100 text-right">
                <button class="btn btn-secondary mr-2 p-2" @click="showModalEditar = false">Cancelar</button>
                <button v-if="!spinLoadModifyDocument" @click="editar('editar', documento_cde_id)" class="btn btn-principal p-2">
                    Editar
                </button>
                <button v-else class="btn btn-principal p-2" :disabled="true">
                    Editar
                    <b-spinner small ></b-spinner>                
                </button>
            </div>
            </template>
        </b-modal>
  </div>
</template>

<script>
import axios from 'axios'
import { calculateSHA256 } from 'pdfjs-dist/build/pdf.worker';
export default {
    name: 'cde',
    data(){
        return{
            tiposArchivo: [],
            masterSpin: false,
            zipPath: null,
            toggle1: false,
            toggle2: false,
            spinLoadAddDocument: false,
            spinLoadModifyDocument: false,
            cdeLink: '',
            xmlerror: '',
            DocumentosAddedBusy: false,
            documentosAdd: [],
            documento_cde_id:'',
            showModalCerrar: false,
            showModalAbrir: false,
            DocumentosAddBusy: false,
            cerrarBussy: false,
            abrirBussy: false,
            selectedDocuments: [],
            fields: [
                {key:'checkbox', label: 'Selección'},
                {key:'tipo.nombre', label: 'Tipo'},
                {key:'fecha_recepcion', label: 'Fecha'},
                {key:'usuario.name', label: 'Usuario'},
                {key:'estado', label: 'Estado Validación'},
            ],

            showModalSubir: false,
            showModalEditar: false,
            contenido: '',
            filename:'',
            documento: {
                archivo: {
                    reverso: '',
                    nombre: ''
                },
                documento_digitalizado:{
                    paginas:[]
                },
                tipoArchivo: ''
            },
            archivo: null,


            documentosAdded: [],
            fieldsAdded: [
                {key:'created_at', label: 'Fecha'},
                {key:'usuario.name', label: 'Usuario'},
                {key:'filename', label: 'Nombre del Documento'},
                {key:'formato', label: 'Contenido'},
                {key:'acciones', label: 'Acciones'},
            ],

            cde: {},
            hash:'',
            url:''
        }
    },
    mounted(){
        this.getTypes();
    },

    created(){
        this.getDocumentsAdd()
        this.getAddedDocuments()
        this.getCdeInfo()
        this.getZipUrl()
    },
    computed:{
        disableAddDocuments(){
            if(this.selectedDocuments.length === 0) return true
            if(this.spinLoadAddDocument) return true
            return false
        },
        selectedAllAdd: function(){
            if(this.selectedDocuments.length === this.documentosAdd.length) return false
            return true
        },
        disableSubirDocument(){
            if(this.contenido === '') return true
            if(this.contenido === 'contenido_xml'){
                if (this.archivo === null) return true
                return false
            }
            if(this.contenido === 'archivo'){
                if (this.documento.archivo.archivo === '' ||
                this.documento.archivo.reverso === '') return true
                return false
            }
            if(this.contenido === 'documento_digitalizado'){
                if (this.documento.documento_digitalizado.paginas.length === 0) return true
                return false
            }
            return false
        }
    },
    methods:{
        copyLink(){
            //const url = axios.defaults.baseURL + '/api/CDE?' + this.hash;
            //this.zipPath = this.url;
            try {
                navigator.clipboard.writeText(this.url);
                this.$root.$emit('bv::show::popover', 'copylink')
            } catch($e) {
                alert('Un error ha ocurrido. Por favor contactese con un administrador.');
            }
        },
        async calcularHash(numero) {
            // Convertir el número a una cadena de bytes
            const data = new TextEncoder().encode(numero.toString());

            // Calcular el hash utilizando SHA-256
            const hashBuffer = await crypto.subtle.digest('SHA-256', data);

            // Convertir el buffer de hash a una cadena hexadecimal
            const hashArray = Array.from(new Uint8Array(hashBuffer));
            const hashHex = hashArray.map(byte => byte.toString(16).padStart(2, '0')).join('');
            await axios.put('/api/admin/CDE/' + this.cde.id + '/hash-cde',{
                hash_cde: hashHex
            });
            this.hash = hashHex
            this.url = axios.defaults.baseURL + '/api/CDE?' + hashHex;

        },

        limpiarForm(){
            this.documento = {
                archivo: {
                    reverso: '',
                    nombre: ''
                },
                documento_digitalizado:{
                    paginas:[]
                }
            }
        },

        async getTypes(){
            await axios.get('api/admin/CDE/listarTipoArchivos').then(resp => {
                this.tiposArchivo = resp.data.data;
                
            })
        },
        eliminarPagina(index){
            this.documento.documento_digitalizado.paginas.splice(index, 1);
        },
        async getZipUrl(){
            await axios.get('api/despacho/'+this.$route.params.despachoId+'/CDE/isZipCreated').then(resp => {
                this.spinLoad = false
                if (resp.data.error === true) return;
                console.log(this.hash)
                this.zipPath = axios.defaults.baseURL + '/api/CDE?' + this.hash 
            }).catch(err => {
                this.spinLoad = false
                console.log("error", err)
            })
        },
        async getCdeInfo(){
            this.masterSpin = true
            await axios.get('api/admin/despacho/'+this.$route.params.despachoId+'/CDE').then(resp => {
                this.spinLoad = false
            this.masterSpin = false
                this.cde = resp.data
                this.calcularHash(this.cde.id)
            }).catch(err => {
            this.masterSpin = false
                this.spinLoad = false
                console.log("error", err)
            })
        },
        addPage(){
            this.documento.documento_digitalizado.paginas.push({
                archivo: null,
                numeroPagina: 1,
                cara: ''
            })
        },
        selectAllDocumentsToAdd(){
            this.selectedDocuments = []
             this.documentosAdd.forEach((e) => {
                this.selectedDocuments.push(e.id);
            });
        },
        unselectAllDocumentsToAdd(){
            this.selectedDocuments = []
        },
        async getDocumentsAdd(){
            this.DocumentosAddBusy = true
            await axios.get('api/admin/despacho/'+this.$route.params.despachoId+'/CDE/getDocumentsToAdd').then(resp => {
                this.DocumentosAddBusy = false
                this.documentosAdd = resp.data
            }).catch(err => {
                this.DocumentosAddBusy = false
                console.log(err)
            })
        },
        async getAddedDocuments(){
            this.DocumentosAddedBusy = true
            await axios.get('api/admin/despacho/'+this.$route.params.despachoId+'/CDE/getAddedDocuments').then(resp => {
                this.DocumentosAddedBusy = false
                this.documentosAdded = resp.data
            }).catch(err => {
                this.DocumentosAddedBusy = false
                console.log(err)
            })
        },
        async crear(){
            await axios.post('api/admin/CDE/crearCDE',{
                despacho_id: this.$route.params.despachoId
            }).then(resp => {
                this.getDocumentsAdd()
                this.getAddedDocuments()
                this.getCdeInfo()
            }).catch(err => {
                console.log(err)
            })
        },
        async subir($mode = 0){
            this.spinLoadAddDocument = true
            this.xmlerror = ''
            
            const formData = new FormData()
            formData.append('despacho_id', this.$route.params.despachoId)
            formData.append('tipoArchivo_id',this.documento.tipoArchivo)
            formData.append('mode', $mode)
            if($mode === 0){
                formData.append('contenido', "documento_digitalizado")
                this.selectedDocuments.forEach(e => {
                    formData.append('documentos[]', e)
                })
                formData.append('archivo', this.archivo)
            }
            else{
                formData.append('contenido', this.contenido)
                formData.append('documentos',JSON.stringify(this.documento))
                if(this.contenido === 'documento_digitalizado'){
                    this.documento.documento_digitalizado.paginas.forEach(pagina => {
                        formData.append('archivo[]', pagina.archivo)
                    })
                }else{
                    formData.append('archivo', this.archivo)
                }

            } 
            await axios.post('api/admin/CDE/cargarDocumento', formData, {
                headers: {
                'Content-Type': "multipart/form-data"
            }
            }).then(resp => {
                this.spinLoadAddDocument = false
                this.selectedDocuments = []
                this.getDocumentsAdd()
                this.getAddedDocuments()
                this.limpiarForm()
            }).catch(err => {
                if (err.response.status === 400 && err.response.data.error === true){
                    this.$swal({
                      icon: 'error',
                      title: 'Un error ha ocurrido',
                      text: err.response.data.data,
                    });
                    this.xmlerror = err.response.data.data
                }else{
                    this.$swal({
                        icon: 'error',
                      title: 'Un error ha ocurrido'
                    });
                }
                this.spinLoadAddDocument = false
            })
        },

        async editar($accion = "editar", $documento_cde_id){
            this.spinLoadModifyDocument = true
            await axios.put('api/admin/CDE/editarDocumento',{
                despacho_id: this.$route.params.despachoId,
                accion: $accion,
                documento_cde_id: $documento_cde_id,
                new_filename: this.filename
            }).then(resp => {
                this.spinLoadModifyDocument = false
                this.showModalEditar = false
                this.showModalCerrar = false
                this.cerrarBussy = false
                this.filename = ''
                this.getDocumentsAdd()
                this.getAddedDocuments()
            }).catch(err => {
                this.spinLoadModifyDocument = false
                this.cerrarBussy = false
                this.filename = ''
                console.log(err)
            })
        },

        editarModal($documento_cde_id){
            this.showModalEditar = !this.showModalEditar
            this.documento_cde_id = $documento_cde_id
        },

        async modificar($accion = "borrar", $documento_cde_id){
            this.spinLoadModifyDocument = true
            await axios.put('api/admin/CDE/modificarCDE',{
                despacho_id: this.$route.params.despachoId,
                accion: $accion,
                documento_cde_id: $documento_cde_id
            }).then(resp => {
                this.spinLoadModifyDocument = false
                this.showModalCerrar = false
                this.cerrarBussy = false
                this.getDocumentsAdd()
                this.getAddedDocuments()
            }).catch(err => {
                this.spinLoadModifyDocument = false
                this.cerrarBussy = false
                console.log(err)
            })
        },

        async cerrar(){
            this.cerrarBussy = true

            await axios.post('api/admin/CDE/cerrarCDE',{
                despacho_id: this.$route.params.despachoId,
            }).then(resp => {
                this.showModalCerrar = false
                this.cerrarBussy = false
                this.getCdeInfo()
                this.getZipUrl()
                this.toggle1 = false
                this.toggle2 = false

                //this.zipPath = axios.defaults.baseURL + '/api/CDE?' + this.hash
            }).catch(err => {
                this.cerrarBussy = false
                console.log(err)
            })
        },
        async openCde(){
            this.abrirBussy = true
            await axios.put('api/admin/CDE/abrirCDE', {
                despacho_id: this.$route.params.despachoId
            }).then(resp => {
                this.showModalAbrir = false
                this.abrirBussy = false
                this.getCdeInfo()
            }).catch(err => {
                this.abrirBussy = false
                console.log("error", err)
            });
        },
        async xml(){
            this.spinLoad = true
            await axios.post('api/xmlTest',{
                despacho_id: this.$route.params.despachoId,
                contenido: "documento_digitalizado"
            }).then(resp => {
                this.spinLoad = false
                // this.cdeLink = resp.data
                const blob = new Blob([resp.data], {type: 'application/octet-stream'});
                this.cdeLink = window.URL.createObjectURL(blob)
                this.fileName = 'test.zip'
            }).catch(err => {
                this.spinLoad = false
                console.log("error", err)
            })
        }
    }
}
</script>

<style>

</style>