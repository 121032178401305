<template>
    <div>

        <b-form-group
            class="text-left"
            :content-cols="cols"
            label="Región / Comuna"
            label-for="region-select"
            invalid-feedback="Elija la Region"
        >
        <b-row>
            <b-col cols="6">
                <div class="form-group">
                    <select class="form-control border border-secondary" name="region" id="region-select" v-model="form.region" @change="regionChange($event)" >
                        <option value="0">Seleccione una Region</option>
                        <option v-for="(reg,index) in regions" :key="index" :value="reg.id">{{reg.nombre}}</option>
                    </select>
                </div>
            </b-col>
            <b-col cols="6">
                <div class="form-group" v-if="form.region != 0">
                    <select class="form-control border border-secondary" name="comuna" id="comuna" v-model="form.comuna_id">
                        <option disabled value="">Seleccione una Comuna</option>
                        <option v-for="(comun,index) in comunas" :key="index" :value="comun.id">{{comun.nombre}}</option>
                    </select>
                </div>
            </b-col>
        </b-row>
        </b-form-group>
        <b-form-group
            class="text-left"
            :content-cols="cols"
            label="Dirección"
            label-for="direccion-input"

            v-if="form.comuna_id"
        >
            <b-form-input id="direccion-input" v-model="form.address" min="3" max="190" required></b-form-input>
            <div class="text-left">
                <span id="address"></span>     
            </div>
        </b-form-group>
    </div>
</template>

<script>
import axios from 'axios'

export default {
    name:"InputRegion",
    props:{
        form: Object,
        user: Object,
        cols:Number,
    },
    data(){
        return{
            regions: [],
            comunas: []
        }
    },
    mounted(){
        this.getRegions(this.user);
    }
    ,
    methods:{
        async getRegions(item){
        await axios.get('/api/regions')
        .then(resp => {
          this.regions = resp.data.data
          this.actualizarRegiones(item);
        })
        .catch(err => {
          console.log(err)
        })
      },
      
      //AACTUAALIZAR 
      actualizarRegiones(item){
        if(item || this.form.comuna_id){
            this.regions.forEach((region) => {
                let existe = false;
                region.comunas.forEach((comuna)=>{ 
                    if(item){
                        if(comuna.id == item.comuna_id) existe = true;
                    }else{
                        if(comuna.id == this.form.comuna_id) existe = true;
                    }
                })
                if(existe){
                    this.form.region = region.id;
                    this.comunas = region.comunas;
                    if(item){
                        this.form.comuna_id =item.comuna_id;
                    }else{
                        this.form.comuna_id = this.form.comuna_id;
                    }
                    
                }
            })
            if(!this.form.region){
                this.form.region = 0
            }
        }else{
            this.form.region = 0;
        }
      },
      
        regionChange(event){
        this.regions.filter( (region, index) => {
            if (region.id == event.target.value) this.comunas = region.comunas;
        });
        this.form.comuna_id = "";
        },
    }
}
</script>

<style>

</style>