<template>
  <b-form class="form-input" @submit.prevent="submit">
    <b-row>
      <b-col cols="4" class="text-left">
        <label for="codigo-input" >N° Despacho
          <!-- AutoIncr -->
        <input
          class="ml-5"
          type="checkbox"
          id="checkbox"
          :disabled="disabledAI"
          v-model="AutoIncr"
        />
        <label class="ml-1 " for="checkbox">Auto Incremental</label>
        </label>
        <b-form-group
          class="text-left"
          label-for="codigo-input"
        >
          <b-form-input
            :state="limitLength() && limitHand()"
            type="number"
            id="codigo-input"
            v-model="form.codigo"
            :disabled="AutoIncr"
            :change="limitLength()"
            max= "485000"
            @keydown="valorGrande($event)"
            required
          >
          </b-form-input>
          <div class="text-left">
            <div v-if="form.codigo.length !== 0">
              <span class="error" v-if="!limitLength() && !AutoIncr"
                >El número de despacho debe ser de 6 digitos de largo</span
              >
            </div>
            <div v-if="form.codigo >= 485000">
              <span class="error" v-if="!limitHand()"
                >El número de despacho debe ser menor que 485000</span
              >
            </div>
            <span class="error" id="codigo"></span>
          </div>
        </b-form-group>
      </b-col>
     
      <!-- <b-col cols="4">
                <b-form-group
                    class="text-left"
                    label="Fecha Inicio" 
                    label-for="fecha_inicio-input" 
                >
                    <b-form-input id="fecha_inicio-input" type="date" v-model="form.fecha_inicio" required>    
                    </b-form-input>
                    <div class="text-left">
                        <span class="error" id="codigo"></span>     
                    </div>
                </b-form-group>
            </b-col> -->      
    </b-row>
    <b-row>
      <b-col cols="4">
        <b-form-group
          class="text-left"
          label="Despacho Nuevo/DAP"
          label-for="nuevo_dat_despacho-select"
        >
          <select
          class="border border-secondary"
            id="nuevo_dat_despacho-select"
            v-model="newOrDat"
            required
            @change="heredarDespacho(newOrDat)"
          >
            <option value="" disabled>Seleccione tipo de despacho</option>
            <option value="nuevo" selected>
              Nuevo despacho
            </option>
            <option
              value="dap"
            >
              DAP o parcial
            </option>
          </select>
        </b-form-group>
      </b-col>
      <b-col cols="4">
        <b-form-group
          v-if="newOrDat == 'dap'"
          class="text-left"
          label="Despachos"
          label-for="despachos-select"
        >
        <b-form-input 
            type="number"
            id="codigo_despacho"
            v-model="form.padre"
            placeholder="Ingrese un código de despacho"
        >

        </b-form-input>
          <!-- <Select2
          class="mi-select2"
            v-model="form.padre_id"
            :options="listaDespachos"
            :settings="{
              noResults: 'es',
              placeholder: 'Seleccione un Despacho',
              width: '100%',
              multiple: false,
            }"
          /> -->
        </b-form-group>
      </b-col>
    </b-row>
  <b-form-group
    v-if="newOrDat == 'nuevo'"
  >
    <b-row>
      <b-col cols="4">
        <b-form-group
          class="text-left"
          label="Referencia Cliente"
          label-for="ref_cliente-input"
        >
          <b-form-input
            type="text"
            id="ref_cliente-input"
            v-model="form.ref_cliente"
            v-validate.immediate="'required_if:form.padre_id,null'"
          >
          </b-form-input>
          <div class="text-left">
            <span class="error" id="ref_cliente"></span>
          </div>
        </b-form-group>
      </b-col>
    </b-row>
    <b-row>
      <b-col cols="4">
        <b-form-group
          v-if="listaUsuarios"
          class="text-left"
          label="Cliente"
          label-for="cliente-select"
        >
          <Select2
          class="mi-select2"
            v-model="form.clientes_id"
            :options="listaClientes"
            :settings="{
              noResults: 'es',
              placeholder: 'Seleccione un Cliente',
              width: '100%',
              multiple: false,
            }"
          />
        </b-form-group>
      </b-col>
    </b-row>
    <b-row>
      <b-col cols="4">
        <b-form-group
          v-if="listaUsuarios"
          class="text-left"
          label="Cod. Agente"
          label-for="cod_agente-select"
        >
          <select id="cod_agente-select" class="border border-secondary" v-model="form.cod_agente"
          v-validate.immediate="'required_if:form.padre_id,null'"
          >
            <option value="" disabled>Seleccione un Cod. Agente</option>
            <option value="G-02 Juan León Valenzuela">
              G-02 Juan León Valenzuela
            </option>
            <option value="C-25 Andrés León Cabrera">
              C-25 Andrés León Cabrera
            </option>
            <option value="C-74 Pablo León Cabrera">
              C-74 Pablo León Cabrera
            </option>
          </select>
        </b-form-group>
      </b-col>
    </b-row>
    <b-row>
      <b-col cols="4">
        <b-form-group
          v-if="listaUsuarios"
          class="text-left"
          label="Vistos Buenos"
          label-for="tramitador_servicio-select"
        >
          <b-form-checkbox
            id="checkbox-vistos_buenos"
            v-model="form.requiere_vistos_buenos"
            name="vistos_buenos"
            :value="true"
          >
            Requiere Documento Vistos Buenos
          </b-form-checkbox>
        </b-form-group>
      </b-col>
      <b-col cols="4" v-if="form.requiere_vistos_buenos">
        <b-form-group
          v-if="listaUsuarios"
          class="text-left"
          label="Tramitador de servicio"
          label-for="tramitador_servicio-select"
        >
          <select
            id="tramitador_servicio-select"
            class="border border-secondary"
            v-model="form.tramitador_servicios_id"
          >
            <option value="" disabled>
              Seleccione un Tramitador de Servicio
            </option>
            <option
              v-for="(usuario, index) in listaUsuarios.listaTramitadorServicio"
              :key="index"
              :value="usuario.id"
            >
              {{ usuario.name }}
            </option>
          </select>
        </b-form-group>
      </b-col>
    </b-row>
    <b-row>
      <b-col cols="4">
        <b-form-group
          class="text-left"
          label="Tipo de Despacho"
          label-for="tipo_despacho-select"
        >
          <select
          class="border border-secondary"
            id="tipo_despacho-select"
            v-model="form.tipo_despacho"
            v-validate.immediate="'required_if:form.padre_id,null'"
            @change="usuarios()"
          >
            <option value="" disabled>Seleccione el Tipo de Despacho</option>
            <option v-permisos="'gestor.view'" value="importacion">
              Importación
            </option>
            <option
              v-permisos="'pedidor_exportaciones.view'"
              value="exportacion"
            >
              Exportación
            </option>
          </select>
        </b-form-group>
      </b-col>
    </b-row>
    <b-row v-if="form.tipo_despacho == 'importacion'">
      <b-col cols="4">
        <b-form-group
          v-if="listaUsuarios"
          class="text-left"
          label="Pedidor"
          label-for="pedidor-select"
        >
          <select id="pedidor-select" class="border border-secondary" v-model="form.pedidor_id" 
          v-validate.immediate="'required_if:form.padre_id,null'"
          >
            <option value="" disabled>Seleccione un Pedidor</option>
            <option
              v-for="(usuario, index) in listaUsuarios.listaPedidor"
              :key="index"
              :value="usuario.id"
            >
              {{ usuario.name }}
            </option>
          </select>
        </b-form-group>
      </b-col>

      <b-col cols="4">
        <b-form-group
          v-if="listaUsuarios"
          class="text-left"
          label="Jefe de Operaciones"
          label-for="jefe_operaciones-select"
        >
          <select
          class="border border-secondary"
            id="jefe_operaciones-select"
            v-model="form.jefe_operaciones_id"
            v-validate.immediate="'required_if:form.padre_id,null'"
          >
            <option value="" disabled>Seleccione un Jefe de Operaciones</option>
            <option
              v-for="(usuario, index) in listaUsuarios.listaJefeOperaciones"
              :key="index"
              :value="usuario.id"
            >
              {{ usuario.name }}
            </option>
          </select>
        </b-form-group>
      </b-col>
      <b-col cols="4">
        <b-form-group
          v-if="listaUsuarios"
          class="text-left"
          label="Jefe de Facturación"
          label-for="jefe_facturacion-select"
        >
          <select 
          class="border border-secondary"
            id="jefe_facturacion-select"
            v-model="form.jefe_facturacion_id"
            v-validate.immediate="'required_if:form.padre_id,null'"
          >
            <option value="" disabled>Seleccione un Jefe de Facturación</option>
            <option
              v-for="(usuario, index) in listaUsuarios.listaJefeFacturacion"
              :key="index"
              :value="usuario.id"
            >
              {{ usuario.name }}
            </option>
          </select>
        </b-form-group>
      </b-col>
    </b-row>
    <b-row v-if="form.tipo_despacho == 'exportacion'">
      <b-col cols="4">
        <b-form-group
          v-if="listaUsuarios"
          class="text-left"
          label="Pedidor"
          label-for="pedidor-select"
        >
          <select id="pedidor-select" class="border border-secondary" v-model="form.pedidor_id" 
          v-validate.immediate="'required_if:form.padre_id,null'">
            <option value="" disabled>Seleccione un Pedidor</option>
            <option
              v-for="(usuario, index) in listaUsuarios.listaPedidorExportacion"
              :key="index"
              :value="usuario.id"
            >
              {{ usuario.name }}
            </option>
          </select>
        </b-form-group>
      </b-col>
      <b-col cols="4">
        <b-form-group
          v-if="listaUsuarios"
          class="text-left"
          label="Jefe de Operaciones"
          label-for="jefe_operaciones-select"
        >
          <select
          class="border border-secondary"
            id="jefe_operaciones-select"
            v-model="form.jefe_operaciones_id"
            v-validate.immediate="'required_if:form.padre_id,null'"
          >
            <option value="" disabled>Seleccione un Jefe de Operaciones</option>
            <option
              v-for="(usuario, index) in listaUsuarios.listaJefeOperaciones"
              :key="index"
              :value="usuario.id"
            >
              {{ usuario.name }}
            </option>
          </select>
        </b-form-group>
      </b-col>
      <b-col cols="4">
        <b-form-group
          v-if="listaUsuarios"
          class="text-left"
          label="Jefe de Facturación"
          label-for="jefe_facturacion-select"
        >
          <select
          class="border border-secondary"
            id="jefe_facturacion-select"
            v-model="form.jefe_facturacion_id"
            v-validate.immediate="'required_if:form.padre_id,null'"
          >
            <option value="" disabled>Seleccione un Jefe de Facturación</option>
            <option
              v-for="(usuario, index) in listaUsuarios.listaJefeFacturacion"
              :key="index"
              :value="usuario.id"
            >
              {{ usuario.name }}
            </option>
          </select>
        </b-form-group>
      </b-col>
      <!-- <b-col cols="4">
        <b-form-group
          v-if="listaUsuarios"
          class="text-left"
          label="Tramitador Aduanero"
          label-for="tramitador_aduanero-select"
        >
          <select
            id="tramitador_aduanero-select"
            v-model="form.tramitador_aduanero_id"
            v-validate.immediate="'required_if:form.padre_id,null'"
          >
            <option value="" disabled>Seleccione un Tramitador Aduanero</option>
            <option
              v-for="(usuario, index) in listaUsuarios.listaTramitadorAduanero"
              :key="index"
              :value="usuario.id"
            >
              {{ usuario.name }}
            </option>
          </select>
        </b-form-group>
      </b-col> -->
      <!-- <b-col cols="4">
        <b-form-group
          v-if="listaUsuarios"
          class="text-left"
          label="Presentador"
          label-for="presentador-select"
        >
          <select
            id="presentador-select"
            v-model="form.presentador_id"
            v-validate.immediate="'required_if:form.padre_id,null'"
          >
            <option value="" disabled>Seleccione un Presentador</option>
            <option
              v-for="(usuario, index) in listaUsuarios.listaPresentador"
              :key="index"
              :value="usuario.id"
            >
              {{ usuario.name }}
            </option>
          </select>
        </b-form-group>
      </b-col> -->
    </b-row>
  </b-form-group>
    <b-row>
      <b-col>
        <button
          class="btn-principal btn-large mr-2"
          type="submit"
          :disabled="!limitLength()"
        >
          Guardar
        </button>
        <button class="btn-secundario btn-large mr-2" @click="cancelar">
          Cancelar
        </button>
      </b-col>
    </b-row>
  </b-form>
</template>

<script>
import { mapGetters } from "vuex";
import { store } from "@/store";
import axios from "axios";

export default {
  props: {
    submit: Function,
    form: Object,
    listaUsuarios: Object,
    clientes: Array,
    despachos: Array,
    listaTipoImportacion: Array,
    listaTipoExportacion: Array,
    usuarios: Function,
    valorGrande: Function,
    // ultimo: Number,
  },
  data() {
    return {
      limite: false,
      listaExportacion: [],
      listaImportacion: [],
      listaClientes: [],
      listaDespachos: [],
      AutoIncr: false,
      disabledAI: false,
      newOrDat:'nuevo',
    };
  },
  computed: {
    ...mapGetters({
      permission: "auth/permission",
    }),
  },
  mounted() {
    this.getUltimoDespacho();
    this.listaExportacion = [];
    this.listaTipoExportacion.forEach((el) => {
      this.listaExportacion.push({ id: el.id, text: el.tipo_operacion });
    });

    this.listaImportacion = [];
    this.listaTipoImportacion.forEach((el) => {
      this.listaImportacion.push({ id: el.id, text: el.tipo_operacion });
    });
    this.listaClientes = [];
    this.clientes.forEach((el) => {
      //console.log(el);
      this.listaClientes.push({ id: el.id, text: el.nombre });
    });
    this.listaDespachos = [];
    this.despachos.forEach((el) => {
      this.listaDespachos.push({ id: el.id, text: el.codigo });
    });

    // if (this.ultimo > 480000) {
    //   this.form.codigo = this.ultimo + 1;
    // }
    this.setValueCodigo();
    this.form.autoincremental = this.AutoIncr

    // this.listaUsuarios.listaJefeFacturacion.forEach(e => {
    //     console.log("e", e)
    //     if (e.name === "Katherine Roa Gonzalez"){
    //         this.form.jefe_facturacion_id = e.id

    //     }
    //     if (e.id === 28){ // verifica si existe el id 28 Katherine Roa
    //         this.form.jefe_facturacion_id = e.id // asigna esa id como seleccionada por defecto si es que existe
    //         return
    //     }
    // })
  },
  methods: {
    setValueCodigo() {
      if (this.ultimo >= 485000) {
        this.form.codigo = this.ultimo + 1;
        this.AutoIncr = true;
        this.disabledAI = true;
        this.limitLength();
      }
    },

    async getUltimoDespacho() {
      await axios
        .get("api/admin/despachos/last")
        .then((resp) => {
          //console.log(resp.data.ultimo);
          this.ultimo = resp.data.ultimo?.codigo;
          if(!this.ultimo){
            this.ultimo = 484999;
          }

        })
        .catch((err) => console.log(err));
    },

    limitLength() {
      if (this.form.codigo.length === 0) {
        return null;
      }
      //si codigos son iguales true
      if (
        this.form.codigo == this.ultimo ||
        this.form.codigo == this.ultimo + 1
      ) {
        return true;
      } else if (this.form.codigo.length === 6) {
        return true;
      }

      return false;
    },
  
    limitHand() {
      if (this.form.codigo >= 485000 && this.AutoIncr ) {
        return true;
      }
      if (this.form.codigo >= 485000 && !this.AutoIncr) {
        //si es mayor a...  y es distinto de los valores de ultimo o +1
        return false;
      }
    },

    limitUltimo() {
      if (this.form.codigo >= 485000) {
        return true;
      }
      return false;
    },

    heredarDespacho(newOrDat) {
      //console.log(newOrDat);
      if(newOrDat == 'nuevo') {
        this.form.padre_id = '';
      } else {
        this.form.ref_cliente = '';
        this.form.cod_agente = '';
        this.form.tipo_despacho = '';
        this.form.pedidor_id = '';
        this.form.jefe_operaciones_id = '';
        this.form.jefe_facturacion_id = '';
        this.form.tramitador_aduanero_id = '';
        this.form.pedidor_id = '';
        this.form.presentador_id = '';

      }
    },

    myChangeEvent(val) {
      console.log(val);
    },
    mySelectEvent({ id, text }) {
      console.log({ id, text });
    },
    cancelar() {
      this.$router.replace({ name: "Despachos" });
    },
  },
  watch: {
    ultimo(newValue, oldValue) {
      if (this.ultimo >= 485000) {
        this.AutoIncr = true;
        this.form.autoincremental = this.AutoIncr;
        this.form.codigo = parseInt(this.ultimo + 1);
      }
    },

    AutoIncr(newValue, oldValue) {
      this.form.autoincremental = this.AutoIncr;
      this.getUltimoDespacho();
      this.form.codigo = parseInt(this.ultimo + 1);
    },
  },
};
</script>

<style>
</style>
