<template>

    <div class="total-despachos">
        <b-row class="informacion-despacho">
            <b-col cols="3" class="datos icono text-right">
                <inline-svg class="icono-dashboard" src="../../assets/images/containersdashboard.svg" title="Despachos"/>
            </b-col>
            <b-col cols="4" class="datos texto text-left">
                Total Despachos
            </b-col>
            <b-col cols="5" class="datos total">
                {{total_despachos}}
            </b-col>
        </b-row>
    </div>
</template>



<script>
export default {
    name:"Card",
    props:{
        total_despachos:Number,
    }
}
</script>

<style>
    .section-card{
        border-radius: 10px;
    }
    .total-despachos{
        border-radius: 10px;
        margin-top: 30px;
        background: #5699F4;
        color: #FFFFFF;

        padding-top: 20px ;
        padding-bottom: 30px;

        box-shadow: 0px 0px 10px #0000001a;

        cursor:pointer;
    }


    .total-despachos .informacion-despacho .datos{
        align-self: center;
    }

    .total-despachos .informacion-despacho .texto{
        font-size: 20px;
        font-weight: 600;
    }
    .total-despachos .informacion-despacho .total{
        font-size: 40px;
        font-weight: 900;
    }


    



   
        


</style>
