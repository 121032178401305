

export default {
  namespaced: true,

  state: {
    authenticated: false,
    user: null,
    permission: null
  },

  getters: {
    authenticated (state) {
      return state.authenticated
    },

    user (state) {
      return state.user
    },

    permission (state) {
      return state.permission
    },
  },

  mutations: {
    SET_AUTHENTICATED (state, value) {
      state.authenticated = value
    },

    SET_USER (state, value) {
        state.user = value
    },
    SET_PERMISSION (state, value) {
      state.permission = value;
    }
  },

  actions: {
    async signIn ({ dispatch }, credentials) {
        
      await this.$axios.get('/api/csrf-cookie')
      await this.$axios.post('login', credentials).then(()=>{
        localStorage.setItem('sw',true)
      })
      await dispatch('getUser');
    },

    signOut ({  commit }) {
      
      commit('SET_AUTHENTICATED', false)
      commit('SET_USER', null)
      commit('SET_PERMISSION', null)
    },

    async signInGoogle({ dispatch }){
      await dispatch('getUser');
    },

    async getUser ({ commit, dispatch }) { 
      await this.$axios.get('/api/me').then((response) => {
        commit('SET_AUTHENTICATED', true)
        commit('SET_USER', response.data.user)
      }).catch(() => {
        commit('SET_AUTHENTICATED', false)
        commit('SET_USER', null)
      })
      await dispatch('getPermissions');

      
    },

    async getPermissions({commit}){
      await this.$axios.get('/api/users/permission-list').then((response) => {
        commit('SET_PERMISSION', response.data)       
      }).catch(() => {
        commit('SET_PERMISSION', null)
      })
    },

    async recuperar ({ dispatch }, credentials) {
      await this.$axios.post('/api/forgot-password', credentials)
      
    },
    async reset ({ dispatch }, credentials) {
      await this.$axios.post('/api/reset-password', credentials)
      
    },

  

    
  }
}