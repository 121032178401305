import Vue from 'vue'
import Vuex from 'vuex'
import createPersistedState from "vuex-persistedstate";
import auth from './auth'
import filtros from './filtros'
import nav from './nav'

import axios from 'axios';

axios.defaults.withCredentials = true
axios.defaults.baseURL = process.env.VUE_APP_API_URL

Vue.use(Vuex)

const store = new Vuex.Store({
  modules: {
    auth,
    filtros,
    nav,
  },
  plugins: [createPersistedState()],
})

const $axios = axios.create();

store.$axios = $axios

export default store;


