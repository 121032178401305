<template>

    <b-tabs content-class="mt-3" class="tabs-secciones" >
        <b-tab title="Asignar Usuarios" @click="cambiarSeccion('usuarios')">
            <template #title>
                <div class="title-tab">

                    <inline-svg  class="icono-titulo" src="../../assets/images/usuarios.svg" title="My Image"/>
                    <p class="titulo text-center">Asignar Usuarios</p>
                </div>
            </template>
            <usuarios
                v-if="listaUsuarios && secciones.usuarios"
                :agregarUsuario="true"
                :usuarios="usuariosPorRol"
                :listaUsuarios="listaUsuarios"
                :tiposUsuarios="tipoUsuariosPorRol"
                :changeForm="changeForm"
                :submit="submit"

                :addUser="addUser"
                :deleteUser="deleteUser"
            >
                
            </usuarios>
        </b-tab>
        <b-tab title="Documentación" @click="cambiarSeccion('documentos')">
            <template #title>
                <div class="title-tab">
                    <inline-svg  class="icono-titulo" src="../../assets/images/documento.svg" title="My Image"/>
                    <p class=" titulo text-center">Documentación</p>
                </div>
            </template>
            <documentos-validar                
            >

            </documentos-validar>

        </b-tab>
        <b-tab title="Pagos" @click="cambiarSeccion('pagos')">
                <template #title>
                    <div class="title-tab">
                        <inline-svg  class="icono-titulo" src="../../assets/images/dollar.svg" title="My Image"/>
                        <p class="titulo text-center" > Pagos</p>
                    </div>
                </template>
                <pagos
                        v-if="secciones.pagos"
                        :despacho="despacho"
                        :agregarPago="true"
                        :postPago="postPago"
                        :updatePago="updatePago"
                        :pagosTabla="pagosTabla"
                        :eliminarPago="eliminarPago"

                    >

                    </pagos>

            </b-tab>
    </b-tabs>
    
  
</template>

<script>
import axios from 'axios'
import { mapActions } from 'vuex';
import DocumentosValidar from '../Componentes/DocumentosValidar.vue';
import Usuarios from '../Componentes/Usuarios.vue';
import Pagos from '../Componentes/Pagos.vue';
export default {
  components: {DocumentosValidar, Usuarios, Pagos },
    
    props:{
        despacho:Object,
        listaUsuarios: Object,

        submit: Function,
        changeForm:Function,
        changeValue:Function,
        estados:Object,

        //PAGOS
        pagos: Object,
        pagosTabla:Array,
        postPago: Function,
        updatePago: Function,

        changeState:Function,
        usuarios:Array,
        addUser:Function,
        deleteUser: Function,


    },
    
    data(){
        return{
            
            usuariosPorRol: [],
            tipoUsuariosPorRol: [
                {text:'Presentador', value:'prestador'},
            ],

            secciones: 
            {
                documentos: true,
                usuarios: true,
                pagos: true,
            },


            
        }
    },
    mounted(){
       this.usuariosPorRol = []
       if(this.usuarios !== undefined){
           this.usuarios.forEach(el => {
               if(el.role.name == 'prestador') this.usuariosPorRol.push(el)
           })
       }
    },
      watch:{
        usuarios:function(newValue, oldValue){
            if(this.usuarios){
                this.usuariosPorRol = []
                this.usuarios.foreach(el => {
                    if(el.role.name == 'prestador') this.usuariosPorRol.push(el)
                })
            }
        },
    },

    methods:{
       
        //secciones
    cambiarSeccion(seccion){
      Object.keys(this.secciones).forEach(key => {

        if(key == seccion)this.secciones[key] = true;
        else this.secciones[key] = false
      });
    }
    

    }
}
</script>

<style>

</style>