<template>

    <div class="estatus-despachos">
        <div class="text-right mr-4">
      <slot name="ver"></slot>
    </div>
        <h5>{{titulo}}</h5>
        <p v-bind:style="{color:color}"> {{cantidad}}</p>
    </div>
</template>



<script>
export default {
    props:{
        titulo:String,
        cantidad:Number,
        color:String,
    }
}
</script>

<style>
    .estatus-despachos{
        border-radius: 10px;
        margin-top: 30px;
        background: #ffffff;
        color: #043161;
        padding-top: 20px ;
        padding-bottom: 30px;

        box-shadow: 0px 0px 10px #0000001a
    }

    .estatus-despachos h5{
        font-size: 16px;
        font-weight: 600;
    }
    .estatus-despachos p{
        font-size: 60px;
        font-weight: bold;
    }

    @media only screen and (max-width: 600px) {
        .estatus-despachos h5{
        font-size: 12px;
        font-weight: 600;
    }
    .estatus-despachos p{
        font-size: 40px;
        font-weight: bold;
    }
    }





    



   
        


</style>
