<template>
<b-row>
    <b-col cols="12 informacion">
        <!--REFERENCIA CLIENTE --> 
        <b-row v-if="despacho.ref_cliente && estados.ref_cliente">
            <b-col cols="4" class="text-left dato">
                <p>Referencia Cliente</p>
            </b-col>
            <b-col 
                cols="4" class="text-right dato despacho" 
            >
                <p v-if="despacho.ref_cliente">{{despacho.ref_cliente}}</p> 
                <p v-else>-</p> 
                
            </b-col>
            <b-col
                cols="4" class="text-right dato despacho" 
            >
                <b-button
                v-if="pantalla == true"
                    class="btn-principal btn-small"
                    @click="editar('pedidor','ref_cliente')"
                >
                    Editar <inline-svg  class="icono-titulo" src="../../assets/images/Editar.svg" title="My Image"/>
                </b-button>
                <b-button
                v-if="pantalla == false"
                    @click="editar('pedidor','ref_cliente')"
                    class="btn-principal-chico"
                >
                    <inline-svg  class="icono-titulo" src="../../assets/images/EditarAzul.svg" title="My Image"/>
                </b-button>
            </b-col>
            
        </b-row>
        <b-row v-else>
            <b-col 
                cols="12" class="text-right dato despacho" 
            >
                <form class="form-input">
                    <b-row>
                        <b-col sm="12" md="12" lg="6">
                            <b-form-group
                                class="text-left"
                                label="Referencia Cliente"
                                label-for="refCliente-select"
                            >
                                <b-input v-model="ref_cliente" :placeholder="despacho.ref_cliente" required>
                                </b-input>
                            </b-form-group>
                        </b-col>
                        <b-col sm="12" md="12" lg="6">
                            <b-button
                                class="btn-extra-primario btn-small"
                                type="button"
                                @click="editar('pedidor','ref_cliente',true,{'ref_cliente': ref_cliente})"
                            >
                                Guardar
                            </b-button>
                            <b-button
                                class="btn-extra-secundario btn-small"
                                type="button"
                                @click="editar('pedidor','ref_cliente')"
                            >
                                Cancelar
                            </b-button>
                        </b-col>
                    </b-row>
                </form>
            </b-col>
        </b-row>

        <b-row v-if="despacho.tipo.nombre_transporte && estados.nombre_transporte">
            <b-col cols="4" class="text-left dato">
                <p>Medio de Transporte</p>
            </b-col>
            <b-col cols="4" class="text-right dato despacho" >
                <p v-if="despacho.tipo.nombre_transporte">{{despacho.tipo.nombre_transporte}} - {{despacho.tipo.numero_manifiesto}}</p> 
                <p v-else>-</p> 
            </b-col>
            <b-col cols="4" class="text-right dato despacho" >
                <b-button
                v-if="pantalla == true"
                    @click="editar('pedidor','nombre_transporte')"
                    class="btn-principal btn-small"
                >
                    Editar <inline-svg  class="icono-titulo" src="../../assets/images/Editar.svg" title="My Image"/>
                </b-button>
                <b-button
                v-if="pantalla == false"
                    @click="editar('pedidor','nombre_transporte')"
                    class="btn-principal-chico"
                >
                    <inline-svg  class="icono-titulo" src="../../assets/images/EditarAzul.svg" title="My Image"/>
                </b-button>
            </b-col>
            <b-col v-if="despacho.tipo.nombre_nave !== null" cols="4" class="text-left dato">
                <p>Nombre de Nave</p>
            </b-col>
            <b-col v-if="despacho.tipo.nombre_nave !== null" cols="4" class="text-right dato despacho" >
                <p>{{despacho.tipo.nombre_nave}}</p>
            </b-col>
        </b-row>
        <b-row v-else>
            <b-col 
                cols="12" class="text-right dato despacho" 
            >
                <form class="form-input">
                    <b-row>
                        <b-col sm="12" md="12" lg="4">
                            <b-form-group
                                class="text-left"
                                label="Medio de Transporte"
                                label-for="importancia-select"
                            >
                                <select class="form-control border border-secondary border border-secondary"  id="importancia-select" v-model="nombre_transporte"  required>
                                    <option value="0" disabled>Selecciona el Medio de Transporte</option>
                                    <option :key="index" v-for="(nombreTrasporte,index) in informacionGeneral.nombre_transporte" :value="nombreTrasporte" >{{nombreTrasporte}}</option>
                                    
                                </select>
                            </b-form-group>
                        </b-col>
                        <b-col sm="12" md="12" lg="4">
                            <b-form-group
                                class="text-left"
                                label-for="numero_manifiesto-select"
                            >
                            <template slot="label">Número Manifiesto
                                <fas id="icon" icon="exclamation-circle"></fas>
                                <b-tooltip target="icon" placement="top">
                                    De no contar con Número de Manifiesto, ingresar <strong>'0000'</strong>
                                </b-tooltip>
                            </template>
                            <b-input type="text" v-model="numero_manifiesto" required>

                            </b-input>
                            </b-form-group>
                        </b-col>
                        <b-col sm="12" md="12" lg="4">
                            <b-button
                                class="btn-extra-primario btn-small"
                                type="button"
                                @click="editar('pedidor','nombre_transporte', true,{'nombre_transporte': nombre_transporte,'numero_manifiesto': numero_manifiesto, 'nombre_nave': nombre_nave })"
                            >
                                Guardar
                            </b-button>
                            <b-button
                                class="btn-extra-secundario btn-small"
                                type="button"
                                @click="editar('pedidor','nombre_transporte')"
                            >
                                Cancelar
                            </b-button>
                        </b-col>
                        <b-col v-if="nombre_transporte === 'MARÍTIMO'" sm="12" md="12" lg="4">
                            <b-form-group
                                class="text-left"
                                label-for="numero_manifiesto-select"
                            >
                            <template slot="label">Nombre de Nave
                                <!-- <fas id="icon" icon="exclamation-circle"></fas> -->
                                <!-- <b-tooltip target="icon" placement="top">
                                    De no contar con Número de Manifiesto, ingresar <strong>'0000'</strong>
                                </b-tooltip> -->
                            </template>
                            <b-input type="text" v-model="nombre_nave">

                            </b-input>
                            </b-form-group>
                        </b-col>
                    </b-row>
                </form>
            </b-col>
        </b-row>
        <b-row v-if="!editar_visto_bueno">
            <b-col cols="4" class="text-left dato">
                <p>Requiere Vistos Buenos</p>
            </b-col>
            <b-col cols="4" class="text-right dato despacho" >
                <p v-if="despacho.requiere_vistos_buenos">Sí</p> 
                <p v-else>No</p> 
            </b-col>
            <b-col cols="4" class="text-right dato despacho" >
                <b-button
                v-if="pantalla == true"
                    @click="editar_visto_bueno_btn()"
                    class="btn-principal btn-small"
                >
                    Editar <inline-svg  class="icono-titulo" src="../../assets/images/Editar.svg" title="My Image"/>
                </b-button>
                <b-button
                v-if="pantalla == false"
                    @click="editar_visto_bueno_btn()"
                    class="btn-principal-chico"
                >
                    <inline-svg  class="icono-titulo" src="../../assets/images/EditarAzul.svg" title="My Image"/>
                </b-button>
            </b-col>
        </b-row>
        <b-row v-else>
            <b-col cols="12" class="text-right dato despacho">
                <form class="form-input">
                    <b-row>
                        <b-col sm="12" md="12" lg="4">
                            <b-form-group
                                class="text-left"
                                label="Requiere vistos buenos"
                                label-for="requiere_visto_bueno-select"
                            >
                                <select class="form-control border border-secondary"  id="requiere_visto_bueno-select" v-model="visto_bueno"  required>
                                    <option value="0" >No</option>
                                    <option value="1" >Sí</option>
                                </select>
                            </b-form-group>
                        </b-col>
                        <b-col sm="12" md="12" lg="4">
                                <b-button
                                    class="btn-extra-primario btn-small"
                                    type="button"
                                    @click="editar('pedidor','requiere_vistos_buenos', true,{'requiere_vistos_buenos': visto_bueno })"
                                >
                                    Guardar
                                </b-button>
                                <b-button 
                                    class="btn-extra-secundario btn-small"
                                    type="button"
                                    @click="editar_visto_bueno_btn()"
                                >
                                    Cancelar
                                </b-button>
                            </b-col>
                    </b-row>
                </form>
            </b-col>
        </b-row>
        <b-row v-if="despacho.tipo.tipo_carga && estados.tipo_carga">
            <b-col cols="4" class="text-left dato">
                <p>Tipo de Carga</p>
            </b-col>
            <b-col cols="4" class="text-right dato despacho" >
                <p v-if="despacho.tipo.tipo_carga">{{despacho.tipo.tipo_carga}}</p> 
                <p v-else>-</p> 
            </b-col>
            <b-col cols="4" class="text-right dato despacho" >
                <b-button
                v-if="pantalla == true"
                    @click="editar('pedidor','tipo_carga')"
                    class="btn-principal btn-small"
                >
                    Editar <inline-svg  class="icono-titulo" src="../../assets/images/Editar.svg" title="My Image"/>
                </b-button>
                <b-button
                v-if="pantalla == false"
                    @click="editar('pedidor','tipo_carga')"
                    class="btn-principal-chico"
                >
                    <inline-svg  class="icono-titulo" src="../../assets/images/EditarAzul.svg" title="My Image"/>
                </b-button>
            </b-col>
        </b-row>
        <b-row v-else>
            <b-col 
                cols="12" class="text-right dato despacho" 
            >
                <form class="form-input">
                    <b-row>
                        <b-col sm="12" md="12" lg="4">
                            <b-form-group
                                class="text-left"
                                label="Tipo de Carga"
                                label-for="importancia-select"
                            >
                                <select class="form-control border border-secondary"  id="importancia-select" v-model="tipo_carga"  required>
                                    <option value="0" disabled>Selecciona el Tipo de Carga</option>
                                    <option :key="index" v-for="(tipo_carga,index) in informacionGeneral.tipo_carga" :value="tipo_carga" >{{tipo_carga}}</option>
                                    
                                </select>
                            </b-form-group>
                        </b-col>
                        <b-col sm="12" md="12" lg="4">
                            <b-button
                                class="btn-extra-primario btn-small"
                                type="button"
                                @click="editar('pedidor','tipo_carga', true,{'tipo_carga': tipo_carga })"
                            >
                                Guardar
                            </b-button>
                            <b-button
                                class="btn-extra-secundario btn-small"
                                type="button"
                                @click="editar('pedidor','tipo_carga')"
                            >
                                Cancelar
                            </b-button>
                        </b-col>
                    </b-row>
                </form>
                </b-col>
            </b-row>
        <b-row v-if="despacho.tipo.caracteristicas_carga && estados.caracteristicas_carga">
            <b-col cols="4" class="text-left dato">
                <p>Características Carga</p>
            </b-col>
            <b-col cols="4" class="text-right dato despacho" >
                <p v-if="despacho.tipo.caracteristicas_carga">{{despacho.tipo.caracteristicas_carga}}</p> 
                <p v-else>-</p> 
            </b-col>
            <b-col cols="4" class="text-right dato despacho" >
                <b-button
                 v-if="pantalla == true"
                    @click="editar('pedidor','caracteristicas_carga')"
                    class="btn-principal btn-small"
                >
                    Editar <inline-svg  class="icono-titulo" src="../../assets/images/Editar.svg" title="My Image"/>
                </b-button>
                <b-button
                v-if="pantalla == false"
                    @click="editar('pedidor','caracteristicas_carga')"
                    class="btn-principal-chico"
                >
                    <inline-svg  class="icono-titulo" src="../../assets/images/EditarAzul.svg" title="My Image"/>
                </b-button>
            </b-col>
        </b-row>
        <b-row v-else>
            <b-col 
                cols="12" class="text-right dato despacho" 
            >
                <form class="form-input">
                    <b-row>
                        <b-col sm="12" md="12" lg="4">
                            <b-form-group
                                class="text-left"
                                label="Características Carga"
                                label-for="importancia-select"
                            >
                                <select class="form-control border border-secondary"  id="importancia-select" v-model="caracteristicas_carga"  required>
                                    <option value="0" disabled>Selecciona Característica Carga</option>
                                    <option :key="index" v-for="(caracteristicas_carga,index) in informacionGeneral.caracteristicas_carga" :value="caracteristicas_carga" >{{caracteristicas_carga}}</option>
                                    
                                </select>
                            </b-form-group>
                        </b-col>
                        <b-col sm="12" md="12" lg="4">
                            <b-button
                                class="btn-extra-primario btn-small"
                                type="button"
                                @click="editar('pedidor','caracteristicas_carga', true,{'caracteristicas_carga': caracteristicas_carga })"
                            >
                                Guardar
                            </b-button>
                            <b-button
                                class="btn-extra-secundario btn-small"
                                type="button"
                                @click="editar('pedidor','caracteristicas_carga')"
                            >
                                Cancelar
                            </b-button>
                        </b-col>
                    </b-row>
                </form>
            </b-col>
        </b-row>
        <b-row v-if="despacho.tipo.aduana && estados.aduana">
            <b-col cols="4" class="text-left dato">
                <p>Aduana</p>
            </b-col>
            <b-col cols="4" class="text-right dato despacho">
                <p>{{despacho.tipo.aduana.nombre}}</p> 
            </b-col>
            <b-col cols="4" class="text-right dato despacho">
                <b-button
                v-if="pantalla == true"
                    @click="editar('pedidor','aduana')"
                    class="btn-principal btn-small"
                >
                    Editar <inline-svg  class="icono-titulo" src="../../assets/images/Editar.svg" title="My Image"/>
                </b-button>
                <b-button
                v-if="pantalla == false"
                    @click="editar('pedidor','aduana')"
                    class="btn-principal-chico"
                >
                    <inline-svg  class="icono-titulo" src="../../assets/images/EditarAzul.svg" title="My Image"/>
                </b-button>
            </b-col>
        </b-row>
        <b-row v-else>
             <b-col 
                cols="12" class="text-right dato despacho" 
            >
                <form class="form-input">
                    <b-row>
                        <b-col sm="12" md="12" lg="6">
                            <b-form-group
                                class="text-left"
                                label="Aduana"
                                label-for="importancia-select"
                            >
                                <select class="form-control border border-secondary"  id="importancia-select" v-model="aduana_id"  required>
                                    <option value="0" disabled>Selecciona una Aduana</option>
                                    <option :key="index" v-for="(aduana,index) in informacionGeneral.aduana" :value="aduana.id" >{{aduana.nombre}}</option>
                                    
                                </select>
                            </b-form-group>
                        </b-col>
                        <b-col sm="12" md="12" lg="6">
                            <b-button
                                class="btn-extra-primario btn-small"
                                type="button"
                                @click="editar('pedidor','aduana', true,{'aduanas_id': aduana_id })"
                            >
                                Guardar
                            </b-button>
                            <b-button
                                class="btn-extra-secundario btn-small"
                                type="button"
                                @click="editar('pedidor','aduana')"
                            >
                                Cancelar
                            </b-button>
                        </b-col>
                    </b-row>
                </form>
            </b-col>
        </b-row>
        <b-row v-if="despacho.tipo.fecha_eta && estados.fecha_eta">
            <b-col cols="4" class="text-left dato">
                <p>Fecha Eta</p>
            </b-col>
            <b-col cols="4" class="text-right dato despacho">
                <p v-if="despacho.tipo.fecha_eta">{{moment(despacho.tipo.fecha_eta).format('DD-MM-YYYY')}}</p> 
                <p v-else>-</p> 
            </b-col>
            <b-col cols="4" class="text-right dato despacho">
                <b-button
                v-if="pantalla == true"
                    @click="editar('pedidor','fecha_eta')"
                    class="btn-principal btn-small"
                >
                    Editar <inline-svg  class="icono-titulo" src="../../assets/images/Editar.svg" title="My Image"/>
                </b-button>
                <b-button
                v-if="pantalla == false"
                    @click="editar('pedidor','fecha_eta')"
                    class="btn-principal-chico"
                >
                    <inline-svg  class="icono-titulo" src="../../assets/images/EditarAzul.svg" title="My Image"/>
                </b-button>
            </b-col>
        </b-row>
        <b-row v-else> 
             <b-col cols="12" class="text-right dato despacho" >
                <form class="form-input">
                    <b-row>
                        <b-col sm="12" md="12" lg="6">
                            <b-form-group
                                class="text-left"
                                label="Fecha ETA"
                                label-for="importancia-select"
                            >
                            <b-input type="date" v-model="fecha_eta" required>

                            </b-input>
                            </b-form-group>
                        </b-col>
                        <b-col sm="12" md="12" lg="6">
                            <b-button
                                class="btn-extra-primario btn-small"
                                type="button"
                                @click="editar('pedidor','fecha_eta', true,{'fecha_eta': fecha_eta })"
                            >
                                Guardar
                            </b-button>
                            <b-button
                                class="btn-extra-secundario btn-small"
                                type="button"
                                @click="editar('pedidor','fecha_eta')"
                            >
                                Cancelar
                            </b-button>
                        </b-col>
                    </b-row>
                </form>
            </b-col>
        </b-row>

        <b-row v-if="despacho.transporte && estados.transporte" hidden>
            <b-col cols="4" class="text-left dato">
                <p>Transporte</p>
            </b-col>
            <b-col cols="4" class="text-right dato despacho">
                <p v-if="despacho.transporte">{{despacho.transporte}} - {{despacho.correo_transporte}}</p> 
                <p v-else>-</p> 
            </b-col>
            <b-col cols="4" class="text-right dato despacho">
                <b-button
                v-if="pantalla == true"
                    @click="editar('pedidor','transporte')"
                    class="btn-principal btn-small"
                >
                    Editar <inline-svg  class="icono-titulo" src="../../assets/images/Editar.svg" title="My Image"/>
                </b-button>
                <b-button
                v-if="pantalla == false"
                    @click="editar('pedidor','transporte')"
                    class="btn-principal-chico"
                >
                    <inline-svg  class="icono-titulo" src="../../assets/images/EditarAzul.svg" title="My Image"/>
                </b-button>
            </b-col>
        </b-row>
        <b-row v-else hidden>
            <b-col cols="12" class="text-right dato despacho" >
                <form class="form-input">
                    <b-row>
                        <b-col sm="12" md="12" lg="4">
                            <b-form-group
                                class="text-left"
                                label="Nombre de Transporte Local (Chile)"
                                label-for="transporte-select"
                            >
                            <b-input type="text" v-model="transporte" required>

                            </b-input>
                            </b-form-group>
                        </b-col>
                        <b-col sm="12" md="12" lg="4">
                            <b-form-group
                                class="text-left"
                                label="Correo de Transporte Local"
                                label-for="correo_transporte-select"
                            >
                                <b-input type="email" v-model="correo_transporte" required>

                                </b-input>
                            </b-form-group>
                        </b-col>
                        
                        <b-col sm="12" md="12" lg="4">
                            <b-button
                                class="btn-extra-primario btn-small"
                                type="button"
                                @click="editar('pedidor','transporte', true,{'transporte': transporte, 'correo_transporte': correo_transporte })"
                            >
                                Guardar
                            </b-button>
                            <b-button
                                class="btn-extra-secundario btn-small"
                                type="button"
                                @click="editar('pedidor','transporte')"
                            >
                                Cancelar
                            </b-button>
                        </b-col>
                    </b-row>
                </form>
            </b-col>
        </b-row>

    </b-col>
    <b-col cols="12 informacion" v-if="despacho.tipo.nombre_transporte == 'MARÍTIMO'" hidden>
        
        <b-row v-if="despacho.lugar_bl && estados.lugar_bl">
            <b-col cols="4" class="text-left dato">
                <p>Lugar Bl</p>
            </b-col>
            <b-col cols="4" class="text-right dato despacho" >
                <p v-if="despacho.lugar_bl">{{despacho.lugar_bl}} </p> 
                <p v-else>-</p> 
            </b-col>
            <b-col cols="4" class="text-right dato despacho" >
                <b-button
                v-if="pantalla == true"
                    @click="editar('pedidor','lugar_bl')"
                    class="btn-principal btn-small"
                >
                    Editar <inline-svg  class="icono-titulo" src="../../assets/images/Editar.svg" title="My Image"/>
                </b-button>
                <b-button
                v-if="pantalla == false"
                    @click="editar('pedidor','lugar_bl')"
                    class="btn-principal-chico"
                >
                    <inline-svg  class="icono-titulo" src="../../assets/images/EditarAzul.svg" title="My Image"/>
                </b-button>
            </b-col>
        </b-row>
      
        <b-row v-else>
            <b-col 
                cols="12" class="text-right dato despacho" 
            >
                <form class="form-input">
                    <b-row>
                        <b-col sm="12" md="12" lg="6">
                            <b-form-group
                                class="text-left"
                                label="Lugar Bl"
                                label-for="lugarbl-select"
                            >
                                <select class="form-control border border-secondary"  id="lugarbl-select" v-model="lugar_bl"  required>
                                    <option value="0" disabled>Selecciona el Lugar Bl</option>
                                    <option :key="index" v-for="(lugarBl,index) in informacionGeneral.lugar_bl" :value="lugarBl" >{{lugarBl}}</option>
                                    
                                </select>
                            </b-form-group>
                        </b-col>
                        <b-col sm="12" md="12" lg="6">
                            <b-button
                                class="btn-extra-primario btn-small"
                                type="button"
                                @click="editar('pedidor','lugar_bl', true,{'lugar_bl': lugar_bl})"
                            >
                                Guardar
                            </b-button>
                            <b-button
                                class="btn-extra-secundario btn-small"
                                type="button"
                                @click="editar('pedidor','lugar_bl')"
                            >
                                Cancelar
                            </b-button>
                        </b-col>
                    </b-row>
                </form>
            </b-col>
        </b-row>
    </b-col>  

    
    
    
    

</b-row>
</template>

<script>
import moment from 'moment';
import InputRut from '../InputRut.vue';
import InputRegion from '../InputRegion.vue';
export default {
    components: { InputRut, InputRegion },
    name:"FormUsuario",

    props: {
        despacho:Object,
        postInformacionGeneral:Function,
        informacionGeneral:Object,
        editar:Function,
        estados:Object

    },
    data(){
        return{
            nombre: '',
            nombre_transporte: '',
            numero_manifiesto: '',
            nombre_nave: '',
            cliente_id: 0,
            fecha_eta: '',
            aduana_id: '',
            tipo_carga: '',
            caracteristicas_carga: '',
            transporte: '',
            correo_transporte: '',
            lugar_bl: '',
            pantalla:false,
            visto_bueno: 0,
            editar_visto_bueno: false,
            ref_cliente: ''
        }
    },
    mounted(){
        if (screen.width <= 660) this.pantalla = false
        else this.pantalla = true
        this.visto_bueno = this.despacho.requiere_vistos_buenos
    },
    methods:{
        editar_visto_bueno_btn(){
            this.editar_visto_bueno = !this.editar_visto_bueno
        },
      cambiarRut(value){
        this.cliente_rut = value;
      },
      cambiarRutTransporte(value){
        this.transporte = value;
      },
    }
}
</script>