<template>
<div class="sidebar">
    <div class="sidebar-backdrop" @click="toggleNav" v-if="isPanelOpen"></div>
    <transition name="slide">
        <div v-if="isPanelOpen"
             class="sidebar-panel">
            <slot></slot>
        </div>
    </transition>
</div>
</template>
<script>
import { store, mutations } from '@/store';
import { mapGetters, mapActions } from 'vuex'
export default {
    methods: {
        ...mapActions({
        toggleNav: 'nav/toggleNav'
      }),
    },
    computed: {
      
        ...mapGetters({
            isNavOpen: 'nav/isNavOpen',
        }),
        isPanelOpen() {
            return this.isNavOpen
        }
    },
    watch: {
        '$route' () {
            if(this.isNavOpen){
                this.toggleNav(!this.isNavOpen);
            }
        }
    }
}
</script>
<style>
.slide-enter-active,
.slide-leave-active
{
    transition: transform 0.2s ease;
}

.slide-enter,
.slide-leave-to {
    transform: translateX(-100%);
    transition: all 150ms ease-in 0s
}

.sidebar-backdrop {
    width: 100%;
    height: 100%;
    position: fixed;
    top: 0;
    left: 0;
    cursor: pointer;
    z-index: 1000;
}

.sidebar-panel {
    overflow-y: auto;
    position: fixed;
    left: 0;
    top: 0;
    height: 100%;
    z-index: 999;
    padding: 3rem 20px 2rem 20px;
    width: 300px;
    z-index: 1001;
}
</style>