<template>
<b-row>
    <b-col cols="12 informacion">
        <b-row v-if="(despacho.aforo == 0 || despacho.aforo == 1 || despacho.aforo == 2) && estados.aforo">
            <b-col cols="4" class="text-left dato">
                <p>Tipo de Aforo</p>
            </b-col>
            <b-col cols="4" class="text-right dato despacho">
                <p v-if="despacho.aforo == 2">Sin Aforo</p> 
                <p v-if="despacho.aforo == 1">Examen Físico</p> 
                <p v-if="despacho.aforo == 0">Examen Documental</p> 
            </b-col>
            <b-col cols="4" class="text-right dato despacho">
                <b-button
                    @click="editar('presentador_imp','aforo')"
                    class="btn-principal btn-small"
                >
                    Editar <inline-svg  class="icono-titulo" src="../../assets/images/Editar.svg" title="My Image"/>
                </b-button>
            </b-col>
        </b-row>
        <b-row v-else>
            <b-col 
                cols="12" class="text-right dato despacho" 
            >
                <form class="form-input">
                    <b-row>
                        <b-col sm="12" md="12" lg="4">
                            <b-form-group
                                class="text-left"
                                label="Tipo de Aforo"
                                label-for="aforo-select"
                            >
                                <select class="form-control border border-secondary"  id="aforo-select" v-model="aforo"  required>
                                    <option value="3" disabled>Selecciona el Tipo de Aforo</option>
                                    <option  value="2" >Sin Aforo</option>
                                    <option  value="1" >Examen Físico</option>
                                    <option  value="0" >Examen Documental</option>
                                    
                                </select>
                            </b-form-group>
                        </b-col>
                        <b-col sm="12" md="12" lg="4">
                            <b-button
                                class="btn-extra-primario btn-small"
                                type="button"
                                @click="editar('presentador_imp','aforo', true,{'aforo': aforo })"
                            >
                                Guardar
                            </b-button>
                            <b-button
                                class="btn-extra-secundario btn-small"
                                type="button"
                                @click="editar('presentador_imp','aforo')"
                            >
                                Cancelar
                            </b-button>
                        </b-col>
                    </b-row>
                </form>
            </b-col>
        </b-row>
        <b-row v-if="despacho.tipo && despacho.tipo.subtipo && despacho.tipo.subtipo.tipo_operacion && estados.sub_tipo">
            <b-col cols="4" class="text-left dato">
                <p>Sub Tipo</p>
            </b-col>
            <b-col cols="4" class="text-right dato despacho">
                <p v-if="despacho.tipo.subtipo.tipo_operacion">{{despacho.tipo.subtipo.tipo_operacion}}</p> 
            </b-col>
            <b-col cols="4" class="text-right dato despacho">
                <b-button
                    @click="editar('presentador_imp','sub_tipo')"
                    class="btn-principal btn-small"
                >
                    Editar <inline-svg  class="icono-titulo" src="../../assets/images/Editar.svg" title="My Image"/>
                </b-button>
            </b-col>
        </b-row>
        <b-row v-else>
            <b-col 
                cols="12" class="text-right dato despacho" 
            >
                <form class="form-input">
                    <b-row>
                        <b-col sm="12" md="12" lg="6">
                            <b-form-group
                                class="text-left"
                                label="Sub Tipo"
                                label-for="sub_tipo-select"
                            >
                                <select v-if="despacho.tipo_despacho == 'importacion'" class="form-control border border-secondary"  id="sub_tipo-select" v-model="sub_tipo"  required>
                                    <option value="0" disabled>Selecciona el Sub Tipo Importación</option>
                                    <option  :key="index" v-for="(sub_tipo,index) in listaTipoImportacion" :value="sub_tipo.id" >{{sub_tipo.tipo_operacion}}</option>
                                
                                    
                                </select>
                                <select v-if="despacho.tipo_despacho == 'exportacion'" class="form-control border border-secondary"  id="sub_tipo-select" v-model="sub_tipo"  required>
                                    <option value="0" disabled>Selecciona el Sub Tipo Exportación</option>
                                    
                                    <option :key="index" v-for="(sub_tipo,index) in listaTipoExportacion" :value="sub_tipo.id" >{{sub_tipo.tipo_operacion}}</option>
                                    
                                </select>
                            </b-form-group>
                        </b-col>
                        <b-col sm="12" md="12" lg="6">
                            <b-button
                                class="btn-extra-primario btn-small"
                                type="button"
                                @click="editar('presentador_imp','sub_tipo', true,{'tipo_id': sub_tipo})"
                            >
                                Guardar
                            </b-button>
                            <b-button
                                class="btn-extra-secundario btn-small"
                                type="button"
                                @click="editar('presentador_imp','sub_tipo')"
                            >
                                Cancelar
                            </b-button>
                        </b-col>
                    </b-row>
                </form>
            </b-col>
        </b-row>
    </b-col>

    
    
    
    

</b-row>
</template>

<script>
import InputRut from '../InputRut.vue';
import InputRegion from '../InputRegion.vue';
import axios from 'axios';
export default {
    components: { InputRut, InputRegion },
    name:"FormUsuario",

    props: {
        despacho:Object,
        postInformacionGeneral:Function,
        informacionGeneral:Object,
        editar:Function,
        estados:Object

    },
    data(){
        return{
            condicion: 0,
            extraportuario: '',
            aforo:'0',
            pantalla:false,

            sub_tipo:'',

            listaTipoImportacion: [],
            listaTipoExportacion: [],
        }
    },
    mounted(){
        this.getSubTipos()
        if (screen.width <= 660) this.pantalla = false
        else this.pantalla = true
    },
    methods:{
    
        async getSubTipos(){
      await axios.get('api/admin/despachos/subtipos')
        .then(resp => {
          console.log(resp.data)
          this.listaTipoImportacion = resp.data.importaciones;
          this.listaTipoExportacion = resp.data.exportaciones;

        })
        .catch(err => console.log(err))
    },
    }
}
</script>