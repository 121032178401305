<template>
  <b-row class="header">
      <!-- <b-col cols="2"  class="buscador">
          <burger v-if="pantalla == true"></burger>
          <input v-if="pantalla == false" placeholder="¿Qué buscas?">
      </b-col> -->
      <b-col  class="acciones">
          <b-row class="seccion-acciones justify-content-between">
              <div class="px-4 py-2 d-flex justify-content-center align-items-center">
                  <button @click="toggleSidebar()" class="collapse-button">
                      <fas size="lg" :icon="['fas','align-justify']"></fas>
                  </button>
              </div>
              <div class="d-flex align-items-start">
                <inline-svg class="logo-usuario" src="../../assets/images/usuario.svg" title="My Image"/>
                <router-link to="/account" class="cuenta">{{user.name}}</router-link>
                <button class="salir"  href="#" @click.prevent="signOut">Salir</button>
              </div>
          </b-row>
      </b-col>
  </b-row>
</template>

<script>
import Burger from '../../views/Menu/Burger.vue'


export default {
  components: { Burger },
    name:"Header",
    props: {
        signOut: Function,
        user: Object,
        pantalla:Boolean
    },
    methods:{
        toggleSidebar(){
            const sidebar = document.getElementById('sidebar-wrapper');
            sidebar.classList.toggle('toggled');
        }
    }
}
</script>

<style>
    .collapse-button{
        padding: 0;
        background: transparent;
        border: none;   
    }
    .header{
        position: fixed;
        background:#FFFFFF; 
        height: 40px;
        top: 0;
        left: 245px;
        box-shadow: 5px 2px 5px #0000001a;
        z-index: 26;
        width: calc(100% - 230px);
    }
    .header .buscador{
        padding-top: 9px;
    }

    .header .buscador input{
        background: #F2F2F2;

        border: none;
        border-radius: 10px;
        padding-left: 8px;
    }

    .buscador{
        text-align: left;
    }


    .seccion-acciones{
        height: 100%;
        text-align: right;
    }

    .seccion-acciones a {
        font-size: 14px;

        margin-right: 20px;
        margin-bottom: 0px;
        padding-top: 10px;
    }
    .seccion-acciones .logo-usuario{
        margin-top: 8px;
        margin-right: 5px;
    }

    .salir{
        border: none;
        width: 150px;
        height: 40px;

        padding: 0 ;
        background: #5699F4;

        text-align: center;
        align-items: center;

        color: white;
        font-size: 16px;
    }
@media (min-width: 991px) {
    .collapse-button{
        display: none;
    }

}
    @media only screen and (max-width: 1024px) {
        .salir{
            width: 50px;
            height: 40px;
        }
        .cuenta{
            margin-right: 0 !important;
        }
        .header .buscador{
        padding-top: 0 !important;
    }
    }
    @media (max-width: 991px) {
    .header{
        left: 15px !important;
        width: 100% !important;
    }
}

</style>