<template>
  <div >
    <link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/semantic-ui/2.2.9/semantic.min.css">
    <div id="buttons" class="ui grey three item inverted bottom menu transition">
      <a class="item" @click="page > 1 ? page-- : 1">
        <i class="left chevron icon"></i>
        Atras
      </a>
      <a class="ui active item">
        {{page}} / {{ numPages ? numPages : '∞' }}
      </a>
      <a class="item" @click="page < numPages ? page++ : 1">
        Adelante
        <i class="right chevron icon"></i>
      </a>
    </div>
    <div id="buttons" class="ui grey three item inverted bottom fixed menu transition hidden">
      <a class="item" @click="scale -= scale > 0.2 ? 0.1 : 0">
        <i class="left chevron icon" />
          Zoom -
      </a>
      <a class="ui active item">
        {{ formattedZoom }} %
      </a>
      <a class="item" @click="scale += scale < 2 ? 0.1 : 0">
        Zoom +
        <i class="right chevron icon" />
      </a>
    </div>
    <pdf :src="pdfdata" v-for="i in numPages" :key="i" :id="i" :page="i"
      :scale.sync="scale" style="width:100%;margin:20px auto;"
        :annotation="false"
        :text="true"
        :resize="true"
        @link-clicked="handle_pdf_link">
      <template slot="loading">
        loading content here...
      </template>
    </pdf>
  </div>
</template>

<script>
import pdfvuer from './Pdfvuer.vue'
import PdfjsWorker from 'pdfjs-dist/build/pdf.worker.entry'
import axios from 'axios'

PdfjsWorker

export default {
  components: {
    pdf: pdfvuer
  },
  props:{
    idDocumento: String,
  },
  data() {
    return {
      page: 1,
      numPages: 0,
      pdfdata: undefined,
      errors: [],
      scale: 'page-width',
      doc_loading: false
    }
  },
  computed: {
    formattedZoom() {
      return Number.parseInt(this.scale * 100);
    },
  },
  mounted() {
    this.getPdf()
  },
  watch: {
    show: function (s) {
      if (s) {
        this.getPdf();
      }
    },
    page: function (p) {
      if (window.pageYOffset <= this.findPos(document.getElementById(p)) || (document.getElementById(p + 1) && window.pageYOffset >= this.findPos(document.getElementById(p + 1)))) {

        document.getElementById(p).scrollIntoView();
      }
    },
    // Wait for our document to be loaded and highlight our search terms
    doc_loading: function () {
      var self = this;
      if (this.doc_loading == false) {
        this.$children.forEach(function (obj) {
          if (obj && obj.$el && obj.$el.classList && obj.$el.classList.contains('pdf-pages')) {
            obj.pdfFindController.executeCommand('find', {
              caseSensitive: false,
              findPrevious: undefined,
              highlightAll: true,
              phraseSearch: false,
              query: "link"
            });
          }
        });
      }
    }
  },
  methods: {
    handle_pdf_link: function (params) {
      var page = document.getElementById(String(params.pageNumber));
      page.scrollIntoView();
    },
    getPdf () {
      var self = this;
      self.pdfdata = pdfvuer.createLoadingTask(process.env.VUE_APP_API_URL +`/api/archivo/${this.idDocumento}/descargar`);
      self.pdfdata.then(pdf => {
        self.numPages = pdf.numPages;
        window.onscroll = function() { 
          changePage() 
          //stickyNav()  
        }

        // Get the offset position of the navbar
        var sticky = document.getElementById('buttons').offsetTop

        // Add the sticky class to the self.$refs.nav when you reach its scroll position. Remove "sticky" when you leave the scroll position
        function stickyNav() {
          if (window.pageYOffset >= sticky) {
            document.getElementById('buttons').classList.remove("hidden")
          } else {
            document.getElementById('buttons').classList.add("hidden")
          }
        }

        function changePage () {
          var i = 1, count = Number(pdf.numPages);
          do {
            if(window.pageYOffset >= self.findPos(document.getElementById(i)) && 
                window.pageYOffset <= self.findPos(document.getElementById(i+1))) {
              self.page = i
            }
            i++
          } while ( i < count)
          if (window.pageYOffset >= self.findPos(document.getElementById(i))) {
            self.page = i
          }
        }
      });
    },
    findPos(obj) {
      return obj.offsetTop;
    }
  }
}
</script>
    <style src="pdfjs-dist/web/pdf_viewer.css"></style>
    <style src="pdfvuer/dist/pdfvuer.css"></style>
<style lang="css" scoped>
  #buttons {
    margin-left: 0 !important;
    margin-right: 0 !important;
  }
  /* Page content */
  .content {
    padding: 16px;
  }
</style>
