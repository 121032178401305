import store from "./index";
import axios from "axios";
import router from "@/router/index"
import Swal from 'sweetalert2'

export default function setup() {
  axios.interceptors.response.use(
    response => {
      // Any status code that lie within the range of 2xx cause this function to trigger
      // Do something with response data
      return response;
    },
    error => {
      // Any status codes that falls outside the range of 2xx cause this function to trigger
      // Do something with response error
      let sw = localStorage.getItem('sw')
      if (error.response.status === 401 && sw === "true") {
        // redirect to   login page
        Swal.fire({
            icon: 'info',
            title: 'Sesión expirada',
            text: 'Sesión expirada. Por favor vuelva a iniciar sesión.'
        }).then(() => {
            localStorage.setItem('sw', false)
            logout()
        });
    }
    return Promise.reject(error);
}
);
}

async function logout(){
    await store.dispatch('auth/signOut')
    router.replace({ name: 'SignIn' })
}
