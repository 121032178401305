import Vue from 'vue'
import VueRouter from 'vue-router'
import SignIn from '../views/SignIn.vue'
import Users from '../views/Users.vue'
import Account from '../views/usuario/Account.vue'
import Clientes from '../views/Clientes.vue' 
import Aduanas from '../views/Aduanas.vue'
import Agentes from '../views/Agentes.vue'
import Extraportuarios from '../views/Extraportuarios.vue'
import CompaniasNavieras from '../views/CompaniasNavieras.vue'
import Proveedores from '../views/Proveedores.vue'



import Swal from 'sweetalert2'
/**
 * Despachos
 */
import Despachos from '../views/Despachos.vue';
import Index from '../components/Despachos/Index.vue';
import Perfil from '../components/Despachos/Perfil.vue';
import Crear from '../components/Despachos/Crear.vue'
import Visualizador from '../components/Despachos/Visualizador.vue'

import notfound from '../components/errors/404.vue'

import FormularioCorreo from '../views/recuperar/FormularioCorreo.vue'
import ResetPassword from '../views/recuperar/ResetPassword.vue'

import Dashboard from '../views/Dashboard.vue';


import Roles from '../constants/Roles';

import store from '../store/index';
import ShortLink from '@/views/ShortLink'
import Grupos from '@/views/GrupoUsuarios'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    meta: {home : true}
  },
  {
    path: '/s/:shortLink',
    meta: {authorize : Object.values(Roles)},
    component: ShortLink
  },
  {
    path: '/login',
    name: 'SignIn',
    component: SignIn,
    meta: {guest : true}
  },
  {
    path: '/recuperar/password',
    name: 'Recuperar',
    component: FormularioCorreo,
    meta: {guest : true}
  },
  {
    path: '/reset-password',
    name: 'Reset',
    component: ResetPassword,
    meta: {guest : true}
  },
  {
    path: '/usuarios',
    name: 'Users',
    component: Users,
    meta: {authorize : [
      Roles.superuser,
      Roles.admin,
      Roles.supervisor
    ]}
  },
  {
    path: '/clientes',
    name: 'Clients',
    component: Clientes,
    meta: {authorize : [
      Roles.superuser,
      Roles.admin,
      // Roles.supervisor
    ]}
  },
  {
    path: '/agentes-carga',
    name: 'Agentes',
    component: Agentes,
    meta: {authorize : [
      Roles.superuser,
      Roles.admin,
      Roles.supervisor
    ]}
  },
  {
    path: '/aduanas',
    name: 'Aduanas',
    component: Aduanas,
    meta: {authorize : [
      Roles.superuser,
      Roles.admin,
      Roles.supervisor
    ]}
  },
  {
    path: '/extraportuarios',
    name: 'Extraportuarios',
    component: Extraportuarios,
    meta: {authorize : [
      Roles.superuser,
      Roles.admin,
      Roles.supervisor
    ]}
  },
  {
    path: '/proveedores',
    name: 'Proveedores',
    component: Proveedores,
    meta: {authorize : [
      Roles.superuser,
      Roles.admin,
      Roles.supervisor
    ]}
  },
  {
    path: '/companias-navieras',
    name: 'CompaniasNavieras',
    component: CompaniasNavieras,
    meta: {authorize : [
      Roles.superuser,
      Roles.admin,
      Roles.supervisor
    ]}
  },
  {
    path: '/account',
    name: 'Account',
    component: Account,
    meta: {authorize : Object.values(Roles)}
  },
  {
    path: '/dashboard',
    name: 'Dashboard',
    component: Dashboard,
    meta: {authorize : Object.values(Roles)
    }
  },
  {
    path: '/grupos',
    name: 'Grupos',
    component: Grupos,
    meta: {authorize : [
      Roles.superuser,
      Roles.admin,
      // Roles.supervisor
    ]}
  },
  {
    path: '/despachos',
    component: Despachos,
    meta: {
      authorize : Object.values(Roles)
    },
    children: [
      {
        path: 'perfil/:despachoId',
        name: 'PerfilDespacho',
        component: Perfil

      },
      {
        path: 'index',
        name: 'Despachos',
        component: Index,
        meta: {
          authorize : Object.values(Roles)
        }
      },
      {
        path:'crear',
        name:'Crear',
        component: Crear,
        meta: {
          authorize : Object.values(Roles)
        }
      },
      {
        path:'ver/:despachoId/documentos/:documentoId',
        name:'Documento',
        component: Visualizador,
        meta: {
          authorize : Object.values(Roles)
        }
      },
    ]
  },


  {
    path:'/verified',
    name:'Verified',
    meta:{
      verified: true
    }
  },
  
  {
    path:'*',
    name:'Not Found',
    component:notfound,
    meta: {
      notfound:true
    }
  },
]





const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

router.beforeEach((to, from, next) => {
  if(to.meta.authorize) {
    if (store.getters['auth/authenticated']) {
      const usuario = store.getters['auth/user']
      if(!usuario.email_verified_at){
        if(to.name == 'Account') next();
        else next('/account')
        return 
      }
      let permitirAcceso = false;
      to.meta.authorize.forEach(element => {
        if(element == usuario.role.name){
          permitirAcceso = true
        }
      });
      if(permitirAcceso) next()
      else next('/despachos')

      return
        
    }else{

      next('/login')
    }
  } else if(to.meta.guest) {
    if (store.getters['auth/authenticated']) {
      next('/despachos');
    }
    next()
  }else if(to.meta.verified) {
    if (store.getters['auth/authenticated']) {
    
      
      Swal.fire({icon: 'success',
      title: '¡Exito!',
      showConfirmButton: false,
      timer: 1000,
      text: 'Se ha verificado de forma correcta su correo electronico'})
      next('/account');
    }else{
      Swal.fire({icon: 'success',
      title: '¡Exito!',
      showConfirmButton: false,
      timer: 1000,
      text: 'Se ha verificado de forma correcta su correo electrónico, porfavor ingrese sesión para su validación'})
      next('/login')
    }
  }if(to.meta.home) {
    if (store.getters['auth/authenticated']) {
      next('/despachos');
    }
    next('/login')
  }
  else{
    next()
  }
})

export default router
