<template>
  <div id="app">
      <b-container fluid v-if="!authenticated">
         <router-view/>
      </b-container>
      <div class="main-wrap" fluid v-else >
          <transition name="slide">
          <div v-if="isPanelOpen && pantalla == true" class="sidebar sidebar-panel">
            <navigation :pantalla="pantalla"></navigation>  
          </div>
          </transition>
          <div id="sidebar-wrapper" class="side-wrap p-0">
            <navigation ></navigation>  
          </div>
          <div class="main-section">
            <Header :pantalla="pantalla" :signOut="signOut" :user="user"/>
            <div>
              <router-view/>
            </div>
          </div>
      </div>

  </div>
</template>


<script>
  import { mapGetters, mapActions } from 'vuex'

  import SignIn from './views/SignIn.vue'

  import Sidebar from './components/layout/Sidebar.vue'
  import Header from './components//layout/Header.vue'
import FormularioCorreo from './views/recuperar/FormularioCorreo.vue'
import axios from 'axios'
import Navigation from './views/Menu/Navigation.vue'


  export default {
    components: {
      Sidebar,
      Header,
      SignIn,
      FormularioCorreo,
        Navigation,
    },
    computed: {
      
      ...mapGetters({
        authenticated: 'auth/authenticated',
        user: 'auth/user',
        isNavOpen: 'nav/isNavOpen',
      }),
      isPanelOpen() {
            return this.isNavOpen
        }
    },
    data(){
      return{
        show:true,
        pantalla:false,
      }
    },
    mounted(){
      if(screen.width <= 1024) {
        this.pantalla = true;
      }else{
        this.pantalla = false
      }
    },

    methods: {
      ...mapActions({
        signOutAction: 'auth/signOut'
      }),

      async signOut () {
        if(this.authenticated){
          await axios.post('logout').then(res =>{
            }).catch(err => console.log(err))
        }
        this.signOutAction()
        this.$router.replace({ name: 'SignIn' })
      },
      
    },
  }
</script>

<style>
.main-wrap{

}
.main-section{
  margin-left: 230px;
  padding: 40px 25px;
}
.side-wrap{
  animation: .2s all ease-in-out;
  transition: .2s all ease-in-out;
  width: 230px;
  position: fixed;
  z-index: 25;
}

#app {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

#nav {
  padding: 30px;
}

#nav a {
  font-weight: bold;
  color: #2c3e50;
}

#nav a.router-link-exact-active {
  color: #42b983;
}

.fade-enter-active, .fade-leave-active {
  transition: opacity .5s;
}
.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
  opacity: 0;
}
/* .slide-enter,
.slide-leave-to
{
    transition: transform 0.2s ease;
}

.slide-enter,
.slide-leave-to {
    transform: translateX(-100%);
    transition: all 150ms ease-in 0s
} */
.sidebar-backdrop {
    width: 100%;
    height: 100%;
    position: fixed;
    top: 0;
    left: 0;
    cursor: pointer;
    z-index: 1000;
}

.sidebar-panel {
    overflow-y: auto;
    position: fixed;
    left: 0;
    top: 0;
    height: 100%;
    z-index: 999;
    padding: 3rem 20px 2rem 20px;
    width: 300px;
    z-index: 1001;
}
@media (max-width: 991px) {
 
  .side-wrap{
    white-space: nowrap;
    max-width: 0;
    width: 0;
    overflow: hidden;
  }
  .side-wrap .sidebar-content{
    overflow: hidden;
  }

  .main-section{
    margin-left: 0;
  }
  .toggled{
  width: 230px !important;
  max-width: 230px !important;
  z-index: 25 !important;
  overflow: auto !important;
}
  .toggled .sidebar-content{
    overflow: auto !important;
  }

.toggled .sidebar-header{
  padding-top: 50px !important;
}
}

</style>
