<template>
<div class="title-section">
  <p>{{title}}</p>
</div>
</template>

<script>
export default {
    name:"TitlePage",
    props: {
        title: String
    }
}
</script>

<style>
    .title-section{
        border-bottom: 1px solid #707070;
        margin-bottom: 20px;
        margin-top: 20px;
    }

    .title-section p {
        color: #707070;
        font-weight: 600;
        font-size: 10px;

        text-align: left;
        margin-bottom: 0
    }
</style>