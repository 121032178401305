<template>
  <div v-if="loaded">
    <title-page title="DESPACHOS"/>
    <card title="Perfil despacho N°"  :permiso="'dispatch.create'" :boton="false"
      :despacho="despacho"
      :segundoaccionBoton="descargarExcel"
      :segundoboton="true"
      segundotext="Generar Reporte"
      :canManage="canManage"
      v-if="canManage"
    >
    <template #extraButtons>
      <button :disabled="loading_url" id="test" @click="genShortLink()" class="btn-principal btn-small mr-4">
        Compartir <fas v-if="!loading_url" icon="share"></fas> <b-spinner v-else small class="align-middle"></b-spinner>
      </button>
      
      <b-popover placement="top" triggers="click blur" target="test" title="Copiado!">
        <b>La url ha sido copiada en el portapapeles correctamente.</b>
        <p>
          <small>
            El enlace tendrá una duración de 3 meses.
          </small>
        </p>
      </b-popover>
    </template>
     <b-tabs v-if="despacho && despacho.tipo_despacho == 'importacion'" class="tabs-despacho " fill>
            <b-tab  title="General" v-if="show('dispatch.index')" @click="cambiarSeccion('general')">
                <general
                  v-if="despacho && documentosDespacho && montos && secciones.general"
                  :documentosTabla="documentosDespacho"
                  :pagosTabla="pagosDespacho"
                  :montos="montos"
                  

                  :despacho="despacho"
                  :usuarios="usuarios"
                  :submit="postDispatch"
                  :canManage="canManage"

                  :eliminarDocumentos ="eliminarDocumentos"
                  :getComprobante="getComprobante"
                  :showDeleteDocumentos = "showDeleteDocumentos"
                  :actualizarDocumentos= "actualizarDocumentos"
                  :confirmarDeleteDocumentos= "confirmarDeleteDocumentos"
                  :cerrarModal="cerrarModal"
                  :metodoThen= "metodoThen" 
                  :metodoCatch="metodoCatch"
                  
                >
                </general>    
            </b-tab>
            <b-tab  title="Gestor" v-if="show('gestor.view')" @click="cambiarSeccion('gestor')">
                <gestor
                  v-if="despacho && listaUsuarios && usuarios && documentos.tipos_documentos && documentosPorRol && secciones.gestor "
                  :documentos="documentos"

                  :eliminarDocumento="eliminarDocumento"
                  :getComprobante="getComprobante"
                  :eliminarPago="eliminarPago"

                  :pagosTabla="pagosDespacho"
                  :postPago="postPago"
                  :updatePago="updatePago"

                  :postInformacionGeneral="postInformacionGeneral"

                  :despacho="despacho"
                  :listaUsuarios="listaUsuarios"
                  :usuarios="usuarios"
                  :submit="postDispatch"
                  
                  :addUser="addUser"
                  :deleteUser="deleteUser"

                  :documentosTabla="documentosDespacho"
                  :postDocumento="postDocumento"
                  :updateDocumento="updateDocumento"

                  :eliminarDocumentos ="eliminarDocumentos"
                  :showDeleteDocumentos = "showDeleteDocumentos"
                  :actualizarDocumentos= "actualizarDocumentos"
                  :confirmarDeleteDocumentos= "confirmarDeleteDocumentos"
                  :cerrarModal="cerrarModal"
                  :metodoThen= "metodoThen"
                  :metodoCatch="metodoCatch"
                >

                </gestor>
            </b-tab>
            <b-tab  title="Pedidor" v-if="show('pedidor.view')" @click="cambiarSeccion('pedidor')">
                <pedidor
                  v-if="despacho && listaUsuarios && usuarios && documentos.tipos_documentos  && secciones.pedidor"
                  :documentos="documentos"
                  

                  :eliminarDocumento="eliminarDocumento"
                  :getComprobante="getComprobante"
                  :eliminarPago="eliminarPago"
                  
                  :pagosTabla="pagosDespacho"
                  :postPago="postPago"
                  :updatePago="updatePago"

                  :documentosTabla="documentosDespacho"
                  :postDocumento="postDocumento"
                  :updateDocumento="updateDocumento"

                  :postInformacionGeneral="postInformacionGeneral"


                  :despacho="despacho"
                  :listaUsuarios="listaUsuarios"
                  :usuarios="usuarios"
                  :submit="postDispatch"
                  
                  :addUser="addUser"
                  :deleteUser="deleteUser"

                  :eliminarDocumentos ="eliminarDocumentos"
                  :showDeleteDocumentos = "showDeleteDocumentos"
                  :actualizarDocumentos= "actualizarDocumentos"
                  :confirmarDeleteDocumentos= "confirmarDeleteDocumentos"
                  :cerrarModal="cerrarModal"
                  :metodoThen= "metodoThen"
                  :metodoCatch="metodoCatch"
                >

                </pedidor>
            </b-tab>
            <b-tab  title="Presentador" v-if="show('prestador.view')" @click="cambiarSeccion('presentador')">
              
                <prestador
                  v-if="despacho  && documentos.tipos_documentos  && secciones.presentador"
                  :documentos="documentos"
  
                  :eliminarDocumento="eliminarDocumento"
                  :getComprobante="getComprobante"
                  :eliminarPago="eliminarPago"

                  :pagosTabla="pagosDespacho"
                  :postPago="postPago"
                  :updatePago="updatePago"

                  :documentosTabla="documentosDespacho"
                  :postDocumento="postDocumento"
                  :updateDocumento="updateDocumento"

                  :despacho="despacho"
                  :postInformacionGeneral="postInformacionGeneral"

                  :eliminarDocumentos ="eliminarDocumentos"
                  :showDeleteDocumentos = "showDeleteDocumentos"
                  :actualizarDocumentos= "actualizarDocumentos"
                  :confirmarDeleteDocumentos= "confirmarDeleteDocumentos"
                  :cerrarModal="cerrarModal"
                  :metodoThen= "metodoThen"
                  :metodoCatch="metodoCatch"
                >

                </prestador>
            </b-tab>
            <b-tab  title="Tramitador Servicio" v-if="show('tramitador_servicios.view')" @click="cambiarSeccion('tramitador_servicio')">
             
                <tramitador-servicio
                  v-if="despacho && documentos.tipos_documentos  && secciones.tramitador_servicio"
                  :documentos="documentos"

                  :eliminarDocumento="eliminarDocumento"
                  :getComprobante="getComprobante"
                  :eliminarPago="eliminarPago"

                  :pagosTabla="pagosDespacho"
                  :postPago="postPago"
                  :updatePago="updatePago"

                  :documentosTabla="documentosDespacho"
                  :postDocumento="postDocumento"
                  :updateDocumento="updateDocumento"

                  :despacho="despacho"

                  :submit="postDispatch"

                  :eliminarDocumentos ="eliminarDocumentos"
                  :showDeleteDocumentos = "showDeleteDocumentos"
                  :actualizarDocumentos= "actualizarDocumentos"
                  :confirmarDeleteDocumentos= "confirmarDeleteDocumentos"
                  :cerrarModal="cerrarModal"
                  :metodoThen= "metodoThen"
                  :metodoCatch="metodoCatch"
                >
                </tramitador-servicio>
            </b-tab>
            <b-tab  title="Garantías y Correcciones" v-if="show('administrativa.view')" @click="cambiarSeccion('administrativa_garantias')">
                <administrativa-garantias
                   v-if="despacho && documentos.tipos_documentos  && secciones.administrativa_garantias"
                  :despacho="despacho"
                  :documentos="documentos"

                  :eliminarDocumento="eliminarDocumento"
                  :getComprobante="getComprobante"
                  :eliminarPago="eliminarPago"
                  

                  :postInformacionGeneral="postInformacionGeneral"

                  :pagosTabla="pagosDespacho"
                  :postPago="postPago"
                  :updatePago="updatePago"
                  
                  :documentosTabla="documentosDespacho"
                  :postDocumento="postDocumento"
                  :updateDocumento="updateDocumento"


                  :listaUsuarios="listaUsuarios"
                  :submit="postDispatch"

                  :eliminarDocumentos ="eliminarDocumentos"
                  :showDeleteDocumentos = "showDeleteDocumentos"
                  :actualizarDocumentos= "actualizarDocumentos"
                  :confirmarDeleteDocumentos= "confirmarDeleteDocumentos"
                  :cerrarModal="cerrarModal"
                  :metodoThen= "metodoThen"
                  :metodoCatch="metodoCatch"
                  
                
                >
                </administrativa-garantias>
            </b-tab>
            <b-tab  title="Operaciones" v-if="show('operaciones.view')" @click="cambiarSeccion('operaciones')">
                <operaciones
                 v-if="despacho && listaUsuarios && usuarios && documentos.tipos_documentos && secciones.operaciones"
                  :documentos="documentos"

                  :eliminarDocumento="eliminarDocumento"
                  :getComprobante="getComprobante"
                  :eliminarPago="eliminarPago"

                  :postInformacionGeneral="postInformacionGeneral"

                  :pagosTabla="pagosDespacho"
                  :postPago="postPago"
                  :updatePago="updatePago"
                  
                  :despacho="despacho"
                  :listaUsuarios="listaUsuarios"
                  :usuarios="usuarios"
                  :submit="postDispatch"
                  
                  :addUser="addUser"
                  :deleteUser="deleteUser"

                  :documentosTabla="documentosDespacho"
                  :postDocumento="postDocumento"
                  :updateDocumento="updateDocumento"

                  :eliminarDocumentos ="eliminarDocumentos"
                  :showDeleteDocumentos = "showDeleteDocumentos"
                  :actualizarDocumentos= "actualizarDocumentos"
                  :confirmarDeleteDocumentos= "confirmarDeleteDocumentos"
                  :cerrarModal="cerrarModal"
                  :metodoThen= "metodoThen"
                  :metodoCatch="metodoCatch"
                >
                </operaciones> 
            </b-tab>
            <b-tab  title="Tramitador Aduanero" v-if="show('tramitador_aduanero.view')" @click="cambiarSeccion('tramitador_aduanero')">
                <tramitador-aduanero
                  v-if="despacho  && documentos.tipos_documentos && secciones.tramitador_aduanero"
                  :documentos="documentos"

                  :eliminarDocumento="eliminarDocumento"
                  :getComprobante="getComprobante"
                  :eliminarPago="eliminarPago"

                  :pagosTabla="pagosDespacho"
                  :postPago="postPago"
                  :updatePago="updatePago"

                  :documentosTabla="documentosDespacho"
                  :postDocumento="postDocumento"
                  :updateDocumento="updateDocumento"

                  :postInformacionGeneral="postInformacionGeneral"

                  :despacho="despacho"
                  :submit="postDispatch"

                  :eliminarDocumentos ="eliminarDocumentos"
                  :showDeleteDocumentos = "showDeleteDocumentos"
                  :actualizarDocumentos= "actualizarDocumentos"
                  :confirmarDeleteDocumentos= "confirmarDeleteDocumentos"
                  :cerrarModal="cerrarModal"
                  :metodoThen= "metodoThen"
                  :metodoCatch="metodoCatch"
                >
                </tramitador-aduanero> 
            </b-tab>
            <b-tab  title="Factura" v-if="show('facturador.view')" @click="cambiarSeccion('facturas')">
                <facturas
                  v-if="despacho && listaUsuarios && usuarios && documentos.tipos_documentos && secciones.facturas"
                  :documentos="documentos"
                  :documentosTabla="documentosDespacho"
                  :postDocumento="postDocumento"
                  :updateDocumento="updateDocumento"

                  :pagosTabla="pagosDespacho"
                  :montos="montos"
                  :postPago="postPago"
                  :updatePago="updatePago"
                  :getComprobante="getComprobante"
                  
                  :despacho="despacho"
                  :listaUsuarios="listaUsuarios"
                  :usuarios="usuarios"
                  :submit="postDispatch"
                  
                  :addUser="addUser"
                  :deleteUser="deleteUser"

                  :eliminarDocumentos ="eliminarDocumentos"
                  :showDeleteDocumentos = "showDeleteDocumentos"
                  :actualizarDocumentos= "actualizarDocumentos"
                  :confirmarDeleteDocumentos= "confirmarDeleteDocumentos"
                  :cerrarModal="cerrarModal"
                  :metodoThen= "metodoThen"
                  :metodoCatch="metodoCatch"
                >
                  
                </facturas>
            </b-tab>
            <b-tab  title="Revisor" v-if="show('revisor.view')" @click="cambiarSeccion('revisor')">
                <revisor
                  v-if="despacho && listaUsuarios && usuarios && secciones.revisor"
                  :documentos="documentos"

                  :eliminarDocumento="eliminarDocumento"
                  :getComprobante="getComprobante"
                  :eliminarPago="eliminarPago"

                  :pagosTabla="pagosDespacho"
                  :postPago="postPago"
                  :updatePago="updatePago"

                  :despacho="despacho"
                  :listaUsuarios="listaUsuarios"
                  :usuarios="usuarios"
                  :submit="postDispatch"
                  
                  :addUser="addUser"
                  :deleteUser="deleteUser"

                  :eliminarDocumentos ="eliminarDocumentos"
                  :showDeleteDocumentos = "showDeleteDocumentos"
                  :actualizarDocumentos= "actualizarDocumentos"
                  :confirmarDeleteDocumentos= "confirmarDeleteDocumentos"
                  :cerrarModal="cerrarModal"
                  :metodoThen= "metodoThen"
                  :metodoCatch="metodoCatch"
                
                >

                </revisor>
            </b-tab>
            <b-tab  title="Historial" @click="cambiarSeccion('historial')">
              
                <historial
                  v-if="secciones.historial"
                >
                </historial>
            </b-tab>
            <b-tab  title="Aforos" @click="cambiarSeccion('aforos')">
              
                <Aforos
                  v-if="secciones.aforos"
                >
                </Aforos>
            </b-tab>
            <b-tab title="Comentarios" @click="cambiarSeccion('comentarios'); markAsRead()">
              <template #title>
                Comentarios
                <span v-if="notifications.comments.hasNotifications === true" class='badge badge-primary'>{{notifications.comments.amount}}</span>
              </template>
                <comentarios
                  :canManage="canManage"
                  v-if="secciones.comentarios"
                >
                </comentarios> 
            </b-tab>
      </b-tabs>
      <b-tabs  v-if="despacho && despacho.tipo_despacho == 'exportacion'" class="tabs-despacho " fill>
           
            <b-tab  title="Informacion General" v-if="show('dispatch.index')" @click="cambiarSeccion('general')">
                <general
                  v-if="despacho && documentosDespacho && montos && secciones.general"
                  :documentosTabla="documentosDespacho"
                  :pagosTabla="pagosDespacho"
                  :montos="montos"
                  :getComprobante="getComprobante"

                  :despacho="despacho"
                  :usuarios="usuarios"
                  :submit="postDispatch"
                  :eliminarDocumentos ="eliminarDocumentos"
                  :showDeleteDocumentos = "showDeleteDocumentos"
                  :actualizarDocumentos= "actualizarDocumentos"
                  :confirmarDeleteDocumentos= "confirmarDeleteDocumentos"
                  :cerrarModal="cerrarModal"
                  :metodoThen= "metodoThen"                  
                >
                </general>    
            </b-tab>
            <b-tab  title="Pedidor" v-if="show('pedidor.view')" @click="cambiarSeccion('pedidor')">
                <pedidor-exportacion
                  v-if="despacho && listaUsuarios && usuarios && documentos.tipos_documentos && secciones.pedidor"
                  :documentos="documentos"
                  

                  :eliminarDocumento="eliminarDocumento"
                  :getComprobante="getComprobante"
                  :eliminarPago="eliminarPago"
                  
                  :pagosTabla="pagosPorRol.pedidor"
                  :postPago="postPago"
                  :updatePago="updatePago"

                  :documentosTabla="documentosDespacho"
                  :postDocumento="postDocumento"
                  :updateDocumento="updateDocumento"

                  :postInformacionGeneral="postInformacionGeneral"


                  :despacho="despacho"
                  :listaUsuarios="listaUsuarios"
                  :usuarios="usuarios"
                  :submit="postDispatch"
                  
                  :addUser="addUser"
                  :deleteUser="deleteUser"
                
                  :eliminarDocumentos ="eliminarDocumentos"
                  :showDeleteDocumentos = "showDeleteDocumentos"
                  :actualizarDocumentos= "actualizarDocumentos"
                  :confirmarDeleteDocumentos= "confirmarDeleteDocumentos"
                  :cerrarModal="cerrarModal"
                  :metodoThen= "metodoThen"
                  :metodoCatch="metodoCatch"
                >
                </pedidor-exportacion>
                
            </b-tab>
            <b-tab  title="Tramitador Aduanero" v-if="show('tramitador_aduanero.view')" @click="cambiarSeccion('pedidor')">
                <aduanero-exportacion

                  v-if="despacho  && documentos.tipos_documentos && secciones.pedidor"
                  :documentos="documentos"
                  

                  :eliminarDocumento="eliminarDocumento"
                  :getComprobante="getComprobante"
                  :eliminarPago="eliminarPago"
                  
                  :pagosTabla="pagosPorRol.pedidor"
                  :postPago="postPago"
                  :updatePago="updatePago"

                  :documentosTabla="documentosDespacho"
                  :postDocumento="postDocumento"
                  :updateDocumento="updateDocumento"

                  :postInformacionGeneral="postInformacionGeneral"


                  :despacho="despacho"
                  :submit="postDispatch"
                  
                  :eliminarDocumentos ="eliminarDocumentos"
                  :showDeleteDocumentos = "showDeleteDocumentos"
                  :actualizarDocumentos= "actualizarDocumentos"
                  :confirmarDeleteDocumentos= "confirmarDeleteDocumentos"
                  :cerrarModal="cerrarModal"
                  :metodoThen= "metodoThen"
                  :metodoCatch="metodoCatch"

                >
                </aduanero-exportacion>
                
            </b-tab>

            <b-tab  title="Presentador" v-if="show('prestador.view')" @click="cambiarSeccion('presentador')">
                <presentador-exportacion
                  
                  v-if="despacho  && documentos.tipos_documentos  && secciones.presentador"
                  :documentos="documentos"
                  

                  :eliminarDocumento="eliminarDocumento"
                  :getComprobante="getComprobante"
                  :eliminarPago="eliminarPago"
                  
                  :pagosTabla="pagosPorRol.pedidor"
                  :postPago="postPago"
                  :updatePago="updatePago"

                  :documentosTabla="documentosDespacho"
                  :postDocumento="postDocumento"
                  :updateDocumento="updateDocumento"

                  :postInformacionGeneral="postInformacionGeneral"


                  :despacho="despacho"
                  :listaUsuarios="listaUsuarios"
                  :usuarios="usuarios"
                  :submit="postDispatch"
                  
                  :addUser="addUser"
                  :deleteUser="deleteUser"

                  :eliminarDocumentos ="eliminarDocumentos"
                  :showDeleteDocumentos = "showDeleteDocumentos"
                  :actualizarDocumentos= "actualizarDocumentos"
                  :confirmarDeleteDocumentos= "confirmarDeleteDocumentos"
                  :cerrarModal="cerrarModal"
                  :metodoThen= "metodoThen"
                  :metodoCatch="metodoCatch"
                

                >
                </presentador-exportacion>
                
            </b-tab>
            <b-tab  title="Tramitador Servicios" v-if="show('tramitador_servicio.view')" @click="cambiarSeccion('tramitador_servicio')">
                <servicios-exportacion
                  
                  v-if="despacho && listaUsuarios && usuarios && documentos.tipos_documentos   && secciones.pedidor"
                  :documentos="documentos"
                  

                  :eliminarDocumento="eliminarDocumento"
                  :getComprobante="getComprobante"
                  :eliminarPago="eliminarPago"
                  
                  :pagosTabla="pagosPorRol.pedidor"
                  :postPago="postPago"
                  :updatePago="updatePago"

                  :documentosTabla="documentosDespacho"
                  :postDocumento="postDocumento"
                  :updateDocumento="updateDocumento"

                  :postInformacionGeneral="postInformacionGeneral"


                  :despacho="despacho"
                  :listaUsuarios="listaUsuarios"
                  :usuarios="usuarios"
                  :submit="postDispatch"
                  
                  :addUser="addUser"
                  :deleteUser="deleteUser"
                
                  :eliminarDocumentos ="eliminarDocumentos"
                  :showDeleteDocumentos = "showDeleteDocumentos"
                  :actualizarDocumentos= "actualizarDocumentos"
                  :confirmarDeleteDocumentos= "confirmarDeleteDocumentos"
                  :cerrarModal="cerrarModal"
                  :metodoThen= "metodoThen"
                  :metodoCatch="metodoCatch"
                >
                </servicios-exportacion>
                
            </b-tab>
            <b-tab  title="Revisor" v-if="show('revisor.view')" @click="cambiarSeccion('revisor')">
              <revisor
                  v-if="despacho && listaUsuarios && usuarios && secciones.revisor"
                  :documentos="documentos"

                  :eliminarDocumento="eliminarDocumento"
                  :getComprobante="getComprobante"
                  :eliminarPago="eliminarPago"

                  :pagosTabla="pagosPorRol.revisor"
                  :postPago="postPago"
                  :updatePago="updatePago"

                  :despacho="despacho"
                  :listaUsuarios="listaUsuarios"
                  :usuarios="usuarios"
                  :submit="postDispatch"
                  
                  :addUser="addUser"
                  :deleteUser="deleteUser"

                  :eliminarDocumentos ="eliminarDocumentos"
                  :showDeleteDocumentos = "showDeleteDocumentos"
                  :actualizarDocumentos= "actualizarDocumentos"
                  :confirmarDeleteDocumentos= "confirmarDeleteDocumentos"
                  :cerrarModal="cerrarModal"
                  :metodoThen= "metodoThen"
                  :metodoCatch="metodoCatch"
                >

                </revisor>
            </b-tab>           
            <b-tab  title="Historial" @click="cambiarSeccion('historial')">
              
                <historial
                  v-if="secciones.historial"
                >
                </historial>
            </b-tab>
            <b-tab  title="Aforos" @click="cambiarSeccion('aforos')">
              
                <Aforos
                  v-if="secciones.aforos"
                >
                </Aforos>
            </b-tab>
            <b-tab @click="cambiarSeccion('comentarios'); markAsRead()">
              <template #title>
                Comentarios
                <span v-if="notifications.comments.hasNotifications === true" class='badge badge-primary'>{{notifications.comments.amount}}</span>
              </template>
                <comentarios
                  :canManage="canManage"
                  v-if="secciones.comentarios"
                >
                </comentarios> 
            </b-tab>
      </b-tabs>
    </card>

    <b-modal  v-model="showDeleteUser" id="modal-eliminar" centered  @hidden="cerrarModal()">
      <template #modal-title>
        Eliminar usuario
      </template>
      <div class="d-block text-center">
        <h3>¿Desea eliminar al usuario "{{NombreUsuarioEliminar}}"?</h3>
      </div>
      <template #modal-footer>
        <button type="button" class="btn-secundario btn-large " @click="confirmarDeleteUser()">Eliminar</button>
        <button type="button" class="btn-principal btn-large "   @click="cerrarModal()">Cancelar</button>
      </template>
    </b-modal>
    <b-modal  v-model="showDeleteDocumento" id="modal-eliminar" centered  @hidden="cerrarModal()">
      <template #modal-title>
        Eliminar documento
      </template>
      <div class="d-block text-center">
        <h3>¿Desea eliminar el documento "{{TipoDocumentoEliminar}}"?</h3>
      </div>
      <template #modal-footer>
        <button type="button" class="btn-secundario btn-large " @click="confirmarDeleteDocumento()">Eliminar</button>
        <button type="button" class="btn-principal btn-large "   @click="cerrarModal()">Cancelar</button>
      </template>
    </b-modal>
    <b-modal  v-model="showDeletePago" id="modal-eliminar" centered  @hidden="cerrarModal()">
      <template #modal-title>
        Eliminar pago
      </template>
      <div class="d-block text-center">
        <h3>¿Desea eliminar al pago "{{TipoPagoEliminar}}"?</h3>
      </div>
      <template #modal-footer>
        <button type="button" class="btn-secundario btn-large " @click="confirmarDeletePago()">Eliminar</button>
        <button type="button" class="btn-principal btn-large "   @click="cerrarModal()">Cancelar</button>
      </template>
    </b-modal>
  </div>
</template>

<script>
import axios from 'axios'
import TitlePage from '../layout/TitlePage.vue'
import Card from '../ui/Card.vue'
import AdministrativaGarantias from './Secciones/Importacion/AdministrativaGarantias.vue'
import General from './Secciones/Importacion/General.vue'
import Operaciones from './Secciones/Importacion/Operaciones.vue'
import TramitadorServicio from './Secciones/Importacion/TramitadorServicio.vue'
import Facturas from './Secciones/Importacion/Facturas.vue'
import Historial from './Secciones/Historial.vue'
import Pedidor from './Secciones/Importacion/Pedidor.vue'
import Comentarios from './Secciones/Comentarios.vue'
import Aforos from '@/components/Despachos/Secciones/Aforos.vue'
import Prestador from './Secciones/Importacion/Prestador.vue'

import { mapGetters, mapActions } from 'vuex'
import Gestor from './Secciones/Importacion/Gestor.vue'
import TramitadorAduanero from './Secciones/Importacion/TramitadorAduanero.vue'
import Serve from '../ui/prueba/serve.vue'
import PedidorExportacion from './Secciones/Exportacion/PedidorExportacion.vue'
import AduaneroExportacion from './Secciones/Exportacion/AduaneroExportacion.vue'
import PresentadorExportacion from './Secciones/Exportacion/PresentadorExportacion.vue'
import ServiciosExportacion from './Secciones/Exportacion/ServiciosExportacion.vue'
import Revisor from './Secciones/Revisor.vue'
export default {
  components: { Gestor, TitlePage, Card, General, TramitadorServicio, AdministrativaGarantias, Operaciones, Facturas,Historial, Pedidor, Comentarios, Prestador, TramitadorAduanero, Serve, PedidorExportacion, AduaneroExportacion,PresentadorExportacion,ServiciosExportacion,Revisor, Aforos },
  data(){
    return{
      loading_url: false,
      id:'',
          //Despacho
      despacho:null,
      documentosDespacho:null,
      documentosPorRol:[],
      

      pagosDespacho:null,
      pagosPorRol:[],

      IdUsuarioEliminar: '',
      NombreUsuarioEliminar: '',
      showDeleteUser: false,

      showDeleteDocumento: false,
      TipoDocumentoEliminar: '',
      IdTipoDocumentoEliminar: '',

      showDeleteDocumentos: false,

      showDeletePago: false,
      TipoPagoEliminar: '',
      IdTipoPagoEliminar: '',
      
      
      formulario:{},

      posicion: 1,
      usuarios:null,
      listaUsuarios: null,


      //SECCION CREAR DOCUMENTOS
      bl: {},
      documento_transporte:[],
      tipos_documentos: [],
      tipos_vistos_buenos: [],
      documentos:{},

      //SECCION CREAR PAGOS
      montos: null,

      loaded: false,
      isBusy: false,
      //SECCIONES

      secciones: 
        {
          general: true,
          historial: false,
          comentarios: false,
          facturas: false,
          tramitador_aduanero: false,
          operaciones: false,
          administrativa_garantias: false,
          tramitador_servicio: false,
          presentador: false,
          pedidor: false,
          pedidor_exportacion: false,
          gestor: false,  
          revisor: false,   
          aforos: false,   
        },
      // NOTIFICACIONES
      notifications: {
        comments:{
          hasNotifications: false,
          amount: 0
        }
      }

    }
  },
  computed:{
      ...mapGetters({
        permission: 'auth/permission',
        authenticated: 'auth/authenticated',
      }),
    },
  mounted(){
    this.id = this.$route.params.despachoId;

    this.getDispatch();
    this.getNotifications();
    
    
  },
  
  methods: {
    async genShortLink(){
      this.$root.$emit('bv::disable::popover', 'test')
      this.loading_url = true
      await axios.post('api/generate-shorten-link',{
        link: this.$route.fullPath
      }).then(response => {
      this.$root.$emit('bv::enable::popover', 'test')
        this.loading_url = false
        const url = window.location.origin +"/s/"+ response.data.shortLink
        try {
          navigator.clipboard.writeText(url);
          this.$root.$emit('bv::show::popover', 'test')
        } catch($e) {
          alert('Un error ha ocurrido. Por favor contactese con un administrador.');
        }
      }).catch(err => {
        this.loading_url = false

        console.log(err)
      })
    },

    async markAsRead(){
      axios.get('api/notifications/mark-all-as-read').then(response => {
        this.notifications.comments.hasNotifications = false
        this.notifications.comments.amount = 0
      })
    },
    ...mapActions({
          signOutAction: 'auth/signOut'
        }),
    async getNotifications(){
      await axios.get('api/notifications/unread-notifications').then(response => {
        let count = 0
        response.data.data.forEach(element => {
          if(element.data.despacho.toString() === this.id.toString())
          {
            this.notifications.comments.hasNotifications = true
            count = count + 1
          }
        })
        this.notifications.comments.amount = count
      })
    },

    async actualizarDocumentos(){
      await this.getDocumentoPorDespacho(this.despacho.id);
      this.toggleBusy();
    },

    async getDispatch(){
      
      this.usuarios = null
      this.montos = null
      this.listaUsuarios = null
      await axios.get(`api/admin/despachos/${this.id}`)
        .then(res => {
          this.despacho = res.data.data
          if(!this.usuarios) this.usuarios = []
          this.usuarios = this.despacho.usuarios

          this.getUsers(this.despacho.id);
          this.getDocumentoPorDespacho(this.despacho.id);
          this.getPagosPorDespacho(this.despacho.id);
          this.getTiposDocumentos();
          this.loaded = true;
        })
        .catch(err => {
          // if (err.response.status == 401) {
          //         this.signOutAction()
          //         this.$router.replace({ name: 'SignIn' })
          //   }
          if(this.permission && this.authenticated){

            this.$router.replace({ name: 'Despachos' })
            this.$swal({
              icon: 'warning',
              title: 'Lo sentimos',
              text: 'No tiene permisos para ingresar a este despacho',
            });
          }else{
            this.$router.replace({ name: 'SignIn' })
          }
        })
      
    },
    
    async getUsers(id){
      await axios.get(`api/admin/users/user-list-role/${id}`)
        .then(resp => { 
          const usuarios = resp.data;
          this.listaUsuarios = {
            'gestor' : [],
            'pedidores' : [],
            'administrativa' : [],
            'prestadores' : [],
            'tramitadores_servicios' : [],
            'jefe_operaciones' : [],
            'tramitadores_aduaneros' : [],
            'jefe_facturacion' : [],
            'facturadores' : [],
            'pedidor_exportaciones' : [],
            'revisor' :[],
          },
          this.listaUsuarios.pedidores = usuarios.pedidor;
          this.listaUsuarios.prestadores = usuarios.prestador;
          this.listaUsuarios.tramitador_servicios = usuarios.tramitador_servicios ?? [];
          this.listaUsuarios.administrativa = usuarios.administrativa;
          this.listaUsuarios.jefe_operaciones = usuarios.jefe_operaciones;
          this.listaUsuarios.tramitadores_aduaneros = usuarios.tramitador_aduanero;
          this.listaUsuarios.jefe_facturacion = usuarios.jefe_facturas;
          this.listaUsuarios.facturador = usuarios.facturador;
          this.listaUsuarios.revisor = usuarios.revisor;
          this.listaUsuarios.gestor = usuarios.gestor;
          this.listaUsuarios.pedidor_exportaciones = usuarios.pedidor_exportaciones;
        })
        .catch(err => console.log(err))
    },
    async getPagosPorDespacho(despacho){
        this.pagosDespacho = null
        await axios.get(`api/admin/pagos/despacho/${despacho}`)
        .then(res => {
            this.montos = {
              monto_entrante: res.data.monto_entrante,
              monto_saliente: res.data.monto_saliente
            }
            this.pagosDespacho = res.data.pagos
            //this.pagosPorRol = res.data.pagosPorRol
        })
        .catch(err => {
          // if (err.response.status == 401) {
          //         this.signOutAction()
          //         this.$router.replace({ name: 'SignIn' })
          //   }
          console.log(err)})
    },
    async getDocumentoPorDespacho(despacho){
      
        await axios.get(`api/admin/documentos/despacho/${despacho}`)
        .then(res => {
            this.documentosDespacho = res.data.data.documentos
            this.documentosPorRol = res.data.data.documentosPorRol
        })
        .catch(err => {
          // if (err.response.status == 401) {
          //         this.signOutAction()
          //         this.$router.replace({ name: 'SignIn' })
          //   }
          
          console.log(err)})
    },
    async getTiposDocumentos(){
      
      await axios.get(`api/admin/documentos/tipos-documentos/${this.despacho.tipo_despacho}/${this.id}`)
      .then(res => {
          this.bl = res.data.bl
          this.documento_transporte = res.data.documentos_transporte.tipo_transporte
          this.tipos_documentos = res.data.tipos_documentos
          this.tipos_vistos_buenos = res.data.visto_bueno.tipo_vistos_buenos
          this.documentos = {
              bl: this.bl,
              documento_transporte : this.documento_transporte,
              tipos_documentos: this.tipos_documentos,
              tipos_vistos_buenos: this.tipos_vistos_buenos 
          }
      })
      .catch(err => {
        // if (err.response.status == 401) {
        //   this.signOutAction()
        //   this.$router.replace({ name: 'SignIn' })
        // }
        
        
        console.log(err)})
    },
    
    ///POST
    async postDispatch(usuarios){
      
      await axios.put(`api/admin/despachos/assign/${this.id}`,usuarios)
        .then(res=>{
          this.metodoThen()
          this.getDispatch()
        })
        .catch(err => {this.metodoCatch(err)})
    },
    editar(item, index, button) {

      this.$router.replace({ name: 'Editar', params:{despachoId: this.despacho.id} })
    },

    //DOCUMENTOS
    async postDocumento(formData){
      await axios.post(`api/admin/documentos`,formData,
      {
          headers: {
              'Content-Type': 'multipart/form-data'
          },
      })
      .then(res=>{
        this.metodoThen()
        this.actualizarDocumentos()
        this.getDispatch()
      })
      .catch(err => {this.metodoCatch(err)})
    },
    async updateDocumento(id,formData){
      await axios.post(`api/admin/documentos/${id}`,formData,
      {
          headers: {
              'Content-Type': 'multipart/form-data'
          },
      })
      .then(res=>{
        this.metodoThen()
        this.getDispatch()
      })
      .catch(err => {this.metodoCatch(err)})
    },
    //Eliminar un documento
    async confirmarDeleteDocumento(){
      this.showDeleteDocumento = false
      await axios.delete(`api/admin/documentos/${this.IdTipoDocumentoEliminar}`)
      .then(res=>{
        this.metodoThen()
        this.actualizarDocumentos()
      })
      .catch(err => {this.metodoCatch(err)})
    },

    eliminarDocumento(id, nombre){
      this.IdTipoDocumentoEliminar = id;
      this.TipoDocumentoEliminar = nombre; 

      this.showDeleteDocumento = true
    },
    //Eliminar todos los documentos de un despacho{
      async confirmarDeleteDocumentos(){
      this.showDeleteDocumentos = false
      await axios.delete(`api/admin/documentos/eliminar-documentos/${this.id}`)
      .then(res=>{
        this.metodoThen()
        this.actualizarDocumentos()
      })
      .catch(err => {this.metodoCatch(err)})
    },

    eliminarDocumentos(id, nombre){
      this.showDeleteDocumentos = true
    },
    
    //PAGOS
    async postPago(formData){
      await axios.post(`api/admin/pagos`,formData,
      {
          headers: {
              'Content-Type': 'multipart/form-data'
          },
      })
      .then(res=>{
        this.metodoThen()
        this.getDispatch()
      })
      .catch(err => {this.metodoCatch(err)})
    },
    async updatePago(id,formData){
      await axios.post(`api/admin/pagos/${id}`,formData,
      {
          headers: {
              'Content-Type': 'multipart/form-data'
          },
      })
      .then(res=>{
        this.metodoThen()
        this.getDispatch()
      })
      .catch(err => {this.metodoCatch(err)})
    },
     async confirmarDeletePago(){
       this.showDeletePago = false
      await axios.delete(`api/admin/pagos/${this.IdTipoPagoEliminar}`)
      .then(res=>{
        this.metodoThen()
        this.getDispatch()
      })
      .catch(err => {this.metodoCatch(err)})
    },

    eliminarPago(id, nombre){
      this.IdTipoPagoEliminar = id;
      this.TipoPagoEliminar = nombre; 

      this.showDeletePago = true
    },

    async actualizarPagos(){
      await this.getPagosPorDespacho(this.despacho.id);
      this.toggleBusy();
    },

    async getComprobante(){
        this.$swal({
          title: 'Cargando...',
          allowOutsideClick: false,
          showConfirmButton: false,
          didOpen: () => {
            this.$swal.showLoading();
          }
          
        })

        await axios.get(`/api/admin/pagos/${this.id}/obtener-comprobante`).then(resp => {
          this.$swal.close();
          this.metodoThen()
          this.actualizarPagos()

        }).catch(err => {
          this.$swal.close();
          
          this.$swal({
              icon: 'error',
              title: '¡Error!',
              text: err.response.data.message,
          });
            
          
        });
    },


    //INFORMACION GENERAL
    async postInformacionGeneral(form){
      await axios.put(`api/admin/despachos/${this.id}`,form)
      .then(res=>{
        if(res.data.error){
          this.$swal({
            icon: 'error',
            title: '¡Error!',
            text: res.data.error,
          });
        }else{
          this.metodoThen()
          this.getDispatch()
        }
      })
      .catch(err => {
        this.metodoCatch(err)
        
      })
    },
    ///////////
    changeState(id,tipo ,estado){
      Object.keys(this.usuarios).forEach(key =>{
        if(key == tipo){
          this.usuarios[key].forEach(el =>{
            if(el.id == id) el.estado = estado
          })
        }
      })
    },
    //USUARIOS
    addUser(id){
      let listaUsuariosEnviar = []
      this.usuarios.forEach(el =>{
        listaUsuariosEnviar.push(el.id)
      })
      listaUsuariosEnviar.push(id)
      this.postDispatch({users: listaUsuariosEnviar})
    },
    deleteUser(id,nombre ){
      this.IdUsuarioEliminar = id;
      this.NombreUsuarioEliminar = nombre;
      this.showDeleteUser = true;

    },
    confirmarDeleteUser(){
      let listaUsuariosEnviar = []
      this.usuarios.forEach(el =>{
        if(el.id != this.IdUsuarioEliminar)listaUsuariosEnviar.push(el.id)
      })
      this.postDispatch({users: listaUsuariosEnviar})
      this.showDeleteUser = false;
    },
    cerrarModal(){
      this.IdUsuarioEliminar = '';
      this.NombreUsuarioEliminar = '';

      this.IdTipoDocumentoEliminar = '';
      this.IdTipoPagoEliminar = '';

      this.TipoDocumentoEliminar = '';
      this.TipoPagoEliminar = '';

      this.showDeleteUser = false;
      this.showDeleteDocumento = false;
      this.showDeleteDocumentos = false;
      this.showDeletePago = false;
    },
    //EXCEL
    descargarExcel(){
      window.open(process.env.VUE_APP_API_URL +`/api/admin/reportes/despacho/${this.id}`);
    },

    canManage(){
      if (this.despacho.readonly) return false
      return true
    },

    //USUARIOS
    show(permiso){
      if (this.despacho.readonly && permiso !== "dispatch.index") return false
      let existe = false;
      this.permission.permissions.forEach(element => {
          if(element == permiso) existe = true;
          
      });
      if(existe) return true
      return false
    },
    metodoCatch(error){
      if(error.response){
        this.$swal({
          icon: 'error',
          title: '¡Error!',
          text: 'El valor ingresado no es válido, intente nuevamente',
        });
      }
    },
    metodoThen(){
      this.toggleBusy();

    },

    toggleBusy() {
      this.isBusy = !this.isBusy
    },

    //secciones
    cambiarSeccion(seccion){
      Object.keys(this.secciones).forEach(key => {
        if(key == seccion)this.secciones[key] = true;
        else this.secciones[key] = false
      });
    }
  }
}
</script>

<style>
  @media only screen and (max-width: 600px) {
        .nav{
          display: block;
        }
    }

</style>