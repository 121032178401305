<template>
  <div class="container">
      <div class="row mt-5">
          <div class="col-12 mb-4">
              <div class="w-100 d-flex justify-content-between px-4">
                <h4>Información sobre la CDE</h4>
                <h4 class="text-right">Historial CDE</h4>
              </div>
          </div>
        <div class="col-lg-6 mb-4 d-flex justify-content-center">
            <div class="custom-card-2">
                <div class="card-header-2">
                    <h4>
                        Carpeta de Despacho Electrónica <fas icon="folder-open" size="lg"></fas>
                    </h4>
                </div>
                <div v-if="!spinLoad" class="card-body-2">
                    <p>Estado: 
                        <b v-if="cde.estado === 0">CDE Creada</b>
                        <b v-if="cde.estado === 1">Carga de documento</b>
                        <b v-if="cde.estado === 2">Modificacion del documento</b>
                        <b v-if="cde.estado === 3">CDE cerrada</b>
                    </p>
                    <p>Ultima modificacion: <b>{{moment(cde.updated_at).format('DD-MM-YYYY')}}</b> a las 
                        <b>
                            {{moment(cde.updated_at).format('hh:mm a')}}
                        </b>
                    </p>
                    <p>Cantidad de documentos: 
                        <b>    
                            {{cde.documentos.length}}
                        </b>
                    </p>
                    <p v-if="cde.estado === 3 && zipPath !== null">Link de descarga: 
                        <a target="_blank" :href="zipPath">Descargar CDE</a> 
                        <button @click="copyLink()" id="copylink2" class="btn btn-principal p-2 ml-3">Copiar link</button>
                        <b-popover placement="top" triggers="click blur" target="copylink2" title="Copiado!">
                            <b>La url ha sido copiada en el portapapeles correctamente.</b>
                        </b-popover>
                    </p>
                </div>
                <div v-else class="card-body-2">
                    <b-spinner></b-spinner>
                </div>
            </div>
        </div>
         <div class="col-lg-6">
            <b-table style="max-height: 230px; overflow-y: auto" show-empty small :busy="spinLoad_log" :fields="fields_log" :items="cde_log" striped hover responsive>
                <template #table-busy>
                    <div class="text-center my-2" style="color: #FFCE1E !important ">
                    <b-spinner class="align-middle"></b-spinner>
                    <strong> Cargando...</strong>
                    </div>
                </template>
                <template #cell(tipo_text)="row">
                        <span v-if="row.item.tipo === 0" class="badge badge-pill badge-success">{{row.item.tipo_text}}</span>
                        <span v-if="row.item.tipo === 1" class="badge badge-pill badge-primary">{{row.item.tipo_text}}</span>
                        <span v-if="row.item.tipo === 2" class="badge badge-pill badge-warning text-white">{{row.item.tipo_text}}</span>
                        <span v-if="row.item.tipo === 3" class="badge badge-pill badge-danger">{{row.item.tipo_text}}</span>
                </template>
                <template #cell(created_at)="row">
                        <p>{{moment(row.item.created_at).format('DD-MM-YYYY HH:mm')}}</p> 
                </template>
            </b-table>
        </div>
        <div class="col-12 mt-5">
            <div class="w-100 text-left">
                <h4>Documentos</h4>
                <hr>
            </div>
        </div>
       
        <div class="col-lg-12 my-2">
            <b-table show-empty :busy="spinLoad" :fields="fields" :items="cde.documentos" striped hover responsive>
                <template #empty >
                    <h4 class="text-center">No hay documentos para listar</h4>
                </template>
                <template #table-busy>
                    <div class="text-center my-2" style="color: #FFCE1E !important ">
                    <b-spinner class="align-middle"></b-spinner>
                    <strong> Cargando...</strong>
                    </div>
                </template>

                <template #cell(created_at)="row">
                        <p>{{moment(row.item.created_at).format('DD-MM-YYYY HH:mm')}}</p> 
                </template>
                <template #cell(formato)="row">
                        <p v-if="row.item.formato === 0">Documento Xml</p>
                        <p v-if="row.item.formato === 1">Archivo</p>
                        <p v-if="row.item.formato === 2">Documento Digitalizado</p>
                        <p v-if="row.item.formato === 3">Documento de despacho</p>
                </template>
                <template #cell(checkbox)="row">
                    <b-form-checkbox
                        :value="row.item.id"
                        v-model="selectedDocuments"
                        >
                    </b-form-checkbox>
                </template>
                <template #cell(acciones)="row">
                        <button class="btn btn-principal p-2" @click="openModalDetalle(row.item)">Ver detalle</button>
                </template>
            </b-table>
        </div>
      </div>
        <b-modal centered scrollable v-model="showModalDetalle">
            <template #modal-title>
                <h3>Detalles del documento.</h3>
            </template>
            <div class="p-2">
                <h5>Documento</h5>
                <hr>
                <p>Fecha de subida de archivo: <b>{{moment(modalDetalleData.fecha_añadido).format('DD-MM-YYYY')}}</b></p>
                <p>Usuario <b>{{modalDetalleData.user.name}}</b></p>
                <p>Correo Usuario: <b>{{modalDetalleData.user.email}}</b></p>
                <p>Permisos usuario: <b>{{modalDetalleData.user.role.display_name}}</b></p>
                <p>Contenido de documento:
                    <b v-if="modalDetalleData.contenido === 0">Documento Xml</b>
                    <b v-if="modalDetalleData.contenido === 1">Archivo</b>
                    <b v-if="modalDetalleData.contenido === 2">Documento Digitalizado</b>
                    <b v-if="modalDetalleData.contenido === 3">Documento de despacho</b>
                </p>

                <h5>Archivos</h5>
                <hr>
                <p>Archivos: <b>{{modalDetalleData.archivos.length}}</b></p>
                <ul>
                    <li v-for="archivo in modalDetalleData.archivos" :key="archivo.id">
                        Nombre: <b>{{archivo.ruta.split('/').pop()}}</b> 
                        <a target="_blank" :href="baseUrl +'/storage/'+ archivo.ruta">
                            <fas size="md" class="ml-3" icon="download"></fas>
                        </a>
                    </li>
                </ul>

            </div>
            <template #modal-footer>
                <div class="text-right">
                    <button class="btn btn-secondary p-2" @click="showModalDetalle = false">Cerrar</button>
                </div>
            </template>
        </b-modal>
  </div>
</template>

<script>
import axios from 'axios'
export default {
    name: 'cde',
    data(){
        return{
            baseUrl: axios.defaults.baseURL,
            hash:'',
            zipPath: null,
            spinLoad: false,
            spinLoad_log: false,
            cde: {},
            showModalDetalle: false,
            modalDetalleData: {
                contenido: '',
                fecha_añadido: '',
                user: {
                    name: '',
                    email: '',
                    role: {
                        display_name: ''
                    }
                },
                archivos: []
            },
            fields: [
                {key:'created_at', label: 'Fecha añadido'},
                {key:'usuario.name', label: 'Usuario'},
                {key:'formato', label: 'Contenido'},
                {key:'archivos.length', label: 'Archivos'},
                {key:'acciones', label: 'Acciones'},
            ],
            cde_log: [],
            fields_log: [
                {key:'tipo_text', label: 'Accion'},
                {key:'user.name', label: 'Usuario'},
                {key:'created_at', label: 'Fecha'},
            ],
        }
    },
    created(){
        this.getCdeData()
        this.getZipUrl()
        this.getCdeLog()
    },
    methods:{
        copyLink(){
            try {
                navigator.clipboard.writeText(this.zipPath);
                this.$root.$emit('bv::show::popover', 'copylink2')
            } catch($e) {
                alert('Un error ha ocurrido. Por favor contactese con un administrador.');
            }
        },
        async getCdeLog(){
            this.spinLoad_log = true
            await axios.post('api/admin/despacho/'+this.$route.params.despachoId+'/CDE/log').then(resp => {
                this.spinLoad = false
                this.cde_log = resp.data
                this.spinLoad_log = false

            }).catch(err => {
                this.spinLoad = false
                console.log("error", err)
                this.spinLoad_log = false

            })
        },
        openModalDetalle(documento){
            this.modalDetalleData.contenido = documento.formato
            this.modalDetalleData.fecha_añadido = documento.created_at
            this.modalDetalleData.user = documento.usuario
            this.modalDetalleData.archivos = documento.archivos
            this.showModalDetalle = true
        },
        async getZipUrl(){
            await axios.get('api/despacho/'+this.$route.params.despachoId+'/CDE/isZipCreated').then(resp => {
                this.spinLoad = false
                if (resp.data.error === true) return;
                this.zipPath = axios.defaults.baseURL+'/api/CDE'+this.hash
            }).catch(err => {
                this.spinLoad = false
                console.log("error", err)
            })
        },
        async calcularHash(numero) {
            // Convertir el número a una cadena de bytes
            const data = new TextEncoder().encode(numero.toString());

            // Calcular el hash utilizando SHA-256
            const hashBuffer = await crypto.subtle.digest('SHA-256', data);

            // Convertir el buffer de hash a una cadena hexadecimal
            const hashArray = Array.from(new Uint8Array(hashBuffer));
            const hashHex = hashArray.map(byte => byte.toString(16).padStart(2, '0')).join('');
            await axios.put('/api/admin/CDE/' + this.cde.id + '/hash-cde',{
                hash_cde: hashHex
            });
            this.hash = hashHex
            this.zipPath = axios.defaults.baseURL + '/api/CDE?' + hashHex;

        },
        async getCdeData(){
            this.spinLoad = true
            await axios.get('api/admin/despacho/'+this.$route.params.despachoId+'/CDE').then(resp => {
                this.spinLoad = false
                this.cde = resp.data
                this.calcularHash(this.cde.id)
            }).catch(err => {
                this.spinLoad = false
                console.log("error", err)
            })
        }
    }
}
</script>

<style sass>
 .custom-card-2{
     box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.116);
     width: 500px;
 }

  .custom-card-2 .card-header-2{
      background: rgb(211, 241, 247);
      padding: 15px;
  }
    .custom-card-2 .card-body-2{
        padding: 15px;
        font-size: 13px;
    }
</style>