<template>
    <b-form-group
    class="text-left"
    :content-cols="cols"
    :label="label"
    :label-for="id+'-input'"
    >
        <b-form-input :id="id+'-input'" type="text"   v-model="form.rut" @keydown="validarTecla($event)" @input="validarRut(form.rut)" @change="validarRut(form.rut)"   >    
        </b-form-input>
        <div :id="idForm+'-error'" class="error"></div>
    </b-form-group>
</template>

<script>
export default {
    name: 'InputRut',
    props:{
        rut: String,
        cambiarRut: Function,
        cols:Number,
        label: String,
        id: String
    },
    data(){
       return{
           form:{
                rut: ''
            },
            labelForm: '',
            idForm: '',
        } 
    },
    created() {
      this.form.rut = this.rut;
      this.labelForm = this.label;
      this.idForm = this.id;
      if(!this.labelForm){
        this.labelForm = 'Rut';
      }
      if(!this.idForm){
        this.idForm = 'rut'
      }


    },
    mounted(){
      
    },
    methods: {
      validarTecla(event){
        if(event.keyCode == 8){
          return true;
        }
        if(event.target.value.length < 12){
          if(event.keyCode >= 48 && event.keyCode <= 57 ){
            return true;
          }
          if(event.keyCode == 75){
            return true;
          }
        }
        if (event.keyCode >= 96 && event.keyCode <= 105){
          return true;
        }
        event.preventDefault()
      },
    validarRut(value) {

      const error = document.getElementById(this.idForm+'-error');

      let rutFormateado = value.replaceAll("-",'');
      rutFormateado = rutFormateado.replaceAll(".",'');
      
      this.cambiarRut(rutFormateado.substring(0,rutFormateado.length-1) + '-' + rutFormateado.substr(-1))
      this.form.rut = rutFormateado.substring(0,rutFormateado.length-1) + '-' + rutFormateado.substr(-1)
      if(this.form.rut.length >= 6){
        this.cambiarRut(rutFormateado.substring(0,rutFormateado.length-4) +'.' +rutFormateado.substring(rutFormateado.length-4,rutFormateado.length-1)  + '-' + rutFormateado.substr(-1))
        this.form.rut = rutFormateado.substring(0,rutFormateado.length-1) + '-' + rutFormateado.substr(-1)
      }
      if(this.form.rut.length >= 10){
        this.cambiarRut(rutFormateado.substring(0,rutFormateado.length-7) + '.' + rutFormateado.substring(rutFormateado.length-7,rutFormateado.length-4) +'.' +rutFormateado.substring(rutFormateado.length-4,rutFormateado.length-1)  + '-' + rutFormateado.substr(-1))
        this.form.rut =rutFormateado.substring(0,rutFormateado.length-7) + '.' + rutFormateado.substring(rutFormateado.length-7,rutFormateado.length-4) +'.' +rutFormateado.substring(rutFormateado.length-4,rutFormateado.length-1)  + '-' + rutFormateado.substr(-1)
      }
      if(this.form.rut == "-") {this.cambiarRut('') ; this.form.rut = ''};

      if (!value || value == '') {
        error.innerHTML = 'El Rut es requerido';
      }

      // if the field is not a valid email
      if (!this.validaRut(this.form.rut)) {
        error.innerHTML =  'El rut ingresado es invalido';
      } else {
        error.innerHTML =  '';
      }

    },
      validaRut(rutCompleto){
        rutCompleto = rutCompleto.replace("‐","-");
        rutCompleto = rutCompleto.replaceAll(".",'')
        if (!/^[0-9]+[-|‐]{1}[0-9kK]{1}$/.test( rutCompleto ))
            return false;
        var tmp     = rutCompleto.split('-');
        var digv    = tmp[1]; 
        var rut     = tmp[0];
        if ( digv == 'K' ) digv = 'k' ;

        return (this.dv(rut) == digv );
      },
      dv(T){
        var M=0,S=1;
        for(;T;T=Math.floor(T/10))
            S=(S+T%10*(9-M++%6))%11;
        return S?S-1:'k';
      },
    }
}
</script>

<style>

</style>