<template>
  <b-container fluid>
    <b-row>
      <b-col cols="12">
        <div class="text-right">
          <button id="deleteAll" @click="eliminarDocumentos()" class="btn-principal btn-small mr-4 mb-4" style="width: 180px;">
            Eliminar Documentos
            <inline-svg
              class="icono-titulo"
              src="../../assets/images/basura.svg"
              title="My Image"
            />
          </button>
        </div>
        
        <b-modal  v-model="showDeleteDocumentos" id="modal-eliminar" centered  @hidden="cerrarModal()">
        <template #modal-title>
          Eliminar documentos
        </template>
        <div class="d-block text-center">
          <h3>¿Desea eliminar todos los documentos?</h3>
        </div>
        <template #modal-footer>
          <button type="button" class="btn-secundario btn-large " @click="confirmarDeleteDocumentos()">Eliminar</button>
          <button type="button" class="btn-principal btn-large "   @click="cerrarModal()">Cancelar</button>
        </template>
      </b-modal>


        <form-documento
          v-if="agregarDocumento && !editar"
          :postDocumento="postDocumento"
          :documentos="documentos"
          :cancelar="cancelar"
        >
        </form-documento>

        <form-documento-editar
          v-if="agregarDocumento && editar && documentoEditar"
          :postDocumento="updateDocumento"
          :documentos="documentos"
          :documentoEditar="documentoEditar"
          :cancelar="cancelar"
          :files_old="files_old"
        >
          <template slot="cancelar">
            <b-button
              class="btn-secundario btn-small"
              @click="cancelar()"
              type="button"
            >
              Cancelar
            </b-button>
          </template>
        </form-documento-editar>
      </b-col>
      <b-col cols="12" class="text-left mx-auto">
        <b-table
          striped
          hover
          :items="documentosTabla"
          :fields="fields"
          show-empty
          responsive
        >
          <template #empty>
            <h4 class="text-center">No hay documentos disponibles</h4>
          </template>

          <template #cell(tipo)="row">
            <p v-if="row.item.tipo_documentos_id != 20">
              {{ row.item.tipo.nombre }}
            </p>
            <p v-else>{{ row.item.base.nombre_documento }}</p>
          </template>

          <template #cell(documentos)="row">
            <b-button
              class="btn-archivo-tabla"
              v-b-tooltip.hover
              :title="documento.ruta"
              v-for="(documento, index) in row.item.archivos"
              :key="index"
              style="width: 20px; height: 80px"
              @click="downloadDocumento(row.item.id)"
            >
              <inline-svg
                v-if="documento.ruta.split('.').at(-1) == 'pdf'"
                src="../../assets/images/archivo-pdf.svg"
              />
              <inline-svg
                v-if="
                  documento.ruta.split('.').at(-1) == 'png' ||
                  documento.ruta.split('.').at(-1) == 'jpg' ||
                  documento.ruta.split('.').at(-1) == 'jpeg'
                "
                src="../../assets/images/archivo-png.svg"
              />
              <inline-svg
                v-if="
                  documento.ruta.split('.').at(-1) == 'xlsx' ||
                  documento.ruta.split('.').at(-1) == 'xls'
                "
                src="../../assets/images/732220.svg"
              />
            </b-button>
          </template>

          <template #cell(fecha_recepcion)="row">
            <p>{{ moment(row.item.fecha_recepcion).format("DD-MM-YYYY") }}</p>
          </template>

          <!-- <template #cell(actions)="row">
                        <b-button  @click="downloadDocumento(row.item.id)" class="btn-extra-primario btn-small mr-1">
                            Ver 
                        </b-button>
                        <b-button v-if="agregarDocumento"  @click="editarDocumento(row.item)" class="btn-extra-secundario btn-small mr-1">
                            Editar <inline-svg  class="icono-titulo" src="../../assets/images/Editar.svg" title="My Image"/>
                        </b-button>
                        <b-button v-if="agregarDocumento" @click="eliminarDocumento(row.item.id, row.item.tipo.nombre)" class=" btn-extra-primario btn-small">
                            Eliminar <inline-svg  class="icono-titulo" src="../../assets/images/basura.svg" title="My Image"/>
                        </b-button>
                    </template> -->
          <template #cell(actions)="row">
            <div class="col text-center">
              <b-button
                @click="downloadDocumento(row.item.id)"
                class="btn-extra-primario btn-small mr-1"
              >
                Ver
              </b-button>
              <b-button
                v-if="agregarDocumento"
                @click="editarDocumento(row.item)"
                class="btn-extra-secundario btn-small mr-1"
              >
                Editar
                <inline-svg
                  class="icono-titulo"
                  src="../../assets/images/Editar.svg"
                  title="My Image"
                />
              </b-button>
              <b-button
                v-if="agregarDocumento"
                @click="eliminarDocumento(row.item.id, row.item.tipo.nombre)"
                class="btn-extra-primario btn-small"
              >
                Eliminar
                <inline-svg
                  class="icono-titulo"
                  src="../../assets/images/basura.svg"
                  title="My Image"
                />
              </b-button>
            </div>
            <div v-if="validate_permission" class="col text-center">
              <b-button
                @click="validar(row.item.id, 'Aceptado')"
                class="btn-extra-secundario btn-small mr-1"
              >
                Aceptado
                <inline-svg
                  class="icono-titulo"
                  src="../../assets/images/Editar.svg"
                  title="My Image"
                />
              </b-button>
              <b-button
                @click="validar(row.item.id, 'Rechazado')"
                class="btn-extra-primario btn-small"
              >
                Rechazado
                <inline-svg
                  class="icono-titulo"
                  src="../../assets/images/basura.svg"
                  title="My Image"
                />
              </b-button>
            </div>
          </template>
        </b-table>
      </b-col>
    </b-row>
  </b-container>
</template>


<script>
import axios from "axios";
import FormDocumento from "../../formulario/FormDocumento.vue";
import FormDocumentoEditar from "../../formulario/FormDocumentoEditar.vue";
import moment from "moment";
export default {
  components: { FormDocumento, FormDocumentoEditar },
  props: {
    agregarDocumento: Boolean,
    documentosTabla: Array,
    documentos: Object,
    validate_permission: { type: Boolean, required: false, default: false },
    postDocumento: Function,
    updateDocumento: Function,
    eliminarDocumento: Function,
    showDeleteDocumentos: Boolean,
    eliminarDocumentos: Function,
    actualizarDocumentos: Function,
    confirmarDeleteDocumentos: Function,
    cerrarModal: Function,
    metodoThen: Function,
    metodoCatch: Function,
    
  },
  data() {
    return {
      fields: [
        { key: "tipo", label: "Tipo" },
        { key: "fecha_recepcion", label: "Fecha" },
        { key: "usuario.name", label: "Usuario" },
        { key: "documentos", label: "Documento" },
        { key: "estado", label: "Estado Validación" },
        { key: "actions", label: "Acciones", class: "text-center" },
      ],
      despacho: "",
      editar: false,

      documentoEditar: null,

      files_old: [],

      idDespacho: "",

    };
  },
  mounted() {
    this.idDespacho = this.$route.params.despachoId;
    this.id = this.$route.params.despachoId;
    this.getDispatch();
  },
  methods: {
    editarDocumento(documento) {
      this.documentoEditar = documento;

      this.files_old = [];
      this.documentoEditar.archivos.forEach((el) => {
        this.files_old.push({ id: el.id, name: el.ruta });
      });
      this.editar = true;
    },
    cancelar() {
      this.editar = false;
    },

    async downloadDocumento(id) {
      //window.open(process.env.VUE_APP_API_URL +`/api/admin/archivo/${id}/descargar`);
      this.$router.replace({
        name: "Documento",
        params: { despachoId: this.idDespacho, documentoId: id },
      });
    },

    async getDispatch() {
      await axios
        .get(`api/admin/despachos/${this.idDespacho}`)
        .then((res) => {
          this.despacho = res.data.data;
          this.getDocumentoPorDespacho(this.despacho.id);
        })
        .catch((err) => {});
    },

    async getDocumentoPorDespacho(despacho) {
      await axios
        .get(`api/admin/documentos/despacho/${despacho}`)
        .then((res) => {
          this.documentosDespacho = res.data.data.documentos;
          //this.documentosTabla = res.data.data.documentosPorRol.prestador;
        })
        .catch((err) => {
          // if (err.response.status == 401) {
          //         this.signOutAction()
          //         this.$router.replace({ name: 'SignIn' })
          //   }

          console.log(err);
        });
    },
    
    async validar(id, estado) {
      await axios
        .post(`api/admin/documentos/${id}/validar`, { estado: estado })
        .then((res) => {
          this.getDispatch();
        })
        .catch((err) => {
          console.log(err);
        });
    },
  },
};
</script>

<style>
</style>