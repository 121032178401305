
<template>
  <div>
        <b-table v-if="historial" striped hover :items="historial.data" :fields="fieldsHistorial" :busy="isBusy" responsive>

            <template #cell(created_at)="row">
               <p>{{moment(row.item.created_at).format('DD-MM-YYYY HH:mm')}}</p> 
            </template>
            <template #table-busy>
                <div class="text-center my-2" style="color: #FFCE1E !important ">
                <b-spinner class="align-middle"></b-spinner>
                <strong> Cargando...</strong>
                </div>
            </template>
        
        </b-table>
        <b-pagination
            v-if="historial"
            v-model="currentPage"
            
            style="width: 100px; margin: auto;"
            
            :total-rows="historial.total"
            :per-page="historial.per_page"
            align="fill"
            size="sm"
            class="my-0"
        ></b-pagination>
  </div>
</template>

<script>
import moment from 'moment';
import axios from 'axios';
export default {
     watch:{
      currentPage:function(newValue, oldValue){
          this.toggleBusy()
          this.getHistorial(this.currentPage).then(rep => this.toggleBusy());
      }
    },
    data(){
        return{
            currentPage: 1,
            isBusy: false,
            itemsHistorial:[
                {accion: 'Modifico usuario bl', fecha: '08-09-2021',  usuario: 'usuario'},
                {accion: 'Subida Documento BL', fecha: '08-09-2021',  usuario: 'usuario'},
                {accion: 'Subida Documento Transporte', fecha: '08-09-2021',  usuario: 'usuario'},
                {accion: 'Asigno usuario pedidor', fecha: '08-09-2021',  usuario: 'usuario'},
                {accion: 'Cambio estado', fecha: '08-09-2021',  usuario: 'usuario'},
                {accion: 'Subida documento vistos buenos', fecha: '08-09-2021',  usuario: 'usuario'},
            ],
            fieldsHistorial: [
                {key:'mensaje', label: 'Accion'},
                {key:'created_at', label: 'Fecha'},
                {key:'username', label: 'Usuario'},
            ],

            historial: null,
            id: null,
        }
    },
    mounted(){
        
        this.id = this.$route.params.despachoId;
        this.getHistorial(this.currentPage)
    },
    methods:{
        
        async getHistorial(page){
            const formHistorial = {page:page}
            this.historial = null
            await axios.post(`api/admin/despachos/historial/${this.id}`,formHistorial)
            .then(resp => {
                this.historial = resp.data
            })
            .catch(err => console.log(err))
        },
        toggleBusy() {
          this.isBusy = !this.isBusy
        },
    }
}
</script>

<style>

</style>