<template>
<b-row>
    <b-col sm="12" md="12" lg="6">
        <b-form-group
        class="text-left form-label"
        label="Nombre Extraportuario" 
        label-for="nombre-input" 
    >
        <b-form-input id="nombre-input" v-model="form.nombre" min="3" max="190" required>    
        </b-form-input>
        <div class="text-left">
            <span id="nombre"></span>     
        </div>
    </b-form-group>
    </b-col>
    <b-col sm="12" md="12" lg="6">
        <b-form-group
                v-if="aduanas"
                class="text-left"
                label="Aduana"
                label-for="aduana-select"
            >
            <select class="form-control border border-secondary"  id="aduana-select" v-model="form.aduanas_id"  required>
                <option value="" disabled>Seleccione una Aduana</option>
                <option v-for="(aduana,index) in aduanas" :key="index" :value="aduana.value">{{aduana.text}}</option>
            </select>
        </b-form-group>
    </b-col>
    <b-col cols="12">
        <button
            class="btn-principal btn-large m-2"
            type="submit"
        >Guardar</button>
        <slot></slot>
    </b-col>
</b-row>
</template>

<script>
export default {
    name:"FormUsuario",

    props: {
        form: Object,
        user: Object,
        aduanas: Array,

    },
}
</script>

<style>

</style>