const Role = {
    superuser: 'superuser',
    admin : 'admin',
    gestor : 'gestor',
    pedidor: 'pedidor',
    prestador: 'prestador',
    tramitador_servicios: 'tramitador_servicios',
    administrativa: "administrativa",
    jefe_operaciones: "jefe_operaciones",
    tramitador_aduanero: 'tramitador_aduanero',
    jefe_facturas: 'jefe_facturas',
    facturador: 'facturador',
    pedidor_exportaciones: 'pedidor_exportaciones',
    revisor:'revisor',
    supervisor:'supervisor',

}

export default Role;

