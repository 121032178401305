<template>
  <div>
      <b-form class="form-input" @submit.prevent="submit(formFiltro)" >
          <b-row>
                <b-col cols="12" class="text-left filtros-dashboard" v-if="filtro.estado == true">
                    <h4 class="titulo">Filtros Dashboard</h4>
                    <b-row class="fecha">
                        <b-col cols="12">
                            <h6 class="dato">
                            Fecha
                            </h6>
                        </b-col>
                        <b-col sm="12" md="12" lg="4">
                            <p class="valor" v-if="fecha == '3'">7 Días</p>
                            <p class="valor" v-if="fecha == '2'">1 mes</p>
                            <p class="valor" v-if="fecha == '1'">1 año</p>
                        </b-col>
                    </b-row>
                    <b-row class="filtro">
                        <b-col cols="12">
                            <h6 class="dato">
                            {{filtro.text}}
                        </h6>
                        </b-col>
                        <b-col sm="12" md="12" lg="4">
                            <p class="valor">{{filtro.value}}</p>
                        </b-col>
                    </b-row>
                </b-col>
                <b-col sm="12" md="12" lg="7">
                    <b-form-group
                        class="text-left"
                        :label=label_filtro
                        label-for="agregar_filtro-select"
                    >
                        <select class="border border-secondary" id="agregar_filtro-select" v-model="tipoFiltro"  >
                            <option v-bind:key="tipo.value" v-for="tipo in tipos" :value="tipo.value" > {{tipo.text}}</option>
                        </select>
                    
                    </b-form-group>
                    
                </b-col>
                <b-col sm="12" md="12" lg="3" class="text-left mt-4 mb-4">
                  <button
                        class="btn-principal btn-small mt-2"
                        type="button"
                        @click="agregar"
                    >
                        Agregar filtro
                    </button>
                    
                   
                </b-col>
                <b-col sm="12" md="12" lg="8" class="text-left  mt-4 mb-4">
                    <button
                        v-if="formFiltro.length > 0  || filtro.estado == true"
                        type="button"
                        @click="borrarFiltro"
                        class="btn-secundario btn-large mr-2"
                    >
                        Limpiar
                    </button>
                    <button
                        v-if="formFiltro.length > 0"
                        type="submit"
                        class="btn-principal btn-large mr-2"
                    >
                        Filtrar
                    </button>
                </b-col>
               
              
              <b-col cols="12"  v-bind:key="filtro.id" v-for="filtro in formFiltro">
                <nuevo-filtro
                    :filtro="filtro"
                    :borrar="borrar"
                    :changeDate="changeDate"
                    :changeValue="changeValue"
                    :filtros="filtros"
                >
                </nuevo-filtro>
              </b-col>
          </b-row>

          
        
      </b-form>
  </div>
</template>

<script>
import NuevoFiltro from './NuevoFiltro.vue'
import { mapGetters, mapActions } from 'vuex'
export default {
  components: { NuevoFiltro },
    
    props:{
        limpiar: Function,
        submit: Function,
        filtros:Object,
        label_filtro:String
    },
    data(){
        return{
            
            id: 1,
            tipoFiltro: 'fecha',
            tipos:[
            //NOMBRES Y VALORES DENTRO DEL SELECT
                {text: 'Rango de fechas', value: 'fecha'},
                {text: 'Referencia Cliente', value: 'ref_cliente'},
                {text: 'Nombre Cliente', value: 'cliente_id'},
                //{text: 'Despacho', value: 'codigo'},    
                {text: 'Pedidor', value: 'pedidor'},
                {text: 'Garantías y Correcciones', value: 'administrativas'},
                {text: 'Revisor', value: 'revisor'},
                {text: 'Agentes Carga', value: 'agentes_carga'},
                {text: 'Caracteristicas Carga', value: 'caracteristicas_carga'},
                {text: 'Estados de Despacho', value: 'estados_despacho'},
                {text: 'Estados Retiro', value: 'estados_retiro'},
                {text: 'Gestores', value: 'gestores'},
                {text: 'Jefes de Operaciones', value: 'jefes_operaciones'},
                {text: 'Nombres de Transporte', value: 'nombres_transporte'},
                {text: 'Nombre de nave', value: 'nombre_nave'},
                {text: 'Tipos Carga', value: 'tipos_carga'},
                {text: 'Tipos Condicion', value: 'tipos_condicion'},
                {text: 'Tramitadores Aduaneros', value: 'tramitadores_aduaneros'},
                {text: 'Aduana', value: 'aduana'}, 
                {text: 'Aforo', value: 'aforo'},
                {text: 'Número de Manifiesto', value: 'numero_manifiesto'},
                {text: 'Número de Documento de Transporte', value: 'numero_documento_transporte'},
            ],
            formFiltro:[]
        }
    },
    computed: {
      
      ...mapGetters({
        filtro: 'filtros/filtro',
        fecha: 'filtros/fecha',
      })
    },
    mounted(){
    },
    methods:{
        agregar(){
            //se agrega el campo de filtro abajo
            this.id = this.id + 1;
            switch(this.tipoFiltro){
                case 'fecha':
                    this.formFiltro.push({id: this.id, tipo:'fecha', fecha: ['','']})
                    break;
                case 'ref_cliente':
                    this.formFiltro.push({id: this.id, tipo:'ref_cliente', value:''})
                    break;
                case 'cliente_id':
                    this.formFiltro.push({id: this.id, tipo:'cliente_id', value:''})
                    break;
               /*  case 'codigo':
                    this.formFiltro.push({id: this.id, tipo:'codigo', value:''})
                    break; */
                case 'pedidor':
                    this.formFiltro.push({id: this.id, tipo:'pedidor', value: ''})
                    break;
                case 'revisor':
                    this.formFiltro.push({id: this.id, tipo:'revisor', value: ''})
                    break;
                case 'administrativas':
                    this.formFiltro.push({id: this.id, tipo:'administrativas', value: ''})
                    break
                case 'agentes_carga':
                    this.formFiltro.push({id: this.id, tipo:'agentes_carga', value: ''})
                    break
                case 'caracteristicas_carga':
                    this.formFiltro.push({id: this.id, tipo:'caracteristicas_carga', value: ''})
                    break
                case 'estados_despacho':
                    this.formFiltro.push({id: this.id, tipo:'estados_despacho', value: ''})
                    break
                case 'estados_retiro':
                    this.formFiltro.push({id: this.id, tipo:'estados_retiro', value: ''})
                    break
                case 'gestores':
                    this.formFiltro.push({id: this.id, tipo:'gestores', value: ''})
                    break
                case 'jefes_operaciones':
                    this.formFiltro.push({id: this.id, tipo:'jefes_operaciones', value: ''})
                    break
                case 'nombres_transporte':
                    this.formFiltro.push({id: this.id, tipo:'nombres_transporte', value: ''})
                    break
                case 'nombre_nave':
                    this.formFiltro.push({id: this.id, tipo:'nombre_nave', value: ''})
                    break
                case 'tipos_carga':
                    this.formFiltro.push({id: this.id, tipo:'tipos_carga', value: ''})
                    break
                case 'tipos_condicion':
                    this.formFiltro.push({id: this.id, tipo:'tipos_condicion', value: ''})
                    break
                case 'tramitadores_aduaneros':
                    this.formFiltro.push({id: this.id, tipo:'tramitadores_aduaneros', value: ''})
                    break
                case 'aduana':
                    this.formFiltro.push({id: this.id, tipo:'aduana', value: ''})
                    break
                case 'aforo':
                    this.formFiltro.push({id: this.id, tipo:'aforo', value: ''})
                    break
                case 'numero_manifiesto':
                    this.formFiltro.push({id: this.id, tipo:'numero_manifiesto', value: ''})
                    break
                case 'numero_documento_transporte':
                    this.formFiltro.push({id: this.id, tipo:'numero_documento_transporte', value: ''})
                    break
            }
        },
        borrar(id){
           this.formFiltro = this.formFiltro.filter(el => el.id != id)
           if(this.formFiltro.length == 0) this.borrarFiltro()
           else this.submit(this.formFiltro)
        },
        borrarFiltro(){
            this.formFiltro = []
            this.limpiar()
        },
        

        ///CHANGE
        changeDate(id, tipo, campo){
            this.formFiltro.forEach(el => {
                if(el.id == id){
                    if(tipo == 'inicio') el.fecha[0] = campo;
                    else el.fecha[1] = campo;
                }
            })
        },
        changeValue(id, campo){
            this.formFiltro.forEach(el => {
                if(el.id == id){
                    el.value = campo;
                }
            })
        }
    }
}
</script>

<style>
    .filtros-dashboard .titulo{
        font-weight: 500;
    }
    .filtros-dashboard .fecha{
        margin-top: 10px;
    }
    .filtros-dashboard .filtro{
        margin-top: 10px;
    }
    .filtros-dashboard .fecha .valor, .filtro .valor{
        padding: 7px;
        padding: 5px 7px;
        background-color: #F0F5FA;
    }
</style>