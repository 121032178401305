<template>
  <div>
      <div v-if="!spinLoad">
          <b-table striped hover responsive show-empty :items="aforos" :fields="fieldsAforos" :busy="isBusy" >
              <template #empty >
                <h4 class="text-center">No hay Aforos disponibles</h4>
                </template>

                <template #cell(created_at)="row">
                    <p>{{moment(row.item.created_at).format('DD-MM-YYYY HH:mm')}}</p> 
                </template>
                 <template #cell(acciones)="row">
                    <button type="button" @click="activeAforoModal(row)" class="btn boton-card btn-principal btn-small btn-secondary">Ver detalle</button>
                </template>

                <template #table-busy>
                    <div class="text-center my-2" style="color: #FFCE1E !important ">
                    <b-spinner class="align-middle"></b-spinner>
                    <strong> Cargando...</strong>
                    </div>
                </template>
        </b-table>
        <!-- <b-pagination
            v-if="aforos.length !== 0"
            v-model="currentPage"
            
            style="width: 100px; margin: auto;"
            
            :total-rows="aforos.total"
            :per-page="aforos.per_page"
            align="fill"
            size="sm"
            class="my-0"
        ></b-pagination> -->
      </div>
      <div v-else class="p-4">
            <b-spinner class="align-middle"></b-spinner>
            <strong> Cargando...</strong>
      </div>

        <b-modal class="x-hidden" size="lg" scrollable v-model="aforoModal.active">
            <template #modal-title>
                <div class="px-2 pt-3">
                    <h5><b>Detalle aforo</b></h5>
                </div>
            </template>
            <!-- contenido del modal -->
            <div class="px-4 pb-4">
                <div class="form-group">
                <div class="row">
                    <div class="col-sm-6">
                        <label for="">Correo del inspector</label>
                        <input type="text" readonly="on" :value="aforoModal.data.email" class="form-control">
                    </div>
                    <div class="col-sm-6">
                        <label for="">Funcionario agencia</label>
                        <input type="text" readonly="on" :value="aforoModal.data.funcionario_agencia" class="form-control">
                    </div>
                </div>
            </div>
            <div class="form-group">
                <div class="row">
                    <div class="col-sm-6">
                        <label for="">Fecha aforo</label>
                        <input type="text" readonly="on" :value="moment(aforoModal.data.fecha_aforo).format('DD-MM-YYYY HH:mm')" class="form-control">
                    </div>
                    <div class="col-sm-6">
                        <label for="">Prestador de servicio</label>
                        <input type="text" readonly="on" :value="aforoModal.data.prestador_servicio" class="form-control">
                    </div>
                </div>
            </div>
            <div class="form-group">
                <div class="row">
                    <div class="col-sm-6">
                        <label for="">Número de sello</label>
                        <input type="text" readonly="on" class="form-control">
                    </div>
                    <div class="col-sm-6">
                        <label for="">Surucursal ciudad</label>
                        <input type="text" readonly="on" :value="aforoModal.data.sucursal" class="form-control">
                    </div>
                </div>
            </div>
            <div class="form-group">
                <div class="row">
                    <div class="col-sm-6">
                        <label for="">Puntos</label>
                        <ul>
                            <li v-for="(punto, index) in aforoModal.data.puntos" :key="index">{{punto}}</li>
                        </ul>
                    </div>
                    <div class="col-sm-6">
                        <label for="">Comentarios</label>
                        <textarea v-model="aforoModal.data.observaciones" class="form-control" readonly="on" rows="5"></textarea>
                    </div>
                </div>
            </div>
            <div class="form-group">
                <label for="">Fotos</label>
                <div class="img-container">
                    <img v-for="(url, index) in aforoModal.data.fotos" :key="index" :src="url" class="aforo-img zoom p-2" alt="image">
                </div>
            </div>
            </div>
            <!-- <pre>
                {{aforoModal.data}}
            </pre> -->
            <!-- fin contenido modal -->
            <template #modal-footer>
                <button type="button" class="btn btn-secondary btn-small">Cerrar</button>
            </template>
        </b-modal>
  </div>
</template>

<script>
import axios from 'axios'
export default {
name:'Aforos',
data(){
    return{
        spinLoad: false,
        id: 0,
        isBusy: false,
        currentPage: 1,
        aforos: [],
        aforoModal:{
            active: false,
            data: {}
        },
        fieldsAforos: [
            {key:'email', label: 'Correo'},
            {key:'observaciones', label: 'Observaciones'},
            {key:'sucursal', label: 'Sucursal'},
            {key:'created_at', label: 'Fecha creacion'},
            {key:'acciones', label: 'Acciones', tdClass:'w-0'},
        ],
    }
},
created(){
    this.id = this.$route.params.despachoId;
    this.getData()
},
methods:{
    async getData(){
        // this.spinLoad = true
        this.isBusy = true
        await axios.get('api/admin/aforos/despacho/' + this.id).then(response => {
            this.isBusy = false
            this.aforos = response.data.data
            console.log(response)
        }).catch(err => {
            // this.spinLoad = false
            this.isBusy = false
            console.log(err)
        })
    },
    activeAforoModal(row){
        this.aforoModal.active = true
        this.aforoModal.data = row.item
    }
}
}
</script>

<style scoped>
.form-control{
    border-radius: 3px !important;
}
label{
    font-weight: normal;
    color: #043161 !important;
    font-size: 12px;
}
.form-control:disabled, .form-control[readonly]{
    background-color: #F0F5FA;
    border-bottom: none;
    opacity: 1;
    padding: 0px 20px;
    color: #043161;
    font-weight: bold;
    box-shadow: none;
    cursor: default;
}
.img-container{
    display: flex;
    flex-wrap: wrap;
}
.aforo-img{
    display: block;
    width: 33.3333333%;
}

</style>