<template>
    <b-row>
        <!-- <b-col sm="12" md="12" lg="2" hidden>
            <b-form-group class="text-left" :content-cols="12" label-for="tipo_select-select">
                <select class="form-control border border-secondary" v-model="nuevoSelect" @change="showSelect" required>
                    <option value="0" disabled>Selección</option>
                    <option value="1">Individual</option>
                    <option value="2">Multiple</option>
                </select>
            </b-form-group>
        </b-col> -->
        <b-col sm="12" md="12" lg="4">
            <b-form-group class="text-left" :content-cols="12" label-for="tipo_usuario-select">
                <select class="form-control border border-secondary" v-model="nuevoTipo" @change="tipo(nuevoTipo)" required>
                    <option value="0" disabled>Selecciona un Rol</option>
                    <option v-for="(tipo, index) in tiposUsuario" :key="index" :value="tipo.value">{{ tipo.text }}</option>
                </select>
            </b-form-group>
        </b-col>
        <b-col sm="12" md="12" lg="5">
            <b-form-group class="text-left" :content-cols="12" label-for="nuevo-select">
                <div v-if="usuarios.length > 0">
                    <select class="form-control border border-secondary" v-model="nuevoUsuario" @change="user(nuevoUsuario)" required>
                        <option value="0" disabled>Selecciona un usuario</option>
                        <option v-for="(usuario, index) in usuarios" :key="index" :value="usuario.id">{{ usuario.name }}
                        </option>
                    </select>
                </div>

                <!-- <div v-else-if="usuarios.length > 0 && this.mostrarSelect2" hidden>

                    <vue-multi-select
                    v-model="nuevoUsuario"
                    class="form-control"
                    @change="user(nuevoUsuario)"
                    search
                    historyButton
                    :filters="filters"
                    :position="position"
                    :options="options"
                    :selectOptions="listaUsuariosSelect" /> 
                </div> -->

                <div v-else-if="usuarios.length <= 0 && nuevoTipo != 0">
                    No hay usuarios disponibles
                </div>
            </b-form-group>
        </b-col>
        <b-col sm="12" md="12" lg="2">
            <button class="mt-3 btn-principal btn-large" type="submit">
                Asignar
            </button>
            <slot></slot>
        </b-col>
    </b-row>
</template>

<script>
/* import vueMultiSelect from 'vue-multi-select'
import 'vue-multi-select/dist/lib/vue-multi-select.css'; */

export default {
    /* components: {
        vueMultiSelect,
    }, */
    props: {
        listaUsuarios: Object,

        submit: Function,
        titulo: String,
        campo: String,
        seccion: String,
        changeForm: Function,
        changeUser: Function,

        changeTipo: Function,


        id: String,

        usuarios: Array,
        tipoUsuario: String,
        usuario: Number,

        tiposUsuario: Array
    },
    data() {
        return {
            nuevoUsuario: 0,

            nuevoTipo: 0,

            nuevoSelect: 0,

            /* mostrarSelect: false,

            mostrarSelect2: false,

            listaUsuariosSelect: [],

            selected: null,

            filters: [{
                nameAll: 'Select all',
                nameNotAll: 'Deselect all',
                func() {
                    return true;
                },
            }],
            position: 'top-right',
            options: {
                multi: true,
                groups: false,
                cssSelected: option => (option.selected ? { 'background-color': '#5764c6' } : ''),
            }, */
        }
    },

    watch: {
        usuario: function (newValue, oldValue) {
            this.nuevoUsuario = this.usuario
        },
        tipoUsuario: function (newValue, oldValue) {
            this.nuevoTipo = this.tipoUsuario
        }
    },
    methods: {
        tipo(tipo) {
            this.nuevoTipo = this.changeTipo(tipo)
        },
        user(id) {
            this.nuevoUsuario = this.changeUser(id)
        },

    }

}
</script>

<style></style>