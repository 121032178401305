<template>
    <div>
      <TitlePage title="Proveedores"/>
      <card title="Proveedores" :permiso="'providers.manage'" :accionBoton="agregar" responsive :boton="true" text="Agregar">
        <!-- <modal :show="showEditar" id="modal-editar" :cerrarModal="cerrarModal"></modal> -->
        <div class="d-flex justify-content-end align-items-center">
          <label for="">Filtro proveedores</label>
          <input type="text" v-model="filter" placeholder="filtrar proveedores" class="form-control w-25 m-3">
          <b-spinner v-if="spinLoad" label="Cargando..."></b-spinner>
          <button @click="filtrarProveedores()" class="btn btn-principal mx-4 px-2 py-1"><fas icon="search"></fas></button>
          <button @click="filter = ''; filtrarProveedores()" class="btn btn-principal mr-4 px-2 py-1">Restablecer</button>
        </div>  
        <div class="table-container">
          <b-table class="text-left"
            hover 
            :items="items" :fields="fields" :busy="isBusy" responsive>> 
  
            <template #cell(rut)="row">
              <p>
                {{row.item.rut}}-{{row.item.dv}}
              </p>
            </template>
            <template #cell(state)="row">
              <div class="estado-despacho importacion" v-if="!row.item.deleted_at">
                <p class="" >
                  Activo
                </p>
              </div>
              <div class="estado-despacho exportacion" v-else>
                <p >
                  Eliminado
                </p>
              </div>
            </template>
  
            <template #cell(domicilio)="row">
              <p style="max-width: 175px; word-wrap: break-word">
                {{row.item.domicilio ? row.item.domicilio : 'n/a'}}
              </p>
            </template>
            <template #cell(correo)="row">
              <p>
                {{row.item.correo ? row.item.correo : 'n/a'}}
              </p>
            </template>
            <template #cell(telefono)="row">
              <p>
                {{row.item.telefono ? row.item.telefono : 'n/a'}}
              </p>
            </template>
  
            <template #cell(actions)="row">
                <div class="d-flex flex-column">
                    <button v-permisos="'providers.manage'" class="btn-extra-secundario btn-small" v-if="row.item.deleted_at" size="sm" v-b-modal.modal-activar @click="activar(row.item)" >
                        Activar
                    </button>
                    <button  v-permisos="'providers.manage'" class="btn-extra-primario btn-small mr-2" v-if="!row.item.deleted_at" size="sm" v-b-modal.modal-editar @click="editar(row.item, row.index, $event.target)" >
                        Editar <inline-svg  class="icono-titulo" src="../../assets/images/Editar.svg" title="My Image"/>
                    </button>
                    <button v-permisos="'providers.manage'" class="btn-extra-secundario btn-small" v-if="!row.item.deleted_at" size="sm" v-b-modal.modal-eliminar @click="eliminar(row.item, row.index, $event.target)"  >
                        Eliminar <inline-svg  class="icono-titulo" src="../../assets/images/basura.svg" title="My Image"/>
                    </button>
                </div>
              
            </template>
  
            <template #table-busy>
            <div class="text-center my-2" style="color: #FFCE1E !important ">
              <b-spinner class="align-middle"></b-spinner>
              <strong> Cargando...</strong>
            </div>
          </template>
          
          </b-table>
          <b-pagination
              v-model="currentPage"
              
              style="width: 100px; margin: auto;"
              
              :total-rows="totalRows"
              :per-page="perPage"
              align="fill"
              size="sm"
              class="my-0"
            ></b-pagination>
        </div>
      </card>
      <modal-form-proveedor
        :titulo="titulo"
        :form="form" 
        :show="showEditar" 
        :cerrarModal="cerrarModal" 
        :submit="submit"       
        :roles="roles"
        :password="password"
        :user="item"
        :cambiarPassword="cambiarPassword"
        :cambiarPasswordCondicion="cambiarPasswordCondicion"
  
  
        
      >
      
      </modal-form-proveedor>
  
      <b-modal  v-model="showEliminar" id="modal-eliminar" centered  @hidden="cerrarModal()">
        <template #modal-title>
          Eliminar Proveedor
        </template>
        <div class="d-block text-center">
          <h3>¿Desea eliminar al proveedor "{{nombreProveedorEliminar}}"?</h3>
        </div>
        <template #modal-footer>
          <button type="button" class="btn-secundario btn-large " @click="confirmarEliminar()">Eliminar</button>
          <button type="button" class="btn-principal btn-large "   @click="cerrarModal()">Cancelar</button>
        </template>
      </b-modal>
      <b-modal  v-model="showActivar" id="modal-activar" centered  @hidden="cerrarModal()">
        <template #modal-title>
          Activar proveedor
        </template>
        <div class="d-block text-center">
          <h3>¿Desea activar al proveedor "{{nombreProveedorEliminar}}"?</h3>
        </div>
        <template #modal-footer>
          <button type="button" class="btn-secundario btn-large "  @click="confirmarActivar()">Activar</button>
          <button type="button" class="btn-principal btn-large "   @click="cerrarModal()">Cancelar</button>
        </template>
      </b-modal>
      
      
  
    </div>
  </template>
  
  <script>
   import axios from "axios";
  
  import { mapGetters, mapActions } from 'vuex'
  
  
  import TitlePage from '../components/layout/TitlePage.vue';
  import Card from '../components/ui/Card.vue';
  import Modal from '../components/ui/Modal.vue';
  
  import Permisos from '../constants/Permisos.js';
  import ModalFormUsuario from '../components/modals/ModalFormUsuario.vue';
  import ModalFormProveedor from '../components/modals/ModalFormProveedor.vue';
  
    export default {
      components: { TitlePage, Card,Modal, ModalFormUsuario, ModalFormProveedor },
      computed: {
        ...mapGetters({
          permission: 'auth/permission',
          user: 'auth/user',
        }),
      },
      data() {
        return {
          spinLoad: false,
          filter: '',
          showEditar: false,
          showEliminar: false,
          showActivar: false,
          password: false,
          titulo:'',
          currentPage: 1,
          totalRows: 0,
          perPage: 0,
          fields: [
            {key:'nombre', label: 'Nombre'},
            {key:'rut', label: 'Rut'},
            {key:'correo', label: 'Correo'},
            {key:'telefono', label: 'Telefono'},
            {key:'domicilio', label: 'Domicilio'},
            {key:'state', label: 'Estado'},
            {key:'actions',label: 'Acciones'}],
          items: [],
          isBusy: false,
  
          roles:[],
          form:{
              id: '',
              nombre: '',
              rut:'',
              domicilio: '',
              telefono: '',
              correo: '',
          },
          regions: [],
          comunas: [],
  
          nombreProveedorEliminar: '',
          idProveedorEliminar: 0,
  
          rutDisponible: false,
  
          item: {},
  
          cambiarPasswordCondicion: false,
  
        }
      },
      mounted(){
          this.getProveedores();
      },
      
      watch:{
        currentPage:function(newValue, oldValue){
            this.getProveedores()
        }
      },
      methods: {
          async filtrarProveedores(){
            this.spinLoad = true
            await axios.post(`/api/admin/proveedores/filter`,{filter: this.filter}).then(resp => {
              console.log('response: ',resp);
              this.spinLoad = false;
              this.items = resp.data.data;
              this.currentPage =  resp.data.current_page;
              this.totalRows = resp.data.total;
              this.perPage = resp.data.per_page;
            }).catch(err => {
              console.log(err);
              this.spinLoad = false
              
            });
          },
         ...mapActions({
            signOutAction: 'auth/signOut'
          }),
         //LLAMADAS API
          async getProveedores(){
              this.toggleBusy();
              await axios.get(`/api/admin/proveedores?page=${this.currentPage}`).then(resp => {
                  this.items = resp.data.data
                  this.currentPage =  resp.data.meta.current_page;
                  this.totalRows = resp.data.meta.total;
                  this.perPage = resp.data.meta.per_page
                  this.toggleBusy();
              })
              .catch(err => {
                  this.toggleBusy();
              })
          },
          async confirmarEliminar(){
            await axios.delete(`/api/admin/proveedores/${this.idProveedorEliminar}`)
            .then(resp => {
              this.getProveedores();
            })
            .catch(err => {
                console.log(err)
                this.$swal({
                  icon: 'error',
                  title: '¡Error!',
                  text: 'Lo sentimos, ha ocurrido un error, por favor intente nuevamente',
                });
            })
            this.cerrarModal();
          },
          async confirmarActivar(){
            await axios.post(`/api/admin/proveedores/restore/${this.idProveedorEliminar}`)
            .then(resp => {
              this.getProveedores();
  
            })
            .catch(err => {
                console.log(err)
                this.$swal({
                  icon: 'error',
                  title: '¡Error!',
                  text: 'Lo sentimos, ha ocurrido un error, por favor intente nuevamente',
                });
            })
            this.cerrarModal();
          },
  
          async submit () {
            if(this.form.id != ''){
              await axios.put(`api/admin/proveedores/${this.form.id}`,this.form).then((response) => {
                this.metodoThen();
              }).catch((error)=>{
                  this.metodoCatch(error);
              })
            }else{
              await axios.post(`api/admin/proveedores`,this.form).then((response) => {
                this.metodoThen()
              }).catch((error)=>{
                if (error.response.status == 422){
                  if (error.response.data.errors.rut){
                    this.$swal({
                      icon: 'error',
                      title: '¡Error!',
                      text: 'ya existe un proveedor con este rut',
                    });
                  }
                }
                this.metodoCatch(error);
              })
            }
          },
          //METODOS ACTUALIZAR
          actualizarRoles(roles){
            roles.forEach(rol =>{
              this.roles.push({value:rol.name, text: rol.display_name })
            })
          },
          //Modificacion variables otros componentes
            cambiarPassword(){
              this.password = !this.password;
            },
          //
          agregar(item, index, button) {
  
            this.form.nombre = '';
            this.form.rut =  '';
            this.form.domicilio = '';
            this.form.telefono = '';
            this.form.correo = '';
  
            this.password = false;
            this.cambiarPasswordCondicion = false;
  
            this.item = null;
            this.form.id = '';
            this.showEditar = true;
            this.titulo = 'Agregar Proveedor'
  
          },
  
          editar(item, index, button) {
           
  
            this.item = item
            this.form.id = item.id;
            this.form.nombre = item.nombre;
            this.form.rut =  item.rut + "-" + item.dv;
            this.form.domicilio = item.domicilio;
            this.form.telefono = item.telefono;
            this.form.correo = item.correo;
  
            this.password = false;
            this.cambiarPasswordCondicion = true;
            this.showEditar = true;
  
            this.titulo = 'Editar Proveedor'
  
          },
  
          
          eliminar(item, index, button) {
            this.nombreProveedorEliminar = item.nombre;
            this.idProveedorEliminar = item.id;
            this.showEliminar = true;
            this.titulo = 'Eliminar Proveedor'
          },
          activar(item, index, button) {
            this.nombreProveedorEliminar = item.nombre;
            this.idProveedorEliminar = item.id;
            this.showActivar = true;
            this.titulo = 'Activar Proveedor'
          },
  
  
          //GENERICOS API
          metodoCatch(error){
            if(error.response){
              Object.keys(error.response.data.errors).forEach((key) => {
                if(document.getElementById(`${key}-error`)){
                  document.getElementById(`${key}-error`).innerHTML = error.response.data.errors[key];
                }
              })
            }
          },
          metodoThen(){
            this.cerrarModal();
            this.getProveedores();
  
          },
          //GENERICOS
          cerrarModal(){
            this.showEditar = false;
            this.showEliminar = false;
            this.showActivar = false;
          },
          tienePermisos(permisoBuscar){
            if(this.permission.roles.includes( permisoBuscar )) return true;
            return false;
          },
          toggleBusy() {
            this.isBusy = !this.isBusy
          },
  
      },
    }
  </script>
  
  <style>
    .boton-editar{
  
      width: 70px;
      height: 30px;
  
      font-size: 12px !important;
  
      border: none !important;
      border-radius: 20px !important;
      background: #FFCE1E !important;
  
      opacity: 0.8;
  
      transition: opacity 0.5s !important;
    }
  
    .boton-editar:hover{
      border: none !important;
      opacity: 1 !important;
      background: #FFCE1E !important;
    }
  
  
  
    .boton-eliminar{
      width: 70px;
      height: 30px;
  
      font-size: 12px !important;
  
      border: 1px solid #E35F42 !important;
      border-radius: 20px !important;
      background: transparent !important;
  
      color: #E35F42 !important;
      transition: background-color 0.5s !important;
    }
  
    .boton-eliminar:hover{
      border: 1px solid #E35F42 !important;
      background: red21 !important;
    }
  
  
  </style>
  