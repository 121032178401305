<template>
  
    <b-container fluid>
        <b-row>
            <b-col cols="12" class="text-left mx-auto">
                <b-table striped hover :items="documentos" :fields="fields" show-empty responsive>

                    <template #empty >
                        <h4 class="text-center">No hay documentos disponibles</h4>
                    </template>

                    <template #cell(documentos)="row">
                        <b-button class="btn-archivo-tabla" v-b-tooltip.hover :title="documento.ruta" v-for="(documento,index) in row.item.archivos" :key="index" style="width:20px" @click="downloadDocumento(documento.id)" >
                           <inline-svg v-if="documento.ruta.split('.').at(-1) == 'pdf'" src="../../assets/images/archivo-pdf.svg" />
                           <inline-svg 
                           v-if="documento.ruta.split('.').at(-1) == 'png' || documento.ruta.split('.').at(-1) == 'jpg' || documento.ruta.split('.').at(-1) == 'jpeg'" 
                           src="../../assets/images/archivo-png.svg"/>
                        </b-button>
                    </template>

                    <template #cell(fecha_recepcion)="row">
                        <p>{{moment(row.item.fecha_recepcion).format('DD-MM-YYYY')}}</p> 
                    </template>

                    <template #cell(actions)="row">
                        <b-button  @click="downloadDocumento(row.item.id)" class="btn-extra-primario btn-small mr-1">
                            Ver 
                        </b-button>
                        <b-button  @click="validar(row.item.id, 'Aceptado')" class="btn-extra-secundario btn-small mr-1">
                            Aceptado  <inline-svg  class="icono-titulo" src="../../assets/images/Editar.svg" title="My Image"/>
                        </b-button>
                        <b-button  @click="validar(row.item.id, 'Rechazado')" class=" btn-extra-primario btn-small">
                            Rechazado <inline-svg  class="icono-titulo" src="../../assets/images/basura.svg" title="My Image"/>
                        </b-button>
                    </template>

                </b-table>
            </b-col>
        </b-row>
    </b-container>
</template>

<script>
import axios from 'axios'
import FormDocumento from '../../formulario/FormDocumento.vue'
import FormDocumentoEditar from '../../formulario/FormDocumentoEditar.vue'
import moment from 'moment';
export default {
    components: { FormDocumento, FormDocumentoEditar },
    
    data(){
        return{
                fields: [
                    {key:'tipo.nombre', label: 'Tipo'},
                    {key:'fecha_recepcion', label: 'Fecha'},
                    {key:'usuario.name', label: 'Usuario'},
                    {key:'documentos', label: 'Documento'},
                    {key:'estado', label: 'Estado Validación'},
                    {key:'actions',label: 'Acciones'}
                ],

                documentos: [],
                editar: false,
                documentoEditar: null,
                files_old: [],
                idDespacho: '',
        }
    },
    mounted(){
        this.idDespacho = this.$route.params.despachoId;
        this.getDocumentoPorDespacho()
    },
    methods:{
        async getDocumentoPorDespacho(){
            this.documentos = null
            await axios.get(`api/admin/documentos/despacho/${this.idDespacho}`)
            .then(res => {
                this.documentos = res.data.data.documentos
            })
            .catch(err => {console.log(err)})
        },
        async validar(id,estado){
            await axios.post(`api/admin/documentos/${id}/validar`,{estado: estado})
            .then(res => {
                this.getDocumentoPorDespacho()
            })
            .catch(err => {console.log(err)})
        },
        cancelar(){
            this.editar = false
        },
        

        async downloadDocumento(id){
            console.log(id)
            //window.open(process.env.VUE_APP_API_URL +`/api/admin/archivo/${id}/descargar`);
            this.$router.replace({ name: 'Documento', params:{despachoId: this.idDespacho, documentoId: id} })
        }
    }

}
</script>

<style>

</style>