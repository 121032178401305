<template>
  <div>
    <TitlePage title="COMPAÑIAS NAVIERAS"/>
    <card title="Compañias navieras" :permiso="'companias.manage'" :accionBoton="agregar" :boton="true" text="Agregar">
      <!-- <modal :show="showEditar" id="modal-editar" :cerrarModal="cerrarModal"></modal> -->
      <div class="table-container">
        <b-table class="text-left"
          hover 
          :items="items" :fields="fields" :busy="isBusy" responsive> 

          <template #cell(id)="row">
            <div class="codigo-importacion" v-if="!row.item.deleted_at">
              <p class="" >
                {{row.item.id}}
              </p>
            </div>
            <div class="codigo-exportacion" v-else>
              <p>
                {{row.item.id}}
              </p>
            </div>
          </template>
          <template #cell(state)="row">
            <div class="estado-despacho importacion" v-if="!row.item.deleted_at">
              <p class="" >
                Activo
              </p>
            </div>
            <div class="estado-despacho exportacion" v-else>
              <p >
                Eliminado
              </p>
            </div>
          </template>

          <template #cell(rut)="row">
            <p>
              {{row.item.rut}}-{{row.item.dv}}
            </p>
          </template>

          <template #cell(actions)="row">
            <button v-permisos="'companias.manage'" class="btn-extra-secundario btn-small" v-if="row.item.deleted_at" size="sm" v-b-modal.modal-activar @click="activar(row.item)" >
              Activar
            </button>
            <button  v-permisos="'companias.manage'" class="btn-extra-primario btn-small mr-2" v-if="!row.item.deleted_at" size="sm" v-b-modal.modal-editar @click="editar(row.item, row.index, $event.target)" >
              Editar <inline-svg  class="icono-titulo" src="../../assets/images/Editar.svg" title="My Image"/>
            </button>
            <button v-permisos="'companias.manage'" class="btn-extra-secundario btn-small" v-if="!row.item.deleted_at" size="sm" v-b-modal.modal-eliminar @click="eliminar(row.item, row.index, $event.target)"  >
              Eliminar <inline-svg  class="icono-titulo" src="../../assets/images/basura.svg" title="My Image"/>
            </button>
          </template>

          <template #table-busy>
          <div class="text-center my-2" style="color: #FFCE1E !important ">
            <b-spinner class="align-middle"></b-spinner>
            <strong> Cargando...</strong>
          </div>
        </template>
        
        </b-table>
        <b-pagination
            v-model="currentPage"
            
            style="width: 100px; margin: auto;"
            
            :total-rows="totalRows"
            :per-page="perPage"
            align="fill"
            size="sm"
            class="my-0"
          ></b-pagination>
      </div>
    </card>

    <modal-form-compania-naviera
      :titulo="titulo"
      :form="form" 
      :show="showEditar" 
      :cerrarModal="cerrarModal" 
      :submit="submit"       
      :roles="roles"
      :password="password"
      :user="item"
      :cambiarPassword="cambiarPassword"
      :cambiarPasswordCondicion="cambiarPasswordCondicion"


      
    >
    
    </modal-form-compania-naviera>

    <b-modal  v-model="showEliminar" id="modal-eliminar" centered  @hidden="cerrarModal()">
      <template #modal-title>
        Eliminar compañia naviera
      </template>
      <div class="d-block text-center">
        <h3>¿Desea eliminar la compañia naviera"{{nombreCompaniaEliminar}}"?</h3>
      </div>
      <template #modal-footer>
        <button type="button" class="btn-secundario btn-large " @click="confirmarEliminar()">Eliminar</button>
        <button type="button" class="btn-principal btn-large "   @click="cerrarModal()">Cancelar</button>
      </template>
    </b-modal>
    <b-modal  v-model="showActivar" id="modal-activar" centered  @hidden="cerrarModal()">
      <template #modal-title>
        Activar compañia naviera
      </template>
      <div class="d-block text-center">
        <h3>¿Desea activar la compañia naviera "{{nombreCompaniaEliminar}}"?</h3>
      </div>
      <template #modal-footer>
        <button type="button" class="btn-secundario btn-large "  @click="confirmarActivar()">Activar</button>
        <button type="button" class="btn-principal btn-large "   @click="cerrarModal()">Cancelar</button>
      </template>
    </b-modal>
    
    

  </div>
</template>

<script>
 import axios from "axios";

import { mapGetters, mapActions } from 'vuex'


import TitlePage from '../components/layout/TitlePage.vue';
import Card from '../components/ui/Card.vue';
import Modal from '../components/ui/Modal.vue';

import Permisos from '../constants/Permisos.js';
import ModalFormUsuario from '../components/modals/ModalFormUsuario.vue';
import ModalFormAgentes from '../components/modals/ModalFormAgentes.vue';
import ModalFormCompaniaNaviera from '../components/modals/ModalFormCompaniaNaviera.vue';

  export default {
    components: { TitlePage, Card,Modal, ModalFormUsuario, ModalFormAgentes, ModalFormCompaniaNaviera },
    computed: {
      ...mapGetters({
        permission: 'auth/permission',
        user: 'auth/user',
      }),
    },
    data() {
      return {
        showEditar: false,
        showEliminar: false,
        showActivar: false,
        password: false,
        titulo:'',
        currentPage: 1,
        totalRows: 0,
        perPage: 0,
        fields: [
          {key:'id', label: '#'},
          {key:'nombre', label: 'Nombre'},
          {key:'state', label: 'Estado'},
          {key:'actions',label: 'Acciones'}],
        items: [],
        isBusy: false,

        roles:[],
        form:{
            id: '',
            nombre: '',
            rut:'',
        },
        regions: [],
        comunas: [],

        nombreCompaniaEliminar: '',
        idCompaniaEliminar: 0,

        rutDisponible: false,

        item: {},

        cambiarPasswordCondicion: false,

      }
    },
    mounted(){
        this.getCompania();
    },
    
    watch:{
      currentPage:function(newValue, oldValue){
          this.getCompania()
      }
    },
    methods: {
      ...mapActions({
          signOutAction: 'auth/signOut'
        }),
       
       //LLAMADAS API
        async getCompania(){
            this.toggleBusy();
            await axios.get(`/api/admin/companias-navieras?page=${this.currentPage}`).then(resp => {
                this.items = resp.data.data
                
                this.currentPage =  resp.data.meta.current_page;
                this.totalRows = resp.data.meta.total;
                this.perPage = resp.data.meta.per_page
                this.toggleBusy();
            })
            .catch(err => {
              // if (err.response.status == 401) {
              //   this.signOutAction()
              //   this.$router.replace({ name: 'SignIn' })
              // }
                this.toggleBusy();
            })
        },
        async confirmarEliminar(){
          await axios.delete(`/api/admin/companias-navieras/${this.idCompaniaEliminar}`)
          .then(resp => {
            this.getCompania();
          })
          .catch(err => {
              console.log(err)
              this.$swal({
                icon: 'error',
                title: '¡Error!',
                text: 'Lo sentimos, ha ocurrido un error, por favor intente nuevamente',
              });
          })
          this.cerrarModal();
        },
        async confirmarActivar(){
          await axios.post(`/api/admin/companias-navieras/restore/${this.idCompaniaEliminar}`)
          .then(resp => {
            this.getCompania();
          })
          .catch(err => {
              console.log(err)
              this.$swal({
                icon: 'error',
                title: '¡Error!',
                text: 'Lo sentimos, ha ocurrido un error, por favor intente nuevamente',
              });
          })
          this.cerrarModal();
        },

        async submit () {
          if(this.form.id != ''){
            await axios.put(`api/admin/companias-navieras/${this.form.id}`,this.form).then((response) => {
              this.metodoThen();
            }).catch((error)=>{
                this.metodoCatch(error);
            })
          }else{
            await axios.post(`api/admin/companias-navieras`,this.form).then((response) => {
              this.metodoThen()
            }).catch((error)=>{
              this.metodoCatch(error);
            })
          }
        },
        //METODOS ACTUALIZAR
        actualizarRoles(roles){
          roles.forEach(rol =>{
            this.roles.push({value:rol.name, text: rol.display_name })
          })
        },
        //Modificacion variables otros componentes
          cambiarPassword(){
            this.password = !this.password;
          },
        //
        agregar(item, index, button) {

          this.form.nombre = '';
          this.password = true;
          this.form.id = '';
          this.cambiarPasswordCondicion = false;

          this.item = null;
          this.showEditar = true;
          this.titulo = 'Agregar Compañia Naviera'

        },

        editar(item, index, button) {
         

          this.item = item
          this.form.id = item.id
          this.form.nombre = item.nombre;
          

          this.password = false;
          this.cambiarPasswordCondicion = true;
          this.showEditar = true;

          this.titulo = 'Editar Compañia Naviera'

        },

        
        eliminar(item, index, button) {
          this.nombreCompaniaEliminar = item.name;
          this.idCompaniaEliminar = item.id;
          this.showEliminar = true;
          this.titulo = 'Eliminar Compañia Naviera'
        },
        activar(item, index, button) {
          this.nombreCompaniaEliminar = item.name;
          this.idCompaniaEliminar = item.id;
          this.showActivar = true;
          this.titulo = 'Activar Compañia Naviera'
        },


        //GENERICOS API
        metodoCatch(error){
          if(error.response){
            Object.keys(error.response.data.errors).forEach((key) => {
              if(document.getElementById(`${key}`)){
                document.getElementById(`${key}`).innerHTML = error.response.data.errors[key];
              }
            })
          }
        },
        metodoThen(){
          this.cerrarModal();
          this.getCompania();

        },
        //GENERICOS
        cerrarModal(){
          this.showEditar = false;
          this.showEliminar = false;
          this.showActivar = false;
        },
        tienePermisos(permisoBuscar){
          if(this.permission.roles.includes( permisoBuscar )) return true;
          return false;
        },
        toggleBusy() {
          this.isBusy = !this.isBusy
        },

    },
  }
</script>

<style>
  .boton-editar{

    width: 70px;
    height: 30px;

    font-size: 12px !important;

    border: none !important;
    border-radius: 20px !important;
    background: #FFCE1E !important;

    opacity: 0.8;

    transition: opacity 0.5s !important;
  }

  .boton-editar:hover{
    border: none !important;
    opacity: 1 !important;
    background: #FFCE1E !important;
  }



  .boton-eliminar{
    width: 70px;
    height: 30px;

    font-size: 12px !important;

    border: 1px solid #E35F42 !important;
    border-radius: 20px !important;
    background: transparent !important;

    color: #E35F42 !important;
    transition: background-color 0.5s !important;
  }

  .boton-eliminar:hover{
    border: 1px solid #E35F42 !important;
    background: red21 !important;
  }


</style>
