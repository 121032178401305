<template>
    <b-col :cols="12" class="contenedor-informacion">
        <div class="text-right" v-if="despacho.estado_despacho == 'Presentado'">
            <button id="deleteAll" @click="getComprobante()" class="btn-principal btn-medium mr-4 mb-4" style="width: 180px;">
                Buscar Comprobante TGR
                <fas icon="share"></fas>
            </button>
        </div>
        <form-pago
            v-if="agregarPago && !editar && pagos && proveedores"
            :postPago="postPago"
            :pagos="pagos"
            :proveedores="proveedores"
            :cancelar="cancelar"
        >
        </form-pago>

        <form-pago-editar
            v-if="agregarPago && editar && pagoEditar && pagos && proveedores"
            :postPago="updatePago"
            :pagos="pagos"
            :pagoEditar="pagoEditar"
            :proveedores="proveedores"
            :cancelar="cancelar"
            :files_old="files_old"
        >
        
        <template slot="cancelar">
            <b-button
            class="btn-secundario btn-small"
                @click="cancelar()"
                type="button"
            >
                Cancelar
            </b-button>
        </template>
        </form-pago-editar>
        <b-row v-if="montos">
            <b-col  sm="12" md="12" lg="9" class="seccion-informacion-seccion mx-auto">
                <b-row>
                    <b-col cols="4" class="borde-derecho">
                        <h5 class="titular-dato">MONTO ENTRANTE</h5>
                        <p class="contenido-dato">{{montos.monto_entrante}}</p>
                    </b-col>
                    <b-col cols="4" class="borde-derecho">
                        <h5 class="titular-dato">MONTO SALIENTE</h5>
                        <p class="contenido-dato">{{montos.monto_saliente}}</p>
                    </b-col>
                    <b-col cols="4">
                        <h5 class="titular-dato">BALANCE</h5>
                        <p class="contenido-dato" >{{parseInt(montos.monto_entrante) - parseInt(montos.monto_saliente)}}</p>
                    </b-col>
                </b-row>
            </b-col>
        </b-row>
        <b-table striped hover :items="pagosTabla" :fields="fields" show-empty responsive>
            <template #empty>
                <h4>No hay pagos disponibles</h4>
            </template>
            <template #cell(created_at)="row">
               <p>{{moment(row.item.created_at).format('DD-MM-YYYY HH:mm')}}</p> 
            </template>
             <template #cell(fecha_pago)="row">
               <p v-if="row.item.fecha_pago === null">{{ }}</p> 
               <p v-else>{{moment(row.item.fecha_pago).format('DD-MM-YYYY')}}</p>
            </template>
            <template #cell(proveedor)="row">
                <p>{{  }}</p>
            </template>
            <template #cell(monto)="row">
                <p>{{new Intl.NumberFormat('es-CL', {currency: 'CLP', style: 'currency'}).format(row.item.monto)}}</p>
            </template>
            <template #cell(documentos)="row">
                
                <b-button class="btn-archivo-tabla" :title="pago.ruta" v-for="(pago,index) in row.item.archivos" :key="index" style="width:20px" @click="downloadPago(pago.id)" >
                    <inline-svg v-if="pago.ruta.split('.').at(-1) == 'pdf'" src="../../assets/images/archivo-pdf.svg" />
                    <inline-svg 
                    v-if="pago.ruta.split('.').at(-1) == 'png' || pago.ruta.split('.').at(-1) == 'jpg' || pago.ruta.split('.').at(-1) == 'jpeg'" 
                    src="../../assets/images/archivo-png.svg"/>
                </b-button>
            </template>
            <template #cell(documento_name)="row">
                <p v-for="(pago,index) in row.item.archivos">{{ pago.ruta.split('/')[1] }}</p>
                
            </template>
            <template #cell(acciones)="row">
                <!-- <b-button  @click="editarPago(row.item)" class="btn-extra-secundario btn-small mr-1">
                    Editar <inline-svg  class="icono-titulo" src="../../assets/images/Editar.svg" title="My Image"/>
                </b-button> -->

                <div v-if="row.item.id_gasto && !(row.item.tipo_documento_contable || row.item.nro_documento || row.item.fecha_pago || row.item.archivos.length != 0)">
                    <b-button  @click="editarPago(row.item)" v-if="canManage()" class=" btn-extra-secundario btn-small mr-2">
                        Completar <inline-svg  class="icono-titulo" src="../../assets/images/Editar.svg" title="My Image"/>
                    </b-button>
                    <b-button  @click="eliminarPagoRuta(row.item.id)" v-if="canManage()" class=" btn-extra-primario btn-small">
                        Eliminar <inline-svg  class="icono-titulo" src="../../assets/images/basura.svg" title="My Image"/>
                    </b-button>
                </div>
                <div v-else>
                    <b-button  @click="eliminarPagoRuta(row.item.id)" v-if="canManage()" class=" btn-extra-primario btn-small">
                        Eliminar <inline-svg  class="icono-titulo" src="../../assets/images/basura.svg" title="My Image"/>
                    </b-button>
                </div>
            </template>
            
        </b-table>

    </b-col>
</template>

<script>
import axios from 'axios';
import FormPago from '../../formulario/FormPago.vue';
import FormPagoEditar from '../../formulario/FormPagoEditar.vue';
import moment from 'moment';
export default {
  components: { FormPago, FormPagoEditar },
    props:{
        despacho:Object,
        agregarPago:Boolean,
        pagosTabla: Array,
        montos:Object,
        
        canManage:{type: Function, default: ()=>{return true}},

        postPago: Function,
        updatePago: Function,
        eliminarPago: Function,
        getComprobante: Function

        
    },
    data(){
        return{
                fields: [
                    {key:'tipo.nombre', label: 'Tipo'},
                    {key:'tipo_documento_contable', label: 'Documento Contable'},
                    {key:'proveedor.nombre', label: 'Proveedor'},
                    {key:'monto', label: 'Monto'},
                    {key:'fecha_pago', label: 'Fecha Documento'},
                    {key:'usuario.name', label: 'Usuario'},
                    {key:'documentos', label: 'Pagos'},
                    {key:'documento_name',label: 'Nombre Documento'},
                    {key:'acciones',label: 'Acciones'}
                ],
                
                editar: false,

                pagoEditar: null,

                files_old: [],
                pagos: null,
                proveedores: null,

        }
    },
    mounted(){
        if(!this.agregarPago) this.fields.pop();
        else this.getTiposPagos()
        this.getProveedores()
        
    
    },
    methods:{
        async eliminarPagoRuta(id){
            axios.delete('/api/admin/pagos/' + id,).then(res => {
                this.pagosTabla.splice(this.pagosTabla.findIndex(function(i){
                    return i.id === id;
                }), 1);
            })

        },
        editarPago(pago){

            this.pagoEditar = pago
            
            this.files_old = []
            this.pagoEditar.archivos.forEach(el => {
                this.files_old.push({id: el.id, name: el.ruta})
            })
            this.editar = true
        },
        cancelar(){
            this.editar = false
        },

        async downloadPago(id){
          
            window.open(process.env.VUE_APP_API_URL +`/api/admin/archivo/${id}/descargar/despacho`);
        },
        async getTiposPagos(){
        await axios.get(`api/admin/pagos/tipos-pagos/${this.despacho.id}`)
        .then(res => {
            

            this.pagos = {
                tipos_pagos : res.data.data.tipo_pagos,
                tipo_documento_contable: res.data.data.tipo_documento_contable,
            }
        })
        .catch(err => {console.log(err)})
    },
    async getProveedores(){
            await axios.get('/api/admin/proveedores/pagos').then(resp => {
              this.proveedores = resp.data.data
            })
            .catch(err => {console.log(err)}) 
          },
    }

    
}
</script>

<style>

</style>