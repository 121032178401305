<template>
  <b-col sm="12" md="12" lg="9" class="contenedor-informacion mx-auto">
        
       <b-row>
        <b-col cols="12" class="text-left">
            <gestor-informacion-general
                v-if="tipo == 'gestor'"
                :despacho="despacho"
                :postInformacionGeneral="postInformacionGeneral"
                :informacionGeneral="informacionGeneral"
                :estados="estadosGestor"
                :editar="editar"
            >
            </gestor-informacion-general> 
            <pedidor-informacion-general
                v-if="tipo == 'pedidor' || tipo == 'gestor'"
                :despacho="despacho"
                :postInformacionGeneral="postInformacionGeneral"
                :informacionGeneral="informacionGeneral"
                :estados="estadosPedidor"
                :editar="editar"
            >
            </pedidor-informacion-general>
            <presentador-informacion-general
                v-if="tipo == 'presentador_imp'" 
                :despacho="despacho"
                :postInformacionGeneral="postInformacionGeneral"
                :informacionGeneral="informacionGeneral"
                :estados="estadosPresentador"
                :editar="editar"
            >
            </presentador-informacion-general>


        
            <operaciones-informacion-general
                v-if="tipo == 'operaciones'"
                :despacho="despacho"
                :postInformacionGeneral="postInformacionGeneral"
                :informacionGeneral="informacionGeneral"
                :estados="estadosOperaciones"
                :editar="editar"
            >
            </operaciones-informacion-general> 
            <administrativa-informacion-general
                v-if="tipo == 'administrativa' || tipo == 'operaciones'"
                :despacho="despacho"
                :postInformacionGeneral="postInformacionGeneral"
                :informacionGeneral="informacionGeneral"
                :estados="estadosAdministrativa"
                :editar="editar"
            >
            </administrativa-informacion-general> 

            <aduanero-informacion-general
                v-if="tipo == 'tramitador_aduanero' || tipo == 'operaciones'"
                :despacho="despacho"
                :postInformacionGeneral="postInformacionGeneral"
                :informacionGeneral="informacionGeneral"
                :estados="estadosAduanero"
                :editar="editar"
            >
            </aduanero-informacion-general> 

            <!-- EXPORTACION -->
            <pedidor-informacion-general-exp
                v-if="tipo == 'pedidor_exportacion'"
                :despacho="despacho"
                :postInformacionGeneral="postInformacionGeneral"
                :informacionGeneral="informacionGeneral"
                :estados="estadosPedidorExportacion"
                :editar="editar"
            >
            </pedidor-informacion-general-exp>
            <presentador-informacion-general-exp
                v-if="tipo == 'presentador'"
                :despacho="despacho"
                :postInformacionGeneral="postInformacionGeneral"
                :informacionGeneral="informacionGeneral"
                :estados="estadosPresentadorExportacion"
                :editar="editar"
            >
            </presentador-informacion-general-exp>

            <aduanero-informacion-general-exp
                v-if="tipo == 'aduanero_exportacion'"
                :despacho="despacho"
                :postInformacionGeneral="postInformacionGeneral"
                :informacionGeneral="informacionGeneral"
                :estados="estadosAduaneroExportacion"
                :editar="editar"
            >
            </aduanero-informacion-general-exp>
            
        </b-col>
    </b-row>
    </b-col>
</template>

<script>
import axios from 'axios'
import FormDocumento from '../../formulario/FormDocumento.vue'
import FormDocumentoEditar from '../../formulario/FormDocumentoEditar.vue'
import PedidorInformacionGeneral from '../../formulario/formInformacionGeneral/pedidorInformacionGeneral.vue'
import AdministrativaInformacionGeneral from '../../formulario/formInformacionGeneral/administrativaInformacionGeneral.vue'
import AduaneroInformacionGeneral from '../../formulario/formInformacionGeneral/aduaneroInformacionGeneral.vue'
import OperacionesInformacionGeneral from '../../formulario/formInformacionGeneral/operacionesInformacionGeneral.vue'
import GestorInformacionGeneral from '../../formulario/formInformacionGeneral/gestorInformacionGeneral.vue'
import PedidorInformacionGeneralExp from '../../formulario/formInformacionGeneralExportacion/pedidorInformacionGeneralExp.vue'
import AduaneroInformacionGeneralExp from '../../formulario/formInformacionGeneralExportacion/aduaneroInformacionGeneralExp.vue'
import PresentadorInformacionGeneralExp from '../../formulario/formInformacionGeneralExportacion/presentadorInformacionGeneralExp.vue'
import PresentadorInformacionGeneral from '../../formulario/formInformacionGeneral/presentadorInformacionGeneral.vue'
export default {
    components: { FormDocumento, FormDocumentoEditar, PedidorInformacionGeneral, AdministrativaInformacionGeneral, AduaneroInformacionGeneral, OperacionesInformacionGeneral, GestorInformacionGeneral, PedidorInformacionGeneralExp, AduaneroInformacionGeneralExp, PresentadorInformacionGeneralExp, PresentadorInformacionGeneral },
    props:{
            postInformacionGeneral: Function,
            despacho:Object,
            tipo:String
    },
    data(){
        return{
                //SECCION INFORMACION GENERAL
                informacionGeneral: {
                    aduana: null,
                    agente_cargas: null,
                    companias_navieras: null,
                    nombre_transporte: null,
                    tipo_condicion: null,
                    extraportuarios: null,
                    manifiesto:null,
                    caracteristicas_carga: null,
                    tipo_carga: null,
                    estado_presentacion_matriz: null,
                    dus_primer_envio: null,
                    dus_legalizado: null,
                    lugar_bl: null,
                },
                
                //IMPORTACION
                estadosPedidor:{
                    cliente: true,
                    ref_cliente: true,
                    nombre_transporte: true,
                    aduana: true,
                    fecha_eta: true,
                    transporte: true,
                    tipo_carga: true,
                    caracteristicas_carga: true,
                    lugar_bl: true,
                },
                estadosAdministrativa:{
                    compania_naviera: true,
                    manifiesto: true,
                    agente_cargas: true,
                },
                estadosAduanero:{
                    tipo_condicion: true,
                    extraportuario: true,
                    aforo:true,
                },
                estadosOperaciones:{
                    fecha_eta: true,
                    transporte: true,
                },
                estadosGestor:{
                    codigo: true,
                    ref_cliente: true,
                    fecha_inicio: true,
                    tipo_despacho: true,
                    tipo_despacho_id: true,
                    lugar_bl: true,
                },
                estadosPresentador:{
                    aforo: true,
                    sub_tipo:true,
                },

                //EXPORTACION
                estadosPedidorExportacion:{
                    ref_cliente: true,
                    estado_presentacion_matriz: true,
                    dus_primer_envio: true,
                    dus_legalizado:true,
                    prorroga:true,
                    aduana: true,
                    nombre_transporte: true,
                    lugar_bl: true,
                },
                estadosAduaneroExportacion:{
                    ref_cliente: true,
                    puerto_aereo: true,
                    numero_reserva: true,
                    transporte: true,
                    fecha_salida_programada: true,
                    estado:true,
                    estado_salida: true,
                    aforo_zona_primaria: true,
                    fecha_aforo_zona_primaria: true,
                    aforo_dus_legalizado: true,
                    fecha_aforo_dus_legalizado: true,
                    fecha_ingreso_zona_primaria:true,
                    aforo: true,
                },
                estadosPresentadorExportacion:{
                    ref_cliente: true,
                    aforo: true,
                    sub_tipo:true,
                },

        }
    },
    mounted(){
        this.getInformacionGeneral()
    },
    methods:{
    editar(tipo,nombre,post = false, campo = null){
        
        switch (tipo){
            case 'pedidor':
                this.estadosPedidor[nombre] = !this.estadosPedidor[nombre]
                break;
            case 'administrativa':
                this.estadosAdministrativa[nombre] = !this.estadosAdministrativa[nombre]
                break;
            case 'tramitador_aduanero':
                this.estadosAduanero[nombre] = !this.estadosAduanero[nombre]
                break;
            case 'operaciones':
                this.estadosOperaciones[nombre] = !this.estadosOperaciones[nombre]
                break;
            case 'gestor':
                this.estadosGestor[nombre] = !this.estadosGestor[nombre]
                break;
            case 'pedidor_exportacion':
                this.estadosPedidorExportacion[nombre] = !this.estadosPedidorExportacion[nombre]
                break;
            case 'aduanero_exportacion':
                this.estadosAduaneroExportacion[nombre] = !this.estadosAduaneroExportacion[nombre]
                break;
            case 'presentador':
                this.estadosPresentadorExportacion[nombre] = !this.estadosPresentadorExportacion[nombre]
                break;
            case 'presentador_imp':
                this.estadosPresentador[nombre] = !this.estadosPresentador[nombre]
                break;

        }
        if(post) this.postInformacionGeneral(campo)

    },

    async getInformacionGeneral(){
      await axios.get(`api/admin/despachos/formulario-despacho/${this.despacho.id}`)
        .then(resp => {
            if(resp.data.aduana) this.informacionGeneral.aduana = resp.data.aduana
            if(resp.data.nombre_transporte) this.informacionGeneral.nombre_transporte = resp.data.nombre_transporte
            if(resp.data.extraportuarios) this.informacionGeneral.extraportuarios = resp.data.extraportuarios
            if(resp.data.lugar_bl) this.informacionGeneral.lugar_bl = resp.data.lugar_bl



            if(this.despacho.tipo_despacho == 'importacion'){
                if(resp.data.agente_cargas) this.informacionGeneral.agente_cargas = resp.data.agente_cargas
                if(resp.data.companias_navieras) this.informacionGeneral.companias_navieras = resp.data.companias_navieras
                if(resp.data.nombre_transporte) this.informacionGeneral.nombre_transporte = resp.data.nombre_transporte
                if(resp.data.tipo_condicion) this.informacionGeneral.tipo_condicion = resp.data.tipo_condicion
                if(resp.data.manifiesto) this.informacionGeneral.manifiesto = resp.data.manifiesto
                if(resp.data.tipo_carga) this.informacionGeneral.tipo_carga = resp.data.tipo_carga
                if(resp.data.caracteristicas_carga) this.informacionGeneral.caracteristicas_carga = resp.data.caracteristicas_carga
            }

            if(this.despacho.tipo_despacho == 'exportacion'){
                if(resp.data.estado_presentacion_matriz) this.informacionGeneral.estado_presentacion_matriz = resp.data.estado_presentacion_matriz
                if(resp.data.dus_primer_envio) this.informacionGeneral.dus_primer_envio = resp.data.dus_primer_envio
                if(resp.data.dus_legalizado) this.informacionGeneral.dus_legalizado = resp.data.dus_legalizado
                if(resp.data.estado_salida) this.informacionGeneral.estado_salida = resp.data.estado_salida
                if(resp.data.aforo_zona_primaria) this.informacionGeneral.aforo_zona_primaria = resp.data.aforo_zona_primaria
                if(resp.data.aforo_dus_legalizado) this.informacionGeneral.aforo_dus_legalizado = resp.data.aforo_dus_legalizado
                




            }
          

        })
        .catch(err => console.log(err))
    },




  }

}
</script>