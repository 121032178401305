<template>
  <b-row>
    <b-col cols="12" v-if="filtro.tipo == 'fecha'">
      <b-row>
        <b-col sm="12" md="12" lg="4">
          <b-form-group class="text-left" label="Fecha Inicial">
            <b-input
              type="date"
              v-model="fecha_inicial"
              @change="changeDate(filtro.id, 'inicio', fecha_inicial)"
            >
            </b-input>
          </b-form-group>
        </b-col>
        <b-col sm="12" md="12" lg="4">
          <b-form-group class="text-left" label="Fecha final">
            <b-input
              type="date"
              v-model="fecha_final"
              @change="changeDate(filtro.id, 'fin', fecha_final)"
            >
            </b-input>
          </b-form-group>
        </b-col>
        <b-col sm="12" md="12" lg="4" class="text-left boton-filtro">
          <button class="btn-borrar" type="button" @click="borrar(filtro.id)">
            <inline-svg
              class="icono-borrar-filtro"
              src="../../assets/images/basura.svg"
              title="My Image"
            />
          </button>
        </b-col>
      </b-row>
    </b-col>
    <!-- <b-col cols="12" v-if="filtro.tipo == 'codigo'">
      <b-row>
        <b-col sm="12" md="12" lg="4">
          <b-form-group class="text-left" label="Depacho">
            <b-input
              type="text"
              v-model="value"
              @change="changeValue(filtro.id, value)"
              required
            >
            </b-input>
          </b-form-group>
        </b-col>
        <b-col sm="12" md="12" lg="4" class="text-left boton-filtro">
          <button class="btn-borrar" type="button" @click="borrar(filtro.id)">
            <inline-svg
              class="icono-borrar-filtro"
              src="../../assets/images/basura.svg"
              title="My Image"
            />
          </button>
        </b-col>
      </b-row>
    </b-col> -->
    <b-col cols="12" v-if="filtro.tipo == 'ref_cliente'">
      <b-row>
        <b-col sm="12" md="12" lg="4">
          <b-form-group class="text-left" label="Referencia Cliente">
            <b-input
              type="text"
              v-model="value"
              @change="changeValue(filtro.id, value)"
              required
            >
            </b-input>
          </b-form-group>
        </b-col>
        <b-col sm="12" md="12" lg="4" class="text-left boton-filtro">
          <button class="btn-borrar" type="button" @click="borrar(filtro.id)">
            <inline-svg
              class="icono-borrar-filtro"
              src="../../assets/images/basura.svg"
              title="My Image"
            />
          </button>
        </b-col>
      </b-row>
    </b-col>
    <b-col cols="12" v-if="filtro.tipo == 'cliente_id'">
      <b-row>
        <b-col sm="12" md="12" lg="4" >
          <b-form-group class="text-left" label="Cliente">
            <Select2
            class="mi-select2"
            v-model="value"
            @change="changeValue(filtro.id, value)"
            :options="listaCliente"
            :settings="{
              noResults: 'es',
              placeholder: 'Seleccione un Cliente',
              width: '100%',
              multiple: false,
            }"
          />
          </b-form-group>
        </b-col>
        <b-col sm="12" md="12" lg="4" class="text-left boton-filtro">
          <button class="btn-borrar" type="button" @click="borrar(filtro.id)">
            <inline-svg
              class="icono-borrar-filtro"
              src="../../assets/images/basura.svg"
              title="My Image"
            />
          </button>
        </b-col>
      </b-row> 
    </b-col>
      <b-col cols="12" v-if="filtro.tipo == 'nombre_nave'">
      <b-row>
        <b-col sm="12" md="12" lg="4">
          <b-form-group class="text-left" label="Nombre de nave">
            <b-input
              type="text"
              v-model="value"
              @change="changeValue(filtro.id, value)"
              required
            >
            </b-input>
          </b-form-group>
        </b-col>
        <b-col sm="12" md="12" lg="4" class="text-left boton-filtro">
          <button class="btn-borrar" type="button" @click="borrar(filtro.id)">
            <inline-svg
              class="icono-borrar-filtro"
              src="../../assets/images/basura.svg"
              title="My Image"
            />
          </button>
        </b-col>
      </b-row>
    </b-col>

    <b-col cols="12" v-if="filtro.tipo == 'numero_manifiesto'">
      <b-row>
        <b-col sm="12" md="12" lg="4">
          <b-form-group class="text-left" label="Número de Manifiesto">
            <b-input
              type="text"
              v-model="value"
              @change="changeValue(filtro.id, value)"
              required
            >
            </b-input>
          </b-form-group>
        </b-col>
        <b-col sm="12" md="12" lg="4" class="text-left boton-filtro">
          <button class="btn-borrar" type="button" @click="borrar(filtro.id)">
            <inline-svg
              class="icono-borrar-filtro"
              src="../../assets/images/basura.svg"
              title="My Image"
            />
          </button>
        </b-col>
      </b-row>
    </b-col>
    <b-col cols="12" v-if="filtro.tipo == 'numero_documento_transporte'">
      <b-row>
        <b-col sm="12" md="12" lg="4">
          <b-form-group
            class="text-left"
            label="Número de Documento de Transporte"
          >
            <b-input
              type="text"
              v-model="value"
              @change="changeValue(filtro.id, value)"
              required
            >
            </b-input>
          </b-form-group>
        </b-col>
        <b-col sm="12" md="12" lg="4" class="text-left boton-filtro">
          <button class="btn-borrar" type="button" @click="borrar(filtro.id)">
            <inline-svg
              class="icono-borrar-filtro"
              src="../../assets/images/basura.svg"
              title="My Image"
            />
          </button>
        </b-col>
      </b-row>
    </b-col>

    <b-col
      
      cols="12"
    >
      
    </b-col>
      <b-col
      v-bind:key="tiposDeFiltro.tipo"
      v-for="tiposDeFiltro in tiposDeFiltros"
      cols="12"
    >
      <b-row v-if="filtro.tipo == tiposDeFiltro.tipo">
        <b-col sm="12" md="12" lg="4">
          <b-form-group class="text-left" :label="tiposDeFiltro.label">
            <select
              :id="tiposDeFiltro.tipo + '-select'"
              v-model="value"
              @change="changeValue(filtro.id, value)"
              required
            >
              <option
                v-bind:key="campo.id"
                v-for="campo in filtros[tiposDeFiltro.lista]"
                :value="tiposDeFiltro.campo != '' ? campo.id : campo"
              >
                {{
                  tiposDeFiltro.campo != "" ? campo[tiposDeFiltro.campo] : campo
                }}
              </option>
            </select>
          </b-form-group>
        </b-col>
        <b-col sm="12" md="12" lg="4" class="text-left boton-filtro">
          <button class="btn-borrar" type="button" @click="borrar(filtro.id)">
            <inline-svg
              class="icono-borrar-filtro"
              src="../../assets/images/basura.svg"
              title="My Image"
            />
          </button>
        </b-col>
      </b-row>
    </b-col>
  </b-row>
</template>

<script>
export default {
  props: {
    filtro: Object,
    borrar: Function,

    //function change
    changeDate: Function,
    changeValue: Function,

    filtros: Object,
  },
  data() {
    return {
      value: "",
      fecha_inicial: "",
      fecha_final: "",
      listaCliente: [],
      tiposDeFiltros: [
            {tipo: 'pedidor',campo:'name', lista: 'listaPedidores', label: 'Pedidor'},
            {tipo: 'revisor',campo:'name', lista: 'listaRevisores', label: 'Revisor'},
            {tipo: 'administrativas',campo:'name', lista: 'listaAdministrativas', label: 'Garantías y Correcciones'},
            {tipo: 'agentes_carga',campo:'name', lista: 'listaAgentes_carga', label: 'Agentes de Carga'},
            {tipo: 'caracteristicas_carga',campo: '', lista: 'listaCaracteristicas_carga', label: 'Características de Carga'},
            {tipo: 'estados_despacho',campo: '', lista: 'listaEstados_despacho', label: 'Estados de Despacho'},
            {tipo: 'estados_retiro',campo:'', lista: 'listaEstados_retiro', label: 'Estados de Retiro'},
            {tipo: 'gestores',campo:'name', lista: 'listaGestores', label: 'Gestores'},
            {tipo: 'jefes_operaciones',campo:'name', lista: 'listaJefes_operaciones', label: 'Jefes de Operaciones'},
            {tipo: 'nombres_transporte',campo:'', lista: 'listaNombres_transporte', label: 'Nombres de Transporte'},
            {tipo: 'tipos_carga',campo:'', lista: 'listaTipos_carga', label: 'Tipos de Carga'},
            {tipo: 'tipos_condicion',campo:'', lista: 'listaTipos_condicion', label: 'Tipos de Condición'},
            {tipo: 'tramitadores_aduaneros',campo:'name', lista: 'listaTramitadores_aduaneros', label: 'Tramitadores Aduaneros'},
            {tipo: 'aduana',campo:'nombre', lista: 'listaAduanas',  label: 'Aduana'},
            {tipo: 'aforo',campo:'aforo', lista: 'listaAforos',  label: 'Aforo'}
        ],
    };
  },
  mounted() {
    this.listaCliente = [];
    this.filtros['listaNombre_cliente'].forEach(client => {
                this.listaCliente.push({id: client.id, text:client.nombre});
            })
  },
};
</script>

<style>
.boton-filtro {
  margin-top: 25px;
}

@media only screen and (max-width: 600px) {
  .boton-filtro {
    margin-top: 0;
    margin-bottom: 25px;
  }
}
</style>