<template>
  <div>
    <title-page title="DESPACHOS" />
    <card title="Crear despacho" :agregar="false">
      <form-despacho 
      v-if="listaTipoImportacion && listaTipoExportacion && listaUsuarios && clientes && despachos"

        :form="form" 

        :usuarios="usuarios" 

        :despachos="despachos" 

        :clientes="clientes" 
        :listaUsuarios="listaUsuarios"

        :listaTipoImportacion="listaTipoImportacion" 
        :listaTipoExportacion="listaTipoExportacion"

        :valorGrande="valorGrande" 
        :submit="submit"
        ></form-despacho>

    </card>
  </div>
</template>

<script>
import axios from 'axios'
import TitlePage from '../layout/TitlePage.vue'
import Card from '../ui/Card.vue'
import FormDespacho from '../formulario/FormDespacho.vue'
import { mapGetters } from 'vuex'
export default {
  components: { TitlePage, Card, FormDespacho },
  data() {
    return {
      form: {
        codigo: '',
        autoincremental: false,
        gestor_id: '',
        cod_agente: '',
        pedidor_id: '',
        tramitador_servicios_id: '',
        administrativa_id: '',
        jefe_operaciones_id: '',
        jefe_facturacion_id: '',
        fecha_inicio: '',
        tipo_despacho: '',
        tipo_despacho_id: '',
        tipo_importacion: '',
        tipo_exportacion: '',
        users: [],
        requiere_vistos_buenos: false,
        clientes_id: '',
        aforo: false,
        padre: '',
      },

      listaUsuarios: {
        'listaPedidor': [],
        'listaPedidorExportacion': [],
        'listaTramitadorServicio': [],
        'listaJefeOperaciones': [],
        'listaJefeFacturacion': [],
        'listaTramitadorAduanero': [],
        'listaPresentador': [],

      },
      listaTipoImportacion: null,
      listaTipoExportacion: null,
      clientes: null,
      despachos: null,

      //codigo
      codigo_anterior: 0,
    }
  },
  computed: {
    ...mapGetters({
      user: 'auth/user',
    }),
  },

  mounted() {
    this.getUsers()
    this.getSubTipos()
    this.getClientes()
    this.getDespachos()
  },
  methods: {
    async getDespachos() {
      await axios.get('api/admin/despachos/get-despachos')
        .then(resp => {
          this.despachos = resp.data.data;
        })
        .catch(err => console.log(err))
    },
    async getUsers() {
      await axios.get('api/admin/users/user-list-role')
        .then(resp => {
          const usuarios = resp.data;

          this.listaUsuarios.listaPedidor = usuarios.pedidor
          this.listaUsuarios.listaTramitadorServicio = usuarios.tramitador_servicios
          this.listaUsuarios.listaJefeOperaciones = usuarios.jefe_operaciones
          this.listaUsuarios.listaJefeFacturacion = usuarios.jefe_facturas
          this.listaUsuarios.listaTramitadorAduanero = usuarios.tramitador_aduanero
          this.listaUsuarios.listaPresentador = usuarios.prestador
          this.listaUsuarios.listaPedidorExportacion = usuarios.pedidor_exportaciones

        })
        .catch(err => console.log(err))
    },
    async getClientes() {
      await axios.get('api/admin/clientes/despacho')
        .then(resp => {
          this.clientes = resp.data.data;
        })
        .catch(err => console.log(err))
    },

    async getSubTipos() {
      await axios.get('api/admin/despachos/subtipos')
        .then(resp => {
          this.listaTipoImportacion = resp.data.importaciones;
          this.listaTipoExportacion = resp.data.exportaciones;

        })
        .catch(err => console.log(err))
    },

    async submit() {
      if (this.form.padre_id == '') {
        if (this.form.tipo_despacho == 'importacion') this.form.tipo_despacho_id = this.form.tipo_importacion
        else this.form.tipo_despacho_id = this.form.tipo_exportacion
        if (this.form.pedidor_id && this.form.pedidor_id != '') this.form.users.push(this.form.pedidor_id)
        if (this.form.tramitador_servicios_id && this.form.tramitador_servicios_id != '') this.form.users.push(this.form.tramitador_servicios_id)
        if (this.form.administrativa_id && this.form.administrativa_id != '') this.form.users.push(this.form.administrativa_id)
        if (this.form.jefe_operaciones_id && this.form.jefe_operaciones_id != '') this.form.users.push(this.form.jefe_operaciones_id)
        if (this.form.jefe_facturacion_id && this.form.jefe_facturacion_id != '') this.form.users.push(this.form.jefe_facturacion_id)
        if (this.form.tramitador_aduanero_id && this.form.tramitador_aduanero_id != '') this.form.users.push(this.form.tramitador_aduanero_id)
        if (this.form.presentador_id && this.form.presentador_id != '') this.form.users.push(this.form.presentador_id)

      }
      // fecha de hoy
      this.form.fecha_inicio = new Date().toISOString().split('T')[0]

      await axios.post('/api/admin/despachos', this.form)
        .then(resp => {
          this.$router.replace({ name: 'PerfilDespacho', params: { despachoId: resp.data.data.id } })
        })
        .catch(err => {
          this.$swal({
            icon: 'error',
            title: '¡Error!',
            text: 'Ha ocurrido un error, por favor verifique que los datos sean ingresados de forma correcta ',
          });
          this.metodoCatch(err)
        })
    },
    metodoCatch(error) {
      if (error.response) {
        Object.keys(error.response.data.errors).forEach((key) => {
          if (document.getElementById(`${key}`)) {
            document.getElementById(`${key}`).innerHTML = error.response.data.errors[key];
          }
        })
      }
    },

    usuarios() {
      this.form.gestor_id = ''
      this.form.pedidor_id = ''
      this.form.tramitador_servicios_id = ''
      this.form.administrativa_id = ''
      this.form.jefe_operaciones_id = ''
      if (this.form.tipo_despacho !== 'importacion') {
        this.form.jefe_facturacion_id = ''
      }
      else {
        this.setJefeFacturacion()
      }
    },
    setJefeFacturacion() {
      for (let e of this.listaUsuarios.listaJefeFacturacion) {
        if (e.name === "Katherine Roa Gonzalez") {
          this.form.jefe_facturacion_id = e.id
        }
        if (e.id === 28) { // verifica si existe el id 28 Katherine Roa
          this.form.jefe_facturacion_id = e.id // asigna esa id como seleccionada por defecto si es que existe
          break
        }
      }
    },

    valorGrande() {
      if (this.form.codigo > 9999999999) this.form.codigo = this.codigo_anterior
      else this.codigo_anterior = this.form.codigo
    }
  },
}
</script>

<style></style>