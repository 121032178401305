<template>
  <b-col cols="12" class="contenedor-informacion">
        <b-row>
            <b-col cols="9 seccion-informacion-seccion mx-auto">
                <b-row>
                    <b-col cols="6" class="borde-derecho">
                        <h5 class="titular-dato">TIPO DE CARGA</h5>
                        <p class="contenido-dato" v-if="despacho.tipo.tipo_carga">{{despacho.tipo.tipo_carga}}</p>
                        <p class="contenido-dato" v-else>-</p>
                    </b-col>
                    <b-col cols="6">
                        <h5 class="titular-dato">CARACTERISTICA DE CARGA</h5>
                        <p class="contenido-dato" v-if="despacho.tipo.caracteristicas_carga">{{despacho.tipo.caracteristicas_carga}}</p>
                        <p class="contenido-dato" v-else>-</p>
                    </b-col>
                </b-row>
            </b-col>

            <form-contenedor
                v-if="editar == false && permiteAgregarContenedor"

                :caracteristica_carga="despacho.tipo.caracteristicas_carga"
                :form="form"
                :variedades="variedades"
                :pagos="pagos"
                :documentos="documentos"
                :agregarContendor="agregarContendor"
                :borrarContenedor="borrarContenedor"
                :postContenedor="postContenedor"
            >

            </form-contenedor>

            <form-contenedor-editar
                v-if="editar == true && contenedor && permiteAgregarContenedor"
                :caracteristica_carga="despacho.tipo.caracteristicas_carga"
                :variedades="variedades"
                :pagos="pagos"
                :documentos="documentos"
                :contenedor="contenedor"
                :files_old="files_old"
                :postContenedor="postEditContenedor"
            >
                <template slot="cancelar">
                    <b-button
                    style="margin-top:25px"
                        class="btn-secundario btn-small"
                        @click="cancelarEditar()"
                    >
                        Cancelar 
                    </b-button>
                </template>
            </form-contenedor-editar>
            

            <b-col cols="12" class="text-left">
                <b-table striped hover :items="items" :fields="fields" show-empty responsive>
                    <template #empty >
                        <h4 class="text-center">No hay carga disponible</h4>
                    </template>
                    <template #cell(numero_guia_despacho)="row">
                        <div v-if="row.item.documento">
                            <p>{{row.item.documento.base.numero_guia_despacho}}</p>
                        </div>
                    </template>
                    <template #cell(documentos)="row">
                        <div v-if="row.item.documento">
                            <b-button class="btn-archivo-tabla" v-b-tooltip.hover :title="documento.ruta" v-for="(documento,index) in row.item.documento.archivos" :key="index" style="width:20px" @click="downloadDocumento(documento.id)" >
                            <inline-svg v-if="documento.ruta.split('.').at(-1) == 'pdf'" src="../../assets/images/archivo-pdf.svg" />
                            <inline-svg 
                            v-if="documento.ruta.split('.').at(-1) == 'png' || documento.ruta.split('.').at(-1) == 'jpg' || documento.ruta.split('.').at(-1) == 'jpeg'" 
                            src="../../assets/images/archivo-png.svg"/>
                            </b-button>
                        </div>
                    </template> 
                    <template #cell(pagos)="row">
                        <div v-if="row.item.pago">
                            <b-button class="btn-archivo-tabla" v-b-tooltip.hover :title="pago.ruta" v-for="(pago,index) in row.item.pago.archivos" :key="index" style="width:20px" @click="downloadDocumento(pago.id)" >
                                
                            <inline-svg v-if="pago.ruta.split('.').at(-1) == 'pdf'" src="../../assets/images/archivo-pdf.svg" />
                           <inline-svg 
                           v-if="pago.ruta.split('.').at(-1) == 'png' || pago.ruta.split('.').at(-1) == 'jpg' || pago.ruta.split('.').at(-1) == 'jpeg'" 
                           src="../../assets/images/archivo-png.svg"/>
                            </b-button>
                        </div>
                        <div v-else>
                            <p>No tiene pagos asociados</p>
                        </div>
                        
                    </template>
                    <template #cell(acciones)="row" >
                        <div>
                            <b-button v-if="canManage()" @click="editarContenedor(row.item)" class="btn-extra-secundario btn-small mr-1">
                                Editar <inline-svg  class="icono-titulo" src="../../assets/images/Editar.svg" title="My Image"/>
                            </b-button>
                            <b-button v-if="canManage()" @click="eliminar(row.item.id)" class=" btn-extra-primario btn-small">
                                Eliminar <inline-svg  class="icono-titulo" src="../../assets/images/basura.svg" title="My Image"/>
                            </b-button>
                        </div>
                    </template>
                </b-table>
            </b-col>
        </b-row>
    </b-col>
</template>

<script>
import axios from 'axios'
import FormDocumento from '../../formulario/FormDocumento.vue'
import FormDocumentoEditar from '../../formulario/FormDocumentoEditar.vue'
import moment from 'moment';
import FormContenedor from '../../formulario/FormContenedor.vue';
import FormContenedorEditar from '../../formulario/FormContenedorEditar.vue';
export default {
  components: { FormDocumento, FormDocumentoEditar, FormContenedor, FormContenedorEditar },
    props:{
        canManage:{type: Function, default: ()=>{return true}},
        despacho:Object,
        permiteAgregarContenedor: Boolean
    },
    data(){
        return{
            fields: [
                {key:'id', label: '#'},
                // {key:'variedad', label: 'Variedad'},
                // {key:'identificacion', label: 'Identificación'},
                {key:'fecha_retiro', label: 'Fecha Hora de Retiro'},
                {key:'patente', label: 'Patente'},
                {key:'numero_guia_despacho', label: 'Número Guía'},
                {key:'documentos', label: 'Documentos'},
                {key:'pagos', label: 'Pagos'},
                {key:'acciones', label: 'Acciones'},
            ],

            items: [],

            editar: false,

            documentoEditar: null,

            files_old: [],

            editar: false,

            idDespacho:1,
            idContenedor: 1,
            form:[
                {id:1,
                // variedad: '',identificacion:'',
                fecha_retiro:'',hora_retiro:'',patente:'',documentos_id:'',pagos_id:''}
            ],

            contenedor:null,
            contenedorAntiguo: {},

            variedades: null,
            pagos:null,
            documentos:null,

        }
    },
    mounted(){



        if(!this.permiteAgregarContenedor) this.fields.pop()
        this.idDespacho = this.$route.params.despachoId;
        this.getContenedores()
        this.getVariedades()
        this.getPagos()
        this.tipoCarga()
    },
    methods:{
        agregarContendor(){
            this.idContenedor++;
            this.form.push({id:this.idContenedor,
            // variedad: '',identificacion:'',
            fecha_retiro:'',hora_retiro:'',patente:'',documentos_id:'',pagos_id:''})

            

            switch(this.despacho.tipo.caracteristicas_carga){
                case 'CONTENEDOR' :
                    this.form.push({id:this.idContenedor,
                        variedad: '',identificacion:'',
                        fecha_retiro:'',hora_retiro:'',patente:'',documentos_id:'',pagos_id:''})
                    break;
                case 'CARGA SUELTA': 
                    this.form.push({id:this.idContenedor,
                        Bultos: '',Kilos:'', CBM: '',
                        fecha_retiro:'',hora_retiro:'',patente:'',documentos_id:'',pagos_id:''})
                    break;
                case 'VEHÍCULOS':
                    this.form.push({id:this.idContenedor,
                        cantidad: '',
                        fecha_retiro:'',hora_retiro:'',patente:'',documentos_id:'',pagos_id:''})
                    break;
                case 'FIERROS': 
                    this.form.push({id:this.idContenedor,
                        cantidad: '',
                        fecha_retiro:'',hora_retiro:'',patente:'',documentos_id:'',pagos_id:''})
                    break;
                case 'GRANELES':
                    this.form.push({id:this.idContenedor,
                        volumen:'',
                        fecha_retiro:'',hora_retiro:'',patente:'',documentos_id:'',pagos_id:''})
                    break;
                default:
                    break;
            }
        },
        tipoCarga(){
            switch(this.despacho.tipo.caracteristicas_carga){
                case  'CONTENEDOR':
                    this.fields = [
                        {key:'id', label: '#'},
                        {key:'base.variedad', label: 'Variedad'},
                        {key:'base.identificacion', label: 'Identificación'}
                    ];
                    break;
                case 'CARGA SUELTA': 
                    this.fields = [
                        {key:'id', label: '#'},
                        {key:'base.bultos', label: 'Bultos'},{key:'base.kilos', label: 'Kilos'},{key:'base.CBM', label: 'CBM'},
                    ];
                    break;
                case 'VEHÍCULOS':
                    this.fields = [
                        {key:'id', label: '#'},
                        {key:'base.cantidad', label: 'Cantidad'},
                    ];
                    break;
                case 'FIERROS': 
                    this.fields = [
                        {key:'id', label: '#'},
                        {key:'base.cantidad', label: 'Cantidad'},
                    ];
                    break;
                case 'GRANELES':
                    this.fields = [
                        {key:'id', label: '#'},
                        {key:'base.volumen', label: 'Volumen'},
                    ];
                    break;
                default:
                    break;
            }

            this.fields.push(
                {key:'fecha_retiro', label: 'Fecha Hora de Retiro'},
                {key:'patente', label: 'Patente'},
                {key:'numero_guia_despacho', label: 'Número Guía'},
                {key:'documentos', label: 'Documentos'},
                {key:'pagos', label: 'Pagos'},
                {key:'acciones', label: 'Acciones'},
            )
        },
        borrarContenedor(id){
            if(this.form .length == 1) return false
            let nuevoForm = [];
            this.form.forEach(el => {
                if(el.id != id) nuevoForm.push(el)
            })

            this.form = nuevoForm
        },
        postContenedor(){
            
            this.form.forEach(contenedor => {
                let formData = new FormData();
                switch(this.despacho.tipo.caracteristicas_carga){
                case 'CONTENEDOR' :
                        formData.append('variedad', contenedor.variedad);
                        formData.append('identificacion', contenedor.identificacion);
                    break;
                case 'CARGA SUELTA': 
                        formData.append('bultos', contenedor.bultos);
                        formData.append('kilos', contenedor.kilos);
                        formData.append('CBM', contenedor.CBM);
                    break;
                case 'VEHÍCULOS':
                    formData.append('cantidad', contenedor.cantidad)
                    break;
                case 'FIERROS': 
                    formData.append('cantidad', contenedor.cantidad);
                    break;
                case 'GRANELES':
                    formData.append('volumen', contenedor.volumen);
                    break;
                default:
                    break;
                }

                let fecha = contenedor.fecha_retiro  + ' ' + contenedor.hora_retiro 
                if(contenedor.fecha_retiro && contenedor.hora_retiro) formData.append('fecha_retiro',  moment(fecha).format('Y-MM-DD HH:mm:ss'));
                if(contenedor.patente) formData.append('patente', contenedor.patente);
                if(contenedor.numero_guia_despacho) formData.append('numero_guia_despacho', contenedor.numero_guia_despacho);
                if(contenedor.pagos_id) formData.append('pagos_id', contenedor.pagos_id);
                if(contenedor.documentos_id) formData.append('documentos_id', contenedor.documentos_id);


                formData.append('despachos_id', this.idDespacho);
                
                
                

                axios.post(`api/admin/contenedores`,formData)
                .then(res => {
                    this.getContenedores()
                })
                .catch(err => console.log(err))
            })
            this.idContenedor = 1
            
            switch(this.despacho.tipo.caracteristicas_carga){
                case 'CONTENEDOR' :
                    this.form =[{id:this.idContenedor,
                        variedad: '',identificacion:'',
                        fecha_retiro:'',hora_retiro:'',patente:'',documentos_id:'',pagos_id:''}]
                    break;
                case 'CARGA SUELTA': 
                    this.form = [{id:this.idContenedor,
                        bultos: '',kilos:'', CBM: '',
                        fecha_retiro:'',hora_retiro:'',patente:'',documentos_id:'',pagos_id:''}]
                    break;
                case 'VEHÍCULOS':
                    this.form = [{id:this.idContenedor,
                        cantidad: '',
                        fecha_retiro:'',hora_retiro:'',patente:'',documentos_id:'',pagos_id:''}]
                    break;
                case 'FIERROS': 
                    this.form = [{id:this.idContenedor,
                        cantidad: '',
                        fecha_retiro:'',hora_retiro:'',patente:'',documentos_id:'',pagos_id:''}]
                    break;
                case 'GRANELES':
                    this.form = [{id:this.idContenedor,
                        volumen:'',
                        fecha_retiro:'',hora_retiro:'',patente:'',documentos_id:'',pagos_id:''}]
                    break;
                default:
                    break;
            }
        },

        async postEditContenedor(files,contenedor,files_delete){
            let formData = new FormData();
            formData.append('despachos_id', this.idDespacho);

            switch(this.despacho.tipo.caracteristicas_carga){
                case 'CONTENEDOR' :
                        formData.append('variedad', contenedor.variedad);
                        formData.append('identificacion', contenedor.identificacion);
                    break;
                case 'CARGA SUELTA': 
                        formData.append('bultos', contenedor.bultos);
                        formData.append('kilos', contenedor.kilos);
                        formData.append('CBM', contenedor.CBM);
                    break;
                case 'VEHÍCULOS':
                    formData.append('cantidad', contenedor.cantidad);
                    break;
                case 'FIERROS': 
                    formData.append('cantidad', contenedor.cantidad);
                    break;
                case 'GRANELES':
                    formData.append('volumen', contenedor.volumen);
                    break;
                default:
                    break;
                }
            

            let fecha = contenedor.fecha_retiro  + ' ' + contenedor.hora_retiro 
            if(contenedor.fecha_retiro && contenedor.hora_retiro) formData.append('fecha_retiro',  moment(fecha).format('Y-MM-DD HH:mm:ss'));
            if(contenedor.patente) formData.append('patente', contenedor.patente);
            if(contenedor.numero_guia_despacho) formData.append('numero_guia_despacho', contenedor.numero_guia_despacho);
            if(contenedor.pagos_id) formData.append('pagos_id', contenedor.pagos_id);
            if(contenedor.documentos_id) formData.append('documentos_id', contenedor.documentos_id);

            formData.append('_method','put')
            await axios.post(`api/admin/contenedores/${contenedor.id}`,formData,
            {
                headers: {
                    'Content-Type': 'multipart/form-data'
                },
            })
            .then( res => {
                this.getContenedores()
                this.cancelarEditar()
            })
            .catch(err => console.log(err))
        },
        async getContenedores(){
            await axios.get(`api/admin/contenedores/despacho/${this.idDespacho}`)
            .then(res => {
                this.items = res.data.data
            })
            .catch(err => console.log(err))
        },
        async getVariedades(){
            await axios.get('api/admin/contenedores/variedades')
            .then(res => {
                this.variedades = res.data.data
            })
            .catch(err => console.log(err))
        },
        async getPagos(){
            await axios.get(`api/admin/contenedores/pagos/despacho/${this.idDespacho}`)
            .then(res => {
                this.pagos = res.data.pagos
                this.documentos = res.data.documentos
            })
            .catch(err => console.log(err))
        },

        ///ACCIONES CONTENEDOR
        editarContenedor(item){

            this.contenedor = null
            this.contenedor = item
            this.contenedor.fecha = moment(item.fecha_retiro).format('Y-MM-DD')
            this.contenedor.hora = moment(item.fecha_retiro).format('HH:mm')

            this.editar = true
        },
        cancelarEditar(){
            this.editar = false
            this.contenedor = null
        },

        async eliminar(container_id){
            let variable = await this.$swal({
            title: "¿Deseas eliminar este contenedor?",
            text: "¿Está seguro? ¡No podrás revertir esto!",
            type: "warning",
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Si, Borrar!',
            cancelButtonText: 'No, cancelar!',
            buttonsStyling: true
        })
        if (variable.isConfirmed == true) {
            await axios.delete(`api/admin/contenedores/${container_id}`)
            .then(res => {
                this.getContenedores()
            })
            .catch(err => console.log(err))
        }
        },

        //DESCARGAR DOCUMENTOS
        async downloadDocumento(id){
            this.$router.replace({ name: 'Documento', params:{despachoId: this.idDespacho, documentoId: id} })
        }
    }

}
</script>