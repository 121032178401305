<template>
    <form class="form-input" @submit.prevent="submit" id="form-pagos" enctype="multipart/form-data">
        <b-row>
            <b-col sm="12" md="12" lg="3">
                <b-form-group
                    :content-cols="12"
                    label="TIPO DE PAGO"
                >
                    <select class="form-control border border-secondary"  id="documento-select"  v-model="tipo_pago"  required>
                        <option value="" disabled>Seleccionar Tipo de Pago</option>
                        <option v-for="(tipo,index) in pagos.tipos_pagos" :key="index" :value="tipo.id">{{tipo.nombre}}</option>
                    </select>
                </b-form-group>
            </b-col>
            <b-col sm="12" md="12" lg="3">
                <b-form-group
                    :content-cols="12"
                    label="TIPO DE DOCUMENTO CONTABLE"
                > 
                    <select class="form-control border border-secondary"  id="documento-select"  v-model="tipo_documento_contable"  required>
                        <option value="" disabled>Seleccionar Tipo de Documento Contable</option>
                        <option v-for="(tipo,index) in pagos.tipo_documento_contable" :key="index" :value="tipo">{{tipo}}</option>
                    </select>
                </b-form-group>
            </b-col>
            <b-col sm="12" md="12" lg="3">
                <b-form-group
                    :content-cols="12"
                    label="PROVEEDOR"
                > 
                <Select2
                    class="mi-select2"
                    v-model="proveedor_id"
                    :options="lista_proveedores"
                    :settings="{
                    noResults: 'es',
                    placeholder: 'Seleccione un Proveedor',
                    width: '100%',
                    multiple: false,
                    }"
                />
                </b-form-group>
            </b-col>
            <b-col sm="12" md="12" lg="3">
                <b-form-group
                    :content-cols="12"
                    label="Monto"
                > 
                    <b-input type="text" @keyup="formatMonto()" @keydown="formatMonto();" v-model="monto" required>
                    </b-input>
                </b-form-group>
            </b-col>
            <b-col sm="12" md="12" lg="3">
                <b-form-group
                    :content-cols="12"
                    label="Fecha Documento"
                > 
                    <b-form-input type="date" max="12" v-model="fecha_pago" required>
                    </b-form-input>
                </b-form-group>
            </b-col>
            <b-col sm="12" md="12" lg="3">
                <b-form-group
                    :content-cols="12"
                    label="N° Documento"
                > 
                    <b-input type="number" v-model="nro_documento" min=0 value="" required>
                    </b-input>
                </b-form-group>
            </b-col>
            <b-col sm="12" md="12" lg="3">
                <b-form-group
                    :content-cols="12"
                    label="ADJUNTAR DOCUMENTO(S)"
                > 
                     
                    <b-form-file
                    browse-text="Buscar"
                        placeholder="Seleccione un archivo"
                        drop-placeholder="Coloque el archivo aquí..."
                     v-model="file" ref="file-input" class="mb-2" multiple></b-form-file>

                    <b-button class="btn-archivo" v-b-tooltip.hover :title="file.name" type="button" v-for="(file,index) in files" :key="index" @click="eliminar(file)">

                             <inline-svg v-if="file.name.split('.').at(-1) == 'pdf'" src="../../assets/images/archivo-pdf.svg" />
                           <inline-svg 
                           v-if="file.name.split('.').at(-1) == 'png' || file.name.split('.').at(-1) == 'jpg' || file.name.split('.').at(-1) == 'jpeg'" 
                           src="../../assets/images/archivo-png.svg"/>
                        </b-button>
                </b-form-group>
            </b-col>
            
            <b-col sm="12" md="12" lg="3">
                <b-button
                style="margin-top:30px"
                class="btn-principal btn-small"
                type="submit"
                >
                    Enviar
                </b-button>
            </b-col>
        </b-row>
    </form>
</template>

<script>
import axios from 'axios'
export default {
    props:{
        pagos: Object,
        proveedores: Array,
        postPago : Function,
        cancelar: Function,
    },
    data(){
        return{
            file: null,
            files: [],
            lista_proveedores: [],
            tipo_pago: '',
            tipo_documento_contable: '',
            monto: 0,
            fecha_pago: '',
            proveedor_id:'',
            nro_documento: null
        }
    },
    mounted(){
        this.lista_proveedores = [];
        this.proveedores.forEach((el) => {
    
        this.lista_proveedores.push({ id: el.id, text: el.nombre });
    });
    },  
    watch:{
        file:function(e){
            this.$refs['file-input'].files.forEach(el =>{
                this.files.push(el)
            })
            this.$refs['file-input'].reset()
        }
    },
    methods:{
        formatMonto(){
            this.monto = this.monto.toString().replace(/\D/g, '')
            let money = this.monto.toString().replace(/[.,$\s]/g, '')
            this.monto = new Intl.NumberFormat('es-CL', {currency: 'CLP', style: 'currency'}).format(money)
        },
        async eliminar(file){
            let variable = await this.$swal({
                    title: "¿Deseas eliminar este documento de pago?",
                    text: "¿Está seguro? ¡No podrás revertir esta acción!",
                    type: "warning",
                    showCancelButton: true,
                    confirmButtonColor: '#3085d6',
                    cancelButtonColor: '#d33',
                    confirmButtonText: 'Si, Borrar!',
                    cancelButtonText: 'No, cancelar!',
                    buttonsStyling: true
                  })
            if(variable.isConfirmed == true){
            let encontro = false;
            this.files = this.files.filter(el => {
                if(!encontro){
                    if (el.name == file.name) {
                        encontro = true;
                    }else{
                        return el

                    }
                }else{
                    return el
                }
            })
            }
            

        },
        async submit(){
            
            let formData = new FormData();
            
            this.id = this.$route.params.despachoId;
            formData.append('despachos_id', this.id);
            formData.append('tipo_pagos_id', this.tipo_pago);
            formData.append('tipo_documento_contable', this.tipo_documento_contable);
            formData.append('fecha_pago', this.fecha_pago);
            formData.append('proveedor_id', this.proveedor_id);
            formData.append('monto', Number(this.monto.toString().replace(/\D/g, '')));
            formData.append('nro_documento', this.nro_documento);

            for( var i = 0; i < this.files.length; i++ ){
                let file = this.files[i];
                formData.append('files['+ i +']', file);
            }

            if(this.files.length > 0 ) {
                this.cancelar()
                this.postPago(formData)
                this.id = ''
                this.tipo_pago = ''
                this.tipo_documento_contable = ''
                this.monto = 0
                this.nro_documento = 0
                this.proveedor_id = ''
                this.fecha_pago = '' 
                this.files = []
                this.$refs['file-input'].files = []
                
                }
            else{
                this.$swal({
                    icon: 'warning',
                    title: '',
                    text: 'Se deben subir documentos para comprobar pago',
                });
            }
            
            
            
        }
    }
}
</script>

<style>

</style>