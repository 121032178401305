<template>
    <div>
        <b-form class="form-input" @submit.prevent="submit(formFiltro)" >
            <b-row>
                    <b-col sm="12" md="12" lg="6">
                        <b-form-group class="text-left" :label="label_filtro">
                            <b-input
                                class="border border-secondary"
                                type="text"
                                v-model="value"
                                @change="changeValue(filtro.id, value)"
                                required
                                >
                                </b-input>
                        </b-form-group>    
                    </b-col>        
                    <b-col sm="12" md="12" lg="2" class="text-left mt-4 mb-4 mr-4">
                        <button
                          type="submit"
                          class="btn-principal btn-small mt-2"
                      >
                          Filtrar
                      </button>
                    </b-col>   
                    <b-col sm="12" md="12" lg="2" class="text-left mt-4 mb-4">
                    <button
                          type="button"
                          @click="borrarFiltro"
                          class="btn-secundario btn-small mt-2"
                      >
                          Limpiar
                      </button>
                    </b-col>
            </b-row>
  
            
          
        </b-form>
    </div>
  </template>
  
  <script>
  import { mapGetters, mapActions } from 'vuex'
  export default {
      
      props:{
          filtro: Object,
          limpiar: Function,
          submit: Function,
          listaDespachos:Array,
          label_filtro:String
      },
      data(){
          return{
              value: "",
              id: 1,
              tipoFiltro: 'codigo',
              tipos:[
              //NOMBRES Y VALORES DENTRO DEL SELECT
                  {text: 'Despacho', value: 'codigo'},    
              ],
              formFiltro:[{id: this.id, tipo:'codigo', value:''}]
          }
      },
      computed: {
        
        ...mapGetters({
          filtro: 'filtros/filtro',
          fecha: 'filtros/fecha',
        })
      },
      mounted(){
      },
      methods:{
          agregar(){
              //se agrega el campo de filtro abajo
          },
          borrar(id){
             this.formFiltro = this.formFiltro.filter(el => el.id != id)
             if(this.formFiltro.length == 0) this.borrarFiltro()
             else this.submit(this.formFiltro)
          },
          borrarFiltro(){
              this.formFiltro = []
              this.limpiar()
          },
          
  
          ///CHANGE
          changeValue(id, campo){
            this.formFiltro=[{id: this.id, tipo:'codigo', value:''}]
            if(this.value != campo){

            }
              this.formFiltro.forEach(el => {
                      el.value = campo;
                      this.value = campo;
                  
              })
          }
      }
  }
  </script>
  
  <style>
      .filtros-dashboard .titulo{
          font-weight: 500;
      }
      .filtros-dashboard .fecha{
          margin-top: 10px;
      }
      .filtros-dashboard .filtro{
          margin-top: 10px;
      }
      .filtros-dashboard .fecha .valor, .filtro .valor{
          padding: 7px;
          padding: 5px 7px;
          background-color: #F0F5FA;
      }
  </style>