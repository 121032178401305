<template>
  <div>
    <TitlePage title="DESPACHOS" />
    <card class="mb-5" title="Filtros" :accionBoton="filtrar" permiso="dispatch.create" :boton="false" text="Filtrar">
      <b-row>
        <b-col>
          <form-filtro v-if="filtros.listaAduanas" :submit="filtrar" :filtros="filtros" :limpiar="limpiar" :label_filtro="'Agregar filtro'">
      </form-filtro>
    </b-col>
        <b-col cols="7" v-bind:key="filtro.id" v-for="filtro in formFiltro2">
          <form-filtro-despacho  :submit="filtrar" :listaDespachos="listaDespachos" :filtro="filtro" :limpiar="limpiar" :label_filtro="'Filtro por despacho'">
      </form-filtro-despacho>
    </b-col>
        
  </b-row>
    </card>
    <card title="Despachos" :accionBoton="agregar" permiso="dispatch.create" :boton="true" text="Agregar"
      :segundoaccionBoton="generarReporteImportaciones" :segundoboton="true" segundotext="Reporte Importaciones" 
      :terceraAccionBoton="generarReporteExportaciones" :tercerBoton="true" tercerText="Reporte Exportaciones">
      <div class="table-container">
        <b-table class="table-index text-left " responsive hover :items="items" :fields="fields" :busy="isBusy" show-empty>

          <template #empty>
            <h4 class="text-center">No hay despachos disponibles</h4>
          </template>

          <template #cell(codigo)="row">
            <div class="codigo-importacion" v-if="row.item.tipo_despacho == 'importacion'">
              <p class="">
                <fas v-if="row.item.readonly" size="sm" :id="'popover-im-' + row.item.id" style="cursor:pointer"
                  :icon="['fas', 'exclamation-circle']"></fas> {{ row.item.codigo }}
                <b-popover v-if="row.item.readonly" :target="`popover-im-` + row.item.id" title="Advertencia - Permisos"
                  triggers="hover focus"
                  :content="'No se cuentan permisos para este despacho. Solo se pueden visualizar los datos!'"></b-popover>
              </p>
            </div>
            <div class="codigo-exportacion" v-else>
              <p>
                <fas v-if="row.item.readonly" size="sm" :id="'popover-ex-' + row.item.id" style="cursor:pointer"
                  :icon="['fas', 'exclamation-circle']"></fas> {{ row.item.codigo }}
                <b-popover v-if="row.item.readonly" :target="`popover-ex-` + row.item.id" title="Advertencia - Permisos"
                  triggers="hover focus"
                  :content="'No se cuentan permisos para este despacho. Solo se pueden visualizar los datos!'"></b-popover>
              </p>
            </div>
          </template>



          <template #cell(tipo_carga)="row">
            <p v-if="row.item.tipo_carga">
              {{ row.item.tipo_carga }}
            </p>
            <p v-else>
              -
            </p>
          </template>
          <template #cell(requiere_vistos_buenos)="row">
            <p v-if="row.item.requiere_vistos_buenos == 1">
              Si
            </p>
            <p v-else>
              No
            </p>
          </template>
          <template #cell(tipo_despacho)="row">
            <div class="estado-despacho importacion" v-if="row.item.tipo_despacho == 'importacion'">
              <p class="">
                Importación
              </p>
            </div>
            <div class="estado-despacho exportacion" v-else>
              <p>
                Exportación
              </p>
            </div>
          </template>
          <template #cell(estado_despacho)="row">
            <div v-if="row.item.contenedores.length != 0">
                <div v-if="row.item.estado_despacho == 'Pendiente'">
                  <button class="btn-principal btn-small mr-4 mb-4"
                    @click="changeState = true">
                    Pendiente
                  </button>
                </div>
                <div v-else-if="row.item.estado_despacho == 'Presentado'">
                  <button class="btn-secundario btn-small mr-4 mb-4"
                    @click="changeState = true">
                    Presentado
                  </button>
                </div>
                <div v-else-if="row.item.estado_despacho == 'Retirado'">
                  <button class="btn-terciario btn-small mr-4 mb-4"
                    @click="changeState = true">
                    Retirado
                  </button>
                  
                </div>
                <b-modal v-model="changeState" id="modal-estado" centered @hidden="cerrarModal()">
                    <template #modal-title>
                      Cambiar estado del despacho
                    </template>
                    <div class="d-block text-center">
                      <h3>Escoja un estado para el despacho</h3>
                    </div>
                    <template #modal-footer>
                      <button v-if="row.item.estado_despacho == 'Retirado' || row.item.estado_despacho == 'Presentado'" type="button" class="btn-principal btn-large "
                        @click="confirmarChangeState(row.item.id, 'Pendiente')"> Pendiente</button>

                        <button v-if="row.item.estado_despacho == 'Retirado' || row.item.estado_despacho == 'Pendiente'" type="button" class="btn-secundario btn-large "
                        @click="confirmarChangeState(row.item.id, 'Presentado')"> Presentado</button>

                        <button v-if="row.item.estado_despacho == 'Presentado' || row.item.estado_despacho == 'Pendiente'" type="button" class="btn-terciario btn-large "
                        @click="confirmarChangeState(row.item.id, 'Retirado')"> Retirado</button>

                      <button type="button" class="btn-borrar btn-large " @click="cerrarModal()">Cancelar</button>
                    </template>
                  </b-modal>
              </div>
            <div v-else>
              <div v-if="row.item.estado_despacho == 'Pendiente'">
                <span class="state-pendiente mr-4 mb-4">Pendiente</span>
              </div>

              <div v-else-if="row.item.estado_despacho == 'Presentado'">
                <span class="state-presentado mr-4 mb-4">Presentado</span>
              </div>

              <div v-else-if="row.item.estado_despacho == 'Retirado'">
                <span class="state-retirado mr-4 mb-4">Retirado</span>
              </div>
            </div>
          </template>
          <template #cell(fecha)="row">
            <!-- si no es nulo escribir fecha -->
            <p v-if="row.item.tipo_despacho == 'importacion' && row.item.tipo.fecha_eta != null">
              {{ moment(row.item.tipo.fecha_eta).format('DD-MM-YYYY') }}
            </p>
            <!-- si es nulo no escribir fecha -->
            <p v-else-if="row.item.tipo_despacho == 'importacion'">
              N/a
            </p>
            <p v-else>
              {{ moment(row.item.tipo.fecha_salida_programada).format('DD-MM-YYYY') }}
            </p>
          </template>
          <template #cell(created_at)="row">
            <p>
              {{ moment(row.item.created_at).format('DD-MM-YYYY') }}
            </p>
          </template>

          <template #cell(actions)="row">
            <button @click="ver(row.item, row.index, $event.target)" class="btn-extra-primario btn-small mr-1">
              Ver
            </button>
            <button v-permisos='"dispatch.delete"' @click="deleteDispatch(row.item.id)"
              class="btn-extra-secundario btn-large mr-1">
              Eliminar
            </button>
          </template>

          <template #table-busy>
            <div class="text-center my-2" style="color: #FFCE1E !important ">
              <b-spinner class="align-middle"></b-spinner>
              <strong> Cargando...</strong>
            </div>
          </template>
        </b-table>
        <b-pagination v-model="currentPage" style="width: 100px; margin: auto;" :total-rows="totalRows"
          :per-page="perPage" align="fill" size="sm" class="my-0"></b-pagination>
      </div>
    </card>
  </div>
</template>

<script>
import axios from "axios";

import moment from 'moment';
import TitlePage from '../layout/TitlePage.vue';
import Card from '../ui/Card.vue';
import Modal from '../ui/Modal.vue';
import FormFiltro from '../formulario/FormFiltro.vue';
import FormFiltroDespacho from '../formulario/FormFiltroDespacho.vue';

import { mapGetters, mapActions } from 'vuex'


export default {
  components: { TitlePage, Card, Modal, FormFiltro, FormFiltroDespacho },
  data() {
    return {
      value:'',
      showEditar: false,
      showEliminar: false,
      password: false,
      currentPage: 1,
      totalRows: 0,
      perPage: 0,
      fields: [
        { key: 'codigo', label: 'N° Despacho' },
        { key: 'cliente.nombre', label: 'Cliente' },
        { key: 'fecha', label: 'ETA' },
        { key: 'tipo_despacho', label: 'Tipo de Despacho' },
        { key: 'tipo.aduana.nombre', label: 'Aduana' },
        { key: 'tipo.nombre_transporte', label: 'Medio de Transporte' },
        { key: 'cod_agente', label: 'Cod. Agente' },
        { key: 'estado_despacho', label: 'Estado' },
        { key: 'created_at', label: 'fecha ingreso' },
        { key: 'actions', label: 'Acciones' },
      ],
      items: [],
      isBusy: false,
      listaDespachos: [],

      //Listas para usar en los select filtros
      filtros: {
        listaAduanas: null,
        listaPedidores: null,
        listaAdministrativas: null,
        listaAgentes_carga: null,
        listaCaracteristicas_carga: null,
        listaEstados_despacho: null,
        listaEstados_retiro: null,
        listaGestores: null,
        listaJefes_operaciones: null,
        listaNombres_transporte: null,
        listaNombre_cliente: null,
        listaNombre_nave: null, //filtro por nombre de nave
        listaTipos_carga: null,
        listaTipos_condicion: null,
        listaTramitadores_aduaneros: null,
        listaAforos: null,
        listaRevisores: null,
        listaDespachos: null,
      },

      formFiltro: {
        page: 1,
        filters: {}

      },
      formFiltro2:[{id: this.id, tipo:'codigo', value:''}],
      formDispatch: {
        estado_despacho: null,
      },
      formDispatchBD: [
        
      ],

      changeState: false,

    }
  },
  computed: {
    ...mapGetters({
      filtro: 'filtros/filtro',
      fecha: 'filtros/fecha',
    }),
  },


  mounted() {
    if (this.filtro != '-') {
      this.filtrar()
    } else {
      this.getDispatches();
    }
    this.dispatchFilterData();
  },
  watch: {
    currentPage: function (newValue, oldValue) {
      this.formFiltro.page = newValue
      this.filtrar();
    }
  },
  methods: {
    ...mapActions({
      cambiarFiltro: 'filtros/cambiarFiltro',
      cambiarFecha: 'filtros/cambiarFecha',
      signOutAction: 'auth/signOut'
    }),

    //LLAMADAS API
    async getDispatches() {
      this.toggleBusy();
      await axios.get('/api/admin/despachos').then(resp => {
        this.items = resp.data.data
        this.currentPage = resp.data.meta.current_page,
          this.totalRows = resp.data.meta.total,
          this.perPage = resp.data.meta.per_page,

          this.toggleBusy();
      })
        .catch(err => {
          this.toggleBusy();
        })
    },

    async deleteDispatch(id) {
      let variable = await this.$swal({
        title: "¿Deseas eliminar este despacho?",
        text: "¿Está seguro? ¡No podrás revertir esto!",
        type: "warning",
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Si, Borrar!',
        cancelButtonText: 'No, cancelar!',
        buttonsStyling: true
      })

      if (variable.isConfirmed == true) {
        await axios.delete(`/api/admin/despachos/${id}`).then(resp => {
          this.getDispatches();

        })
          .catch(err => {
            if (err.response.status == 400) {
              this.$swal({
                icon: 'error',
                title: '¡Error!',
                text: err.response.data.message,
              });
            }
          })
      }

    },
    async confirmarEliminar() {
      await axios.delete(`/api/admin/clients/${this.idUsuarioEliminar}`)
        .then(resp => {
          this.getClients();
        })
        .catch(err => {
          console.log(err)
        })
      this.cerrarModal();
    },
    //Cambiar estado de despacho manualmente
    async confirmarChangeState(item_id, estado) {
      this.items.forEach((key,index) => {
        if (key.id == item_id) {
          this.formDispatch.estado_despacho = estado
          this.items[index].estado_despacho = estado
        }
      })
      await axios.put('/api/admin/despachos/change-state/' + item_id, this.formDispatch).then(resp => {
        this.cerrarModal();
        this.getDispatches();

      }).catch((err) => {
        this.metodoCatch(err)
      })
      this.changeState = false;
    },

    //Cambiar estado de despacho en bd
    /* async updateEstadoDespacho(form) {
      form.forEach(despacho => {
        console.log(despacho);
        this.formDispatchBD.push({id:despacho.id, estado_despacho:despacho.estado_despacho})
      })
      await axios.put('/api/admin/despachos/change-state/', this.formDispatchBD).then(resp => {
        this.getDispatches();

      }).catch((err) => {
        this.metodoCatch(err)
      })
    }, */

    async dispatchFilterData() {
      await axios.get(`/api/admin/despachos/filter-data`)
        .then(resp => {
          this.filtros.listaAduanas = resp.data.aduanas
          this.filtros.listaPedidores = resp.data.pedidores
          this.filtros.listaAdministrativas = resp.data.administrativas
          this.filtros.listaAgentes_carga = resp.data.agentes_carga
          this.filtros.listaCaracteristicas_carga = resp.data.caracteristicas_carga
          this.filtros.listaEstados_despacho = resp.data.estados_despacho
          this.filtros.listaEstados_retiro = resp.data.estados_retiro
          this.filtros.listaGestores = resp.data.gestores
          this.filtros.listaJefes_operaciones = resp.data.jefes_operaciones
          this.filtros.listaNombres_transporte = resp.data.nombres_transporte
          this.filtros.listaNombre_cliente = resp.data.clientes
          this.filtros.listaNombre_nave = resp.data.nombre_nave
          this.filtros.listaTipos_carga = resp.data.tipos_carga
          this.filtros.listaTipos_condicion = resp.data.tipos_condicion
          this.filtros.listaTramitadores_aduaneros = resp.data.tramitadores_aduaneros
          this.filtros.listaRevisores = resp.data.revisores
          this.filtros.listaAforos = [{ id: 1, aforo: 'Si' }, { id: 0, aforo: 'No' }]
          this.filtros.listaDespachos = resp.data.despachos

          this.listaDespachos = [];
          this.filtros['listaDespachos'].forEach(despacho => {
                this.listaDespachos.push({id: despacho.codigo, text:despacho.codigo});
            })
        })
        .catch(err => {
          console.log(err)
        })
    },



    async submit() {

      await axios.put(`api/admin/clients/${this.formCliente.client_id}`, this.formCliente)
        .then(resp => this.metodoThen())
        .catch(err => console.log(err))
    },

    ver(item, index, button) {

      this.$router.replace({ name: 'PerfilDespacho', params: { despachoId: item.id } })
    },
    agregar(item, index, button) {

      this.$router.replace({ name: 'Crear' })
    },
    limpiar() {
      // console.log('entro')
      this.cambiarFiltro({ estado: false, texto: '-', value: '-', filtro: '-' })
      this.formFiltro.page = 1;
      this.formFiltro.filters = {}
      this.filtrar();


    },

    eliminar(item, index, button) {
      this.nombreUsuarioEliminar = item.short_name;
      this.idUsuarioEliminar = item.id;
      this.showEliminar = true;
    },
    async filtrar(form = null) {
      if (this.currentPage <= 1) {
        this.formFiltro.page = 1;
        this.formFiltro.filters = {}
        if (form) {
          console.log(form[0])
          /* if(this.value == form[0].value){
            this.formFiltro.filters = {}
          } */
          this.generarForm(form)
        }
        if (this.filtro.estado == true) {
          this.generarFormFiltrar()
        }
      }
      this.toggleBusy();
      await axios.post('/api/admin/despachos/filtered', this.formFiltro).then(resp => {
        this.items = resp.data.data
        if (this.filtro.estado == true){
          this.currentPage = 1
        } else{
          console.log(this.filtro)
          this.currentPage = resp.data.meta.current_page
        }
        
          this.totalRows = resp.data.meta.total,
          this.perPage = resp.data.meta.per_page,
          this.toggleBusy();
      })
        .catch(error => {

          // if (error.response.status == 401) {
          //       this.signOutAction()
          //       this.$router.replace({ name: 'SignIn' })
          // }

          this.toggleBusy();
        })

    },
    //Generar Forumario
    generarFormFiltrar() {
      if (this.fecha) this.formFiltro.filters['fecha'] = this.fecha;
      switch (this.filtro.filtro) {
        case 'despachos_pendientes':
          this.formFiltro.filters['estado_contenedores'] = 'PENDIENTE';
          break;
        case 'despachos_parcial':
          this.formFiltro.filters['estado_contenedores'] = 'PARCIAL';
          break;
        case 'despachos_total':
          this.formFiltro.filters['estado_contenedores'] = 'TOTAL';
          break;
        case 'aforo':
          this.formFiltro.filters['aforo'] = [1];
          break;
        case 'visto_bueno':
          this.formFiltro.filters['visto_bueno'] = [1];
          break;
        case 'documentos_faltantes':
          this.formFiltro.filters['documentos_faltantes'] = true;
          break;
        case 'documentos_completos':
          this.formFiltro.filters['documentos_completos'] = true;
          break;
        case 'aforo_realizados':
          this.formFiltro.filters['aforo_realizados'] = true;
          break;
        case 'aforo_pendientes':
          this.formFiltro.filters['aforo_pendientes'] = true;
          break;
        case 'visto_buenos-pendientes':
          this.formFiltro.filters['visto_buenos-pendientes'] = true;
          break;
        case 'visto_buenos-relizados':
          this.formFiltro.filters['visto_buenos-relizados'] = true;
          break;
        case 'vencimiento-eta':
          this.formFiltro.filters['vencimiento-eta'] = true;
          break;
        case 'eta-vencida':
          this.formFiltro.filters['eta-vencida'] = true;
          break;
      }

    },
    generarForm(form) {
      form.forEach(el => {
        console.log(el)
        switch (el.tipo) {
          case 'fecha':
            if (!this.formFiltro.filters.fecha_inicio) this.formFiltro.filters['fecha_inicio'] = [];
            this.formFiltro.filters.fecha_inicio.push([el.fecha[0], el.fecha[1]])
            break;
          case 'pedidor':
            if (!this.formFiltro.filters.users) this.formFiltro.filters['users'] = [];
            this.formFiltro.filters.users.push(el.value)
            break;
          case 'revisor':
            if (!this.formFiltro.filters.users) this.formFiltro.filters['users'] = [];
            this.formFiltro.filters.users.push(el.value)
            break;
          case 'aduana':
            if (!this.formFiltro.filters.aduana) this.formFiltro.filters['aduana'] = [];
            this.formFiltro.filters.aduana.push(el.value)
            break;
          case 'administrativas':
            if (!this.formFiltro.filters.users) this.formFiltro.filters['users'] = [];
            this.formFiltro.filters.users.push(el.value)
            break;
          case 'tipos_carga':
            if (!this.formFiltro.filters.tipo_carga) this.formFiltro.filters['tipo_carga'] = [];
            this.formFiltro.filters.tipo_carga.push(el.value)
            break;
          case 'tramitadores_aduaneros':
            if (!this.formFiltro.filters.estados_retiro) this.formFiltro.filters['estados_retiro'] = [];
            this.formFiltro.filters.estados_retiro.push(el.value)
            break;
          case 'estados_despacho':
            if (!this.formFiltro.filters.estados_despacho) this.formFiltro.filters['estados_despacho'] = [];
            this.formFiltro.filters.estados_despacho.push(el.value)
            break;
          case 'estados_retiro':
            if (!this.formFiltro.filters.estados_retiro) this.formFiltro.filters['estados_retiro'] = [];
            this.formFiltro.filters.estados_retiro.push(el.value)
            break;
          case 'gestores':
            if (!this.formFiltro.filters.users) this.formFiltro.filters['users'] = [];
            this.formFiltro.filters.users.push(el.value)
            break;
          case 'agentes_carga':
            if (!this.formFiltro.filters.agentes_carga_id) this.formFiltro.filters['agentes_carga_id'] = [];
            this.formFiltro.filters.agentes_carga_id.push(el.value)
            break;
          case 'caracteristicas_carga':
            if (!this.formFiltro.filters.caracteristicas_carga) this.formFiltro.filters['caracteristicas_carga'] = [];
            this.formFiltro.filters.caracteristicas_carga.push(el.value)
            break;
          case 'jefes_operaciones':
            if (!this.formFiltro.filters.users) this.formFiltro.filters['users'] = [];
            this.formFiltro.filters.users.push(el.value)
            break;
          case 'nombres_transporte':
            if (!this.formFiltro.filters.nombre_transporte) this.formFiltro.filters['nombre_transporte'] = [];
            this.formFiltro.filters.nombre_transporte.push(el.value)
            break;
          //TODO:filtro
          case 'nombre_nave':
            if (!this.formFiltro.filters.nombre_nave) this.formFiltro.filters['nombre_nave'] = [];
            this.formFiltro.filters.nombre_nave.push(el.value)
            break;
          case 'cliente_id':
            if (!this.formFiltro.filters.cliente_id) this.formFiltro.filters['cliente_id'] = [];
            this.formFiltro.filters.cliente_id.push(el.value)
            break;
          case 'codigo':
            if (!this.formFiltro.filters.codigo) this.formFiltro.filters['codigo'] = [];
            this.formFiltro.filters.codigo.push(el.value)
            this.value = el.value
            break;
          case 'aforo':
            if (!this.formFiltro.filters.aforo) this.formFiltro.filters['aforo'] = [];
            this.formFiltro.filters.aforo.push(el.value)
            break;
          case 'aforo_termino':
            if (!this.formFiltro.filters.aforo_termino) this.formFiltro.filters['aforo_termino'] = [];
            this.formFiltro.filters.aforo_termino.push(el.value)
            break;
          case 'tipos_condicion':
            if (!this.formFiltro.filters.tipo_condicion) this.formFiltro.filters['tipo_condicion'] = [];
            this.formFiltro.filters.tipo_condicion.push(el.value)
            break;
          case 'mainifiesto':
            if (!this.formFiltro.filters.tipo_condicion) this.formFiltro.filters['tipo_condicion'] = [];
            this.formFiltro.filters.tipo_condicion.push(el.value)
            break;
          case 'transporte':
            if (!this.formFiltro.filters.transporte) this.formFiltro.filters['transporte'] = [];
            this.formFiltro.filters.transporte.push(el.value)
            break;
          case 'ref_cliente':
            if (!this.formFiltro.filters.referencia_cliente) this.formFiltro.filters['referencia_cliente'] = [];
            this.formFiltro.filters.referencia_cliente.push(el.value)
            break;
          case 'numero_manifiesto':
            if (!this.formFiltro.filters.numero_manifiesto) this.formFiltro.filters['numero_manifiesto'] = [];
            this.formFiltro.filters.numero_manifiesto.push(el.value)
            break;
          case 'numero_documento_transporte':
            if (!this.formFiltro.filters.numero_documento_transporte) this.formFiltro.filters['numero_documento_transporte'] = [];
            this.formFiltro.filters.numero_documento_transporte.push(el.value)
            break;
        }
      })
    },
    //EXCEL
    async generarReporteImportaciones() {
      this.formFiltro.page = 1;
      await axios.post('/api/admin/reportes/despachos/filtros', this.formFiltro).then(resp => {
        window.open(process.env.VUE_APP_API_URL + `/api/admin/reportes/despachos/importaciones`);
        // console.log(resp)
      })
        .catch(err => {
          // this.toggleBusy();
        })
    },

    async generarReporteExportaciones() {
      this.formFiltro.page = 1;
      await axios.post('/api/admin/reportes/despachos/filtros', this.formFiltro).then(resp => {
        window.open(process.env.VUE_APP_API_URL + `/api/admin/reportes/despachos/exportaciones`);
        // console.log(resp)
      })
        .catch(err => {
          // this.toggleBusy();
        })
    },
    //GENERICOS API
    metodoCatch(error) {
      if (error.response) {
        Object.keys(error.response.data.errors).forEach((key) => {
          if (document.getElementById(`${key}`)) {
            document.getElementById(`${key}`).innerHTML = error.response.data.errors[key];
          }
        })
      }
    },
    metodoThen() {
      this.cerrarModal();
      this.getClients();

    },
    //GENERICOS
    cerrarModal() {
      this.showEditar = false;
      this.showEliminar = false;
      this.changeState = false;
    },
    toggleBusy() {
      this.isBusy = !this.isBusy
    },

  },
}
</script>

<style>

.table-index table thead th{
  border-top: 1px solid;
  border-bottom: 1px solid
}

.table-index table tbody td{
  border-top: 1px solid;
  border-bottom: 1px solid
}

.boton-editar {

  width: 70px;
  height: 30px;

  font-size: 12px !important;

  border: none !important;
  border-radius: 20px !important;
  background: #FFCE1E !important;

  opacity: 0.8;

  transition: opacity 0.5s !important;
}

.boton-editar:hover {
  border: none !important;
  opacity: 1 !important;
  background: #FFCE1E !important;
}



.boton-eliminar {
  width: 70px;
  height: 30px;

  font-size: 12px !important;

  border: 1px solid #E35F42 !important;
  border-radius: 20px !important;
  background: transparent !important;

  color: #E35F42 !important;
  transition: background-color 0.5s !important;
}

.boton-eliminar:hover {
  border: 1px solid #E35F42 !important;
  background: red21 !important;
}
</style>