<template>
<nav class="sidebar">
    <div class="sidebar-header">
        <burger v-if="pantalla == true"></burger>
        <inline-svg src="../../assets/images/logo_aduanas.svg" title="My Image"/>
    </div>
    <div class="sidebar-content">
        <ul>
            <Opcion v-bind:key="opcion.id" v-for="opcion in opciones" v-permisos='opcion.permisos' :opciones="opcion"></Opcion>
            <li class="li-item" >
                <a @click="descargarApk()">
                    <fas :icon="['fas', 'download']"></fas>
                    Descargar APK
                </a>
            </li>
        </ul>
    </div>
    <div class="sidebar-footer">
        <img src="@/assets/images/sidebar_footer.svg" width="100%" alt="">
    </div>
</nav>
</template>

<script>

import Opcion from '../ui/Opcion.vue'
import { mapGetters, mapActions } from 'vuex'
import Burger from '../../views/Menu/Burger.vue'
import axios from 'axios'
export default {
    name: 'Sidebar',
    components:{
        Opcion,
        Burger
    },
    props:{
        pantalla:Boolean,
    },
    data(){
        return {
            opciones: [
                {id:1 ,icon: 'chart-pie',ruta:"Dashboard",path:'dashboard',activo:false, title: "Dashboard", opciones: [], permisos: 'dashboard.view'},
                {id:2 ,icon: 'box',ruta:"Despachos",path:'despachos',activo:true, title: "Despachos", opciones: [], permisos: 'dispatch.view'},
                {id:3 ,icon: 'user',ruta:"Users",path:'usuarios',activo:false, title: "Usuarios", opciones: [],permisos: 'users.view'},
                {id:4 ,icon: 'users',ruta:"Grupos",path:'grupos',activo:false, title: "Grupo de usuarios", opciones: [],permisos: 'clients.manage'},
                {id:5 ,icon: 'user-tie',ruta:"Clients",path:'clientes',activo:false, title: "Clientes", opciones: [],permisos: 'clients.manage'},
                {id:6 ,icon: 'file-alt',ruta:"Agentes",path:'agentes-carga',activo:false, title: "Agentes de Carga", opciones: [],permisos: 'agentes.manage'},
                {id:7 ,icon: 'file-alt',ruta:"CompaniasNavieras",path:'companias-navieras',activo:false, title: "Compañias Navieras", opciones: [],permisos: 'companias.manage'},
                {id:8 ,icon: 'flag',ruta:"Aduanas",path:'aduanas',activo:false, title: "Aduanas", opciones: [],permisos: 'aduanas.manage'},
                {id:9 ,icon: 'star',ruta:"Extraportuarios",path:'extraportuarios',activo:false, title: "Extraportuarios", opciones: [],permisos: 'extraportuarios.manage'},
                {id:10,icon: 'truck',ruta:"Proveedores",path:'proveedores',activo:false, title: "Proveedores", opciones: [],permisos: 'providers.manage'},
            ],

        }
    },
    watch:{
        $route:function(){
            let path = this.$route.path.split('/')
            this.opciones.forEach(el => {
                if(el.path == path[1]) el.activo = true
                else el.activo = false
            })
        }
    },
    mounted(){
        let path = this.$route.path.split('/')
        this.opciones.forEach(el => {
            if(el.path == path[1]) el.activo = true
            else el.activo = false
        })
    },

    methods: {
        ...mapActions({
        toggleNav: 'nav/toggleNav'
      }),

      async descargarApk(){
          window.open(process.env.VUE_APP_API_URL +`/api/admin/apk-mobile`);
      }
    },
    computed: {
      
        ...mapGetters({
            isNavOpen: 'nav/isNavOpen',
        }),
        isPanelOpen() {
            return this.isNavOpen
        }
    },

    
}
</script>

<style >
    .sidebar {
        background: #FFFFFF;
        height: 100vh;
        position: relative;
    }

    .sidebar-header{
        padding: 33px;
    }
    .sidebar-content::-webkit-scrollbar-track{
        background: transparent;

    }
    .sidebar-content::-webkit-scrollbar-thumb{
        border-radius: 10px;
        background: rgba(70, 70, 70, 0.57);
    }
    .sidebar-content::-webkit-scrollbar{
        width: 4px !important;
    }
    .sidebar-content{
      padding: 5px 10px;
      overflow: auto;
      max-height: calc(100% - 137.55px - 66.06px);
    }
    .sidebar-content ul{
        list-style: none;
        padding: 0;
        text-align: left;
    }
    .li-item{
        border-bottom: 1px solid rgba(70, 70, 70, 0.07);
    }
    .li-item a:hover{
        color: #043161;
        background: rgba(222, 243, 255, 0.671);
    }
    .li-item a{
        color: #043161;
        display: block;
        padding: 17px 4px;
        text-decoration:none !important;
        outline: none;
        font-weight: 500;
        font-size: 12px !important;
    }
    .li-item:nth-child(5n + 1) svg{
        color: #5699F4 !important;
    }
    .li-item:nth-child(5n + 2) svg{
        color: #02BE92 !important;
    }
    .li-item:nth-child(5n + 3) svg{
        color: #FFC72F !important;
    }
    .li-item:nth-child(5n + 4) svg{
        color: #E65B38 !important;
    }
    .li-item:nth-child(5n + 5) svg{
        color: #B45FE0 !important;
    }

    .li-item svg{
        font-size: 1rem;
        margin-right: 8px;
        color: #000;
        margin-left: 2px;
    }

    .seccion-activa{
        background: rgba(222, 243, 255, 0.671);
    }
    .seccion-activa a{
        font-weight: bold !important;
    }

    .sidebar-footer{
        bottom: 0;
        position: absolute;
        width: 100%;
        padding: 15px 30px;
    }
    /*
    .sidebar .seccion-logo{
        
        margin-bottom: 30px ;
        padding-top: 30px;
        padding-bottom: 30px;
    }

    .sidebar .seccion-logo h1{
        font-weight: 600;
    }

    .sidebar .seccion-opciones{
        padding-right: 0;
    }

    .sidebar .seccion-opciones ul {
        padding: 0;
        text-align: left;
        list-style: none; 
    }
    .sidebar .seccion-opciones ul li {
        border-top-left-radius: 20px;
        border-bottom-left-radius: 20px;

        color: #043161;

    }

    .seccion-activa{
        border-top-left-radius: 20px !important;
        border-bottom-left-radius: 20px !important;
        background-color: #5699F4 !important;

        color: white !important;
    }

    .sidebar .seccion-opciones ul li:hover {
        border-top-left-radius: 20px;
        border-bottom-left-radius: 20px;
        background-color: #5699F4;

        color: white;



    } */

</style>