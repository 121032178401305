<template>
    <sidebar :pantalla="pantalla"></sidebar>
</template>

<script>
import SidebarResp from './SidebarResp.vue';
import Sidebar from '../../components/layout/Sidebar.vue';
export default {
    name: "Navigation",
    components: {
        
        SidebarResp,
       
        Sidebar
    },
    props:{
        pantalla:Boolean
    },
 
    methods: {
        navHome() {
            this.$router.push({ name: 'Home' });
        },
    }
}
</script>

<style>
.main-nav {
    /* display: flex;
    justify-content: space-between;
    padding: 0.5rem 0.8rem; */
}
ul.sidebar-panel-nav {
    list-style-type: none;
}
ul.sidebar-panel-nav > li > a {
    color: #fff;
    text-decoration: none;
    font-size: 1.5rem;
    display: block;
    padding-bottom: 0.5em;
}
</style>