import Vue from 'vue'
import store from '../store/index';

/**
 * Deshabilita u oculta elementos del dom
 * @param {*} el 
 * @param {*} vnode 
 * @param {*} option 
 */
function disableAction(el, vnode, option) {
    
    switch(option){

        case 'hide':
            const comment = document.createComment(' ');
            Object.defineProperty(comment, 'setAttribute', {
            value: () => undefined,
            });
            vnode.elm = comment;
            vnode.text = ' ';
            vnode.isComment = true;
            vnode.context = undefined;
            vnode.tag = undefined;
            vnode.data.directives = undefined;
    
            if (vnode.componentInstance) {
                vnode.componentInstance.$el = comment;
            }
    
            if (el.parentNode) {
                el.parentNode.replaceChild(comment, el);
            }

            break;

        case 'disable':
            el.disabled = true;
            break;

        default:
            break
    }
}

/**
 * Directiva de roles
 */
Vue.directive('rol',{
    bind: function(el,binding,vnode) {
        const user = store.getters && store.getters['auth/user'];
        let opcion = '';
        if (user.role !== binding.value) opcion = 'disable';

        // Se realiza la accion
        disableAction(el,vnode,opcion);
    }
})

/**
 * Directiva de permisos
 */
Vue.directive('permisos', {
    bind: function(el,binding,vnode) {
        const permission = store.getters['auth/permission'] ? store.getters['auth/permission'].permissions : []
        
        let opcion = '';
        if(binding.value){
            let existe = false;
            permission.forEach(element => {
                if(element == binding.value) existe =true;
            });
            if(!existe) opcion = 'hide';
        }

        // Se realiza la accion
        disableAction(el,vnode,opcion);
    }
})