<template>
<b-row>
    <b-col cols="12 informacion">

        <!--REFERENCIA CLIENTE --> 
        <b-row v-if="despacho.ref_cliente && estados.ref_cliente">
            <b-col cols="4" class="text-left dato">
                <p>Referencia Cliente</p>
            </b-col>
            <b-col 
                cols="4" class="text-right dato despacho" 
            >
                <p v-if="despacho.ref_cliente">{{despacho.ref_cliente}}</p> 
                <p v-else>-</p> 
                
            </b-col>
            <b-col
                cols="4" class="text-right dato despacho" 
            >
                <b-button
                v-if="pantalla == true"
                    class="btn-principal btn-small"
                    @click="editar('pedidor_exportacion','ref_cliente')"
                >
                    Editar <inline-svg  class="icono-titulo" src="../../assets/images/Editar.svg" title="My Image"/>
                </b-button>
                <b-button
                v-if="pantalla == false"
                    @click="editar('pedidor_exportacion','ref_cliente')"
                    class="btn-principal-chico"
                >
                    <inline-svg  class="icono-titulo" src="../../assets/images/EditarAzul.svg" title="My Image"/>
                </b-button>
            </b-col>
            
        </b-row>
        <b-row v-else>
            <b-col 
                cols="12" class="text-right dato despacho" 
            >
                <form class="form-input">
                    <b-row>
                        <b-col sm="12" md="12" lg="6">
                            <b-form-group
                                class="text-left"
                                label="Referencia Cliente"
                                label-for="refCliente-select"
                            >
                                <b-input v-model="ref_cliente" :placeholder="despacho.ref_cliente" required>

                                </b-input>
                            </b-form-group>
                        </b-col>
                        <b-col sm="12" md="12" lg="6">
                            <b-button
                                class="btn-extra-primario btn-small"
                                type="button"
                                @click="editar('pedidor_exportacion','ref_cliente',true,{'ref_cliente': ref_cliente})"
                            >
                                Guardar
                            </b-button>
                            <b-button
                                class="btn-extra-secundario btn-small"
                                type="button"
                                @click="editar('pedidor_exportacion','ref_cliente')"
                            >
                                Cancelar
                            </b-button>
                        </b-col>
                    </b-row>
                </form>
            </b-col>
        </b-row>
        <!--ESTADO PRESENTACION MATRIZ --> 
        <b-row v-if="despacho.tipo.estado_presentacion_matriz && estados.estado_presentacion_matriz">
            <b-col cols="4" class="text-left dato">
                <p>Estado Presentación Matriz</p>
            </b-col>
            <b-col cols="4" class="text-right dato despacho" >
                <p v-if="despacho.tipo.estado_presentacion_matriz">{{despacho.tipo.estado_presentacion_matriz}}</p> 
                <p v-else>-</p> 
            </b-col>
            <b-col cols="4" class="text-right dato despacho" >
                <b-button
                v-if="pantalla == true"
                    @click="editar('pedidor_exportacion','estado_presentacion_matriz')"
                    class="btn-principal btn-small"
                >
                    Editar <inline-svg  class="icono-titulo" src="../../assets/images/Editar.svg" title="My Image"/>
                </b-button>
                <b-button
                v-if="pantalla == false"
                    @click="editar('pedidor_exportacion','estado_presentacion_matriz')"
                    class="btn-principal-chico"
                >
                    <inline-svg  class="icono-titulo" src="../../assets/images/EditarAzul.svg" title="My Image"/>
                </b-button>
            </b-col>
        </b-row>
        <b-row v-else>
            <b-col 
                cols="12" class="text-right dato despacho" 
            >
                <form class="form-input">
                    <b-row>
                        <b-col sm="12" md="12" lg="8">
                            <b-form-group
                                class="text-left"
                                label="Estado Presentación Matriz"
                                label-for="importancia-select"
                            >
                                <select class="form-control border border-secondary"  id="importancia-select" v-model="estado_presentacion_matriz"  required>
                                    <option value="0" disabled>Selecciona el Estado Presentación Matriz</option>
                                    <option :key="index" v-for="(estadoPresentacion,index) in informacionGeneral.estado_presentacion_matriz" :value="estadoPresentacion" >{{estadoPresentacion}}</option>
                                    
                                </select>
                            </b-form-group>
                        </b-col>
                        <b-col sm="12" md="12" lg="4">
                            <b-button
                                class="btn-extra-primario btn-small"
                                type="button"
                                @click="editar('pedidor_exportacion','estado_presentacion_matriz', true,{'estado_presentacion_matriz': estado_presentacion_matriz })"
                            >
                                Guardar
                            </b-button>
                            <b-button
                                class="btn-extra-secundario btn-small"
                                type="button"
                                @click="editar('pedidor_exportacion','estado_presentacion_matriz')"
                            >
                                Cancelar
                            </b-button>
                        </b-col>
                    </b-row>
                </form>
            </b-col>
        </b-row>
        <!--ESTADO PRESENTACION MATRIZ --> 
        <b-row v-if="despacho.tipo.dus_primer_envio && estados.dus_primer_envio">
            <b-col cols="4" class="text-left dato">
                <p>Dus 1er Envio</p>
            </b-col>
            <b-col cols="4" class="text-right dato despacho">
                <p v-if="despacho.tipo.dus_primer_envio">{{despacho.tipo.dus_primer_envio}}</p> 
                <p v-else>-</p> 
            </b-col>
            <b-col cols="4" class="text-right dato despacho">
                <b-button
                v-if="pantalla == true"
                    @click="editar('pedidor_exportacion','dus_primer_envio')"
                    class="btn-principal btn-small"
                >
                    Editar <inline-svg  class="icono-titulo" src="../../assets/images/Editar.svg" title="My Image"/>
                </b-button>
                <b-button
                v-if="pantalla == false"
                    @click="editar('pedidor_exportacion','dus_primer_envio')"
                    class="btn-principal-chico"
                >
                    <inline-svg  class="icono-titulo" src="../../assets/images/EditarAzul.svg" title="My Image"/>
                </b-button>
            </b-col>
        </b-row>
        <b-row v-else>
            <b-col 
                cols="12" class="text-right dato despacho" 
            >
                <form class="form-input">
                    <b-row>
                        <b-col sm="12" md="12" lg="8">
                            <b-form-group
                                class="text-left"
                                label="Dus 1er Envio"
                                label-for="importancia-select"
                            >
                                <select class="form-control border border-secondary"  id="importancia-select" v-model="dus_primer_envio"  required>
                                    <option value="0" disabled>Selecciona el Estado Dus 1er Envio</option>
                                    <option :key="index" v-for="(estadoDus1erEnvio,index) in informacionGeneral.dus_primer_envio" :value="estadoDus1erEnvio" >{{estadoDus1erEnvio}}</option>
                                    
                                </select>
                            </b-form-group>
                        </b-col>
                        <b-col sm="12" md="12" lg="4">
                            <b-button
                                class="btn-extra-primario btn-small"
                                type="button"
                                @click="editar('pedidor_exportacion','dus_primer_envio', true,{'dus_primer_envio': dus_primer_envio })"
                            >
                                Guardar
                            </b-button>
                            <b-button
                                class="btn-extra-secundario btn-small"
                                type="button"
                                @click="editar('pedidor_exportacion','dus_primer_envio')"
                            >
                                Cancelar
                            </b-button>
                        </b-col>
                    </b-row>
                </form>
            </b-col>
        </b-row>
        <!--ESTADO PRESENTACION MATRIZ --> 
        <b-row v-if="despacho.tipo.dus_legalizado && estados.dus_legalizado">
            <b-col cols="4" class="text-left dato">
                <p>Dus Legalizado</p>
            </b-col>
            <b-col cols="4" class="text-right dato despacho">
                <p v-if="despacho.tipo.dus_legalizado">{{despacho.tipo.dus_legalizado}}</p> 
                <p v-else>-</p> 
            </b-col>
            <b-col cols="4" class="text-right dato despacho">
                <b-button
                v-if="pantalla == true"
                    @click="editar('pedidor_exportacion','dus_legalizado')"
                    class="btn-principal btn-small"
                >
                    Editar <inline-svg  class="icono-titulo" src="../../assets/images/Editar.svg" title="My Image"/>
                </b-button>
                <b-button
                v-if="pantalla == false"
                    @click="editar('pedidor_exportacion','dus_legalizado')"
                    class="btn-principal-chico"
                >
                    <inline-svg  class="icono-titulo" src="../../assets/images/EditarAzul.svg" title="My Image"/>
                </b-button>
            </b-col>
        </b-row>
        <b-row v-else>
            <b-col 
                cols="12" class="text-right dato despacho" 
            >
                <form class="form-input">
                    <b-row>
                        <b-col sm="12" md="12" lg="8">
                            <b-form-group
                                class="text-left"
                                label="Dus Legalizado"
                                label-for="importancia-select"
                            >
                                <select class="form-control border border-secondary"  id="importancia-select" v-model="dus_legalizado"  required>
                                    <option value="0" disabled>Selecciona el Estado Dus Legalizado</option>
                                    <option :key="index" v-for="(estadoDusLegalizado,index) in informacionGeneral.dus_legalizado" :value="estadoDusLegalizado" >{{estadoDusLegalizado}}</option>
                                    
                                </select>
                            </b-form-group>
                        </b-col>
                        <b-col sm="12" md="12" lg="4">
                            <b-button
                                class="btn-extra-primario btn-small"
                                type="button"
                                @click="editar('pedidor_exportacion','dus_legalizado', true,{'dus_legalizado': dus_legalizado })"
                            >
                                Guardar
                            </b-button>
                            <b-button
                                class="btn-extra-secundario btn-small"
                                type="button"
                                @click="editar('pedidor_exportacion','dus_legalizado')"
                            >
                                Cancelar
                            </b-button>
                        </b-col>
                    </b-row>
                </form>
            </b-col>
        </b-row>    
        <b-row v-if="estados.prorroga">
            <b-col cols="4" class="text-left dato">
                <p>Requiere Prorroga</p>
            </b-col>
            <b-col cols="4" class="text-right dato despacho">
                <p v-if="despacho.tipo.prorroga == 1">Si</p> 
                <p v-else>No</p> 
            </b-col>
            <b-col cols="4" class="text-right dato despacho">
                <b-button
                v-if="pantalla == true"
                    @click="editar('pedidor_exportacion','prorroga')"
                    class="btn-principal btn-small"
                >
                    Editar <inline-svg  class="icono-titulo" src="../../assets/images/Editar.svg" title="My Image"/>
                </b-button>
                <b-button
                v-if="pantalla == false"
                    @click="editar('pedidor_exportacion','prorroga')"
                    class="btn-principal-chico"
                >
                    <inline-svg  class="icono-titulo" src="../../assets/images/EditarAzul.svg" title="My Image"/>
                </b-button>
            </b-col>
        </b-row>
        <b-row v-else>
            <b-col 
                cols="12" class="text-right dato despacho" 
            >
                <form class="form-input">
                    <b-row>
                        <b-col sm="12" md="12" lg="8" class="text-left">
                            <b-form-group
                                class="text-left"
                                label="Prorroga "
                                label-for="importancia-select"
                            >
                                <select class="form-control border border-secondary"  id="importancia-select" v-model="prorroga"  required>
                                    <option value="0" disabled>Seleccione una opción</option>
                                    <option :value="false" >No</option>
                                    <option :value="true" >Si</option>
                                    
                                </select>
                            </b-form-group>
                        </b-col>
                        <b-col sm="12" md="12" lg="4">
                            <b-button
                                class="btn-extra-primario btn-small"
                                type="button"
                                @click="editar('pedidor_exportacion','prorroga', true,{'prorroga': prorroga })"
                            >
                                Guardar
                            </b-button>
                            <b-button
                                class="btn-extra-secundario btn-small"
                                type="button"
                                @click="editar('pedidor_exportacion','prorroga')"
                            >
                                Cancelar
                            </b-button>
                        </b-col>
                    </b-row>
                </form>
            </b-col>
        </b-row>
        <b-row v-if="despacho.tipo.aduana && estados.aduana">
            <b-col cols="4" class="text-left dato">
                <p>Aduana</p>
            </b-col>
            <b-col cols="4" class="text-right dato despacho">
                <p>{{despacho.tipo.aduana.nombre}}</p> 
            </b-col>
            <b-col cols="4" class="text-right dato despacho">
                <b-button
                v-if="pantalla == true"
                    @click="editar('pedidor_exportacion','aduana')"
                    class="btn-principal btn-small"
                >
                    Editar <inline-svg  class="icono-titulo" src="../../assets/images/Editar.svg" title="My Image"/>
                </b-button>
                <b-button
                v-if="pantalla == false"
                    @click="editar('pedidor_exportacion','aduana')"
                    class="btn-principal-chico"
                >
                    <inline-svg  class="icono-titulo" src="../../assets/images/EditarAzul.svg" title="My Image"/>
                </b-button>
            </b-col>
        </b-row>
        <b-row v-else>
             <b-col 
                cols="12" class="text-right dato despacho" 
            >
                <form class="form-input">
                    <b-row>
                        <b-col sm="12" md="12" lg="6">
                            <b-form-group
                                class="text-left"
                                label="Aduana"
                                label-for="importancia-select"
                            >
                                <select class="form-control border border-secondary"  id="importancia-select" v-model="aduana_id"  required>
                                    <option value="0" disabled>Selecciona una Aduana</option>
                                    <option :key="index" v-for="(aduana,index) in informacionGeneral.aduana" :value="aduana.id" >{{aduana.nombre}}</option>
                                    
                                </select>
                            </b-form-group>
                        </b-col>
                        <b-col sm="12" md="12" lg="6">
                            <b-button
                                class="btn-extra-primario btn-small"
                                type="button"
                                @click="editar('pedidor_exportacion','aduana', true,{'aduanas_id': aduana_id })"
                            >
                                Guardar
                            </b-button>
                            <b-button
                                class="btn-extra-secundario btn-small"
                                type="button"
                                @click="editar('pedidor_exportacion','aduana')"
                            >
                                Cancelar
                            </b-button>
                        </b-col>
                    </b-row>
                </form>
            </b-col>
        </b-row>
        <b-row v-if="despacho.tipo.nombre_transporte && estados.nombre_transporte">
            <b-col cols="4" class="text-left dato">
                <p>Medio de Transporte</p>
            </b-col>
            <b-col cols="4" class="text-right dato despacho" >
                <p v-if="despacho.tipo.nombre_transporte">{{despacho.tipo.nombre_transporte}} </p> 
                <p v-else>-</p> 
            </b-col>
            <b-col cols="4" class="text-right dato despacho" >
                <b-button
                v-if="pantalla == true"
                    @click="editar('pedidor_exportacion','nombre_transporte')"
                    class="btn-principal btn-small"
                >
                    Editar <inline-svg  class="icono-titulo" src="../../assets/images/Editar.svg" title="My Image"/>
                </b-button>
                <b-button
                v-if="pantalla == false"
                    @click="editar('pedidor_exportacion','nombre_transporte')"
                    class="btn-principal-chico"
                >
                    <inline-svg  class="icono-titulo" src="../../assets/images/EditarAzul.svg" title="My Image"/>
                </b-button>
            </b-col>
            <b-col v-if="despacho.tipo.nombre_nave !== null" cols="4" class="text-left dato">
                <p>Nombre de Nave</p>
            </b-col>
            <b-col v-if="despacho.tipo.nombre_nave !== null" cols="4" class="text-right dato despacho" >
                <p>{{despacho.tipo.nombre_nave}}</p>
            </b-col>
        </b-row>
        <b-row v-else>
            <b-col 
                cols="12" class="text-right dato despacho" 
            >
                <form class="form-input">
                    <b-row>
                        <b-col sm="12" md="12" lg="6">
                            <b-form-group
                                class="text-left"
                                label="Medio de Transporte"
                                label-for="importancia-select"
                            >
                                <select class="form-control border border-secondary"  id="importancia-select" v-model="nombre_transporte"  required>
                                    <option value="0" disabled>Selecciona el Medio de Transporte</option>
                                    <option :key="index" v-for="(nombreTrasporte,index) in informacionGeneral.nombre_transporte" :value="nombreTrasporte" >{{nombreTrasporte}}</option>
                                    
                                </select>
                            </b-form-group>
                        </b-col>
                        <b-col sm="12" md="12" lg="6">
                            <b-button
                                class="btn-extra-primario btn-small"
                                type="button"
                                @click="editar('pedidor_exportacion','nombre_transporte', true,{'nombre_transporte': nombre_transporte})"
                            >
                                Guardar
                            </b-button>
                            <b-button
                                class="btn-extra-secundario btn-small"
                                type="button"
                                @click="editar('pedidor_exportacion','nombre_transporte')"
                            >
                                Cancelar
                            </b-button>
                        </b-col>
                    </b-row>
                </form>
            </b-col>
        </b-row>
    </b-col>   

    
    
    
    

</b-row>
</template>

<script>
import InputRut from '../InputRut.vue';
import InputRegion from '../InputRegion.vue';
export default {
    components: { InputRut, InputRegion },
    name:"FormUsuario",

    props: {
        despacho:Object,
        postInformacionGeneral:Function,
        informacionGeneral:Object,
        editar:Function,
        estados:Object

    },
    data(){
        return{
            //importacion
            nombre: '',
            nombre_transporte: '',
            numero_manifiesto: '',
            cliente_id: 0,
            fecha_eta: '',
            tipo_carga: '',
            caracteristicas_carga: '',
            transporte: '',
            correo_transporte: '',

            //exportacion
            estado_presentacion_matriz: '',
            dus_primer_envio:'',
            dus_legalizado:'',
            aduana_id: '',
            prorroga: false,


            options:[  { text: 'Prorroga', value: true },  ],

            pantalla: false
        }
    },
    mounted(){
        if (screen.width <= 660) this.pantalla = false
        else this.pantalla = true
    },
    methods:{
      cambiarRut(value){
        this.cliente_rut = value;
      },
      cambiarRutTransporte(value){
        this.transporte = value;
      },
    }
}
</script>