<template>

    <b-modal  v-model="show" :id="id" centered title="BootstrapVue" @hidden="cerrar()">
        <p class="my-4">Vertically centered modal!</p>
        <b-button class="mt-3" variant="outline-danger" block @click="cerrar()">Cerrar</b-button>
    </b-modal>
</template>

<script>
export default {
    name:'Modal',
    props:{
        id: String,
        show: Boolean,
        cerrarModal: Function
    },
    methods:{
        cerrar(){
            this.cerrarModal();
        }
    }

    

}
</script>

<style>

</style>