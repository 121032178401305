<template>

    
    <b-tabs content-class="" class="tabs-secciones" p>
            
            <b-tab title="Documentación" @click="cambiarSeccion('documentos')">
                <template #title>
                    <div class="title-tab">

                        <inline-svg  class="icono-titulo" src="../../assets/images/documento.svg" title="My Image"/>
                        <p class=" titulo text-center">Documentación</p>
                    </div>
                </template>
                <documentos
                        v-if="todosDocumentos.tipos_documentos && secciones.documentos"
                        :agregarDocumento="true"
                        :postDocumento="postDocumento"
                        :updateDocumento="updateDocumento"
                        :documentosTabla="documentosTabla"
                        :documentos="todosDocumentos"
                        :eliminarDocumento="eliminarDocumento"

                        :eliminarDocumentos ="eliminarDocumentos"
                        :showDeleteDocumentos = "showDeleteDocumentos"
                        :actualizarDocumentos= "actualizarDocumentos"
                        :confirmarDeleteDocumentos= "confirmarDeleteDocumentos"
                        :cerrarModal="cerrarModal"
                        :metodoThen= "metodoThen"
                        :metodoCatch="metodoCatch"
                    >

                    </documentos>

            </b-tab>
            <b-tab title="Pagos" @click="cambiarSeccion('pagos')">
                <template #title>
                    <div class="title-tab">
                        <inline-svg  class="icono-titulo" src="../../assets/images/dollar.svg" title="My Image"/>
                        <p class="titulo text-center" > Pagos</p>
                    </div>
                </template>
                <pagos
                        v-if=" secciones.pagos"
                        :despacho="despacho"
                        :agregarPago="true"
                        :postPago="postPago"
                        :updatePago="updatePago"
                        :pagosTabla="pagosTabla"
                        :pagos="todosPagos"
                        :getComprobante="getComprobante"
                        
                        :eliminarPago="eliminarPago"

                    >

                    </pagos>

            </b-tab>
    </b-tabs>

  
</template>

<script>
import axios from 'axios'
import FormAsignarUsuario from '../../../formulario/FormAsignarUsuario.vue'

import { mapActions } from 'vuex';
import Documentos from '../../Componentes/Documentos.vue';
import Pagos from '../../Componentes/Pagos.vue';
export default {
  components: { FormAsignarUsuario, Documentos, Pagos },
    
    props:{
        despacho:Object,

        submit: Function,

        //DOCUMENTOS
        documentos: Object,
        documentosTabla:Array,
        postDocumento: Function,
        updateDocumento: Function,

        showDeleteDocumentos: Boolean,
        eliminarDocumentos: Function,
        actualizarDocumentos: Function,
        confirmarDeleteDocumentos: Function,
        cerrarModal: Function,
        metodoThen: Function,
        metodoCatch: Function,


        //PAGOS
        pagos: Object,
        pagosTabla:Array,
        postPago: Function,
        updatePago: Function,

        eliminarDocumento: Function,
        eliminarPago: Function,
        getComprobante: Function,

    },
    data(){
        return{
            documentosPorRol: [],
            pagosPorRol: [],

            todosDocumentos: {},
            todosPagos: {},
            
            //secciones
            secciones: 
            {
                general: true,
                carga: false,
                pagos: false,
                usuarios: false,
                documentos: true,
            },
        }
    },
    mounted(){
        this.documentosPorRol =[]
        this.documentos.tipos_documentos.forEach(el =>{
            this.documentosPorRol.push(el)
        });
        this.todosDocumentos = {
            tipos_documentos: this.documentosPorRol,
            bl : this.documentos.bl,
            documento_transporte: this.documentos.documento_transporte,
            tipos_vistos_buenos: this.documentos.tipos_vistos_buenos
        }

        // this.pagosPorRol = []
        // this.pagos.tipos_pagos.forEach(el =>{
        //     if(el.id == 4)this.pagosPorRol.push(el)
        // });
        // this.todosPagos= {
        //     tipos_pagos: this.pagosPorRol,
        //     tipos_documentos_contable : this.pagos.tipo_documento_contable,
        // }
    },
    methods:{
        //secciones
        cambiarSeccion(seccion){
        Object.keys(this.secciones).forEach(key => {

            if(key == seccion)this.secciones[key] = true;
            else this.secciones[key] = false
        });
        }
    }
}
</script>

<style>

</style>