import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";

import moment from 'moment'


Vue.prototype.moment = moment

// Importamos JQuery
const $ = require('jquery')
// Lo declaramos globalmente
window.$ = $

// css bootstrap//
import 'bootstrap';
import { BootstrapVue, IconsPlugin } from 'bootstrap-vue'
import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'

//Select2
import Select2 from 'v-select2-component';
Vue.component('Select2', Select2);

//Sweetalert2
import VueSweetalert2 from 'vue-sweetalert2';
import 'sweetalert2/dist/sweetalert2.min.css';

//Directivas
import "./directivas/directivas"

// Font awesome
import { library } from '@fortawesome/fontawesome-svg-core'
import { fas } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
library.add(fas)

Vue.component('fas', FontAwesomeIcon)


import mainCss from '../src/assets/css/main.css'
import {InlineSvgPlugin} from 'vue-inline-svg';

//APEX
import VueApexCharts from 'vue-apexcharts'
Vue.use(VueApexCharts)

Vue.component('apexchart', VueApexCharts)

//tooltip
import VTooltip from 'v-tooltip'

Vue.use(VTooltip)

import interceptor from '@/store/interceptor'
interceptor()

const options = {
  confirmButtonColor: 'red',
  cancelButtonColor: '#ff7674',
};
Vue.use(VueSweetalert2, options);



Vue.use(InlineSvgPlugin);
Vue.use(BootstrapVue) // se usa boostrap en vue
Vue.use(IconsPlugin) // iconos bootsrtap
Vue.use(mainCss) // se importa css global

Vue.config.productionTip = false;


store.dispatch("getUser");

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");
