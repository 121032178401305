<template>
<b-row>
    <b-col cols="12">
        <b-form-group
        class="text-left"
        label="Nombre" 
        label-for="nombre-input" 
    >
        <b-form-input id="nombre-input" v-model="form.nombre" min="3" max="190"  required>    
        </b-form-input>
        <div class="text-left">
        <span id="nombre"></span>     
        </div>
    </b-form-group>
    </b-col>
    <b-col cols="12">
        <button
            class="btn-principal btn-large m-2"
            type="submit"
        >Guardar</button>
        <slot></slot>
    </b-col>
    
    
    
    
    

</b-row>
</template>

<script>
import InputRut from './InputRut.vue';
import InputRegion from './InputRegion.vue';
export default {
    components: { InputRut, InputRegion },
    name:"FormUsuario",

    props: {
        form: Object,
        user: Object,
        roles: Array,
        rutDisponible: Boolean,
        password: Boolean,

    },
    data(){
        return{
            cols: 10,
        }
    },
    methods:{
      cambiarRut(value){
        this.form.rut = value;
      },
      //
    }
}
</script>

<style>

</style>