<template>
  <div>
      <div class="d-flex justify-content-end">
        <b-button v-if="canManage()" type="button" @click="visible = !visible" class="boton-card btn-principal btn-small m-3">Agregar</b-button>
      </div>
        <b-collapse v-model="visible" id="agregar-comentario">
            <b-card title="Agregar Comentario" class="shadow-card px-5 py-2">
                <b-form @submit.prevent="agregarComentario()" class="justify-content-center">
                    <div class="row">
                        <div class="col-lg-6 px-lg-2">
                            <b-form-group>
                                <b-form-input
                                id="asunto"
                                autocomplete="off"
                                v-model="formData.add.asunto"
                                class="mb-2 mr-sm-2 mb-sm-0"
                                :state="validateAsuntoAgregar"
                                placeholder="Asunto"
                                @click="errors.add.asunto = []"
                                ></b-form-input>
                                <b-form-invalid-feedback :state="validateAsuntoAgregar">
                                    <div v-for="(err, index) in errors.add.asunto" :key="index">
                                        {{err}}
                                    </div>
                                </b-form-invalid-feedback>
                            </b-form-group>
                        </div>
                        <div class="col-lg-6 px-lg-2">
                            <b-form-textarea
                            id="contenido"
                            autocomplete="off"
                            v-model="formData.add.contenido"
                            placeholder="Contenido del comentario..."
                            @click="errors.add.contenido = []"
                            :state="validateContenidoAgregar"
                            rows="5"
                            max-rows="3"
                            ></b-form-textarea>
                            <b-form-invalid-feedback :state="validateContenidoAgregar">
                                <div v-for="(err, index) in errors.add.contenido" :key="index">
                                    {{err}}
                                </div>
                            </b-form-invalid-feedback>
                        </div>
                    </div>
                    <b-button type="submit" class="my-4 p-2 w-100 boton-card btn-principal">Agregar comentario</b-button>
                </b-form>
            </b-card>
        </b-collapse>
        <b-table class="heigth-500" v-if="!spinLoad" striped hover :items="itemsComentarios" :fields="fieldsComentarios" show-empty responsive>
            <template #empty >
                <h4 class="text-center">No hay comentarios disponibles</h4>
                </template>
            <template #cell(created_at)="row">
                <p>{{moment(row.item.created_at).format('DD-MM-YYYY')}}</p> 
            </template>
            <template #cell(acciones)="row">
                <!-- editar  -->
                <div  class="d-flex">

                    <b-dropdown v-if="canEdit(row.item.usuario)" @show="dropEditToggle(row)" no-caret dropleft variant="info" class="btn btn-principal btn-small btn-secondary big-dropdown mr-2">
                    <template #button-content>
                         Editar
                        <svg viewBox="0 0 10.84 10.841" height="10.841" width="10.84" xmlns="http://www.w3.org/2000/svg" class="icono-titulo">
                        <path xmlns="http://www.w3.org/2000/svg" class="blanco" d="M2.258,10.841H0V8.583l6.66-6.66L8.919,4.181Zm7.3-7.3h0L7.3,1.278l1.1-1.1a.6.6,0,0,1,.849,0l1.409,1.409a.6.6,0,0,1,0,.849l-1.1,1.1Z" transform="translate(0 0)"></path><title xmlns="http://www.w3.org/2000/svg">My Image</title></svg>
                    </template>

                    <b-dropdown-form @submit.prevent="editarComentario(row.item.id)" style="width:400px !important">
                        <b-form-group>
                            <label for="">Asunto</label>
                            <b-form-input
                                id="asunto"
                                v-model="formData.edit.asunto"
                                class="mb-2 mr-sm-2 mb-sm-0"
                                :state="validateAsuntoEditar"
                                placeholder="Asunto"
                                 autocomplete="off"
                                @click="errors.edit.asunto = []"
                            ></b-form-input>
                            <b-form-invalid-feedback :state="validateAsuntoEditar">
                                <div v-for="(err, index) in errors.edit.asunto" :key="index">
                                    {{err}}
                                </div>
                            </b-form-invalid-feedback>
                        </b-form-group>

                        <b-form-group>
                            <label for="">Contenido</label>
                            <b-form-textarea
                            id="contenido"
                            autocomplete="off"
                            v-model="formData.edit.contenido"
                            placeholder="Contenido del comentario..."
                            @click="errors.edit.contenido = []"
                            :state="validateContenidoEditar"
                            rows="5"
                            max-rows="3"
                            ></b-form-textarea>
                            <b-form-invalid-feedback :state="validateContenidoEditar">
                                <div v-for="(err, index) in errors.edit.contenido" :key="index">
                                    {{err}}
                                </div>
                            </b-form-invalid-feedback>
                        </b-form-group>
                        <b-button type="submit" class=" p-2 w-100 boton-card btn-principal">
                            <div v-if="!spinLoadEdit">
                                Editar comentario

                            </div>
                            <div v-else>
                                <b-spinner class="align-middle"></b-spinner>
                                <strong> Cargando...</strong>
                            </div>
                        </b-button>
                    </b-dropdown-form>
                    </b-dropdown>
                    <button v-else class="btn btn-principal btn-small btn-secondary mr-2" disabled>
                    Editar
                    <svg viewBox="0 0 10.84 10.841" height="10.841" width="10.84" xmlns="http://www.w3.org/2000/svg" class="icono-titulo">
                        <path xmlns="http://www.w3.org/2000/svg" class="blanco" d="M2.258,10.841H0V8.583l6.66-6.66L8.919,4.181Zm7.3-7.3h0L7.3,1.278l1.1-1.1a.6.6,0,0,1,.849,0l1.409,1.409a.6.6,0,0,1,0,.849l-1.1,1.1Z" transform="translate(0 0)"></path><title xmlns="http://www.w3.org/2000/svg">My Image</title></svg>
                    </button>
                    
                    <button v-if="canDelete(row.item.usuario)" @click="activeDeleteModal(row)" class="btn btn-small btn-danger">
                        Eliminar
                    </button>
                    <button v-else disabled class="btn btn-small btn-danger">
                        Eliminar
                    </button>
                </div>
            </template>
            <template #table-busy>
                <div class="text-center my-2" style="color: #FFCE1E !important ">
                    <b-spinner class="align-middle"></b-spinner>
                    <strong> Cargando...</strong>
                </div>
            </template>
        
        </b-table>
        <div v-else>
            <b-spinner class="align-middle"></b-spinner>
            <strong> Cargando...</strong>
        </div>
         <b-modal v-model="deleteModal.active" title="Eliminar Comentario">
             <p>¿Seguro que desea eliminar el comentario: <b> {{deleteModal.content}} </b></p>
             <template #modal-footer>
                 <div class="d-flex justify-content-end">
                    <button @click="deleteModal.active = false" class="btn btn-secondary btn-small mr-2">Cancelar</button>
                    <button @click="eliminarComentario()" class="btn btn-danger btn-small">Eliminar</button>
                 </div>
             </template>
         </b-modal>
  </div>
</template>

<script>
import axios from 'axios'
import moment from 'moment';
export default {
    props:{
        canManage:{type: Function, default: ()=>{return true}},
    },
 data(){
     return{
        itemsComentarios:[],
        fieldsComentarios: [
            {key:'usuario.name', label: 'Usuario'},
            {key:'created_at', label: 'Fecha'},
            {key:'asunto', label: 'Asunto'},
            {key:'contenido', label: 'Contenido'},
            {key:'acciones', label: 'Acciones', tdClass:'w-0'},
        ],
        spinLoad: false,
        spinLoadEdit: false,
        deleteModal: {
            active: false,
            id: 0,
            content: ''
        },
        id: '',
        visible: false,
        formData:{
            add:{
                asunto: '',
                contenido: ''
            },
            edit:{
                asunto:'',
                contenido: ''
            }
        },
        errors:{
            add:{
                asunto:[],
                contenido:[],
            },
            edit:{
                asunto:[],
                contenido:[],
            }
        }
     }
 },
 mounted(){

    this.id = this.$route.params.despachoId;
    this.getComentarios();
    
  },
 methods:{
     async getComentarios(){
         this.spinLoad = true
        await axios.get(`api/admin/comentarios/despacho/${this.id}`)
        .then(res => {
            this.spinLoad = false
            this.itemsComentarios = res.data.data
        })
        .catch(err => {
            this.spinLoad = false
        })
     },
     async editarComentario(id_comentario){
         this.spinLoadEdit = true
         await axios.put('api/admin/comentarios/' + id_comentario,
         {
             asunto: this.formData.edit.asunto,
             contenido: this.formData.edit.contenido,
             despachos_id: this.id
         }).then(()=>{
            this.visible = false
            this.formData.edit.asunto = ''
            this.formData.edit.contenido = ''
            this.getComentarios()
             this.spinLoadEdit = false

         }).catch(err =>{
             this.spinLoadEdit = false
             if(err.response.status !== 422){
                this.makeToast('Error', 'danger', 'Un error inesperado ha ocurrido')
             }else{
                 if (err.response.data.errors.asunto !== undefined) {
                     this.errors.edit.asunto = err.response.data.errors.asunto
                 }
                 if (err.response.data.errors.contenido !== undefined) {
                    this.errors.edit.contenido = err.response.data.errors.contenido
                 }
             }

         })
     },
     async agregarComentario(){
         this.spinLoad = true

         await axios.post('api/admin/comentarios', {asunto: this.formData.add.asunto, contenido: this.formData.add.contenido, despachos_id: this.id}).then(() =>{
            this.visible = false
            this.formData.add.asunto = ''
            this.formData.add.contenido = ''
            this.getComentarios()
         }).catch(error => {
            this.spinLoad = false
             if (error.response.data.errors.asunto !== undefined) {
                 this.errors.add.asunto = error.response.data.errors.asunto
             }
             if (error.response.data.errors.contenido !== undefined) {
                this.errors.add.contenido = error.response.data.errors.contenido
             }
         })
     },
     async eliminarComentario(){
         await axios.delete('api/admin/comentarios/' + this.deleteModal.id).then(res =>{
             this.deleteModal.id = 0
             this.deleteModal.content = ''
             this.deleteModal.active = false
             this.getComentarios()
         }).catch(err =>{
             this.makeToast('Error', 'danger', 'Un error inesperado ha ocurrido. Contactese con el administrador por favor!.')
         })
     },
     makeToast(header = null,variant = null,bodyContent = "") {
        this.$bvToast.toast(`${bodyContent || 'contenido...'}`, {
            title: `${header || 'no header'}`,
            variant: variant,
            toaster: 'b-toaster-bottom-right',
            solid: true
        })
    },
    dropEditToggle(row){
        this.formData.edit.asunto = row.item.asunto
        this.formData.edit.contenido = row.item.contenido

    },
    canEdit(user){
        if(this.canManage === false) return false
        let loggedUser = JSON.parse(localStorage.getItem('vuex')).auth.user
        if(loggedUser.role.name === "superuser")
        {
            return true // si es super usuario podra editar cualquier comentario
        }
        if (loggedUser.id === user.id){
            return true // si el comentario es del usuario podra adminsitrar su comentario
        }
        return false // si no cumple con las condiciones, no podra editar el comentario
     },
     canDelete(user){
        if(this.canManage === false) return false

        let loggedUser = JSON.parse(localStorage.getItem('vuex')).auth.user
        if(loggedUser.role.name === "superuser")
        {
            return true // si es super usuario podra editar cualquier comentario
        }
        return false // si no cumple con las condiciones, no podra eliminar  el comentario
     },
     activeDeleteModal(row){
        this.deleteModal.content = row.item.asunto
        this.deleteModal.id = row.item.id
        this.deleteModal.active = true
     }
 },
 computed:{
     validateAsuntoAgregar() {
         if (this.errors.add.asunto.length > 0){
             return false // malo
         }

         if (this.formData.add.asunto.length > 0){
             return true // bueno
         }
         return undefined // neutro
     },
     validateContenidoAgregar() {
         if (this.errors.add.contenido.length > 0){
             return false // malo
         }

         if (this.formData.add.contenido.length > 0){
             return true // bueno
         }
         return undefined // neutro
     },
     validateAsuntoEditar(){
         if (this.errors.edit.asunto.length > 0){
             return false // malo
         }

         if (this.formData.edit.asunto.length > 0){
             return true // bueno
         }
         return undefined // neutro
     },
     validateContenidoEditar(){
         if (this.errors.edit.contenido.length > 0){
             return false // malo
         }

         if (this.formData.edit.contenido.length > 0){
             return true // bueno
         }
         return undefined // neutro
     }
 }
}
</script>

<style scoped>
    .shadow-card .card-body{
        box-shadow: 0px 0px 7px rgb(167 167 167 / 27%);
        border-radius: 3px;
    }
</style>