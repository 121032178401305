<template>
<b-row>
    <b-col cols="12 informacion">
        <!--ESTADO PRESENTACION MATRIZ --> 
        <b-row v-if="despacho.tipo.puerto_aereo && estados.puerto_aereo">
            <b-col cols="4" class="text-left dato">
                <p>Puerto/Aereo</p>
            </b-col>
            <b-col cols="4" class="text-right dato despacho" >
                
                <p v-if="despacho.tipo.puerto_aereo">{{despacho.tipo.extraportuario.nombre}}</p> 
                <p v-else>-</p> 
            </b-col>
            <b-col cols="4" class="text-right dato despacho" >
                <b-button
                v-if="pantalla == true"
                    @click="editar('aduanero_exportacion','puerto_aereo')"
                    class="btn-principal btn-small"
                >
                    Editar <inline-svg  class="icono-titulo" src="../../assets/images/Editar.svg" title="My Image"/>
                </b-button>
                <b-button
                v-if="pantalla == false"
                    @click="editar('aduanero_exportacion','puerto_aereo')"
                    class="btn-principal-chico"
                >
                    <inline-svg  class="icono-titulo" src="../../assets/images/EditarAzul.svg" title="My Image"/>
                </b-button>
            </b-col>
        </b-row>
        <b-row v-else>
            <b-col 
                cols="12" class="text-right dato despacho" 
            >
                <form class="form-input">
                    <b-row>
                        <b-col sm="12" md="12" lg="4">
                            
                            <b-form-group
                                class="text-left"
                                label="Puerto / Aereo"
                                label-for="importancia-select"
                            >
                                <select class="form-control border border-secondary"  id="importancia-select" v-model="puerto_aereo"  required>
                                    <option value="0" disabled>Selecciona el Puerto / Aereo</option>
                                    <option :key="index" v-for="(extraportuario,index) in informacionGeneral.extraportuarios" :value="extraportuario.id" >{{extraportuario.nombre}}</option>
                                    
                                </select>
                            </b-form-group>
                        </b-col>
                        <b-col sm="12" md="12" lg="4">
                            <b-button
                                class="btn-extra-primario btn-small"
                                type="button"
                                @click="editar('aduanero_exportacion','puerto_aereo', true,{'puerto_aereo': puerto_aereo })"
                            >
                                Guardar
                            </b-button>
                            <b-button
                                class="btn-extra-secundario btn-small"
                                type="button"
                                @click="editar('aduanero_exportacion','puerto_aereo')"
                            >
                                Cancelar
                            </b-button>
                        </b-col>
                    </b-row>
                </form>
            </b-col>
        </b-row>
        <!--NUMERO RESERVA --> 
        <b-row v-if="despacho.tipo.numero_reserva && estados.numero_reserva">
            <b-col cols="4" class="text-left dato">
                <p>Número Reserva</p>
            </b-col>
            <b-col cols="4" class="text-right dato despacho">
                <p v-if="despacho.tipo.numero_reserva">{{despacho.tipo.numero_reserva}}</p> 
                <p v-else>-</p> 
            </b-col>
            <b-col cols="4" class="text-right dato despacho">
                <b-button
                v-if="pantalla == true"
                    @click="editar('aduanero_exportacion','numero_reserva')"
                    class="btn-principal btn-small"
                >
                    Editar <inline-svg  class="icono-titulo" src="../../assets/images/Editar.svg" title="My Image"/>
                </b-button>
                <b-button
                v-if="pantalla == false"
                    @click="editar('aduanero_exportacion','numero_reserva')"
                    class="btn-principal-chico"
                >
                    <inline-svg  class="icono-titulo" src="../../assets/images/EditarAzul.svg" title="My Image"/>
                </b-button>
            </b-col>
        </b-row>
        <b-row v-else>
            <b-col 
                cols="12" class="text-right dato despacho" 
            >
                <form class="form-input">
                    <b-row>
                        <b-col sm="12" md="12" lg="4">
                            <b-form-group
                                class="text-left"
                                label="Número Reserva"
                                label-for="numero_reserva-select"
                            >
                            <b-input type="text" v-model="numero_reserva" required>

                            </b-input>
                            </b-form-group>
                        </b-col>
                        <b-col sm="12" md="12" lg="4">
                            <b-button
                                class="btn-extra-primario btn-small"
                                type="button"
                                @click="editar('aduanero_exportacion','numero_reserva', true,{'numero_reserva': numero_reserva })"
                            >
                                Guardar
                            </b-button>
                            <b-button
                                class="btn-extra-secundario btn-small"
                                type="button"
                                @click="editar('aduanero_exportacion','numero_reserva')"
                            >
                                Cancelar
                            </b-button>
                        </b-col>
                    </b-row>
                </form>
            </b-col>
        </b-row>
        <!--Transporte --> 
        <b-row v-if="despacho.transporte && estados.transporte" hidden>
            <b-col cols="4" class="text-left dato">
                <p>Transporte</p>
            </b-col>
            <b-col cols="4" class="text-right dato despacho">
                <p v-if="despacho.transporte">{{despacho.transporte}} - {{despacho.correo_transporte}}</p> 
                <p v-else>-</p> 
            </b-col>
            <b-col cols="4" class="text-right dato despacho" hidden>
                <b-button
                v-if="pantalla == true"
                    @click="editar('aduanero_exportacion','transporte')"
                    class="btn-principal btn-small"
                >
                    Editar <inline-svg  class="icono-titulo" src="../../assets/images/Editar.svg" title="My Image"/>
                </b-button>
                <b-button
                v-if="pantalla == false"
                    @click="editar('aduanero_exportacion','transporte')"
                    class="btn-principal-chico"
                >
                    <inline-svg  class="icono-titulo" src="../../assets/images/EditarAzul.svg" title="My Image"/>
                </b-button>
            </b-col>
        </b-row>
        <b-row v-else hidden>
            <b-col cols="12" class="text-right dato despacho" >
                <form class="form-input">
                    <b-row>
                        <b-col sm="12" md="12" lg="4">
                            <b-form-group
                                class="text-left"
                                label="Nombre de Transporte Local (Chile)"
                                label-for="transporte-select"
                            >
                            <b-input type="text" v-model="transporte" required>

                            </b-input>
                            </b-form-group>
                        </b-col>
                        <b-col sm="12" md="12" lg="4">
                            <b-form-group
                                class="text-left"
                                label="Correo de Transporte Local"
                                label-for="correo_transporte-select"
                            >
                                <b-input type="email" v-model="correo_transporte" required>

                                </b-input>
                            </b-form-group>
                        </b-col>
                        
                        <b-col sm="12" md="12" lg="4">
                            <b-button
                                class="btn-extra-primario btn-small"
                                type="button"
                                @click="editar('aduanero_exportacion','transporte', true,{'transporte': transporte, 'correo_transporte': correo_transporte })"
                            >
                                Guardar
                            </b-button>
                            <b-button
                                class="btn-extra-secundario btn-small"
                                type="button"
                                @click="editar('aduanero_exportacion','transporte')"
                            >
                                Cancelar
                            </b-button>
                        </b-col>
                    </b-row>
                </form>
            </b-col>
        </b-row>
        <!--FECHA SALIDA PROGRAMADA --> 
        <b-row v-if="despacho.tipo.fecha_ingreso_zona_primaria && estados.fecha_ingreso_zona_primaria">
            <b-col cols="4" class="text-left dato">
                <p>Fecha Ingreso Zona Primaria</p>
            </b-col>
            <b-col cols="4" class="text-right dato despacho">
                <p v-if="despacho.tipo.fecha_ingreso_zona_primaria">{{moment(despacho.tipo.fecha_ingreso_zona_primaria).format('DD-MM-YYYY')}}</p> 
                <p v-else>-</p> 
            </b-col>
            <b-col cols="4" class="text-right dato despacho">
                <b-button
                v-if="pantalla == true"
                    @click="editar('aduanero_exportacion','fecha_ingreso_zona_primaria')"
                    class="btn-principal btn-small"
                >
                    Editar <inline-svg  class="icono-titulo" src="../../assets/images/Editar.svg" title="My Image"/>
                </b-button>
                <b-button
                v-if="pantalla == false"
                    @click="editar('aduanero_exportacion','fecha_ingreso_zona_primaria')"
                    class="btn-principal-chico"
                >
                    <inline-svg  class="icono-titulo" src="../../assets/images/EditarAzul.svg" title="My Image"/>
                </b-button>
            </b-col>
        </b-row>
        <b-row v-else>
            <b-col cols="12" class="text-right dato despacho" >
                <form class="form-input">
                    <b-row>
                        <b-col sm="12" md="12" lg="4">
                            <b-form-group
                                class="text-left"
                                label="Fecha Ingreso Zona Primaria"
                                label-for="importancia-select"
                            >
                            <b-input type="date" v-model="fecha_ingreso_zona_primaria" required>

                            </b-input>
                            </b-form-group>
                        </b-col>
                        
                        <b-col sm="12" md="12" lg="4">
                            <b-button
                                class="btn-extra-primario btn-small"
                                type="button"
                                @click="editar('aduanero_exportacion','fecha_ingreso_zona_primaria', true,{'fecha_ingreso_zona_primaria': fecha_ingreso_zona_primaria})"
                            >
                                Guardar
                            </b-button>
                            <b-button
                                class="btn-extra-secundario btn-small"
                                type="button"
                                @click="editar('aduanero_exportacion','fecha_ingreso_zona_primaria')"
                            >
                                Cancelar
                            </b-button>
                        </b-col>
                    </b-row>
                </form>
            </b-col>
        </b-row>

        <!--FECHA SALIDA PROGRAMADA --> 
        <b-row v-if="despacho.tipo.fecha_salida_programada && estados.fecha_salida_programada">
            <b-col cols="4" class="text-left dato">
                <p>Fecha Salida Programada</p>
            </b-col>
            <b-col cols="4" class="text-right dato despacho">
                <p v-if="despacho.tipo.fecha_salida_programada">{{moment(despacho.tipo.fecha_salida_programada).format('DD-MM-YYYY')}}</p> 
                <p v-else>-</p> 
            </b-col>
            <b-col cols="4" class="text-right dato despacho">
                <b-button
                v-if="pantalla == true"
                    @click="editar('aduanero_exportacion','fecha_salida_programada')"
                    class="btn-principal btn-small"
                >
                    Editar <inline-svg  class="icono-titulo" src="../../assets/images/Editar.svg" title="My Image"/>
                </b-button>
                <b-button
                v-if="pantalla == false"
                    @click="editar('aduanero_exportacion','fecha_salida_programada')"
                    class="btn-principal-chico"
                >
                    <inline-svg  class="icono-titulo" src="../../assets/images/EditarAzul.svg" title="My Image"/>
                </b-button>
            </b-col>
        </b-row>
        <b-row v-else>
            <b-col cols="12" class="text-right dato despacho" >
                <form class="form-input">
                    <b-row>
                        <b-col sm="12" md="12" lg="6">
                            <b-form-group
                                class="text-left"
                                label="Fecha Salida Programada"
                                label-for="importancia-select"
                            >
                            <b-input type="date" v-model="fecha_salida_programada" required>

                            </b-input>
                            </b-form-group>
                        </b-col>
                        
                        <b-col sm="12" md="12" lg="4">
                            <b-button
                                class="btn-extra-primario btn-small"
                                type="button"
                                @click="editar('aduanero_exportacion','fecha_salida_programada', true,{'fecha_salida_programada': fecha_salida_programada})"
                            >
                                Guardar
                            </b-button>
                            <b-button
                                class="btn-extra-secundario btn-small"
                                type="button"
                                @click="editar('aduanero_exportacion','fecha_salida_programada')"
                            >
                                Cancelar
                            </b-button>
                        </b-col>
                    </b-row>
                </form>
            </b-col>
        </b-row>
        <!--ESTADO FECHA SALIDA --> 
        <b-row v-if="despacho.tipo.estado && estados.estado">
            <b-col cols="4" class="text-left dato">
                <p>Estado</p>
            </b-col>
            <b-col cols="4" class="text-right dato despacho">
                <p v-if="despacho.tipo.estado">{{despacho.tipo.estado}}</p> 
                <p v-else>-</p> 
            </b-col>
            <b-col cols="4" class="text-right dato despacho">
                <b-button
                v-if="pantalla == true"
                    @click="editar('aduanero_exportacion','estado')"
                    class="btn-principal btn-small"
                >
                    Editar <inline-svg  class="icono-titulo" src="../../assets/images/Editar.svg" title="My Image"/>
                </b-button>
                <b-button
                v-if="pantalla == false"
                    @click="editar('aduanero_exportacion','estado')"
                    class="btn-principal-chico"
                >
                    <inline-svg  class="icono-titulo" src="../../assets/images/EditarAzul.svg" title="My Image"/>
                </b-button>
            </b-col>
        </b-row>
        <b-row v-else>
            <b-col 
                cols="12" class="text-right dato despacho" 
            >
                <form class="form-input">
                    <b-row>
                        <b-col sm="12" md="12" lg="4">
                            <b-form-group
                                class="text-left"
                                label="Estado"
                                label-for="importancia-select"
                            >
                                <select class="form-control border border-secondary"  id="importancia-select" v-model="estado"  required>
                                    <option value="0" disabled>Selecciona el Estado</option>
                                    <option :key="index" v-for="(estado,index) in informacionGeneral.estado_salida" :value="estado" >{{estado}}</option>
                                    
                                </select>
                            </b-form-group>
                        </b-col>
                        <b-col sm="12" md="12" lg="4">
                            <b-button
                                class="btn-extra-primario btn-small"
                                type="button"
                                @click="editar('aduanero_exportacion','estado', true,{'estado': estado })"
                            >
                                Guardar
                            </b-button>
                            <b-button
                                class="btn-extra-secundario btn-small"
                                type="button"
                                @click="editar('aduanero_exportacion','estado')"
                            >
                                Cancelar
                            </b-button>
                        </b-col>
                    </b-row>
                </form>
            </b-col>
        </b-row>
        <!--ESTADO FECHA SALIDA --> 
        <b-row v-if="despacho.tipo.estado_salida && estados.estado_salida">
            <b-col cols="4" class="text-left dato">
                <p>Estado Salida</p>
            </b-col>
            <b-col cols="4" class="text-right dato despacho">
                <p v-if="despacho.tipo.estado_salida">{{despacho.tipo.estado_salida}}</p> 
                <p v-else>-</p> 
            </b-col>
            <b-col cols="4" class="text-right dato despacho">
                <b-button
                v-if="pantalla == true"
                    @click="editar('aduanero_exportacion','estado_salida')"
                    class="btn-principal btn-small"
                >
                    Editar <inline-svg  class="icono-titulo" src="../../assets/images/Editar.svg" title="My Image"/>
                </b-button>
                <b-button
                v-if="pantalla == false"
                    @click="editar('aduanero_exportacion','estado_salida')"
                    class="btn-principal-chico"
                >
                    <inline-svg  class="icono-titulo" src="../../assets/images/EditarAzul.svg" title="My Image"/>
                </b-button>
            </b-col>
        </b-row>
        <b-row v-else>
            <b-col 
                cols="12" class="text-right dato despacho" 
            >
                <form class="form-input">
                    <b-row>
                        <b-col sm="12" md="12" lg="4">
                            <b-form-group
                                class="text-left"
                                label="Estado Salida"
                                label-for="importancia-select"
                            >
                                <select class="form-control border border-secondary"  id="importancia-select" v-model="estado_salida"  required>
                                    <option value="0" disabled>Selecciona el Estado Salida</option>
                                    <option :key="index" v-for="(estadoSalida,index) in informacionGeneral.estado_salida" :value="estadoSalida" >{{estadoSalida}}</option>
                                    
                                </select>
                            </b-form-group>
                        </b-col>
                        <b-col sm="12" md="12" lg="4">
                            <b-button
                                class="btn-extra-primario btn-small"
                                type="button"
                                @click="editar('aduanero_exportacion','estado_salida', true,{'estado_salida': estado_salida })"
                            >
                                Guardar
                            </b-button>
                            <b-button
                                class="btn-extra-secundario btn-small"
                                type="button"
                                @click="editar('aduanero_exportacion','estado_salida')"
                            >
                                Cancelar
                            </b-button>
                        </b-col>
                    </b-row>
                </form>
            </b-col>
        </b-row>
        <!--ESTADO AFORO ZONA PRIMARIA --> 
        <b-row v-if="despacho.tipo.aforo_zona_primaria && estados.aforo_zona_primaria">
            <b-col cols="4" class="text-left dato">
                <p>Aforo Zona Primaria</p>
            </b-col>
            <b-col cols="4" class="text-right dato despacho">
                <p v-if="despacho.tipo.aforo_zona_primaria">{{despacho.tipo.aforo_zona_primaria}}</p> 
                <p v-else>-</p> 
            </b-col>
            <b-col cols="4" class="text-right dato despacho">
                <b-button
                v-if="pantalla == true"
                    @click="editar('aduanero_exportacion','aforo_zona_primaria')"
                    class="btn-principal btn-small"
                >
                    Editar <inline-svg  class="icono-titulo" src="../../assets/images/Editar.svg" title="My Image"/>
                </b-button>
                <b-button
                v-if="pantalla == false"
                    @click="editar('aduanero_exportacion','aforo_zona_primaria')"
                    class="btn-principal-chico"
                >
                    <inline-svg  class="icono-titulo" src="../../assets/images/EditarAzul.svg" title="My Image"/>
                </b-button>
            </b-col>
        </b-row>
        <b-row v-else>
            <b-col 
                cols="12" class="text-right dato despacho" 
            >
                <form class="form-input">
                    <b-row>
                        <b-col sm="12" md="12" lg="4">
                            <b-form-group
                                class="text-left"
                                label="Aforo Zona Primaria"
                                label-for="aforo_zona_primaria-select"
                            >
                                <select class="form-control border border-secondary"  id="importancia-select" v-model="aforo_zona_primaria"  required>
                                    <option value="0" disabled>Selecciona el Aforo Zona Primaria</option>
                                    <option :key="index" v-for="(aforoZonaPrimaria,index) in informacionGeneral.aforo_zona_primaria" :value="aforoZonaPrimaria" >{{aforoZonaPrimaria}}</option>
                                    
                                </select>
                            </b-form-group>
                        </b-col>
                        <b-col sm="12" md="12" lg="4">
                            <b-button
                                class="btn-extra-primario btn-small"
                                type="button"
                                @click="editar('aduanero_exportacion','aforo_zona_primaria', true,{'aforo_zona_primaria': aforo_zona_primaria })"
                            >
                                Guardar
                            </b-button>
                            <b-button
                                class="btn-extra-secundario btn-small"
                                type="button"
                                @click="editar('aduanero_exportacion','aforo_zona_primaria')"
                            >
                                Cancelar
                            </b-button>
                        </b-col>
                    </b-row>
                </form>
            </b-col>
        </b-row>
        <!--ESTADO FECHA AFORO ZONA PRIMARIA --> 
        <b-row v-if="despacho.tipo.fecha_aforo_zona_primaria && estados.fecha_aforo_zona_primaria">
            <b-col cols="4" class="text-left dato">
                <p>Fecha Aforo Zona Primaria</p>
            </b-col>
            <b-col cols="4" class="text-right dato despacho">
                <p v-if="despacho.tipo.fecha_aforo_zona_primaria">{{moment(despacho.tipo.fecha_aforo_zona_primaria).format('DD-MM-YYYY')}}</p> 
                <p v-else>-</p> 
            </b-col>
            <b-col cols="4" class="text-right dato despacho">
                <b-button
                v-if="pantalla == true"
                    @click="editar('aduanero_exportacion','fecha_aforo_zona_primaria')"
                    class="btn-principal btn-small"
                >
                    Editar <inline-svg  class="icono-titulo" src="../../assets/images/Editar.svg" title="My Image"/>
                </b-button>
                <b-button
                v-if="pantalla == false"
                    @click="editar('aduanero_exportacion','fecha_aforo_zona_primaria')"
                    class="btn-principal-chico"
                >
                    <inline-svg  class="icono-titulo" src="../../assets/images/EditarAzul.svg" title="My Image"/>
                </b-button>
            </b-col>
        </b-row>
        <b-row v-else>
            <b-col 
                cols="12" class="text-right dato despacho" 
            >
                <form class="form-input">
                    <b-row>
                        <b-col sm="12" md="12" lg="4">
                            <b-form-group
                                class="text-left"
                                label="Fecha Aforo Zona Primaria"
                                label-for="importancia-select"
                            >
                            <b-input type="date" v-model="fecha_aforo_zona_primaria" required>

                            </b-input>
                            </b-form-group>
                        </b-col>
                        <b-col sm="12" md="12" lg="4">
                            <b-button
                                class="btn-extra-primario btn-small"
                                type="button"
                                @click="editar('aduanero_exportacion','fecha_aforo_zona_primaria', true,{'fecha_aforo_zona_primaria': fecha_aforo_zona_primaria })"
                            >
                                Guardar
                            </b-button>
                            <b-button
                                class="btn-extra-secundario btn-small"
                                type="button"
                                @click="editar('aduanero_exportacion','fecha_aforo_zona_primaria')"
                            >
                                Cancelar
                            </b-button>
                        </b-col>
                    </b-row>
                </form>
            </b-col>
        </b-row>
         <!--ESTADO AFORO ZONA DUS LEGALIZADA --> 
        <b-row v-if="despacho.tipo.aforo_dus_legalizado && estados.aforo_dus_legalizado">
            <b-col cols="4" class="text-left dato">
                <p>Aforo Dus Legalizado</p>
            </b-col>
            <b-col cols="4" class="text-right dato despacho">
                <p v-if="despacho.tipo.aforo_dus_legalizado">{{despacho.tipo.aforo_dus_legalizado}}</p> 
                <p v-else>-</p> 
            </b-col>
            <b-col cols="4" class="text-right dato despacho">
                <b-button
                v-if="pantalla == true"
                    @click="editar('aduanero_exportacion','aforo_dus_legalizado')"
                    class="btn-principal btn-small"
                >
                    Editar <inline-svg  class="icono-titulo" src="../../assets/images/Editar.svg" title="My Image"/>
                </b-button>
                <b-button
                v-if="pantalla == false"
                    @click="editar('aduanero_exportacion','aforo_dus_legalizado')"
                    class="btn-principal-chico"
                >
                    <inline-svg  class="icono-titulo" src="../../assets/images/EditarAzul.svg" title="My Image"/>
                </b-button>
            </b-col>
        </b-row>
        <b-row v-else>
            <b-col 
                cols="12" class="text-right dato despacho" 
            >
                <form class="form-input">
                    <b-row>
                        <b-col sm="12" md="12" lg="4">
                            <b-form-group
                                class="text-left"
                                label="Aforo Dus Legalizado"
                                label-for="aforo_zona_primaria-select"
                            >
                                <select class="form-control border border-secondary"  id="importancia-select" v-model="aforo_dus_legalizado"  required>
                                    <option value="0" disabled>Selecciona el Aforo Dus Legalizado</option>
                                    <option :key="index" v-for="(aforoDusLegalizado,index) in informacionGeneral.aforo_dus_legalizado" :value="aforoDusLegalizado" >{{aforoDusLegalizado}}</option>
                                    
                                </select>
                            </b-form-group>
                        </b-col>
                        <b-col sm="12" md="12" lg="4">
                            <b-button
                                class="btn-extra-primario btn-small"
                                type="button"
                                @click="editar('aduanero_exportacion','aforo_dus_legalizado', true,{'aforo_dus_legalizado': aforo_dus_legalizado })"
                            >
                                Guardar
                            </b-button>
                            <b-button
                                class="btn-extra-secundario btn-small"
                                type="button"
                                @click="editar('aduanero_exportacion','aforo_dus_legalizado')"
                            >
                                Cancelar
                            </b-button>
                        </b-col>
                    </b-row>
                </form>
            </b-col>
        </b-row>
        <!--ESTADO FECHA AFORO ZONA PRIMARIA --> 
        <b-row v-if="despacho.tipo.fecha_aforo_dus_legalizado && estados.fecha_aforo_dus_legalizado">
            <b-col cols="4" class="text-left dato">
                <p>Fecha Aforo Dus Legalizado</p>
            </b-col>
            <b-col cols="4" class="text-right dato despacho">
                <p v-if="despacho.tipo.fecha_aforo_dus_legalizado">{{moment(despacho.tipo.fecha_aforo_dus_legalizado).format('DD-MM-YYYY')}}</p> 
                <p v-else>-</p> 
            </b-col>
            <b-col cols="4" class="text-right dato despacho">
                <b-button
                v-if="pantalla == true"
                    @click="editar('aduanero_exportacion','fecha_aforo_dus_legalizado')"
                    class="btn-principal btn-small"
                >
                    Editar <inline-svg  class="icono-titulo" src="../../assets/images/Editar.svg" title="My Image"/>
                </b-button>
                <b-button
                v-if="pantalla == false"
                    @click="editar('aduanero_exportacion','fecha_aforo_dus_legalizado')"
                    class="btn-principal-chico"
                >
                    <inline-svg  class="icono-titulo" src="../../assets/images/EditarAzul.svg" title="My Image"/>
                </b-button>
            </b-col>
        </b-row>
        <b-row v-else>
            <b-col 
                cols="12" class="text-right dato despacho" 
            >
                <form class="form-input">
                    <b-row>
                        <b-col sm="12" md="12" lg="4">
                            <b-form-group
                                class="text-left"
                                label="Fecha Aforo Dus Legalizado"
                                label-for="importancia-select"
                            >
                            <b-input type="date" v-model="fecha_aforo_dus_legalizado" required>

                            </b-input>
                            </b-form-group>
                        </b-col>
                        <b-col sm="12" md="12" lg="4">
                            <b-button
                                class="btn-extra-primario btn-small"
                                type="button"
                                @click="editar('aduanero_exportacion','fecha_aforo_dus_legalizado', true,{'fecha_aforo_dus_legalizado': fecha_aforo_dus_legalizado })"
                            >
                                Guardar
                            </b-button>
                            <b-button
                                class="btn-extra-secundario btn-small"
                                type="button"
                                @click="editar('aduanero_exportacion','fecha_aforo_dus_legalizado')"
                            >
                                Cancelar
                            </b-button>
                        </b-col>
                    </b-row>
                </form>
            </b-col>
        </b-row>
        <!-- <b-row v-if="(despacho.aforo == 0 || despacho.aforo == 1 || despacho.aforo == 2) && estados.aforo">
            <b-col cols="4" class="text-left dato">
                <p>Tipo de Aforo</p>
            </b-col>
            <b-col cols="4" class="text-right dato despacho">
                <p v-if="despacho.aforo == 2">Sin Aforo</p> 
                <p v-if="despacho.aforo == 1">Examen Físico</p> 
                <p v-if="despacho.aforo == 0">Examen Documental</p> 
            </b-col>
            <b-col cols="4" class="text-right dato despacho">
                <b-button
                    @click="editar('aduanero_exportacion','aforo')"
                    class="btn-principal btn-small"
                >
                    Editar <inline-svg  class="icono-titulo" src="../../assets/images/Editar.svg" title="My Image"/>
                </b-button>
            </b-col>
        </b-row>
        <b-row v-else>
            <b-col 
                cols="12" class="text-right dato despacho" 
            >
                <form class="form-input">
                    <b-row>
                        <b-col sm="12" md="12" lg="4">
                            <b-form-group
                                class="text-left"
                                label="Tipo de Aforo"
                                label-for="aforo-select"
                            >
                                <select class="form-control border border-secondary"  id="aforo-select" v-model="aforo"  required>
                                    <option value="2" disabled>Selecciona el Tipo de Aforo</option>
                                    <option  value="1" >Examen Físico</option>
                                    <option  value="0" >Examen Documental</option>
                                    
                                </select>
                            </b-form-group>
                        </b-col>
                        <b-col sm="12" md="12" lg="4">
                            <b-button
                                class="btn-extra-primario btn-small"
                                type="button"
                                @click="editar('aduanero_exportacion','aforo', true,{'aforo': aforo })"
                            >
                                Guardar
                            </b-button>
                            <b-button
                                class="btn-extra-secundario btn-small"
                                type="button"
                                @click="editar('aduanero_exportacion','aforo')"
                            >
                                Cancelar
                            </b-button>
                        </b-col>
                    </b-row>
                </form>
            </b-col>
        </b-row> -->
        
    </b-col>

    
    
    
    

</b-row>
</template>

<script>
import moment from 'moment';
import InputRut from '../InputRut.vue';
import InputRegion from '../InputRegion.vue';
export default {
    components: { InputRut, InputRegion },
    name:"FormUsuario",

    props: {
        despacho:Object,
        postInformacionGeneral:Function,
        informacionGeneral:Object,
        editar:Function,
        estados:Object

    },
    data(){
        return{
            //importacion
            transporte: '',
            correo_transporte: '',

            fecha_aforo_dus_legalizado:'',
            aforo_dus_legalizado:'',
            fecha_aforo_zona_primaria:'',
            aforo_zona_primaria:'',
            estado_salida:'',
            fecha_salida_programada:'',
            numero_reserva:'',
            puerto_aereo:'',
            fecha_ingreso_zona_primaria:'',
            estado: '',
            aforo:'',
            pantalla: false
        }
    },
    mounted(){
        if (screen.width <= 660) this.pantalla = false
        else this.pantalla = true
    },
    methods:{
      cambiarRut(value){
        this.cliente_rut = value;
      },
      cambiarRutTransporte(value){
        this.transporte = value;
      },
    }
}
</script>