

<template>
  <b-container fluid class="dashboard">
    <div class="title-dashboard">
      <p>Dashboard</p>
      <div v-if="pantalla == false">
        <b-button class="boton-dashboard mr-2 mb-1" v-bind:class="{ 'filtro-activo': filtros.siete_activo }" @click="cambioActivo(3,'siete_activo')">7 Días</b-button>
        <b-button class="boton-dashboard mr-2 mb-1" v-bind:class="{ 'filtro-activo': filtros.mes_activo }" @click="cambioActivo(2,'mes_activo')">1 Mes</b-button>
        <b-button class="boton-dashboard mb-1" v-bind:class="{ 'filtro-activo': filtros.ano_activo }" @click="cambioActivo(1,'ano_activo')">1 Año</b-button>
      </div>
    </div>
    <div class="botones-fecha">
      <div v-if="pantalla == true">
        <b-button class="boton-dashboard mr-2 mb-1" v-bind:class="{ 'filtro-activo': filtros.siete_activo }" @click="cambioActivo(3,'siete_activo')">7 Días</b-button>
        <b-button class="boton-dashboard mr-2 mb-1" v-bind:class="{ 'filtro-activo': filtros.mes_activo }" @click="cambioActivo(2,'mes_activo')">1 Mes</b-button>
        <b-button class="boton-dashboard mb-1" v-bind:class="{ 'filtro-activo': filtros.ano_activo }" @click="cambioActivo(1,'ano_activo')">1 Año</b-button>
      </div>
    </div>
    <b-row>
      <b-col sm="12" md="12" lg="6" class="mb-4 mb-md-0">
         <b-row>
           <b-col cols="12" class="mx-auto">
             <card class="blue-border" title="Cantidad Despachos">
              <div v-if="isBusy" class="text-center my-2" style="color: #FFCE1E !important ">
                <b-spinner class="align-middle"></b-spinner>
                <strong> Cargando...</strong>
              </div>
              
               <cantidad-despachos
               v-if="fechas.length > 0 && total.length > 0 && exportacion.length > 0 && importacion.length > 0"
                :fechas="fechas"
                :total="total"
                :exportacion="exportacion"
                :importacion="importacion"
               
               
               ></cantidad-despachos>
              </card>
           </b-col>
           <b-col cols="12" class="mx-auto" @click="irADespachos({estado:true,text:'Total Despachos',value:'Mostrar Todos',filtro:'total_despachos'})">
              <total-despachos
                
                :total_despachos="total_despachos"
              ></total-despachos>
           </b-col>
           <b-col cols="12" class="mx-auto">
              <b-row>
                <b-col cols="4">
                   <estatus-despacho 
                    class="blue-border"
                   titulo="Pendientes"
                   color="#E65B38"
                   :cantidad="despachos_pendientes"
                   >
                   
                    <template slot="ver">
                      <b-button class="boton-ver" @click="irADespachos({estado:true,text:'Estado Carga',value:'Pendiente',filtro:'despachos_pendientes'})">
                        Ver
                      </b-button>
                    </template>
                  
                   </estatus-despacho>
                </b-col>
                <b-col cols="4">
                  <estatus-despacho 
                   class="blue-border"
                  titulo="Parcial"
                  color="#02BE92"
                  :cantidad="despachos_parcial"
                  >
                  <template slot="ver">
                    <b-button class="boton-ver" @click="irADespachos({estado:true,text:'Estado Carga',value:'Parcial',filtro:'despachos_parcial'})">
                      Ver
                    </b-button>
                  </template>
                  
                  </estatus-despacho>
                </b-col>
                
                <b-col cols="4">
                   <estatus-despacho 
                    class="blue-border"
                   titulo="Total"
                   color="#5699F4"
                   :cantidad="despachos_total"
                   >
                    <template slot="ver">
                      <b-button class="boton-ver" @click="irADespachos({estado:true,text:'Estado Carga',value:'Total',filtro:'despachos_total'})">
                        Ver
                      </b-button>
                    </template>
                  
                   
                   </estatus-despacho>
                </b-col>
              </b-row>
           </b-col>
         </b-row>
      </b-col>
      <b-col sm="12" md="12" lg="6" >
          <card class="h-100 blue-border" title="Documentos">
            <b-row>
              <b-col cols="12" class="mb-4 py-5">
                <b-row>
                  <b-col sm="12" md="12" lg="12" xl="5" >

                    <documentos-grafico

                      v-if="documentos_completos != null && documentos_faltantes != null"
                      :completos="documentos_completos"
                      :faltantes="documentos_faltantes"
                    >
                    </documentos-grafico>
                  </b-col>
                  <b-col sm="12" md="12" lg="12" xl="7" class="my-auto">
                    <div class="documentos completos" @click="irADespachos({estado:true,text:'Estado Documentos',value:'Completos',filtro:'documentos_completos'})">
                      <p>
                        <inline-svg  class="icono-titulo documento" src="./assets/images/documentodashboard.svg" title="My Image"/>
                        <b>{{documentos_completos}}</b> Completos</p>
                      <span>Cantidad de despachos con todos los documentos</span>
                    </div>
                    <div class="documentos faltantes" @click="irADespachos({estado:true,text:'Estado Documentos',value:'Faltantes',filtro:'documentos_faltantes'})">
                      <p><inline-svg  class="icono-titulo documento" src="./assets/images/documentodashboardverde.svg" title="My Image"/><b>{{documentos_faltantes}}</b> Faltantes</p>
                      <span>Cantidad de despachos con documentos faltantes</span>
                    </div>
                  </b-col>
                </b-row>
              </b-col>
              <div class="col-11 mb-5 mx-auto" style="border-bottom:3px solid #5699F450"></div>
              <b-col cols="12">
                  <b-row>
                    <b-col sm="12" md="12" lg="4">
                      
                      <aforo 
                        :pendientes="aforo_pendientes"
                        :realizados="aforo_realizado"
                        
                        :irADespachosPendientes="irADespachos"
                        :irADespachosRealizados="irADespachos"
                      >
                      <template slot="ver">
                        <b-button class="boton-ver" @click="irADespachos({estado:true,text:'Requiere Aforo',value:'Si',filtro:'aforo'})">
                          Ver
                        </b-button>
                      </template>
                      </aforo>
                    </b-col>
                    <b-col sm="12" md="12" lg="4">
                      
                      <vistos-buenos
                        :pendientes="vistos_buenos_pendientes"
                        :realizados="vistos_buenos_realizados"
                        
                        :irADespachosPendientes="irADespachos"
                        :irADespachosRealizados="irADespachos"
                      >
                      <template slot="ver">
                        <b-button class="boton-ver" @click="irADespachos({estado:true,text:'Requiere Visto Bueno',value:'Si',filtro:'visto_bueno'})">
                          Ver
                        </b-button>
                      </template>
                      </vistos-buenos>
                    </b-col>
                    <b-col sm="12" md="12" lg="4">
                      <fecha-eta 
                        :pendientes="fecha_eta_por_cumplir"
                        :realizados="fecha_eta_vencida"
                        :irADespachosPendientes="irADespachos"
                        :irADespachosRealizados="irADespachos"
                      >
                      <template slot="ver">
                        <b-button class="boton-ver" @click="irADespachos({estado:true,text:'Fecha ETA',value:'Fecha en Rango',filtro:'fecha_eta'})">
                          Ver
                        </b-button>
                      </template>
                      </fecha-eta>
                    </b-col>
                  </b-row>
              </b-col>
            </b-row>     
          </card>
      </b-col>
      
    </b-row>
  </b-container>
</template>

<script>
  import axios from "axios";
import Card from '../components/ui/Card.vue';
import TitlePage from '../components/layout/TitlePage.vue';
import CantidadDespachos from '../components/graficos/CantidadDespachos.vue';
import DocumentosGrafico from '../components/graficos/DocumentosGrafico.vue';
import Aforo from '../components/graficos/Aforo.vue';
import VistosBuenos from '../components/graficos/VistosBuenos.vue';
import FechaEta from '../components/graficos/FechaEta.vue';
import TotalDespachos from '../components/graficos/TotalDespachos.vue';
import EstatusDespacho from '../components/graficos/EstatusDespacho.vue';

import { mapGetters, mapActions } from 'vuex'

  export default {
  components: {  Card, TitlePage, CantidadDespachos, DocumentosGrafico, Aforo, VistosBuenos, FechaEta,TotalDespachos, EstatusDespacho },
    name:"Dashboard",
    
    data() {
      return {

        //Cantidad Despachos
        total:[],
        fechas:[],
        importacion:[],
        exportacion: [],


        total_despachos:null,
        despachos_pendientes:null,
        despachos_parcial:null,
        despachos_total:null,

        aforo_realizado:null,
        aforo_pendientes:null,

        vistos_buenos_pendientes:null,
        vistos_buenos_realizados:null,

        fecha_eta_vencida:null,
        fecha_eta_por_cumplir:null,

        documentos_completos:null,
        documentos_faltantes:null,

        //filtros activo
        filtros:{
          siete_activo: true,
          mes_activo: false,
          ano_activo: false,
        },

        isBusy: false,

        pantalla:false,
      }
    },
    computed: {
      
      ...mapGetters({
        filtro: 'filtros/filtro',
      })
    },
    async mounted(){
      if(screen.width > 660) this.pantalla = false
        else this.pantalla = true


        this.cambiarFecha(3)
        await this.getDashboard();
    },
    methods: {
        ...mapActions({
          cambiarFiltro: 'filtros/cambiarFiltro',
          cambiarFecha: 'filtros/cambiarFecha',
          signOutAction: 'auth/signOut'
        }),
        //Cambio Activo
        cambioActivo(valor,filtro){
          for (var [key, value] of Object.entries(this.filtros)) {
            console.log(this.filtros[key])
              if(key == filtro) this.filtros[key] = true;
              else this.filtros[key]= false
          }
          this.cambiarFecha(valor)
          this.getDashboard(valor);
        },
        toggleBusy() {
          this.isBusy = !this.isBusy
        },

        irADespachos(filtro){
          this.cambiarFiltro(filtro)
          this.$router.replace({ name: 'Despachos' })
        }, 

        //Get Dashboard
        async getDashboard(filtro = 3){
          this.fechas = []
          this.toggleBusy()
          await axios.get(`api/admin/dashboard/${filtro}`)
          .then(res => {
            const informacion = res.data;

            //Aforo
            if(informacion.aforos ) {
              this.aforo_realizado = informacion.aforos.realizados;
              this.aforo_pendientes = informacion.aforos.pendientes;
            }

            //Vistos Buenos
            if(informacion.vistos_buenos ){
              this.vistos_buenos_pendientes = informacion.vistos_buenos.pendientes
              this.vistos_buenos_realizados = informacion.vistos_buenos.realizados
            }

            //ETA
            if(informacion.eta_vencida >= 0) {
              
              this.fecha_eta_vencida = informacion.eta_vencida;
              }
            if(informacion.vencimiento_eta >= 0) {
              this.fecha_eta_por_cumplir = informacion.vencimiento_eta;
            }

            //ESTADO CARGA
            if(informacion.parcial >= 0) {
              this.despachos_parcial = informacion.parcial;
              }
            if(informacion.pendientes >= 0) {
              this.despachos_pendientes = informacion.pendientes;
              }
            if(informacion.total >= 0){ this.despachos_total = informacion.total;
           
            }

            //TOTAL DESPACHOS
            if(informacion.total_despachos >= 0) {this.total_despachos = informacion.total_despachos;
              
            }

            //DOCUMENTOS
            if(informacion.documentos){
              this.documentos_completos = informacion.documentos.completos.total;
              this.documentos_faltantes = informacion.documentos.faltantes.total;
            }

            //DOCUMENTOS
            
            if(informacion.cantidad_despachos){
              this.fechas = informacion.cantidad_despachos.fechas;
              this.total = informacion.cantidad_despachos.total;
              this.importacion = informacion.cantidad_despachos.importacion;
              this.exportacion = informacion.cantidad_despachos.exportacion;
              this.toggleBusy()
            }
      
          })
          .catch(err => {
            // if (err.response.status == 401) {
            //       this.signOutAction()
            //       this.$router.replace({ name: 'SignIn' })
            // }
            console.log(err)
          })
        } 
    },
  }
</script>

<style scoped>
  .title-dashboard{
    display: flex;
    justify-content: space-between;
  
  }

  .title-dashboard{
    border-bottom: 1px solid #969393;
    margin-bottom: 20px;
    margin-top: 20px;
  }

  .title-dashboard p {
    color: #969393;
    text-transform: uppercase;
    font-weight: 500;
    font-size: 10px;

    text-align: left;
    margin-bottom: 0
  }

  .title-dashboard .boton-dashboard{
    width: 80px;
    border-radius: 20px;
    border: none;
    background-color: white;
    color: #043161;
  }
  .title-dashboard .boton-dashboard:hover{
    border: none;
    background-color: #E65B38;
    color: white;
  }
  .filtro-activo{
    background-color: #E65B38 !important;
    color: white !important;
  }


  .botones-fecha{
    display: flex;
    justify-content: center;
    text-align: center;
  }

  .botones-fecha{
    margin-bottom: 20px;
    margin-top: 20px;
  }

  .botones-fecha p {
    color: #707070;
    font-weight: 600;
    font-size: 10px;

    text-align: left;
    margin-bottom: 0
  }

  .botones-fecha .boton-dashboard{
    width: 80px;
    border-radius: 20px;
    border: none;
    background-color: white;
    color: #043161;
  }
  .botones-fecha .boton-dashboard:hover{
    border: none;
    background-color: #E65B38;
    color: white;
  }

  .documentos{
    cursor:pointer
  }

  


</style>