<template>
<b-row>
    <b-col sm="12" md="12" lg="6">
        <b-form-group
        class="text-left"
        label="Nombre" 
        label-for="nombre-input" 
    >
        <b-form-input id="nombre-input" v-model="form.nombre" min="3" max="190"  required>    
        </b-form-input>
        <div class="text-left">
        <span id="nombre"></span>     
        </div>
    </b-form-group>
    </b-col>
    
    <b-col sm="12" md="12" lg="6">
        <b-form-group
                class="text-left"
                label="Impotancia de la Aduana"
                label-for="importancia-select"
            >
            
            <select class="form-control border border-secondary"  id="importancia-select" v-model="form.peso"  required>
                <option value="1">Principal</option>
                <option value="0">Secundario</option>
                
            </select>
        </b-form-group>
    </b-col>
    <b-col cols="12">
        <button
            class="btn-principal btn-large m-2"
            type="submit"
        >Guardar</button>
        <slot></slot>
    </b-col>
    
    
    
    
    
    

    

    <!-- <input-region
        :form="form"
        :cols="cols"
        :user="user"
    
    >
    </input-region> -->
    
    
    
    
    

</b-row>
</template>

<script>
import InputRut from './InputRut.vue';
import InputRegion from './InputRegion.vue';
export default {
    components: { InputRut, InputRegion },
    name:"FormUsuario",

    props: {
        form: Object,
        user: Object,

    },
    data(){
        return{
            
        }
    },
    mounted(){
    },
    methods:{
      cambiarRut(value){
        this.form.rut = value;
      },
      //
    }
}
</script>

<style>

</style>