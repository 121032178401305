<template>
  <div id="charts" class="mt-2 mx-auto" >
    <slot name="ver"></slot>
      <apexchart
      class="mx-auto"
      v-if="series && series.length > 0"
        type="donut" height="250" :options="chartOptions" :series="series"
      />
  </div>
</template>

<script>
import Vue from "vue";

import VueApexCharts from "vue-apexcharts";
Vue.use(VueApexCharts);
Vue.component("apexchart", VueApexCharts);

export default {
  name: "DocumentosCharts",

  props:{
    completos:Number,
    faltantes:Number, 
  },

  data: function() {
    return {
      series: null, //ARREGLAR
      chartOptions: {
        labels: ['Completos', 'Faltantes'],
        chart: {
          width: 100,
          type: 'donut',
        },
        legend: {
        show: false
        },
        dataLabels: {
          enabled: true
        },
        fill: {
          colors: ['#5699F4' ,'#AA49DC']
        },
        responsive: [{
          breakpoint: 430,
          options: {
            chart: {
              width: 100
            },
            legend: {
              show: false,
            },
          }
        }]
      },
    };
  },
  mounted(){
    this.series = [this.completos,this.faltantes];
     
    if(screen.width <= 320) {
        this.height = '800'
    }
    else if(screen.width < 600) {
        this.height = '800'
    }else if(screen.width <=768){
        this.visorWidth = 550
        this.visorHeight = 690
    }
    else if (screen.width < 1024) {
        this.visorWidth = 700
        this.visorHeight = 690
    }
    else{
        this.visorWidth = 1000
        this.visorHeight = 890
    }
  },
  watch:{
      completos:function(newValue, oldValue){
        
          this.series = [this.completos,this.faltantes];
      },
      faltantes:function(newValue, oldValue){
          this.series = [this.completos,this.faltantes];
      }
    },

};
</script>

<style >

.apexcharts-canvas {
  margin: auto !important;
}

</style>
