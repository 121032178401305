<template>
    <form class="form-input" @submit.prevent="submit" id="form-pagos" enctype="multipart/form-data">
        <b-row>
            <!--
            <b-col sm="12" md="12" lg="3">
                <b-form-group
                    :content-cols="12"
                    label="TIPO DE PAGO"
                >
                    <select class="form-control border border-secondary"  id="documento-select"  v-model="tipo_pago"  >
                        <option value="0" disabled>Seleccionar Tipo de Pago</option>
                        <option v-for="(tipo,index) in pagos.tipos_pagos" :key="index" :value="tipo.id">{{tipo.nombre}}</option>
                    </select>
                </b-form-group>
            </b-col>
            -->
        <b-col sm="12" md="12" lg="3">
                <b-form-group
                    :content-cols="12"
                    label="TIPO DE DOCUMENTO CONTABLE"
                > 
                    <select class="form-control border border-secondary"  id="documento-select"  v-model="tipo_documento_contable">
                        <option value="0" disabled>Seleccionar Tipo de Documento Contable</option>
                        <option v-for="(tipo,index) in pagos.tipo_documento_contable" :key="index" :value="tipo">{{tipo}}</option>
                    </select>
                </b-form-group>
            </b-col> 
            <!--
            <b-col sm="12" md="12" lg="3">
                <b-form-group
                    :content-cols="12"
                    label="Monto"
                > 
                    <b-input type="number" v-model="monto">
                    </b-input>
                </b-form-group>
            </b-col>
            -->
            <b-col sm="12" md="12" lg="3">
                <b-form-group
                    :content-cols="12"
                    label="Fecha Documento"
                > 
                    <b-input type="date" v-model="fecha_pago">
                    </b-input>
                </b-form-group>
            </b-col>
            <b-col sm="12" md="12" lg="3">
                <b-form-group
                    :content-cols="12"
                    label="N° Documento"
                > 
                    <b-input type="number" v-model="nro_documento" min=0 value="" required>
                    </b-input>
                </b-form-group>
            </b-col>
            <b-col sm="12" md="12" lg="3">
                <b-form-group
                    :content-cols="12"
                    label="ADJUNTAR DOCUMENTO(S)"
                > 
                     
                    <b-form-file 
                        browse-text="Buscar"
                        placeholder="Seleccione un archivo"
                        drop-placeholder="Coloque el archivo aquí..."
                        accept="application/pdf"
                        v-model="file" ref="file-input" class="mb-2" multiple></b-form-file>

                    
                    <b-button class="btn-archivo" v-b-tooltip.hover :title="file.name" type="button" v-for="(file,index) in files" :key="index" @click="eliminar(file)">
                        
                        <inline-svg v-if="file.name.split('.').at(-1) == 'pdf'" src="../../assets/images/archivo-pdf.svg" />
                           <inline-svg 
                           v-if="file.name.split('.').at(-1) == 'png' || file.name.split('.').at(-1) == 'jpg' || file.name.split('.').at(-1) == 'jpeg'" 
                           src="../../assets/images/archivo-png.svg"/>
                    </b-button>
                </b-form-group>
            </b-col>
            
            <b-col class="text-right" cols="12">
                <b-button
                class="btn-principal btn-small mr-2"
                type="submit"
                >
                    Enviar
                </b-button>
                <slot name="cancelar"></slot>
            </b-col>
        </b-row>
    </form>
</template>

<script>
import axios from 'axios'
import moment from 'moment'
export default {
    props:{
        pagos: Object,

        postPago: Function,

        pagoEditar: Object,
        files_old: Array,
        cancelar: Function
    },
    data(){
        return{
            file: null,
            files: [],
            tipo_pago: '',
            tipo_documento_contable: '',
            nro_documento: 0,
            fecha_pago: '',

            idDocumento: '',
            //Archivos antiguos
            files_delete: [],
        }
    },
    mounted(){
        this.files = this.files_old
        this.setDocumentoEditar()
    },
    watch:{
        file:function(e){
            this.$refs['file-input'].files.forEach(el =>{
                this.files.push(el)
            })
            this.$refs['file-input'].reset()
        },
        files_old:function(e){
            this.files = this.files_old
        },
        documentoEditar:function(e){
            this.setDocumentoEditar()
            
        }
    },
    methods:{

        setDocumentoEditar(){
            this.idDocumento = this.pagoEditar.id
            //this.tipo_pago = this.pagoEditar.tipo.id
            this.tipo_documento_contable = this.pagoEditar.tipo_documento_contable
            this.nro_documento = this.pagoEditar.nro_documento
            this.fecha_pago = this.pagoEditar.fecha_pago
        },
        async eliminar(file){
            let variable = await this.$swal({
                    title: "¿Deseas eliminar este documento de pago?",
                    text: "¿Está seguro? ¡No podrás revertir esta acción!",
                    type: "warning",
                    showCancelButton: true,
                    confirmButtonColor: '#3085d6',
                    cancelButtonColor: '#d33',
                    confirmButtonText: 'Si, Borrar!',
                    cancelButtonText: 'No, cancelar!',
                    buttonsStyling: true
                  })
            if(variable.isConfirmed == true){
            if(file.id){ 
                this.files_delete.push(file.id)
            }
            
            let encontro = false
            this.files = this.files.filter(el => {
                if(file.id){
                    if(el.id) {
                        if(file.id != el.id) {
                            return el
                        }
                    }
                }
                if(!encontro){
                    if (el.name == file.name) {
                        encontro = true;
                    }else{
                        return el
                    }
                }else{
                    return el
                }
            })

            console.log(this.files)
            }

        },
        async submit(){
            
            let formData = new FormData();
            
            this.id = this.$route.params.despachoId;
            formData.append('despachos_id', this.id);
            formData.append('tipo_pagos_id', this.tipo_pago);
            formData.append('tipo_documento_contable', this.tipo_documento_contable);
            formData.append('nro_documento', this.nro_documento);
            formData.append('fecha_pago', this.fecha_pago);

            for(var i = 0; i < this.files_delete.length; i++){
                formData.append('delete_files['+i+']', this.files_delete[i]);
            }

            for( var i = 0; i < this.files.length; i++ ){
                let file = this.files[i];
                if(!file.id) formData.append('files['+ i +']', file);
            }

            
            
            formData.append('_method','put')

            
            if(this.files.length > 0 ) {
                
                this.cancelar()
                this.postPago(this.idDocumento,formData)
                
                this.id = ''
                this.tipo_pago = ''
                this.tipo_documento_contable = ''
                this.nro_documento = null
                this.fecha_pago = ''  
                this.files = []
                this.$refs['file-input'].files = []
            }
            else{
                this.$swal({
                    icon: 'warning',
                    title: '',
                    text: 'Se deben subir documentos para comprobar pago',
                });
            }
            
            
        }
    }
}
</script>

<style>

</style>