<template>
  <div id="charts" class="mt-2" style="border-right: 3px solid #5699F450">
    
    <div class="text-right mr-4">
      <slot name="ver"></slot>
    </div>
    <div class="barra-titulo">
      <h5>Vistos Buenos</h5>
    </div>
    <div class="conjunto-barras">
      <div class="barra">
          <div class="arriba comun" v-bind:style="{ height: altura_arriba + 'px',color:'#FFC72F', background:'#FFC72F' }">
              
              {{altura_arriba == 0 ? '' : '-'}}
          </div>
          <div class="abajo comun" v-bind:style="{ height: altura_abajo + 'px',color:'#02BE92', background:'#02BE92' }">
              {{altura_abajo == 0 ? '' : '-'}}
          </div>
      </div>
      <div class="barra barra-numero">
          <div class="arriba comun" v-bind:style="{ height: altura_arriba_num + 'px' }">
              {{pendientes}}
          </div>
          <div class="abajo comun" v-bind:style="{ height: altura_abajo_num + 'px' }">
              {{realizados}}
          </div>
      </div>
    </div>
    <div class="informacion">
      <p class="arriba" @click="irADespachosPendientes({estado:true,text:'Estado Vistos Buenos',value:'Pendiente',filtro:'visto_buenos-pendientes'})">Pendientes</p>
      <p class="realizados" @click="irADespachosPendientes({estado:true,text:'Estado Vistos Buenos',value:'Realizado',filtro:'visto_buenos-relizados'})">Realizados</p>
    </div>
  </div>
</template>

<script>


export default {
  name: "DocumentosCharts",
  props:{
    pendientes:Number,
    realizados:Number,
    irADespachosPendientes:Function,
    irADespachosRealizados:Function,
  },

  data: function() {
    return {
        arriba:5,
        abajo:25,
        total:30,

        altura:150,
        altura_arriba: 75,
        altura_abajo: 75,

        altura_num: 120,
        altura_arriba_num: 50,
        altura_abajo_num: 50
    };
  },
  mounted(){
    
    this.cambioBarras()
    
  },
  watch:{
      pendientes:function(newValue, oldValue){
        
          this.cambioBarras()
      },
      realizados:function(newValue, oldValue){
          this.cambioBarras()
      }
    },

  methods: {
    cambioBarras(){
      this.total = this.pendientes + this.realizados
    this.arriba = this.pendientes

    this.abajo = this.realizados
      let por_arriba = (this.arriba/this.total)*100
    this.altura_arriba = (por_arriba/ 100)*this.altura
    let por_abajo = (this.abajo/this.total)*100
    this.altura_abajo = (por_abajo/ 100)*this.altura

    por_arriba = (this.arriba/this.total)*100
    this.altura_arriba_num = (por_arriba/ 100)*this.altura_num
    por_abajo = (this.abajo/this.total)*100
    this.altura_abajo_num = (por_abajo/ 100)*this.altura_num
    }
  }
};
</script>

<style scoped>
  .arriba,.realizados{
    cursor: pointer;
  }
</style>
