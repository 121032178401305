<template>
  <div>
    <TitlePage title="USUARIOS" />
    <card
      title="Usuarios"
      :permiso="'users.manage'"
      :accionBoton="agregar"
      :boton="true"
      text="Agregar"
    >
      <!-- <modal :show="showEditar" id="modal-editar" :cerrarModal="cerrarModal"></modal> -->

      <div class="table-container">
        <b-col cols="12 text-right mt-2">
          <b-row class="text-right d-flex justify-content-end mx-1">
            <!-- nombre -->
            <b-form-group class="text-left mr-sm-2">
              <b-input
                type="text"
                v-model="filtro.nombre"
                required
                placeholder="filtro por nombre"
              >
              </b-input>
            </b-form-group>
            <!-- email -->
            <b-form-group class="text-left mr-sm-2">
              <b-input
                type="text"
                v-model="filtro.email"
                required
                placeholder="filtro por email"
              >
              </b-input>
            </b-form-group>
            <!-- roels -->
            <b-form-group
              v-if="roles"
              class="text-left mr-sm-2"
              label-for="rol-select"
            >
              <select
                class="form-control"
                id="rol-select"
                v-model="filtro.rol"
                required
              >
                <option value="">Seleccione un Rol</option>
                <option
                  v-for="(rol, index) in roles"
                  :key="index"
                  :value="rol.value"
                >
                  {{ rol.text }}
                </option>
              </select>
            </b-form-group>
            <button
              type="button"
              @click="borrarFiltro"
              class="btn-secundario btn-large mb-2"
            >
              <!--  v-if="formFiltro.length > 0  || filtro.estado == true" -->
              Limpiar
            </button>

            <button
              type="submit"
              class="ml-sm-2 btn-principal btn-large mb-2"
              @click="getUsers"
            >
              Filtrar
            </button>
          </b-row>
        </b-col>
        <b-table
          class="text-left"
          hover
          :items="items"
          :fields="fields"
          :busy="isBusy"
          responsive
        >
          <template #cell(id)="row">
            <div class="codigo-importacion" v-if="!row.item.deleted_at">
              <p class="">
                {{ row.item.id }}
              </p>
            </div>
            <div class="codigo-exportacion" v-else>
              <p>
                {{ row.item.id }}
              </p>
            </div>
          </template>
          <template #cell(state)="row">
            <div
              class="estado-despacho importacion"
              v-if="!row.item.deleted_at"
            >
              <p class="">Activo</p>
            </div>
            <div class="estado-despacho exportacion" v-else>
              <p>Eliminado</p>
            </div>
          </template>

          <template #cell(actions)="row">
            <button
              v-permisos="'users.manage'"
              class="btn-extra-secundario btn-small"
              v-if="row.item.deleted_at"
              size="sm"
              v-b-modal.modal-activar
              @click="activar(row.item)"
            >
              Activar
            </button>
            <button
              v-permisos="'users.manage'"
              class="btn-extra-primario btn-small mr-2"
              v-if="!row.item.deleted_at"
              size="sm"
              v-b-modal.modal-editar
              @click="editar(row.item, row.index, $event.target)"
            >
              Editar
              <inline-svg
                class="icono-titulo"
                src="../../assets/images/Editar.svg"
                title="My Image"
              />
            </button>
            <button
              v-permisos="'users.manage'"
              class="btn-extra-secundario btn-small"
              v-if="!row.item.deleted_at"
              size="sm"
              v-b-modal.modal-eliminar
              @click="eliminar(row.item, row.index, $event.target)"
            >
              Eliminar
              <inline-svg
                class="icono-titulo"
                src="../../assets/images/basura.svg"
                title="My Image"
              />
            </button>
          </template>

          <template #table-busy>
            <div class="text-center my-2" style="color: #ffce1e !important">
              <b-spinner class="align-middle"></b-spinner>
              <strong> Cargando...</strong>
            </div>
          </template>
        </b-table>
        <b-pagination
          v-model="currentPage"
          style="width: 100px; margin: auto"
          :total-rows="totalRows"
          :per-page="perPage"
          align="fill"
          size="sm"
          class="my-0"
        ></b-pagination>
      </div>
    </card>

    <modal-form-usuario
      :titulo="titulo"
      :form="form"
      :show="showEditar"
      :cerrarModal="cerrarModal"
      :submit="submit"
      :roles="roles"
      :password="password"
      :user="item"
      :cambiarPassword="cambiarPassword"
      :cambiarPasswordCondicion="cambiarPasswordCondicion"
    >
    </modal-form-usuario>

    <b-modal
      v-model="showEliminar"
      id="modal-eliminar"
      centered
      @hidden="cerrarModal()"
    >
      <template #modal-title> Eliminar usuario </template>
      <div class="d-block text-center">
        <h3>¿Desea eliminar al usuario "{{ nombreUsuarioEliminar }}"?</h3>
      </div>
      <template #modal-footer>
        <button
          type="button"
          class="btn-secundario btn-large"
          @click="confirmarEliminar()"
        >
          Eliminar
          <inline-svg
            class="icono-titulo"
            src="../../assets/images/basura.svg"
            title="My Image"
          />
        </button>
        <button
          type="button"
          class="btn-principal btn-large"
          @click="cerrarModal()"
        >
          Cancelar
        </button>
      </template>
    </b-modal>
    <b-modal
      v-model="showActivar"
      id="modal-activar"
      centered
      @hidden="cerrarModal()"
    >
      <template #modal-title> Activar usuario </template>
      <div class="d-block text-center">
        <h3>¿Desea activar al usuario "{{ nombreUsuarioEliminar }}"?</h3>
      </div>
      <template #modal-footer>
        <button
          type="button"
          class="btn-secundario btn-large"
          @click="confirmarActivar()"
        >
          Activar
        </button>
        <button
          type="button"
          class="btn-principal btn-large"
          @click="cerrarModal()"
        >
          Cancelar
        </button>
      </template>
    </b-modal>
  </div>
</template>

<script>
import axios from "axios";

import { mapGetters, mapActions } from "vuex";

import TitlePage from "../components/layout/TitlePage.vue";
import Card from "../components/ui/Card.vue";
import Modal from "../components/ui/Modal.vue";

import Permisos from "../constants/Permisos.js";
import ModalFormUsuario from "../components/modals/ModalFormUsuario.vue";

export default {
  components: { TitlePage, Card, Modal, ModalFormUsuario },
  computed: {
    ...mapGetters({
      permission: "auth/permission",
      user: "auth/user",
    }),
  },
  data() {
    return {
      showEditar: false,
      showEliminar: false,
      showActivar: false,
      password: false,
      titulo: "",
      currentPage: 1,
      totalRows: 0,
      perPage: 0,
      fields: [
        { key: "id", label: "#" },
        { key: "name", label: "Nombre" },
        { key: "email", label: "Email" },
        { key: "role.display_name", label: "Rol" },
        { key: "phone", label: "Teléfono" },
        { key: "state", label: "Estado" },
        { key: "actions", label: "Acciones" },
      ],
      items: [],
      isBusy: false,
      filtro: {
        email: "",
        nombre: "",
        rol: "",
      },
      roles: [],
      form: {
        id: "",
        name: "",
        username: "",
        email: "",
        email_confirmation: "",
        password: "",
        password_confirmation: "",
        rol: "",
        phone: "",
        rut: "",
        region: "",
        comuna_id: "",
        address: "",
      },
      regions: [],
      comunas: [],

      nombreUsuarioEliminar: "",
      idUsuarioEliminar: 0,

      rutDisponible: false,

      item: {},

      cambiarPasswordCondicion: false,
    };
  },
  mounted() {
    this.getUsers();
    this.getRoles();
  },

  watch: {
    currentPage: function (newValue, oldValue) {
      this.getUsers();
    },
  },
  methods: {
    ...mapActions({
      signOutAction: "auth/signOut",
    }),
    //LLAMADAS API
    async getUsers() {
      this.toggleBusy();
      await axios
        .get(`/api/admin/users`, {
          params: {
            page: this.currentPage,
            filtro_nombre: this.filtro.nombre,
            filtro_email: this.filtro.email,
            filtro_rol: this.filtro.rol,
          },
        })
        .then((resp) => {
          this.items = resp.data.data;

          this.currentPage = resp.data.meta.current_page;
          this.totalRows = resp.data.meta.total;
          this.perPage = resp.data.meta.per_page;
          this.toggleBusy();
        })
        .catch((err) => {
          //   if (err.response.status == 401) {
          //       this.signOutAction()
          //       this.$router.replace({ name: 'SignIn' })
          // }
          this.toggleBusy();
        });
    },

    async getRoles() {
      await axios
        .get("/api/admin/users/role-list")
        .then((resp) => {
          this.actualizarRoles(resp.data.roles);
        })
        .catch((err) => {
          // if (err.response.status == 401) {
          //       this.signOutAction()
          //       this.$router.replace({ name: 'SignIn' })
          // }
          console.log(err);
        });
    },
    async confirmarEliminar() {
      await axios
        .delete(`/api/admin/users/${this.idUsuarioEliminar}`)
        .then((resp) => {
          this.getUsers();
        })
        .catch((err) => {
          console.log(err);
          this.$swal({
            icon: "error",
            title: "¡Error!",
            text: "Lo sentimos, ha ocurrido un error, por favor intente nuevamente",
          });
        });
      this.cerrarModal();
    },
    async confirmarActivar() {
      await axios
        .post(`/api/admin/users/restore/${this.idUsuarioEliminar}`)
        .then((resp) => {
          this.getUsers();
        })
        .catch((err) => {
          console.log(err);
          this.$swal({
            icon: "error",
            title: "¡Error!",
            text: "Lo sentimos, ha ocurrido un error, por favor intente nuevamente",
          });
        });
      this.cerrarModal();
    },

    async submit() {
      if (this.form.id != "") {
        await axios
          .put(`api/admin/users/${this.form.id}`, this.form)
          .then((response) => {
            this.metodoThen();
          })
          .catch((error) => {
            this.metodoCatch(error);
          });
      } else {
        await axios
          .post(`api/admin/users`, this.form)
          .then((response) => {
            this.metodoThen();
          })
          .catch((error) => {
            this.metodoCatch(error);
          });
      }
    },
    //METODOS ACTUALIZAR
    actualizarRoles(roles) {
      roles.forEach((rol) => {
        this.roles.push({ value: rol.name, text: rol.display_name });
      });
    },
    //Modificacion variables otros componentes
    cambiarPassword() {
      this.password = !this.password;
    },
    //
    agregar(item, index, button) {
      this.form.name = "";
      this.form.username = "";
      this.form.email = "";
      this.form.email_confirmation = "";
      this.form.phone = "";
      this.form.rut = "";
      this.form.region = "";
      this.form.comuna_id = "";
      this.form.id = "";
      this.form.password = "";
      this.form.password_confirmation = "";
      this.form.roles = "Superuser";

      this.password = true;
      this.cambiarPasswordCondicion = false;

      this.item = null;
      this.showEditar = true;
      this.titulo = "Agregar Usuario";
    },

    editar(item, index, button) {
      this.item = item;

      this.form.name = item.name;
      this.form.username = item.username;
      this.form.email = item.email;
      this.form.email_confirmation = item.email;
      this.form.phone = item.phone;
      this.form.rut = item.rut + "" + item.dv;
      this.form.region = item.region;
      this.form.comuna_id = item.comuna_id;
      this.form.id = item.id;
      this.form.rol = item.role.name;
      this.form.address = item.address;

      this.form.password = undefined;
      this.form.password_confirmation = undefined;

      this.password = false;
      this.cambiarPasswordCondicion = true;
      this.showEditar = true;

      this.titulo = "Editar Usuario";
    },

    eliminar(item, index, button) {
      this.nombreUsuarioEliminar = item.name;
      this.idUsuarioEliminar = item.id;
      this.showEliminar = true;
      this.titulo = "Eliminar Usuario";
    },
    activar(item, index, button) {
      this.nombreUsuarioEliminar = item.name;
      this.idUsuarioEliminar = item.id;
      this.showActivar = true;
      this.titulo = "Activar Usuario";
    },

    //GENERICOS API
    metodoCatch(error) {
      if (error.response) {
        Object.keys(error.response.data.errors).forEach((key) => {
          if (document.getElementById(`${key}`)) {
            document.getElementById(`${key}`).innerHTML =
              error.response.data.errors[key];
          }
        });
      }
    },
    metodoThen() {
      this.cerrarModal();
      this.getUsers();
    },
    //GENERICOS
    cerrarModal() {
      this.showEditar = false;
      this.showEliminar = false;
      this.showActivar = false;
    },
    tienePermisos(permisoBuscar) {
      if (this.permission.roles.includes(permisoBuscar)) return true;
      return false;
    },
    toggleBusy() {
      this.isBusy = !this.isBusy;
    },

    borrarFiltro() {
      this.filtro.email = "";
      this.filtro.nombre = "";
      this.filtro.rol = "";
      this.getUsers();
    },
  },
};
</script>

<style>
.boton-editar {
  width: 70px;
  height: 30px;

  font-size: 12px !important;

  border: none !important;
  border-radius: 20px !important;
  background: #ffce1e !important;

  opacity: 0.8;

  transition: opacity 0.5s !important;
}

.boton-editar:hover {
  border: none !important;
  opacity: 1 !important;
  background: #ffce1e !important;
}

.boton-eliminar {
  width: 70px;
  height: 30px;

  font-size: 12px !important;

  border: 1px solid #e35f42 !important;
  border-radius: 20px !important;
  background: transparent !important;

  color: #e35f42 !important;
  transition: background-color 0.5s !important;
}

.boton-eliminar:hover {
  border: 1px solid #e35f42 !important;
  background: red21 !important;
}
</style>
