<template>
</template>

<script>
import axios from 'axios'
export default {
    async created(){
        await axios.get('/api/s/'+ this.$route.params.shortLink).then(resp => {
            this.$router.push(resp.data.link) 
        }).catch(err => {
            console.log(err)
            this.$router.push('/dashboard') 
        })
    }
}
</script>

<style>

</style>