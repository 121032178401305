<template>
<li class="li-item" @click="cambiarFiltro({estado:false, texto:'-',value:'-',filtro:'-'})" v-bind:class="{ 'seccion-activa': opciones.activo }">
    <router-link v-bind:to="{name: opciones.ruta}" >
        <fas :icon="['fas', opciones.icon]"></fas>
        {{opciones.title}}    
    </router-link> 
</li>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
export default {
    name: "Opcion",
    props:{
        opciones: Object, 
    },
    methods:{
        ...mapActions({
          cambiarFiltro: 'filtros/cambiarFiltro'
        }),
    }
}
</script>

<style>
</style>