

export default {
  namespaced: true,

  state: {
    filtro: {estado:false,text:'-',valor:'-'},
    fecha: 3,
  },

  getters: {
    filtro (state) {
      return state.filtro
    },
    fecha (state) {
      return state.fecha
    },

  },

  mutations: {
    SET_FILTRO (state, value) {
      state.filtro = value
    },
    SET_FECHA (state, value) {
      state.fecha = value
    },

  },

  actions: {
    

    cambiarFiltro ({  commit }, tipo_filtro) {
      commit('SET_FILTRO', tipo_filtro)
    },
    cambiarFecha ({  commit }, fecha) {
      commit('SET_FECHA', fecha)
    },

  

  

    
  }
}