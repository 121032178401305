<template>

    
<b-tabs content-class="mt-3" class="tabs-secciones" >
            <b-tab  title="Informacion General" @click="cambiarSeccion('general')">
                <template #title>
                    <div class="title-tab">

                        <inline-svg  class="icono-titulo" src="../../assets/images/general.svg" title="My Image"/>
                        <p class=" titulo text-center"> Información General</p>
                    </div>
                </template>
                <informacion-general
                    v-if="secciones.general"
                    :despacho="despacho"
                    :postInformacionGeneral="postInformacionGeneral"
                    :informacionGeneral="informacionGeneral"
                    tipo="operaciones"
                >
                </informacion-general>             
            </b-tab>
            <b-tab title="Asignar Usuarios" @click="cambiarSeccion('usuarios')">
                <template #title>
                    <div class="title-tab">

                        <inline-svg  class="icono-titulo" src="../../assets/images/usuarios.svg" title="My Image"/>
                        <p class=" titulo text-center">Asignar Usuarios</p>
                    </div>
                </template>
                <usuarios
                    v-if="listaUsuarios && secciones.usuarios"
                    :agregarUsuario="true"
                    :usuarios="usuariosPorRol"
                    :listaUsuarios="listaUsuarios"
                    :tiposUsuarios="tipoUsuariosPorRol"
                    :changeForm="changeForm"
                    :submit="submit"

                    :addUser="addUser"
                    :deleteUser="deleteUser"
                >
                    
                </usuarios>

            </b-tab>
            <b-tab title="Documentación" @click="cambiarSeccion('documentos')">
                <template #title>
                    <div class="title-tab">
                        <inline-svg  class="icono-titulo" src="../../assets/images/documento.svg" title="My Image"/>
                        <p class=" titulo text-center">Documentación</p>
                    </div>
                </template>
                <documentos
                    v-if="todosDocumentos.tipos_documentos && secciones.documentos"
                    :agregarDocumento="true"
                    :postDocumento="postDocumento"
                    :updateDocumento="updateDocumento"
                    :documentosTabla="documentosTabla"
                    :documentos="todosDocumentos"
                    :eliminarDocumento="eliminarDocumento"
                    
                    :eliminarDocumentos ="eliminarDocumentos"
                    :showDeleteDocumentos = "showDeleteDocumentos"
                    :actualizarDocumentos= "actualizarDocumentos"
                    :confirmarDeleteDocumentos= "confirmarDeleteDocumentos"
                    :cerrarModal="cerrarModal"
                    :metodoThen= "metodoThen"
                    :metodoCatch="metodoCatch"
                >

                </documentos>

            </b-tab>
            <b-tab title="Pagos" @click="cambiarSeccion('pagos')">
                <template #title>
                    <div class="title-tab">
                        <inline-svg  class="icono-titulo" src="../../assets/images/dollar.svg" title="My Image"/>
                        <p class="titulo text-center" > Pagos</p>
                    </div>
                </template>
                <pagos
                         v-if="secciones.pagos"
                         :despacho="despacho"
                        :agregarPago="true"
                        :postPago="postPago"
                        :updatePago="updatePago"
                        :pagosTabla="pagosTabla"
                        :pagos="todosPagos"
                        :getComprobante="getComprobante"
                        :eliminarPago="eliminarPago"
                    >

                    </pagos>

            </b-tab>
            <b-tab title="Detalle Carga" @click="cambiarSeccion('carga')">
                <template #title>
                    <div class="title-tab">
                        <inline-svg  class="icono-titulo" src="../../assets/images/containers.svg" title="My Image"/>
                        <p class=" titulo text-center" > Detalle Carga</p>
                    </div>
                </template>
                <contenedores
                v-if="secciones.carga"
                    :permiteAgregarContenedor="true"
                    :despacho="despacho"
                ></contenedores>

            </b-tab>
            
    </b-tabs>
  
</template>

<script>
import axios from 'axios'
import FormAsignarUsuario from '../../../formulario/FormAsignarUsuario.vue'

import { mapActions, mapGetters } from 'vuex';
import FormDocumento from '../../../formulario/FormDocumento.vue';
import Usuarios from '../../Componentes/Usuarios.vue';
import InformacionGeneral from '../../Componentes/InformacionGeneral.vue';
import Contenedores from '../../Componentes/Contenedores.vue';
import Documentos from '../../Componentes/Documentos.vue';
import Pagos from '../../Componentes/Pagos.vue';

export default {
  components: { FormAsignarUsuario, FormDocumento, Usuarios, InformacionGeneral, Contenedores, Documentos, Pagos },
    
    props:{
        despacho:Object,
        listaUsuarios: Object,
        submit: Function,
        changeForm:Function,
        changeValue:Function,
        estados:Object,
        usuarios:Array,

        changeState:Function,
        addUser:Function,
        deleteUser: Function,

        //DOCUMENTOS
        documentos: Object,
        documentosTabla:Array,
        postDocumento: Function,
        updateDocumento: Function,
        eliminarDocumento: Function,

        showDeleteDocumentos: Boolean,
        eliminarDocumentos: Function,
        actualizarDocumentos: Function,
        confirmarDeleteDocumentos: Function,
        cerrarModal: Function,
        metodoThen: Function,
        metodoCatch: Function,

        //Informacion General
        postInformacionGeneral: Function,
        informacionGeneral: Object,

         //PAGOS
        pagos: Object,
        pagosTabla:Array,
        postPago: Function,
        updatePago: Function,
        eliminarPago: Function,
        getComprobante: Function
    },
    mounted(){
        this.usuariosPorRol = []
        this.usuarios.forEach(el => {
            if(el.role.name == 'administrativa') this.usuariosPorRol.push(el)
            if(el.role.name == 'tramitador_aduanero') this.usuariosPorRol.push(el)
        })

        this.documentosPorRol = []
        this.documentos.tipos_documentos.forEach(el =>{
            this.documentosPorRol.push(el)
        });
        this.todosDocumentos = {
            tipos_documentos: this.documentosPorRol,
            bl : this.documentos.bl,
            documento_transporte: this.documentos.documento_transporte,
            tipos_vistos_buenos: this.documentos.tipos_vistos_buenos
        }
    },
    watch:{
        usuarios:function(newValue, oldValue){
            if(this.usuarios){
                this.usuariosPorRol = []
            this.usuarios.foreach(el => {
                if(el.role.name == 'administrativa') this.usuariosPorRol.push(el)
                if(el.role.name == 'tramitador_aduanero') this.usuariosPorRol.push(el)
            })
            }
        },
        documentos:function(newValue, oldValue){
            if(this.documentos){
                this.documentosPorRol = [];
                this.documentos.tipos_documentos.forEach(el =>{
                    this.documentosPorRol.push(el)
                });
                this.todosDocumentos = {
                tipos_documentos: this.documentosPorRol,
                bl : this.documentos.bl,
                documento_transporte: this.documentos.documento_transporte,
                tipos_vistos_buenos: this.documentos.tipos_vistos_buenos
            }
            }
        }
    },
    data(){
        return{
            usuariosPorRol:[],
            todosPagos: {},

            documentosPorRol: [],

            

            todosDocumentos: {},

            tipoUsuariosPorRol: [
                {text:'Garantías y Correcciones', value:'administrativa'},
                {text:'Tramitador Aduanero', value:'tramitador_aduanero'},
                {text:'Jefe de operaciones', value:'jefe_operaciones'}
            ],

            secciones: 
            {
                general: true,
                carga: false,
                pagos: false,
                usuarios: false,
                documentos: false,
            },
        }
    },
    methods:{
        //secciones
    cambiarSeccion(seccion){
      Object.keys(this.secciones).forEach(key => {

        if(key == seccion)this.secciones[key] = true;
        else this.secciones[key] = false
      });
    }
    }
}
</script>

<style>

</style>