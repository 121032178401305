<template>
  <div id="charts" class="mt-2">
    
      <apexchart
      v-if="series"
        type="bar" height="250" :options="chartOptions" 
        :series="series"
      />
  </div>
</template>

<script>
import Vue from "vue";

import VueApexCharts from "vue-apexcharts";
Vue.use(VueApexCharts);
Vue.component("apexchart", VueApexCharts);

export default {
  name: "BrushCharts",
  props:{
    fechas:Array,
    total:Array,
    exportacion: Array,
    importacion: Array,
  },
  data: function() {
    return {
          
          series: null,
          chartOptions: {
            chart: {
              type: 'bar',
              height: 350
            },
            plotOptions: {
              bar: {
                horizontal: false,
                columnWidth: '55%',
                endingShape: 'rounded'
              },
            },
            dataLabels: {
              enabled: false
            },
            stroke: {
              show: true,
              width: 2,
              colors: ['transparent']
            },
            xaxis: {
              labels: {
                formatter: function (value) {
                  return value;
                }
              }
            },
            fill: {
              opacity: 1
            },
            tooltip: {
              y: {
                formatter: function (val) {
                  return "" + val 
                }
              }
            }
          },
          
          
    };
  },
  mounted(){
    this.series =[
      {
        name: 'Total',
        data: this.total
      }, 
      {
        name: 'Importacion',
        data: this.importacion
      }, 
      {
        name: 'Exportacion',
        data: this.exportacion
      }
    ]
    this.chartOptions.xaxis.categories = this.fechas
  },
  updated(){
    this.chartOptions.xaxis.categories = this.fechas
  },
   watch:{
      fechas:function(newValue, oldValue){
          this.chartOptions.xaxis.categories = this.fechas
          this.series =[
            {
              name: 'Total',
              data: this.total
              
            }, 
            {
              name: 'Importacion',
              data: this.importacion
            }, 
            {
              name: 'Exportacion',
              data: this.exportacion
            }
          ]
          
      },
    },

  methods: {
    
  }
};
</script>

<style scoped>
#chart1,
#chart2 {
  max-width: 650px;
  margin: 35px auto;
}

#chart2 {
  position: relative;
  margin-top: -70px;
  margin-bottom: 0px;
}

#app {
  padding-top: 20px;
  padding-left: 10px;
  background: #fff;
  border: 1px solid #ddd;
  box-shadow: 0 22px 35px -16px rgba(0, 0, 0, 0.1);
  max-width: 650px;
  margin: 35px auto;
}
</style>
