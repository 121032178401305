<template>
  <b-col sm="12" md="12" lg="9" class="contenedor-informacion mx-auto" >
        
        <div class="table-container">
            <b-form class="form-input" @submit.prevent="addUser(usuario)">

                <form-asignar-usuario
                   v-if="agregarUsuario && listaUsuariosPorTipo"
                    :listaUsuarios="listaUsuarios"
                    :tiposUsuario="tiposUsuarios"

                    :usuario="usuario"
                    :tipoUsuario="tipoUsuario"
                    :usuarios="listaUsuariosPorTipo"

                    :changeTipo="changeTipo"
                    :changeUser="changeUser"
                >
                </form-asignar-usuario>             
            </b-form>
            <b-table class="text-left" hover responsive :items="usuarios" :fields="fields" show-empty empty-text="No hay usuarios asignados">
                
                <template #cell(actions)="row" >
                    <b-button :disabled="row.item.rol == 'gestor' || row.item.rol == 'pedidor'"   @click="deleteUser(row.item.id, row.item.name)"  class=" btn-extra-primario btn-small">
                    Eliminar <inline-svg  class="icono-titulo" src="../../assets/images/basura.svg" title="My Image"/>
                    </b-button>
                </template>
            </b-table>
        </div>
    </b-col>
</template>

<script>
import FormAsignarUsuario from '../../formulario/FormAsignarUsuario.vue'


export default {
  components: { FormAsignarUsuario },
  props:{
      agregarUsuario:Boolean,
        usuarios:Array,

        tiposUsuarios:Array,
        listaUsuarios:Object,
        
        form:Object,
        submit: Function,

        addUser: Function,
        deleteUser: Function
  },
  data(){
        return{
        fields: [
            {key:'role.display_name', label: 'ROL'},
            {key:'name', label: 'Nombre'},
            {key:'email', label: 'Email'},
            {key:'actions', label: 'Acciones'},
        ],
        currentPage: 1,
        totalRows: 0,
        perPage: 0,
        tipoUsuario:'',
        usuario:0,
        listaUsuariosPorTipo:[]
            
        }
  },
  mounted(){
      if(!this.agregarUsuario) this.fields.pop();
  },
  methods:{
    changeUser(id){
        this.usuario = id
        return id
    },
    changeTipo(tipo){
        this.listaRoles(tipo)
        return tipo
    },
    listaRoles(rol){
        switch(rol){
            case 'gestor':
                this.listaUsuariosPorTipo = this.listaUsuarios.gestor
                break;
            case 'pedidor':
                this.listaUsuariosPorTipo = this.listaUsuarios.pedidores
                break;
            case 'administrativa':
                this.listaUsuariosPorTipo = this.listaUsuarios.administrativa
                break;
            case 'prestador':
                this.listaUsuariosPorTipo = this.listaUsuarios.prestadores
                break;
            case 'tramitador_servicios':
                this.listaUsuariosPorTipo = this.listaUsuarios.tramitador_servicios
                break;
            case 'tramitador_aduanero':
                this.listaUsuariosPorTipo = this.listaUsuarios.tramitadores_aduaneros
                break;
            case 'facturador':
                this.listaUsuariosPorTipo = this.listaUsuarios.facturadores
                break;
            case 'jefe_operaciones':
                this.listaUsuariosPorTipo = this.listaUsuarios.jefe_operaciones
                break;
            case 'jefe_facturacion':
                this.listaUsuariosPorTipo = this.listaUsuarios.jefe_facturacion
                break;
            case 'revisor':
                this.listaUsuariosPorTipo = this.listaUsuarios.revisor
                break;
        }
    }
  }

}
</script>

<style>

</style>