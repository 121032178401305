<template>
    <b-form class="form-input">
        <b-row>
            <b-col cols="12">
                
                <b-row :key="index" v-for="(contenedor,index) in form">
                    <b-col cols="12 text-left mt-3 ">
                        <h5 class="codigo-exportacion">Nuevo Contenedor</h5>
                    </b-col>
                    
                    <!--- CONTENEDOR -->
                    <b-col sm="12" md="12" lg="2" v-if="caracteristica_carga == 'CONTENEDOR'">
                        <b-form-group
                            class="text-left"
                            
                            label="Variedad"
                        >
                            <select class="form-control border border-secondary"  :id='contenedor.id +"-variedad-select"' v-model="contenedor.variedad"  required>
                                <option value="0" disabled>Selecciona el Tipo de Variedad</option>
                                <option :key="index" v-for="(variedad, index) in variedades" :value="variedad">{{variedad}}</option>
                            </select>
                        </b-form-group>
                    </b-col>
                    <b-col sm="12" md="12" lg="2" v-if="caracteristica_carga == 'CONTENEDOR'">
                        <b-form-group
                            class="text-left"
                            label="Identificador"
                        >
                            <b-input type="text"  v-model="contenedor.identificacion" :maxlength="255" required>

                            </b-input>
                        </b-form-group>
                    </b-col>
                    <!--- CONTENEDOR -->

                    <!--- CARGA SUELTA -->

                    <b-col sm="12" md="12" lg="2" v-if="caracteristica_carga == 'CARGA SUELTA'">
                        <b-form-group
                            class="text-left"
                            label="Bultos"
                        >
                            <b-input type="text"  v-model="contenedor.bultos" :maxlength="255" required>

                            </b-input>
                        </b-form-group>
                    </b-col>
                    <b-col sm="12" md="12" lg="2" v-if="caracteristica_carga == 'CARGA SUELTA'">
                        <b-form-group
                            class="text-left"
                            label="Kilos"
                        >
                            <b-input type="text"  v-model="contenedor.kilos" :maxlength="255" required>

                            </b-input>
                        </b-form-group>
                    </b-col>
                    <b-col sm="12" md="12" lg="2" v-if="caracteristica_carga == 'CARGA SUELTA'">
                        <b-form-group
                            class="text-left"
                            label="CBM"
                        >
                            <b-input type="text"  v-model="contenedor.CBM" :maxlength="255" required>

                            </b-input>
                        </b-form-group>
                    </b-col>

                    <!--- CARGA SUELTA -->

                    <!--- VEHÍCULOS -->
                    <b-col sm="12" md="12" lg="2" v-if="caracteristica_carga == 'VEHÍCULOS'">
                        <b-form-group
                            class="text-left"
                            label="Cantidad de Vehículos"
                        >
                            <b-input type="text"  v-model="contenedor.cantidad" :maxlength="255" required>

                            </b-input>
                        </b-form-group>
                    </b-col>
                    <!--- VEHÍCULOS -->

                    <!--- FIERROS -->
                    <b-col sm="12" md="12" lg="2" v-if="caracteristica_carga == 'FIERROS'">
                        <b-form-group
                            class="text-left"
                            label="Cantidad de Fierros"
                        >
                            <b-input type="text"  v-model="contenedor.cantidad" :maxlength="255" required>

                            </b-input>
                        </b-form-group>
                    </b-col>
                    <!--- FIERROS -->

                    <!--- GRANELES -->
                    <b-col sm="12" md="12" lg="2" v-if="caracteristica_carga == 'GRANELES'">
                        <b-form-group
                            class="text-left"
                            label="Volumen"
                        >
                            <b-input type="text"  v-model="contenedor.volumen" :maxlength="255" required>

                            </b-input>
                        </b-form-group>
                    </b-col>
                    <!--- GRANELES -->
                    
                    <b-col sm="12" md="12" lg="4">
                        <b-form-group
                            class="text-left"
                            label="Fecha de Retiro"
                        >
                            <b-form-datepicker 
                                label-next-month="Siguiente mes"
                                label-next-year="Siguiente año"
                                label-current-month="Mes actual"
                                label-prev-year="Año anterior"
                                label-prev-month="Mes anterior"
                                label-prev-decade="Decada anterior"
                                label-next-decade="Decada siguiente"


                                label-no-date-selected="No hay fecha seleccionada"
                                label-help="Usa el cursos para navegar por las fechas"
                              v-model="contenedor.fecha_retiro" class="mb-2" ></b-form-datepicker>
                        </b-form-group>
                    </b-col>
                    <b-col sm="12" md="12" lg="3">
                        <b-form-group
                            class="text-left"
                            cols="6"
                            label="Hora de Retiro"
                        >
                              <b-form-timepicker 
                                label-no-time-selected="No hay hora seleccionada"
                                label-close-button="Cerrar"
                                label-hours="Horas"
                                label-minutes="Minutes"
                              v-model="contenedor.hora_retiro" locale="es"></b-form-timepicker>
                        </b-form-group>
                    </b-col>
                    <b-col sm="12" md="12" lg="2">
                        <b-form-group
                            class="text-left"
                            cols="6"
                            label="Patente Transporte"
                        >
                            <b-input type="text" v-model="contenedor.patente" :maxlength="255" required>

                            </b-input>
                        </b-form-group>
                    </b-col>
                    <b-col sm="12" md="12" lg="2">
                        <b-form-group
                            class="text-left"
                            cols="6"
                            label="Asociar Guía Despacho"
                        >
                            <select class="form-control border border-secondary"  id="guia_despacho-select" v-model="contenedor.documentos_id"  required>
                                <option value="0" disabled>Selecciona el Guia de Despacho Asociado</option>
                                <option :key="index" v-for="(documento, index) in documentos" :value="documento.id">{{documento.base ? documento.base.numero_guia_despacho: '' }} - {{documento.tipo.nombre}}</option>
                                
                            </select>
                        </b-form-group>
                    </b-col>
                    <b-col sm="12" md="12" lg="2">
                        <b-form-group
                            class="text-left"
                            cols="6"
                            label="Asociar Pago"
                        >
                            <select class="form-control border border-secondary"  id="agente_cargas-select" v-model="contenedor.pagos_id"  required>
                                <option value="0" disabled>Selecciona el Pago Asociado</option>
                                <option :key="index" v-for="(pago, index) in pagos" :value="pago.id">{{pago.tipo.nombre}} - {{moment(pago.fecha_pago).format('DD-MM-YYYY')}}</option>
                                
                            </select>
                        </b-form-group>
                    </b-col>
                    <b-col cols="2" class="text-right" style="margin-top:25px">
                        <b-button
                            class="btn-secundario btn-small"
                            type="button"
                            @click="borrarContenedor(contenedor.id)"
                        >
                            <inline-svg  class="icono-titulo" src="../../assets/images/basura.svg" title="My Image"/>
                            Borrar
                        </b-button>
                    </b-col>
                </b-row>
                
            </b-col>
            <b-col class="text-right">
                <b-button
                    class="btn-principal btn-small"
                    type="button"
                    @click="agregarContendor()"
                >
                    Agregar
                </b-button>
            </b-col>
            
        </b-row>
        <b-row>
            <b-col>
                <b-button

                    class="btn-principal btn-large mt-2"
                    type="button"

                    @click="postContenedor()"
                >
                    Enviar Formulario
                </b-button>
            </b-col>
        </b-row>
        
        
        
        
        
        
        
    </b-form>
</template>

<script>
import moment from 'moment';
import axios from 'axios'
export default {
    props:{
        caracteristica_carga: String,
        form: Array,
        agregarContendor: Function,
        borrarContenedor:Function,
        postContenedor:Function,

        variedades: Array,
        pagos:Array,
        documentos:Array
    },
    data(){
        return{

            file:[],
            variedad_id: '',

            idContenedor: '',
        }
    },
  mounted(){
  },
    methods:{
        eliminar(id,file){
            
            this.form.forEach(el => {
                if(el.id == id) {
                    let files = [];
                    el.archivos.forEach(arc => {
                        console.log(arc.name == file.name)
                        if(arc.name != file.name) files.push(arc)
                    });
                    el.archivos = files
                }
            })
            

        },
        documento(id){
            this.idContenedor = id
            
        }
    }
}
</script>

<style>

</style>