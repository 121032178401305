<template>
    
    <b-container fluid>
        <b-row align-v="center" align-h="center" class="h100 modal-inicial">
            <b-col class="custom-col">
                <!-- card -->
                <b-card class="login-card">
                    <!-- card header -->
                    <template #header>
                        <div class="container">
                          <inline-svg src="../assets/images/logo_aduanas.svg" title="My Image"/>
                            <p class="ingreso">RECUPERAR CONTRASEÑA</p>
                            <p class="m0 sistema"><b>SISTEMA DE GESTIÓN DE DESPACHOS</b></p>
                        </div>
                    </template>

                    <!-- form -->
                    <b-form @submit.prevent="submit">
                        <!-- Usuario -->
                         <b-row>
                          <b-col cols="12">
                        <b-form-group
                            id="grupo_nombre"
                            label="Email"
                            label-for="email"
                        >
                          <b-form-input
                              id="email"
                              v-model="form.email"
                              placeholder="Ingresar email"
                              required>
                          </b-form-input>
                        </b-form-group>
                         </b-col>
                         <b-col cols="12">
                        <b-form-group
                            id="grupo_nombre"
                            label="Nueva Contraseña"
                            label-for="contrasena"
                        >
                          <b-form-input
                              id="contrasena"
                              v-model="form.password"
                              type="password"
                              placeholder="Nueva contraseña"
                              required>
                          </b-form-input>
                        </b-form-group>
                         </b-col>
                          <b-col cols="12">
                            <b-form-group
                              id="grupo_nombre"
                              label="Confirmar contraseña"
                              label-for="confirmar"
                            >
                              <b-form-input
                                id="confirmar"
                                v-model="form.password_confirmation"
                                type="password"
                                placeholder="Confirme contraseña"
                                required>
                              </b-form-input>
                            </b-form-group>
                          </b-col>
                          <b-col cols="12">
                            <b-button 
                            type="submit"
                            size="lg"
                            >
                            Ingresar
                            </b-button>
                          </b-col>
                          <b-col cols="12" class="text-left mt-2">
                            <router-link v-bind:to="{name: 'SignIn'}">Iniciar Sesión</router-link>
                          </b-col>
                        </b-row> 
                    </b-form>
                </b-card>
            </b-col>
        </b-row>
    </b-container>
    
</template>


<script>
  import { mapActions } from 'vuex'

  export default {
    name: 'ResetPassword',

    data () {
      return {
        form: {
          email: '',
          password: '',
          password_confirmation: '',
          token: ''
        }
      }
    },

    methods: {
      ...mapActions({
        reset: 'auth/reset'
      }),

      async submit () {
        
        const valores = window.location.search;
        const urlParams = new URLSearchParams(valores);
        const token = urlParams.get('token')

        this.form.token = token

        await this.reset(this.form).then(resp => {
          this.$router.replace({ name: 'SignIn' })
        }).catch(err => {
          this.$swal({
            icon: 'error',
            title: '¡Error!',
            text: 'Lo sentimos, ha ocurrido un error. Intente nuevamente',
          });
        })

        
      }
    }
  }
</script>
<style>

  .modal-inicial{
    position:absolute;
    left:0;
    right:0;
    margin-left:auto;
    margin-right:auto;
  }

</style>