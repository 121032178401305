<template>
    
    <b-container fluid>
        <b-row align-v="center" align-h="center" class="h100 modal-inicial">
            <b-col class="custom-col">
                <!-- card -->
                <b-card class="login-card">
                    <!-- card header -->
                    <template #header>
                        <div class="container">
                          <inline-svg src="../assets/images/logo_aduanas.svg" title="My Image"/>
                            <p class="ingreso">RECUPERAR CONTRASEÑA</p>
                            <p class="m0 sistema"><b>SISTEMA DE GESTIÓN DE DESPACHOS</b></p>
                        </div>
                    </template>

                    <!-- form -->
                    <b-form @submit.prevent="submit">
                        <!-- Usuario -->
                        <b-form-group
                        class="input-login"
                            id="grupo_nombre"
                            label="Email"
                            label-for="email"
                        >
                            <b-form-input
                                id="email"
                                v-model="form.email"
                                placeholder="Ingresar email"
                                required>
                            </b-form-input>
                        </b-form-group>


                        <div class="row">
                            
                            <div class="col-12">
                                <b-button 
                                type="submit"
                                size="lg"
                                >Ingresar</b-button>
                            </div>
                            <b-col cols="12" class="text-left mt-2">
                              <router-link v-bind:to="{name: 'SignIn'}">Iniciar Sesión</router-link>
                            </b-col>
                        </div>
                    </b-form>
                </b-card>
            </b-col>
        </b-row>
    </b-container>
    
</template>


<script>
  import { mapActions } from 'vuex'
  import Modal from '../../components/ui/Modal.vue'

  export default {
    name: 'Recuperar',
    components: {
      Modal
    },

    data () {
      return {
        nombre: '',
        form: {
          email: '',
          password: '',
        }
      }
    },

    methods: {
      ...mapActions({
        recuperar: 'auth/recuperar'
      }),

      async submit () {
        await this.recuperar(this.form).then(resp => {
        }).catch(err => {
          this.$swal({
            icon: 'error',
            title: '¡Error!',
            text: 'Lo sentimos, ha ocurrido un error. Intente nuevamente',
          });
        })
      }
    }
  }
</script>
