<template>

    <b-tabs content-class="mt-3" class="tabs-secciones" >
            <b-tab  title="Informacion General" @click="cambiarSeccion('general')" >
                <template #title>
                    <div class="title-tab">

                        <inline-svg class="icono-titulo" src="../../assets/images/general.svg" title="My Image"/>
                        <p class=" titulo text-center">Información General</p>
                    </div>
                </template>
                <b-container fluid v-if="secciones.general">
                    <b-row  v-if="despacho.tipo_despacho == 'exportacion'">   
                        <b-col sm="12" md="12" lg="6" class="contenedor-informacion">
                            <b-row>
                                <b-col cols="12" class="informacion">
                                    <b-row>
                                        <b-col cols="6" class="text-left dato">
                                            <p>N° Despacho</p>
                                        </b-col>
                                        <b-col cols="6" class="text-right dato despacho">
                                            <p>{{despacho.codigo}}</p>
                                        </b-col>
                                    </b-row>
                                </b-col> 
                                <b-col cols="12" class="informacion">
                                    <b-row>
                                        <b-col cols="6" class="text-left dato">
                                            <p>Despacho Padre</p>
                                        </b-col>
                                        <b-col cols="6" class="text-right dato despacho">
                                            <a target="_blank" :href="despachoPadre + despacho.padre_id" v-if="despacho.padre_id">{{despachoPadre}}  {{despacho.padre_id}}</a>
                                            <p v-else> - </p>
                                        </b-col>
                                    </b-row>
                                </b-col>   
                                <b-col cols="12" class="informacion">
                                    <b-row>
                                        <b-col cols="6" class="text-left dato">
                                            <p>Cod Agente</p>
                                        </b-col>
                                        <b-col cols="6" class="text-right dato despacho">
                                            <p>{{despacho.cod_agente}}</p>
                                        </b-col>
                                    </b-row>
                                </b-col>
                                <b-col cols="12" class="informacion">
                                    <b-row>
                                        <b-col cols="6" class="text-left dato">
                                            <p>Cliente</p>
                                        </b-col>
                                        <b-col cols="6" class="text-right dato despacho">
                                            <p>{{despacho.cliente.nombre}}</p>
                                        </b-col>
                                    </b-row>
                                </b-col>  
                                <b-col cols="12" class="informacion">
                                    <b-row>
                                        <b-col cols="6" class="text-left dato">
                                            <p>Referencia Cliente</p>
                                        </b-col>
                                        <b-col cols="6" class="text-right dato despacho">
                                            <p>{{despacho.ref_cliente}}</p>
                                        </b-col>
                                    </b-row>
                                </b-col> 
                                <b-col cols="12 informacion">
                                    <b-row>
                                        <b-col cols="6" class="text-left dato">
                                            <p>Fecha Creacion</p>
                                        </b-col>
                                        <b-col cols="6" class="text-right dato despacho">
                                            <p>{{moment(despacho.fecha_inicio).format('DD-MM-YYYY')}}</p> 
                                        </b-col>
                                    </b-row>
                                </b-col>
                                <b-col cols="12 informacion">
                                    <b-row>
                                        <b-col cols="6" class="text-left dato">
                                            <p>Estado Despacho</p>
                                        </b-col>
                                        <b-col cols="6" class="text-right dato despacho">
                                            <p>{{despacho.estado_despacho}}</p> 
                                        </b-col>
                                    </b-row>
                                </b-col>      
                                <b-col cols="12 informacion">
                                    <b-row>
                                        <b-col cols="6" class="text-left dato">
                                            <p>Tipo de Despacho</p>
                                        </b-col>
                                        <b-col cols="6" class="text-right dato despacho">
                                            <p>{{despacho.tipo_despacho}}</p>
                                        </b-col>
                                    </b-row>
                                </b-col>
                                <b-col cols="12 informacion">
                                    <b-row>
                                        <b-col cols="6" class="text-left dato">
                                            <p>Estado Retiro</p>
                                        </b-col>
                                        <b-col cols="6" class="text-right dato despacho">
                                            <p v-if="despacho.estado_retiro">{{despacho.estado_retiro}}</p> 
                                            <p v-else>-</p> 
                                        </b-col>
                                    </b-row>
                                </b-col>
                                    <b-col cols="12 informacion">
                                    <b-row>
                                        <b-col cols="6" class="text-left dato">
                                            <p>Aforo Dus Legalizado</p>
                                        </b-col>
                                        <b-col cols="6" class="text-right dato despacho">
                                            <p v-if="despacho.tipo.aforo_dus_legalizado">{{despacho.tipo.aforo_dus_legalizado}}</p> 
                                            <p v-else>-</p> 
                                        </b-col>
                                    </b-row>
                                </b-col>
                                
                                <b-col cols="12 informacion">
                                    <b-row>
                                        <b-col cols="6" class="text-left dato">
                                            <p>Aforo Zona Primaria</p>
                                        </b-col>
                                        <b-col cols="6" class="text-right dato despacho">
                                            <p v-if="despacho.tipo.aforo_zona_primaria">{{despacho.tipo.aforo_zona_primaria}}</p> 
                                            <p v-else>-</p> 
                                        </b-col>
                                    </b-row>
                                </b-col>
                                <b-col cols="12 informacion">
                                    <b-row>
                                        <b-col cols="6" class="text-left dato">
                                            <p>Estado Dus Legalizado</p>
                                        </b-col>
                                        <b-col cols="6" class="text-right dato despacho">
                                            <p v-if="despacho.tipo.dus_legalizado">{{despacho.tipo.dus_legalizado}}</p> 
                                            <p v-else>-</p> 
                                        </b-col>
                                    </b-row>
                                </b-col>
                                <b-col cols="12 informacion">
                                    <b-row>
                                        <b-col cols="6" class="text-left dato">
                                            <p>Estado Dus Primer Envio</p>
                                        </b-col>
                                        <b-col cols="6" class="text-right dato despacho">
                                            <p v-if="despacho.tipo.dus_primer_envio">{{despacho.tipo.dus_primer_envio}}</p> 
                                            <p v-else>-</p> 
                                        </b-col>
                                    </b-row>
                                </b-col>
                                <b-col cols="12 informacion">
                                    <b-row>
                                        <b-col cols="6" class="text-left dato">
                                            <p>Número Reserva</p>
                                        </b-col>
                                        <b-col cols="6" class="text-right dato despacho">
                                            <p v-if="despacho.tipo.numero_reserva">{{despacho.tipo.numero_reserva}}</p> 
                                            <p v-else>-</p> 
                                        </b-col>
                                    </b-row>
                                </b-col>
                                <b-col cols="12 informacion">
                                    <b-row>
                                        <b-col cols="6" class="text-left dato">
                                            <p>Aduana</p>
                                        </b-col>
                                        <b-col cols="6" class="text-right dato despacho">
                                            <p v-if="despacho.tipo.aduana">{{despacho.tipo.aduana.nombre}}</p> 
                                            <p v-else>-</p> 
                                        </b-col>
                                    </b-row>
                                </b-col>
                                <b-col cols="12 informacion">
                                    <b-row>
                                        <b-col cols="6" class="text-left dato">
                                            <p>Puerto / Aereo </p>
                                        </b-col>
                                        <b-col cols="6" class="text-right dato despacho">
                                            <p v-if="despacho.tipo.extraportuario">{{despacho.tipo.extraportuario.nombre}}</p> 
                                            <p v-else>-</p> 
                                        </b-col>
                                    </b-row>
                                </b-col>

                            </b-row>
                        </b-col>
                        <b-col sm="12" md="12" lg="6" class="contenedor-informacion">
                            <b-row>
                                <b-col cols="12 informacion">
                                    <b-row>
                                        <b-col cols="6" class="text-left dato">
                                            <p>Estado Presentacion Matriz</p>
                                        </b-col>
                                        <b-col cols="6" class="text-right dato despacho">
                                            <p v-if="despacho.tipo.estado_presentacion_matriz">{{despacho.tipo.estado_presentacion_matriz}}</p> 
                                            <p v-else>-</p> 
                                        </b-col>
                                    </b-row>
                                </b-col>
                                <b-col cols="12 informacion">
                                    <b-row>
                                        <b-col cols="6" class="text-left dato">
                                            <p>Requiere Vistos Buenos</p>
                                        </b-col>
                                        <b-col cols="6" class="text-right dato despacho">
                                            <p v-if="despacho.requiere_vistos_buenos">Si</p> 
                                            <p v-else>No</p> 
                                        </b-col>
                                    </b-row>
                                </b-col>
                                <b-col cols="12 informacion">
                                    <b-row>
                                        <b-col cols="6" class="text-left dato">
                                            <p>Fecha Aforo Dus Legalizado</p>
                                        </b-col>
                                        <b-col cols="6" class="text-right dato despacho">
                                            <p v-if="despacho.tipo.fecha_aforo_dus_legalizado">{{moment(despacho.tipo.fecha_aforo_dus_legalizado).format('DD-MM-YYYY')}}</p> 
                                            <p v-else>-</p> 
                                        </b-col>
                                    </b-row>
                                </b-col>
                                <b-col cols="12 informacion">
                                    <b-row>
                                        <b-col cols="6" class="text-left dato">
                                            <p>Fecha Aforo Zona Primaria</p>
                                        </b-col>
                                        <b-col cols="6" class="text-right dato despacho">
                                            <p v-if="despacho.tipo.fecha_aforo_zona_primaria">{{moment(despacho.tipo.fecha_aforo_zona_primaria).format('DD-MM-YYYY')}}</p> 
                                            <p v-else>-</p> 
                                        </b-col>
                                    </b-row>
                                </b-col>
                                <b-col cols="12 informacion">
                                    <b-row>
                                        <b-col cols="6" class="text-left dato">
                                            <p>Fecha Ingreso Zona Primaria</p>
                                        </b-col>
                                        <b-col cols="6" class="text-right dato despacho">
                                            <p v-if="despacho.tipo.fecha_ingreso_zona_primaria">{{moment(despacho.tipo.fecha_ingreso_zona_primaria).format('DD-MM-YYYY')}}</p> 
                                            <p v-else>-</p> 
                                        </b-col>
                                    </b-row>
                                </b-col>
                                <b-col cols="12 informacion">
                                    <b-row>
                                        <b-col cols="6" class="text-left dato">
                                            <p>Fecha Salida Programada</p>
                                        </b-col>
                                        <b-col cols="6" class="text-right dato despacho">
                                            <p v-if="despacho.tipo.fecha_salida_programada">{{moment(despacho.tipo.fecha_salida_programada).format('DD-MM-YYYY')}}</p> 
                                            <p v-else>-</p> 
                                        </b-col>
                                    </b-row>
                                </b-col>
                                <b-col cols="12 informacion">
                                    <b-row>
                                        <b-col cols="6" class="text-left dato">
                                            <p>Medio de Transporte</p>
                                        </b-col>
                                        <b-col cols="6" class="text-right dato despacho">
                                            <p v-if="despacho.tipo.nombre_transporte">{{despacho.tipo.nombre_transporte}}</p> 
                                            <p v-else>-</p> 
                                        </b-col>
                                    </b-row>
                                </b-col>
                                <b-col cols="12 informacion">
                                    <b-row>
                                        <b-col cols="6" class="text-left dato">
                                            <p>Nombre de Nave</p>
                                        </b-col>
                                        <b-col cols="6" class="text-right dato despacho">
                                            <p v-if="despacho.tipo.nombre_nave !== null">{{despacho.tipo.nombre_nave}}</p> 
                                            <p v-else>-</p> 
                                        </b-col>
                                    </b-row>
                                </b-col>
                                <b-col cols="12 informacion" v-if="despacho.tipo.nombre_transporte == 'MARÍTIMO'" hidden>
                                    <b-row>
                                        <b-col cols="6" class="text-left dato">
                                            <p>Lugar Bl</p>
                                        </b-col>
                                        <b-col cols="6" class="text-right dato despacho">
                                            <p v-if="despacho.lugar_bl">{{despacho.lugar_bl}}</p> 
                                            <p v-else>-</p> 
                                        </b-col>
                                    </b-row>
                                </b-col>
                            </b-row>
                        </b-col>
                    </b-row>
                    <b-row  v-if="despacho.tipo_despacho == 'importacion'">   
                        <b-col sm="12" md="12" lg="6" class="contenedor-informacion">
                            <b-row>
                                <b-col cols="12" class="informacion">
                                    <b-row>
                                        <b-col cols="6" class="text-left dato">
                                            <p>N° Despacho</p>
                                        </b-col>
                                        <b-col cols="6" class="text-right dato despacho">
                                            <p>{{despacho.codigo}}</p>
                                        </b-col>
                                    </b-row>
                                </b-col>
                                <b-col cols="12" class="informacion">
                                    <b-row>
                                        <b-col cols="6" class="text-left dato">
                                            <p>Despacho Padre</p>
                                        </b-col>
                                        <b-col cols="6" class="text-right dato despacho">
                                            <a href="" @click="ver(despacho.padre_id, $event.target)"  v-if="despacho.padre_id">{{despacho.padre_id}}</a>
                                            <p v-else>-</p>
                                        </b-col>
                                    </b-row>
                                </b-col>
                                <b-col cols="12" class="informacion">
                                    <b-row>
                                        <b-col cols="6" class="text-left dato">
                                            <p>Cliente</p>
                                        </b-col>
                                        <b-col cols="6" class="text-right dato despacho">
                                            <p>{{despacho.cliente.nombre}}</p>
                                        </b-col>
                                    </b-row>
                                </b-col>  
                                <b-col cols="12" class="informacion">
                                    <b-row>
                                        <b-col cols="6" class="text-left dato">
                                            <p>Referencia Cliente</p>
                                        </b-col>
                                        <b-col cols="6" class="text-right dato despacho">
                                            <p>{{despacho.ref_cliente}}</p>
                                        </b-col>
                                    </b-row>
                                </b-col> 
                                 <b-col cols="12" class="informacion">
                                    <b-row>
                                        <b-col cols="6" class="text-left dato">
                                            <p>Cod Agente</p>
                                        </b-col>
                                        <b-col cols="6" class="text-right dato despacho">
                                            <p>{{despacho.cod_agente}}</p>
                                        </b-col>
                                    </b-row>
                                </b-col>  
                                <b-col cols="12 informacion">
                                    <b-row>
                                        <b-col cols="6" class="text-left dato">
                                            <p>Fecha Creacion</p>
                                        </b-col>
                                        <b-col cols="6" class="text-right dato despacho">
                                            <p>{{moment(despacho.fecha_inicio).format('DD-MM-YYYY')}}</p> 
                                        </b-col>
                                    </b-row>
                                </b-col>       
                                <b-col cols="12 informacion">
                                    <b-row>
                                        <b-col cols="6" class="text-left dato">
                                            <p>Estado Despacho</p>
                                        </b-col>
                                        <b-col cols="6" class="text-right dato despacho">
                                            <p>{{despacho.estado_despacho}}</p> 
                                        </b-col>
                                    </b-row>
                                </b-col>                             
                                <b-col cols="12 informacion">
                                    <b-row>
                                        <b-col cols="6" class="text-left dato">
                                            <p>Gestor de Despacho</p>
                                        </b-col>
                                        <b-col cols="6" class="text-right dato despacho">
                                            <p v-if="despacho.gestores.legth > 0">{{despacho.gestores[0].name}}</p>
                                            <p v-else>-</p>
                                        </b-col>
                                    </b-row>
                                </b-col>
                                <b-col cols="12 informacion">
                                    <b-row>
                                        <b-col cols="6" class="text-left dato">
                                            <p>Tipo de Despacho</p>
                                        </b-col>
                                        <b-col cols="6" class="text-right dato despacho">
                                            <p>{{despacho.tipo_despacho}}</p>
                                        </b-col>
                                    </b-row>
                                </b-col>
                                <b-col cols="12 informacion">
                                    <b-row>
                                        <b-col cols="6" class="text-left dato">
                                            <p>Estado Retiro</p>
                                        </b-col>
                                        <b-col cols="6" class="text-right dato despacho">
                                            <p v-if="despacho.estado_retiro">{{despacho.estado_retiro}}</p> 
                                            <p v-else>-</p> 
                                        </b-col>
                                    </b-row>
                                </b-col>
                                    <b-col cols="12 informacion">
                                    <b-row>
                                        <b-col cols="6" class="text-left dato">
                                            <p>Fecha Eta</p>
                                        </b-col>
                                        <b-col cols="6" class="text-right dato despacho">
                                            <p v-if="despacho.tipo.fecha_eta">{{moment(despacho.tipo.fecha_eta).format('DD-MM-YYYY')}}</p> 
                                            <p v-else>-</p> 
                                        </b-col>
                                    </b-row>
                                </b-col>
                                
                                <b-col cols="12 informacion">
                                    <b-row>
                                        <b-col cols="6" class="text-left dato">
                                            <p>Aduana</p>
                                        </b-col>
                                        <b-col cols="6" class="text-right dato despacho">
                                            <p v-if="despacho.tipo.aduana">{{despacho.tipo.aduana.nombre}}</p> 
                                            <p v-else>-</p> 
                                        </b-col>
                                    </b-row>
                                </b-col>
                                <b-col cols="12 informacion">
                                    <b-row>
                                        <b-col cols="6" class="text-left dato">
                                            <p>Extraportuario</p>
                                        </b-col>
                                        <b-col cols="6" class="text-right dato despacho">
                                            <p v-if="despacho.tipo.extraportuario">{{despacho.tipo.extraportuario.nombre}}</p> 
                                            <p v-else>-</p> 
                                        </b-col>
                                    </b-row>
                                </b-col>
                                <b-col cols="12 informacion">
                                    <b-row>
                                        <b-col cols="6" class="text-left dato">
                                            <p>Manifiesto</p>
                                        </b-col>
                                        <b-col cols="6" class="text-right dato despacho">
                                            <p v-if="despacho.tipo.manifiesto">{{despacho.tipo.manifiesto.nombre}}</p> 
                                            <p v-else>-</p> 
                                        </b-col>
                                    </b-row>
                                </b-col>
                            </b-row>
                        </b-col>
                        <b-col sm="12" md="12" lg="6" class="contenedor-informacion">
                            <b-row>
                                <b-col cols="12 informacion">
                                    <b-row>
                                        <b-col cols="6" class="text-left dato">
                                            <p>Tipo de Aforo</p>
                                        </b-col>
                                        <b-col cols="6" class="text-right dato despacho">
                                            <p v-if="despacho.aforo == 2">Sin Aforo</p> 
                                            <p v-if="despacho.aforo == 1">Examen Físico</p> 
                                            <p v-if="despacho.aforo == 0">Examen Documental</p> 
                                        </b-col>
                                    </b-row>
                                </b-col>
                                <b-col cols="12 informacion">
                                    <b-row>
                                        <b-col cols="6" class="text-left dato">
                                            <p>Requiere Vistos Buenos</p>
                                        </b-col>
                                        <b-col cols="6" class="text-right dato despacho">
                                            <p v-if="despacho.requiere_vistos_buenos">Si</p> 
                                            <p v-else>No</p> 
                                        </b-col>
                                    </b-row>
                                </b-col>
                                <b-col cols="12 informacion">
                                    <b-row>
                                        <b-col cols="6" class="text-left dato">
                                            <p>Medio de Transporte</p>
                                        </b-col>
                                        <b-col cols="6" class="text-right dato despacho">
                                            <p v-if="despacho.tipo.nombre_transporte">{{despacho.tipo.nombre_transporte}}</p> 
                                            <p v-else>-</p> 
                                        </b-col>
                                    </b-row>
                                </b-col>
                                <b-col cols="12 informacion">
                                    <b-row>
                                        <b-col cols="6" class="text-left dato">
                                            <p>Nombre de Nave</p>
                                        </b-col>
                                        <b-col cols="6" class="text-right dato despacho">
                                            <p v-if="despacho.tipo.nombre_nave !== null">{{despacho.tipo.nombre_nave}}</p> 
                                            <p v-else>-</p> 
                                        </b-col>
                                    </b-row>
                                </b-col>
                                <b-col cols="12 informacion" v-if="despacho.tipo.nombre_transporte == 'MARÍTIMO'" hidden>
                                    <b-row>
                                        <b-col cols="6" class="text-left dato">
                                            <p>Lugar Bl</p>
                                        </b-col>
                                        <b-col cols="6" class="text-right dato despacho">
                                            <p v-if="despacho.lugar_bl">{{despacho.lugar_bl}}</p> 
                                            <p v-else>-</p> 
                                        </b-col>
                                    </b-row>
                                </b-col>
                                <b-col cols="12 informacion">
                                    <b-row>
                                        <b-col cols="6" class="text-left dato">
                                            <p>Numero Manifiesto</p>
                                        </b-col>
                                        <b-col cols="6" class="text-right dato despacho">
                                            <p v-if="despacho.tipo.numero_manifiesto">{{despacho.tipo.numero_manifiesto}}</p> 
                                            <p v-else>-</p> 
                                        </b-col>
                                    </b-row>
                                </b-col>
                                <b-col cols="12 informacion">
                                    <b-row>
                                        <b-col cols="6" class="text-left dato">
                                            <p>Agente Cargas</p>
                                        </b-col>
                                        <b-col cols="6" class="text-right dato despacho">
                                            <p v-if="despacho.tipo.agente_cargas">{{despacho.tipo.agente_cargas.nombre}}</p> 
                                            <p v-else>-</p> 
                                        </b-col>
                                    </b-row>
                                </b-col>
                                
                                <b-col cols="12 informacion">
                                    <b-row>
                                        <b-col cols="6" class="text-left dato">
                                            <p>Compañia Naviera</p>
                                        </b-col>
                                        <b-col cols="6" class="text-right dato despacho">
                                            <p v-if="despacho.tipo.compania_naviera">{{despacho.tipo.compania_naviera.nombre}}</p> 
                                            <p v-else>-</p> 
                                        </b-col>
                                    </b-row>
                                </b-col> 
                                
                                <b-col cols="12 informacion">
                                    <b-row>
                                        <b-col cols="6" class="text-left dato">
                                            <p>Tipo Condición</p>
                                        </b-col>
                                        <b-col cols="6" class="text-right dato despacho">
                                            <p v-if="despacho.tipo.tipo_condicion">{{despacho.tipo.tipo_condicion}}</p> 
                                            <p v-else>-</p> 
                                        </b-col>
                                    </b-row>
                                </b-col>
                                <b-col cols="12 informacion">
                                    <b-row>
                                        <b-col cols="6" class="text-left dato">
                                            <p>Datos Transporte</p>
                                        </b-col>
                                        <b-col cols="6" class="text-right dato despacho">
                                            <p v-if="despacho.transporte">{{despacho.transporte}} / {{despacho.correo_transporte}}</p> 
                                            <p v-else>-</p> 
                                        </b-col>
                                    </b-row>
                                </b-col>
                            </b-row>
                        </b-col>
                    </b-row> 
                </b-container>
            </b-tab>
            <b-tab title="Asignar Usuarios" @click="cambiarSeccion('usuarios')">
                <template #title>
                    <div class="title-tab">

                        <inline-svg class="icono-titulo" src="../../assets/images/usuarios.svg" title="My Image"/>
                        <p class="titulo text-center">Asignar Usuarios</p>
                    </div>
                </template>
                <usuarios
                    v-if="secciones.usuarios"
                    :agregarUsuario="false"
                    :usuarios="usuarios"
                >

                </usuarios>

            </b-tab>
            <b-tab title="" @click="cambiarSeccion('documentos')">
                <template #title>
                    <div class="title-tab">

                        <inline-svg class="icono-titulo" src="../../assets/images/documento.svg" title="My Image"/>
                        <p class="titulo text-center">Documentación</p>
                    </div>
                </template>
               <documentos
                    v-if="documentosTabla && secciones.documentos"
                    :agregarDocumento="false"
                    :documentosTabla="documentosTabla"
                    :eliminarDocumentos ="eliminarDocumentos"
                    :showDeleteDocumentos = "showDeleteDocumentos"
                    :actualizarDocumentos= "actualizarDocumentos"
                    :confirmarDeleteDocumentos= "confirmarDeleteDocumentos"
                    :cerrarModal="cerrarModal"
                    :metodoThen= "metodoThen"
                    :metodoCatch="metodoCatch"
                >

                </documentos>
            </b-tab>
            <b-tab title="CDE" @click="cambiarSeccion('cde')">
                <template #title>
                    <div class="title-tab">

                        <inline-svg class="icono-titulo" src="../../assets/images/documento.svg" title="My Image"/>
                        <p class="titulo text-center">CDE</p>
                    </div>
                </template>
               <cde
                    v-if="secciones.cde"
                >
                </cde>
            </b-tab>
            <b-tab title="Pagos" @click="cambiarSeccion('pagos')">
                <template #title>
                    <div class="title-tab">
                        <inline-svg  class="icono-titulo" src="../../assets/images/dollar.svg" title="My Image"/>
                        <p class="titulo text-center" > Pagos</p>
                    </div>
                </template>
                <pagos
                    v-if="pagosTabla && secciones.pagos"
                    :agregarPago="false"
                    :despacho="despacho"
                    :getComprobante="getComprobante"
                    :pagosTabla="pagosTabla"
                    :canManage="canManage"
                >
                </pagos>

            </b-tab>
            <b-tab title="Detalle Carga" @click="cambiarSeccion('carga')">
                <template #title>
                    <div class="title-tab">
                        <inline-svg  class="icono-titulo" src="../../assets/images/containers.svg" title="My Image"/>
                        <p class=" titulo text-center" > Detalle Carga</p>
                    </div>
                </template>
                <contenedores
                    v-if="secciones.carga"
                    :permiteAgregarContenedor="false"
                    :despacho="despacho"
                    :canManage="canManage"
                >

                </contenedores>

            </b-tab>

            
    </b-tabs>
    

  
</template>

<script>
import axios from 'axios'
import FormAsignarUsuario from '../../../formulario/FormAsignarUsuario.vue'

import moment from 'moment'
import { mapActions, mapGetters } from 'vuex';
import Usuarios from '../../Componentes/Usuarios.vue';
import Cde from '@/components/Despachos/Componentes/cde/cde.vue';
import Documentos from '../../Componentes/Documentos.vue';
import Pagos from '../../Componentes/Pagos.vue';
import Contenedores from '../../Componentes/Contenedores.vue';
export default {
  components: { FormAsignarUsuario, Usuarios, Documentos,Pagos, Contenedores, Cde},
    props:{
        despacho:Object,
        submit: Function,
        canManage:{type: Function, default: ()=>{return true}},
        usuarios:Array,

        documentosTabla:Array,
        pagosTabla:Array,
        montos:Object,
        getComprobante:Function,

        eliminarDocumentos: Function,
        showDeleteDocumentos: Boolean,
        actualizarDocumentos: Function,
        confirmarDeleteDocumentos: Function,
        cerrarModal: Function,
        metodoThen: Function,
        metodoCatch: Function,
        

    },
    data(){

        return{
            despachoRoute: axios.defaults.baseURL + "api/admin/despachos/",
            despachoPadreRoute: axios.defaults.baseURL + "api/admin/despachos/perfil/",
            secciones: 
            {
                general: true,
                carga: false,
                pagos: false,
                usuarios: false,
                documentos: false,
                cde: false,
            },
        }
    },
    methods:{
        //secciones
        cambiarSeccion(seccion){
        Object.keys(this.secciones).forEach(key => {

            if(key == seccion)this.secciones[key] = true;
            else this.secciones[key] = false
            
        });
        },
        ver(item, button) {

          const routeData = this.$router.resolve({ name: 'PerfilDespacho', params:{despachoId: item} })
          window.open(routeData.href,'_blank')
        },
    }
}
</script>

<style>

</style>