<template>
<b-row>
    <b-col cols="12 informacion">
        <b-row v-if="despacho.tipo.agente_cargas && estados.agente_cargas">
            <b-col cols="4" class="text-left dato">
                <p>Agente de Cargas</p>
            </b-col>
            <b-col cols="4" class="text-right dato despacho" >
                <p v-if="despacho.tipo.agente_cargas">{{despacho.tipo.agente_cargas.nombre}}</p> 
                <p v-else>-</p> 
            </b-col>
            <b-col
                cols="4" class="text-right dato despacho" 
            >
                <b-button
                v-if="pantalla == true"
                    class="btn-principal btn-small"
                    @click="editar('administrativa','agente_cargas')"
                >
                    Editar <inline-svg  class="icono-titulo" src="../../assets/images/Editar.svg" title="My Image"/>
                </b-button>
                <b-button
                v-if="pantalla == false"
                    @click="editar('administrativa','agente_cargas')"
                    class="btn-principal-chico"
                >
                    <inline-svg  class="icono-titulo" src="../../assets/images/EditarAzul.svg" title="My Image"/>
                </b-button>
            </b-col>
        </b-row>
        <b-row v-else>
            <b-col 
                cols="12" class="text-right dato despacho" 
            >
                <form class="form-input">
                    <b-row>
                        <b-col sm="12" md="12" lg="6">
                            <b-form-group
                                class="text-left"
                                label="Seleccionar un Agente de Carga"
                                label-for="agente_cargas-select"
                            >
                            <select class="form-control border border-secondary"  id="agente_cargas-select" v-model="agente_cargas_id"  required>
                                <option value="0" disabled>Selecciona un Agente de Carga</option>
                                <option :key="index" v-for="(agente_cargas, index) in informacionGeneral.agente_cargas" :value="agente_cargas.id">{{agente_cargas.nombre}}</option>
                                
                            </select>
                            </b-form-group>
                        </b-col>
                        <b-col sm="12" md="12" lg="6">
                            <b-button
                                class="btn-extra-primario btn-small"
                                type="button"
                                @click="editar('administrativa','agente_cargas',true,{'agente_cargas_id': agente_cargas_id})"
                            >
                                Guardar
                            </b-button>
                            <b-button
                                class="btn-extra-secundario btn-small"
                                type="button"
                                @click="editar('administrativa','agente_cargas')"
                            >
                                Cancelar
                            </b-button>
                        </b-col>
                    </b-row>
                </form>
            </b-col>
        </b-row>
        <b-row v-if="despacho.tipo.compania_naviera && estados.compania_naviera">
            <b-col cols="4" class="text-left dato">
                <p>Compañia Naviera</p>
            </b-col>
            <b-col cols="4" class="text-right dato despacho" >
                <p v-if="despacho.tipo.compania_naviera">{{despacho.tipo.compania_naviera.nombre}}</p> 
                <p v-else>-</p> 
            </b-col>
            <b-col
                cols="4" class="text-right dato despacho" 
            >
                <b-button
                v-if="pantalla == true"
                    class="btn-principal btn-small"
                    @click="editar('administrativa','compania_naviera')"
                >
                    Editar <inline-svg  class="icono-titulo" src="../../assets/images/Editar.svg" title="My Image"/>
                </b-button>
                <b-button
                v-if="pantalla == false"
                    @click="editar('administrativa','compania_naviera')"
                    class="btn-principal-chico"
                >
                    <inline-svg  class="icono-titulo" src="../../assets/images/EditarAzul.svg" title="My Image"/>
                </b-button>
            </b-col>
        </b-row>
        <b-row v-else>
            <b-col 
                cols="12" class="text-right dato despacho" 
            >
                <form class="form-input">
                    <b-row>
                        <b-col sm="12" md="12" lg="6">
                            <b-form-group
                                class="text-left"
                                label="Seleccionar Compañia Naviera"
                                label-for="importancia-select"
                            >
                            <select class="form-control border border-secondary"  id="importancia-select" v-model="compania_naviera"  required>
                                <option value="0" disabled>Selecciona una Compañia Naviera</option>
                                <option :key="index" v-for="(compania_naviera, index) in informacionGeneral.companias_navieras" :value="compania_naviera.id">{{compania_naviera.nombre}}</option>
                                
                            </select>
                            </b-form-group>
                        </b-col>
                        <b-col sm="12" md="12" lg="6">
                            <b-button
                                class="btn-extra-primario btn-small"
                                type="button"
                                @click="editar('administrativa','compania_naviera',true,{'companias_navieras_id': compania_naviera})"
                            >
                                Guardar
                            </b-button>
                            <b-button
                                class="btn-extra-secundario btn-small"
                                type="button"
                                @click="editar('administrativa','compania_naviera')"
                            >
                                Cancelar
                            </b-button>
                        </b-col>
                    </b-row>
                </form>
            </b-col>
        </b-row>
        <b-row v-if="despacho.tipo.manifiesto && estados.manifiesto">

            <b-col cols="4" class="text-left dato">
                <p>Manifiesto</p>
            </b-col>
            <b-col cols="4" class="text-right dato despacho" >
                <p v-if="despacho.tipo.manifiesto">{{despacho.tipo.manifiesto.nombre}}</p> 
                <p v-else>-</p> 
            </b-col>
            <b-col cols="4" class="text-right dato despacho" >
                <b-button
                v-if="pantalla == true"
                    @click="editar('administrativa','manifiesto')"
                    class="btn-principal btn-small"
                >
                    Editar <inline-svg  class="icono-titulo" src="../../assets/images/Editar.svg" title="My Image"/>
                </b-button>
                <b-button
                v-if="pantalla == false"
                    @click="editar('administrativa','manifiesto')"
                    class="btn-principal-chico"
                >
                    <inline-svg  class="icono-titulo" src="../../assets/images/EditarAzul.svg" title="My Image"/>
                </b-button>
            </b-col>
        </b-row>
        <b-row v-else>
            
            <b-col 
                
                cols="12" class="text-right dato despacho" 
            >
                <form class="form-input">
                    <b-row>
                        <b-col sm="12" md="12" lg="6">
                            <b-form-group
                                class="text-left"
                                label="Manifiesto"
                                label-for="importancia-select"
                            >
                            <select class="form-control border border-secondary"  id="importancia-select" v-model="manifiesto"  required>
                                <option value="0" disabled>Selecciona un Manifiesto</option>
                                <option :key="index" v-for="(extraportuario, index) in informacionGeneral.manifiesto" :value="extraportuario.id">{{extraportuario.nombre}}</option>
                                
                            </select>
                            </b-form-group>
                        </b-col>
                        <b-col sm="12" md="12" lg="6">
                            <b-button
                                class="btn-extra-primario btn-small"
                                type="button"
                                @click="editar('administrativa','manifiesto', true,{'manifiesto_id': manifiesto })"
                            >
                                Guardar
                            </b-button>
                            <b-button
                                class="btn-extra-secundario btn-small"
                                type="button"
                                @click="editar('administrativa','manifiesto')"
                            >
                                Cancelar
                            </b-button>
                        </b-col>
                    </b-row>
                </form>
            </b-col>
        </b-row>

        
    </b-col>

    
    
    
    

</b-row>
</template>

<script>
import InputRut from '../InputRut.vue';
import InputRegion from '../InputRegion.vue';
export default {
    components: { InputRut, InputRegion },
    name:"FormUsuario",

    props: {
        despacho:Object,
        postInformacionGeneral:Function,
        informacionGeneral:Object,
        
        editar:Function,

        estados:Object

    },
    data(){
        return{
            manifiesto: '',
            compania_naviera: 0,
            agente_cargas_id: '',
            pantalla:false,
        }
    },
    mounted(){
        if (screen.width <= 660) this.pantalla = false
        else this.pantalla = true
    },
    methods:{
      //
    }
}
</script>