<template>
    
    <b-container fluid>
        <b-row align-v="center" align-h="center" class="h100 modal-inicial">
            <b-col class="custom-col">
                <!-- card -->
                <b-card class="login-card">
                    <!-- card header -->
                    <template #header>
                        <div class="container">
                            <inline-svg src="../assets/images/logo_aduanas.svg" title="My Image"/>
                            <p class="ingreso">INGRESO</p>
                            <p class="m0 sistema"><b>SISTEMA DE GESTIÓN DE DESPACHOS</b></p>
                        </div>
                    </template>

                    <!-- form -->
                    <b-form @submit.prevent="submit" >
                        <b-row>
                          <b-col cols="12">
                            <b-form-group
                              id="grupo_nombre"
                              label="Usuario o Email"
                              label-for="username"
                            >
                              <b-form-input
                                  id="username"
                                  v-model="form.email"
                                  placeholder="Ingresar nombre de usuario o email"
                                  required
                                  style="border-radius:15px;border:0;">
                              </b-form-input>
                            </b-form-group>
                            
                          </b-col>
                          <b-col cols="12">
                            <b-form-group id="grupo_password" label="Contraseña" label-for="password">
                              <b-form-input
                              class="input-login"
                              id="password"
                              :type="'password'"
                              v-model="form.password"
                              placeholder="Ingresar contraseña"
                              required
                              style="border-radius:15px;border:0;">
                              </b-form-input>
                          </b-form-group>
                          </b-col>
                          <b-col cols="12">
                            <b-row>
                              <b-col cols="12">
                                  <b-link href="#" @click.prevent="recuperar" class="derecha black_link">¿Olvidó su contraseña?</b-link>
                              </b-col>
                              <b-col cols="6">
                                <b-form-group id="grupo_checkbox">
                                  <!-- <b-form-checkbox style="float:right" v-model="form.remember_me" id="remember_me" >Recuérdame</b-form-checkbox> -->
                                </b-form-group>
                              </b-col>
                              
                            </b-row>
                          </b-col>
                          
                          <b-col class="mb-4" cols="12">
                            <button 
                              type="submit"
                              style="color:white"
                              size="lg"
                              ><span  v-if="!spinLoad">Ingresar</span>
                              <b-spinner v-else small label="cargando..."></b-spinner>
                              </button>
                            </b-col>
                          </b-row>
                        </b-form>
                        <b-col class="" cols="12">
                            <button 
                              type="button"
                              class="btn-google btn btn-light"
                              style="padding: 6px;"
                              @click="google()"
                              >
                              
                              <img src="@/assets/images/google_icon.svg" alt="Icono" style="width: 20px;">
                              <span  v-if="!spinLoad"> Ingresar con Google </span>
                              <b-spinner v-else small label="cargando..."></b-spinner>
                              </button>
                            </b-col>
                        
                        <!-- Usuario -->
                        

                        <!-- contraseña -->
                        

                        <!-- checkbox -->   
                            
                    
                </b-card>
            </b-col>
        </b-row>
    </b-container>
    
</template>


<script>
  import { mapActions } from 'vuex'

  export default {
    name: 'SignIn',

    data () {
      return {
        spinLoad: false,
        callback: false,
        form: {
          email: '',
          password: '',
        }
      }
    },

    mounted(){
      const urlParams = new URLSearchParams(window.location.search);
      const google = urlParams.get('google') === 'true';
      if(google){
        this.callback = true
        this.google()
      }    
      
    },

    methods: {
      ...mapActions({
        signIn: 'auth/signIn',
        signInGoogle: 'auth/signInGoogle',
        getUser: 'auth/getUser',
        getPermissions: 'auth/getPermissions'
      }),

      async submit () {
        this.spinLoad = true;
        await this.signIn(this.form).then(rep => {
        this.spinLoad = false;
          this.$router.replace({ name: 'Despachos' })
        }).catch(err => {
          this.spinLoad = false;
          this.$swal({
            icon: 'error',
            title: 'Error!',
            text: err.response.data.message,
          });
        })
      },
      recuperar(){
        this.$router.replace({ name: 'Recuperar' })
      },

      async google() {
        this.spinLoad = true;

        try{
          if(!this.callback){
            window.location = process.env.VUE_APP_API_URL + "/google-auth/redirect"
          }else if(this.callback){
            await this.signInGoogle()
            
          }
        }catch {
          this.spinLoad = false;
          
        }finally{
          if (this.callback) {
            // Redirigir solo cuando callback es true
            this.$router.replace({ name: 'Despachos' });
          }
          //Limpieza
          this.spinLoad = false;
          this.callback = false
          
        }
        
      }

    }
  }
</script>
