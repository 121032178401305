<template>
<b-row>

    <!-- rut -->
    <b-col sm="12" md="12" lg="6">
        <input-rut
        :rut="form.rut"
        :cambiarRut="cambiarRut"
        label="Rut del Cliente"
        id="rut"
        >
        </input-rut>
        <span id="rut-error"></span>
    </b-col>

    <!-- nombre -->
    <b-col sm="12" md="12" lg="6">
        <b-form-group
        class="text-left"
        label="Nombre del Cliente"
        label-for="nombre-input"
    >
            <b-form-input id="nombre-input" v-model="form.nombre" min="3" max="190"  required>
            </b-form-input>
            <div class="text-left">
                <span id="nombre-error"></span>
            </div>
        </b-form-group>
    </b-col>

    <!-- telefono -->
    <b-col sm="12" md="12" lg="6">
        <b-form-group
        class="text-left"
        label="Teléfono del Cliente (opcional)"
        label-for="telefono-input"
    >
            <b-form-input id="telefono-input" v-model="form.telefono" min="3" max="190" >
            </b-form-input>
            <div class="text-left">
                <span id="telefono-error"></span>
            </div>
        </b-form-group>
    </b-col>

    <!-- correo -->
    <b-col sm="12" md="12" lg="6">
        <b-form-group
        class="text-left"
        label="Correo del Cliente (opcional)"
        label-for="correo-input"
    >
            <b-form-input id="correo-input" v-model="form.correo" min="3" max="190" >
            </b-form-input>
            <div class="text-left">
                <span id="correo-error"></span>
            </div>
        </b-form-group>
    </b-col>

    <!-- domicilio -->
    <b-col sm="12" md="12" lg="12">
        <b-form-group
        class="text-left"
        label="Domicilio del Cliente (opcional)"
        label-for="domicilio-input"
    >
            <b-form-input id="domicilio-input" v-model="form.domicilio" min="3" max="190" >
            </b-form-input>
            <div class="text-left">
                <span id="domicilio-error"></span>
            </div>
        </b-form-group>
    </b-col>

    <!-- representante legal -->
    <b-col cols="12">
        <p>Representante legal</p>
        <hr>
    </b-col>
    <!-- rut representante -->
    <b-col sm="12" md="12" lg="6">
        <input-rut
        :rut="form.representante_rut"
        :cambiarRut="cambiarRutRepresentanteLegal"
        label="Rut del representante legal (opcional)"
        id="rut-representante"
        >
        </input-rut>
    </b-col>

    <!-- nombre representante -->
    <b-col sm="12" md="12" lg="6">
        <b-form-group
        class="text-left"
        label="Nombre del Representante (opcional)"
        label-for="representante-nombre-input"
    >
            <b-form-input id="representante-nombre-input" v-model="form.representante_nombre" min="3" max="190" >
            </b-form-input>
            <div class="text-left">
                <span id="representante-nombre-error"></span>
            </div>
        </b-form-group>
    </b-col>

    <!-- subir -->
    <b-col cols="12">
        <button
            class="btn-principal btn-large m-2"
            type="submit"
        >Guardar</button>
        <slot></slot>
    </b-col>
</b-row>
</template>

<script>
import InputRut from './InputRut.vue';
import InputRegion from './InputRegion.vue';
export default {
    components: { InputRut, InputRegion },
    name:"FormUsuario",

    props: {
        form: Object,
        user: Object,
        roles: Array,
        rutDisponible: Boolean,
        password: Boolean,

    },
    data(){
        return{
            cols: 10,
        }
    },
    methods:{
      cambiarRut(value){
        this.form.rut = value;
      },
      cambiarRutRepresentanteLegal(value){
        this.form.representante_rut = value;
      },
      //
    }
}
</script>

<style>

</style>