<template>
<b-row>
    <b-col cols="12 informacion">
        <b-row v-if="despacho.codigo && estados.codigo">
            <b-col cols="4" class="text-left dato">
                <p>N° Despacho</p>
            </b-col>
            <b-col 
                cols="4" class="text-right dato despacho" 
            >
                <p v-if="despacho.codigo">{{despacho.codigo}}</p> 
                <p v-else>-</p> 
                
            </b-col>
        </b-row>
       
        
        <b-row v-if="despacho.fecha_inicio && estados.fecha_inicio">
            <b-col cols="4" class="text-left dato">
                <p>Fecha Inicial</p>
            </b-col>
            <b-col cols="4" class="text-right dato despacho">
                <p v-if="despacho.fecha_inicio">{{moment(despacho.fecha_inicio).format('DD-MM-YYYY')}}</p> 
                <p v-else>-</p> 
            </b-col>
            <!-- <b-col cols="4" class="text-right dato despacho">
                <b-button
                v-if="pantalla == true"
                    @click="editar('gestor','fecha_inicio')"
                    class="btn-principal btn-small"
                >
                    Editar <inline-svg  class="icono-titulo" src="../../assets/images/Editar.svg" title="My Image"/>
                </b-button>
                <b-button
                v-if="pantalla == false"
                    @click="editar('gestor','fecha_inicio')"
                    class="btn-principal-chico"
                >
                    <inline-svg  class="icono-titulo" src="../../assets/images/EditarAzul.svg" title="My Image"/>
                </b-button>
            </b-col> -->
        </b-row>
        <!-- <b-row v-else> 
             <b-col cols="12" class="text-right dato despacho" >
                <form class="form-input">
                    <b-row>
                        <b-col sm="12" md="12" lg="6">
                            <b-form-group
                                class="text-left"
                                label="Fecha Inicio"
                                label-for="importancia-select"
                            >
                            <b-input type="date" v-model="fecha_inicio" required>

                            </b-input>
                            </b-form-group>
                        </b-col>
                        <b-col sm="12" md="12" lg="6">
                            <b-button
                                class="btn-extra-primario btn-small"
                                type="button"
                                @click="editar('gestor','fecha_inicio', true,{'fecha_inicio': fecha_inicio })"
                            >
                                Guardar
                            </b-button>
                            <b-button
                                class="btn-extra-secundario btn-small"
                                type="button"
                                @click="editar('gestor','fecha_inicio')"
                            >
                                Cancelar
                            </b-button>
                        </b-col>
                    </b-row>
                </form>
            </b-col>
        </b-row> -->

        
    </b-col>

    
    
    
    

</b-row>
</template>

<script>
import moment from 'moment';
import InputRut from '../InputRut.vue';
import InputRegion from '../InputRegion.vue';
export default {
    components: { InputRut, InputRegion },
    name:"FormUsuario",

    props: {
        despacho:Object,
        postInformacionGeneral:Function,
        informacionGeneral:Object,
        editar:Function,
        estados:Object

    },
    data(){
        return{
            nombre: '',
            codigo: '',
            ref_cliente: '',
            fecha_inicio: '',
            pantalla:false,
        }
    },
    mounted(){
        if (screen.width <= 660) this.pantalla = false
        else this.pantalla = true
    },
    methods:{
    
    }
}
</script>