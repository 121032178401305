<template>
  <b-tabs content-class="mt-3" class="tabs-secciones" >
            
            <b-tab title="Asignar Usuarios" @click="cambiarSeccion('usuarios')">
                <template #title>
                    <div class="title-tab">

                        <inline-svg class="icono-titulo" src="../../assets/images/usuarios.svg" title="My Image"/>
                        <p class="titulo text-center">Asignar Usuarios</p>
                    </div>
                </template>
                <usuarios
                    v-if="listaUsuarios && secciones.usuarios"
                    :agregarUsuario="true"
                    :usuarios="usuariosPorRol"
                    :listaUsuarios="listaUsuarios"
                    :tiposUsuarios="tipoUsuariosPorRol"
                    :changeForm="changeForm"
                    :submit="submit"

                    :addUser="addUser"
                >
                    
                </usuarios>
            </b-tab>
            <b-tab title="Documentación" @click="cambiarSeccion('documentos')">
                <template #title>
                    <div class="title-tab">
                        <inline-svg  class="icono-titulo" src="../../assets/images/documento.svg" title="My Image"/>
                        <p class=" titulo text-center">Documentación</p>
                    </div>
                </template>
                <documentos
                    v-if="todosDocumentos.tipos_documentos && secciones.documentos"
                    :agregarDocumento="true"
                    :postDocumento="postDocumento"
                    :updateDocumento="updateDocumento"
                    :documentosTabla="documentosTabla"
                    :documentos="todosDocumentos"
                    :eliminarDocumento="eliminarDocumento"

                    :eliminarDocumentos ="eliminarDocumentos"
                    :showDeleteDocumentos = "showDeleteDocumentos"
                    :actualizarDocumentos= "actualizarDocumentos"
                    :confirmarDeleteDocumentos= "confirmarDeleteDocumentos"
                    :cerrarModal="cerrarModal"
                    :metodoThen= "metodoThen"
                    :metodoCatch="metodoCatch"
                    
                >

                </documentos>

            </b-tab>
            <b-tab title="Pagos" @click="cambiarSeccion('pagos')">
                <template #title>
                    <div class="title-tab">
                        <inline-svg  class="icono-titulo" src="../../assets/images/dollar.svg" title="My Image"/>
                        <p class="titulo text-center" > Pagos</p>
                    </div>
                </template>
                <pagos
                    v-if="pagosTabla && secciones.pagos"
                    :agregarPago="true"
                    :despacho="despacho"
                    :pagosTabla="pagosTabla"
                    :montos="montos"
                    :postPago="postPago"
                    :updatePago="updatePago"
                    :getComprobante="getComprobante"
                >
                </pagos>

            </b-tab>
    </b-tabs>
</template>

<script>
import axios from 'axios'
import FormAsignarUsuario from '../../../formulario/FormAsignarUsuario.vue'

import { mapActions } from 'vuex';
import FormDocumento from '../../../formulario/FormDocumento.vue';
import Usuarios from '../../Componentes/Usuarios.vue';
import Pagos from '../../Componentes/Pagos.vue';
import Documentos from '../../Componentes/Documentos.vue';
export default {
  components: { FormAsignarUsuario, FormDocumento, Usuarios,Documentos, Pagos },
    
    props:{
        despacho:Object,
        listaUsuarios: Object,
        form:Object,
        submit: Function,
        changeForm:Function,
        changeValue:Function,
        estados:Object,
        usuarios:Array,

        changeState:Function,
        addUser:Function,
        deleteUser: Function,

        //DOCUMENTOS
        documentos: Object,
        documentosTabla:Array,
        postDocumento: Function,
        updateDocumento: Function,

        showDeleteDocumentos: Boolean,
        eliminarDocumentos: Function,
        actualizarDocumentos: Function,
        confirmarDeleteDocumentos: Function,
        cerrarModal: Function,
        metodoThen: Function,
        metodoCatch: Function,

        //PAGOS
        pagosTabla:Array,
        montos:Object,
        postPago: Function,
        updatePago: Function,
        getComprobante: Function,

    },
    mounted(){
        this.usuariosPorRol = []
        this.usuarios.forEach(el => {
            if(el.role.name == 'facturador') this.usuariosPorRol.push(el)
        })
        this.documentosPorRol = []
        this.documentos.tipos_documentos.forEach(el =>{
            this.documentosPorRol.push(el)
        });
        this.todosDocumentos = {
            tipos_documentos: this.documentosPorRol,
            bl : this.documentos.bl,
            documento_transporte: this.documentos.documento_transporte,
            tipos_vistos_buenos: this.documentos.tipos_vistos_buenos
        }
    },
    watch:{
        usuarios:function(newValue, oldValue){
            if(this.usuarios){
                this.usuariosPorRol = []
                this.usuarios.foreach(el => {
                    if(el.role.name == 'facturador') this.usuariosPorRol.push(el)
                })
            }
        }
    },
    data(){
        return{
            usuariosPorRol:[],
            documentosPorRol: [],
            todosDocumentos: {},


            tipoUsuariosPorRol: [
                {text:'Facturador', value:'facturador'},
            ],
            
            secciones: 
            {
                general: true,
                carga: false,
                pagos: true,
                usuarios: true,
                documentos: true,
            },
        }
    },
    methods:{
        //secciones
        cambiarSeccion(seccion){
            Object.keys(this.secciones).forEach(key => {

                if(key == seccion)this.secciones[key] = true;
                else this.secciones[key] = false
            });
        }
    }
}
</script>

<style>

</style>