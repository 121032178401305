<template>
    <div class="container">
        <div class="row">
            <div class="col-12">
                <div class="title-section">
                    <p>Grupos de Usuarios</p>
                </div>
                <div class="section-card mb-5">
                    <div class="header-card">
                        <!-- <div class="d-flex justify-content-between">
                            <p class="title">Grupos</p>
                            <button class="btn btn-primary" @click="nuevoGrupo()">Nuevo</button>
                        </div> -->

                        <!-- create title to the left and button to the right -->
                        <div class="d-flex justify-content-between w-100 mb-3">
                            <p class="title">Grupos</p>
                            <button class="btn btn-principal p-1 mr-3" @click="crearGrupo()">
                                <span v-if="!spinUserToAdd">Crear Grupo<fas :icon="['fas', 'plus']"></fas></span>
                                <b-spinner v-else class="align-middle" :size="30"></b-spinner>
                            </button>
                        </div>       
                    </div>
                    
                    <b-table responsive show-empty hover striped :busy="isBusy || spinUserToAdd" :items="grupos" :fields="fields" >
                        <template #empty >
                            <h4 class="text-center">No hay grupos</h4>
                        </template>
                        <template #table-busy>
                            <div class="text-center my-2" style="color: #FFCE1E !important ">
                                <b-spinner class="align-middle"></b-spinner>
                                <strong> Cargando...</strong>
                            </div>
                        </template>

                        <template #cell(tramitadores)="row">
                            <p>{{row.item.users.length}}</p>
                        </template>
                        <template #cell(acciones)="row">
                            <!-- two buttons in same line -->
                            <button :disabled="spinUserToAdd" class="btn btn-principal mr-2 p-2" @click="editarGrupo(row.item); getUsersToAdd();">
                                <span v-if="!spinUserToAdd">Administrar <fas :icon="['fas', 'edit']"></fas></span>
                                <b-spinner v-else class="align-middle" :size="30"></b-spinner>
                            </button>
                            <button :disabled="spinUserToAdd" class="btn btn-secundario p-2" @click="eliminarGrupo(row.item)">
                                <span v-if="!spinUserToAdd">Eliminar <fas :icon="['fas', 'trash']"></fas></span>
                                <b-spinner v-else class="align-middle" :size="30"></b-spinner>
                            </button>
                        </template>
                    </b-table>
                </div>
            </div>
        </div>
        <!-- modal editar -->
        <b-modal centered scrollable v-model="modalEditarGrupo" >
            <!-- header -->
            <template #modal-header>
                <h4>Editar Grupo</h4>
            </template>

            <!-- body -->
            <h5>Jefe del grupo</h5>
            <div class="p-1">
                <div v-if="!spinUser" class="form-group">
                    <label>Jefe del grupo</label>
                    <select class="form-control border border-secondary" v-model="modalEditar.jefe_id" @change="getUsersToAdd">
                        <option :selected="user.id == modalEditar.jefe_id" v-for="(user, index) in jefesToModify" :key="index" :value="user.id">{{user.name}}</option>
                    </select>
                </div>
                <b-spinner v-else class="align-middle" :size="'sm'" :variant="'primary'" ></b-spinner>
            </div>

            <h5>Usuarios</h5>
            <div class="p-1">
                <!-- bootstrap-vue acordion -->
                <div class="accordion" role="acciones">
                    <b-card no-body class="mb-1">
                    <b-card-header header-tag="header" class="p-1" role="tab">
                        <button  v-b-toggle.modificar-1 class="btn btn-secundario py-1 w-100">Agregar Usuarios al grupo</button>
                    </b-card-header>
                    <b-collapse id="modificar-1" accordion="acciones" role="tabpanel">
                        <b-card-body>
                            <div class="form-group" v-if="modalEditar.usersToAdd.length">
                                <b>Seleccione usuarios que desea agregar</b>
                                <div class="my-2">
                                    <button @click="selectAllModificarAdd()" class="btn btn-principal p-1 mr-2">Seleccionar todos</button>
                                    <button @click="unselectAllModificarAdd()" class="btn btn-secundario p-1">Deseleccionar todos</button>
                                </div>
                                <div class="mt-1" v-for="(user, key, index) in modalEditar.usersToAdd" :key="index">
                                    <div class="form-check">
                                        <input :id="'tramitador-'+index" type="checkbox" class="form-check-input" v-model="user.addUser">
                                        <label :for="'tramitador-'+index" class="form-check-label">{{user.name}} - <b>{{user.role.display_name}}</b></label>
                                    </div>
                                </div>
                            </div>
                            <b v-else>No hay usuarios para añadir</b>
                        </b-card-body>
                    </b-collapse>
                    </b-card>

                    <b-card no-body class="mb-1">
                        <b-card-header header-tag="header" class="p-1" role="tab">
                            <button  v-b-toggle.modificar-2 class="btn btn-secundario py-1 w-100">Eliminar Usuarios del grupo</button>
                        </b-card-header>
                        <b-collapse id="modificar-2" accordion="acciones" role="tabpanel">
                            <b-card-body>
                                <div class="form-group" v-if="modalEditar.tramitadores.length">
                                    <b>Seleccione usuarios que desea Eliminar</b>
                                    <div class="my-2">
                                        <button @click="selectAllModificarDel()" class="btn btn-principal p-1 mr-2">Seleccionar todos</button>
                                        <button @click="unselectAllModificarDel()" class="btn btn-secundario p-1">Deseleccionar todos</button>
                                    </div>
                                    <div class="mt-1" v-for="(tramitador, index) in modalEditar.tramitadores" :key="index">
                                        <div class="form-check">
                                            <input :id="'tramitador-'+index" type="checkbox" class="form-check-input" v-model="tramitador.delUser">
                                            <label :for="'tramitador-'+index" class="form-check-label">{{tramitador.name}} - <b>{{tramitador.role.display_name}}</b></label>
                                        </div>
                                    </div>
                                </div>
                                <b v-else>No hay usuarios en el grupo para eliminar</b>
                            </b-card-body>
                        </b-collapse>
                    </b-card>
                </div>
            </div>

            <!-- footer -->
            <template #modal-footer>
                <button class="btn btn-secondary mr-2 p-2" @click="modalEditarGrupo = false">Cancelar</button>
                <button :disabled="modalEditarBtnBussy" @click="modificar()" class="btn btn-principal p-2">Guardar cambios
                    <b-spinner v-if="modalEditarBtnBussy" small ></b-spinner>
                </button>
            </template>
        </b-modal>

        <!-- modal crear -->
        <b-modal centered scrollable v-model="modalCrearGrupo" >
            <!-- header -->
            <template #modal-header>
                <h4>Crear Grupo</h4>
            </template>

            <!-- body -->
            <h5>Jefe del grupo</h5>
            <div class="p-1 mb-4">
                <div v-if="jefesToAdd.length" class="form-group">
                    <label>Jefe del grupo</label>
                    <select class="form-control border border-secondary" v-model="modalCrear.jefe_id" @change="getUsersToAdd">
                        <option v-for="(jefe, index) in jefesToAdd" :key="index" :value="jefe.id">{{jefe.name}}</option>
                    </select>
                </div>
                <b v-else>No hay jefes para añadir.</b>
            </div>

            <h5>Usuarios</h5>
            <div class="p-1">
                <div class="form-group" v-if="modalCrear.usersToAdd.length">
                    <b>Seleccione usuarios que desea agregar</b>
                    <div class="my-2">
                        <button @click="selectAllCrearAdd()" class="btn btn-principal p-1 mr-2">Seleccionar todos</button>
                        <button @click="unselectAllCrearAdd()" class="btn btn-secundario p-1">Deseleccionar todos</button>
                    </div>
                    <div class="mt-1 px-3 py-1" v-for="(user, index) in modalCrear.usersToAdd" :key="index">
                        <div class="form-check">
                            <input :id="'tramitadoradd-'+index" type="checkbox" class="form-check-input" v-model="user.addUser">
                            <label :for="'tramitadoradd-'+index" class="form-check-label">{{user.name}} - <b>{{user.role.display_name}}</b></label>
                        </div>
                    </div>
                </div>
                <b v-else>No hay usuarios para añadir</b>
            </div>

            <!-- footer -->
            <template #modal-footer>
                <button class="btn btn-secondary mr-2 p-2" @click="cleanUsers()">Cancelar</button>
                <button :disabled="modalCrearBtnBussy || disableCrearBtn" @click="crear()" class="btn btn-principal p-2">Crear Grupo
                    <b-spinner v-if="modalCrearBtnBussy" small ></b-spinner>
                </button>
            </template>
        </b-modal>

        <!-- modal eliminar -->
        <b-modal centered scrollable v-model="modalEliminarGrupo" >
            <!-- header -->
            <template #modal-header>
                <h4>Eliminar Grupo</h4>
            </template>

            <!-- body -->
            <div class="p-3">
                <p class="m-0"><b>¿Está seguro que desea eliminar el grupo?</b></p>
                <small>Todos los usuarios y jefes serán desvinculados de este grupo</small>
            </div>
            <h5>Detalles del grupo</h5>
            <hr>

            <p>Jefe de Grupo: <b>{{modalEliminar.jefe.name}}</b></p>
            <p>Usuarios:</p>
            <ul>
                <li v-for="(tramitador, index) in modalEliminar.tramitadores" :key="index">{{tramitador.name}} - <b>{{tramitador.role.display_name}}</b></li>
            </ul>

            <!-- footer -->
            <template #modal-footer>
                <button class="btn btn-secondary mr-2 p-2" @click="modalEliminarGrupo = false">Cancelar</button>
                <button :disabled="modalEliminarBtnBussy" @click="eliminar()" class="btn btn-danger p-2">Eliminar Grupo
                    <b-spinner v-if="modalEliminarBtnBussy" small ></b-spinner>
                </button>
            </template>
        </b-modal>
    </div>
</template>

<script>
import axios from 'axios'
import Vue from 'vue'
export default {
    name: 'GrupoUsuarios',
    data(){
        return{
            // Grupos
            isBusy: false,
            fields:[
                {key:'jefe.name',label:'Jefe de grupo'},
                {key:'jefe.email',label:'Correo jefe'},
                {key:'tramitadores',label:'Cantidad de usuarios'},
                {key:'acciones', label: 'Acciones'},
            ],
            grupos:[],

            // Jefes
            spinJefe: false,
            jefes:[],
            jefesToAdd:[],
            jefesToModify:[],
            jefe_id:0,

            // Usuarios
            spinUser: false,
            users:[],
            spinUserToAdd: false,
            usersToAdd:[],

            ///// CRUD /////

            // Crear
            modalCrearGrupo: false,
            modalCrearBtnBussy: false,
            modalCrear:{
                jefe_id: 0,
                usersToAdd: [],
            },

            // Editar
            modalEditarGrupo: false,
            modalEditarBtnBussy: false,
            modalEditar:{
                grupo_id: 0,
                jefe_id: 0,
                tramitadores: [],
                usersToAdd: [],
            },

            // Eliminar
            modalEliminarGrupo: false,
            modalEliminarBtnBussy: false,
            modalEliminar:{
                grupo_id: 0,
                jefe: {},
                tramitadores: [],
            },
        }
    },
    created(){
        this.getAllData()

    },
    computed:{
        disableCrearBtn(){
            return this.modalCrear.jefe_id == 0
        }
    },
    methods:{
        //// javascript ////
        getAllData(){
            this.getGrupos()
            this.getJefesToAdd()
        },

        crearGrupo(){
            this.modalCrear.jefe_id = 0
            this.modalCrear.usersToAdd = []
            this.modalCrearGrupo = true
        },

        cleanUsers(){
            this.modalCrear = {
                jefe_id: 0,
                usersToAdd: [],
            }
            this.modalCrearGrupo = false
        },

        editarGrupo(grupo){
            this.jefe_id = grupo.jefe.id
            this.modalEditar = {
                grupo_id: grupo.id,
                jefe_id: grupo.jefe.id,
                tramitadores: grupo.users,
                usersToAdd: this.usersToAdd,
            }

            // añadir campo DelUser tramitadores
            this.modalEditar.tramitadores.forEach(tramitador => {
                Vue.set(tramitador, 'delUser', false)
            })

            // añadir campo AddUser usersToAdd
            this.modalEditar.usersToAdd.forEach(user => {
                Vue.set(user, 'addUser', false)
            })

            // clean usersToModify
            this.usersToModify = []
            // copy jefesToAdd to jefesToModify
            this.jefesToModify = JSON.parse(JSON.stringify(this.jefesToAdd))
            // push jefe to jefesToModify
            this.jefesToModify.push(grupo.jefe)

            this.modalEditarGrupo = true
        },


        eliminarGrupo(grupo){
            this.modalEliminar = {
                grupo_id: grupo.id,
                jefe: grupo.jefe,
                tramitadores: grupo.users,
            } 
            this.modalEliminarGrupo = true
        },

        // Checkboxes
        selectAllModificarAdd(){
            this.modalEditar.usersToAdd.forEach(element => {
                Vue.set(element, 'addUser', true)
            });
        },

        unselectAllModificarAdd(){
            this.modalEditar.usersToAdd.forEach(element => {
                Vue.set(element, 'addUser', false)
            });
        },

        selectAllModificarDel(){
            this.modalEditar.tramitadores.forEach(element => {
                Vue.set(element, 'delUser', true)
            });
        },

        unselectAllModificarDel(){
            this.modalEditar.tramitadores.forEach(element => {
                Vue.set(element, 'delUser', false)
            });
        },

        selectAllCrearAdd(){
            this.modalCrear.usersToAdd.forEach(element => {
                Vue.set(element, 'addUser', true)
            });
        },

        unselectAllCrearAdd(){
            this.modalCrear.usersToAdd.forEach(element => {
                Vue.set(element, 'addUser', false)
            });
        },


        ///// axios /////

        // Grupos
        async getGrupos(){
            this.isBusy = true
            await axios.get('/api/admin/grupos')
            .then(res => {
                this.grupos = res.data.data
                this.isBusy = false
            })
            .catch(err => {
                this.isBusy = false
                if(err.response.status !== 401){
                    this.$swal({
                        icon: 'error',
                        title: '¡Error!',
                        text: 'Ha ocurrido un error al cargar los grupos de usuarios',
                    });
                }
            })
        },

        // CRUD
        async crear(){
            this.modalCrearBtnBussy = true
                const form = {
                jefe_id: this.modalCrear.jefe_id,
                usersToAdd: this.modalCrear.usersToAdd.filter(user => user.addUser).map(user => user.id),
            }

            await axios.post('/api/admin/grupos/crear', form)
            .then(res => {
                this.modalCrearBtnBussy = false
                this.modalCrearGrupo = false
                this.getAllData()
            })
            .catch(err => {
                this.modalCrearBtnBussy = false
                if(err.response.status !== 401){
                    this.$swal({
                        icon: 'error',
                        title: '¡Error!',
                        text: 'Ha ocurrido un error al crear el grupo de usuarios',
                    });
                }
            })
        },

        async modificar(){
            this.modalEditarBtnBussy = true

            // un array de los id de los usuarios a añadir
            const usersToAdd = this.modalEditar.usersToAdd.filter(user => user.addUser).map(user => user.id)
            const usersToDel = this.modalEditar.tramitadores.filter(user => user.delUser).map(user => user.id)


            const form = {
                jefe_id: this.modalEditar.jefe_id,
                usersToAdd: usersToAdd,
                usersToDel: usersToDel,
            }

            await axios.put('/api/admin/grupos/'+this.modalEditar.grupo_id+'/modificar', form)
            .then(res => {
                this.modalEditarBtnBussy = false
                this.modalEditarGrupo = false
                this.modalEditar = {
                    grupo_id: 0,
                    jefe_id: 0,
                    tramitadores: [],
                    usersToAdd: [],
                }
                this.getAllData()
            })
            .catch(err => {
                this.modalEditarBtnBussy = false
                if(err.response.status !== 401){
                    this.$swal({
                        icon: 'error',
                        title: '¡Error!',
                        text: 'Ha ocurrido un error al modificar el grupo de usuarios',
                    });
                }
            })
        },

        async eliminar(){
            this.modalEliminarBtnBussy = true

            await axios.delete('/api/admin/grupos/'+this.modalEliminar.grupo_id+'/eliminar')
            .then(res => {
                this.modalEliminarBtnBussy = false
                this.modalEliminarGrupo = false
                this.modalEliminar = {
                    grupo_id: 0,
                    jefe: '',
                    tramitadores: [],
                }
                this.getAllData()
            })
            .catch(err => {
                this.modalEliminarBtnBussy = false
                if(err.response.status !== 401){
                    this.$swal({
                        icon: 'error',
                        title: '¡Error!',
                        text: 'Ha ocurrido un error al eliminar el grupo de usuarios',
                    });
                }
            })
        },
        // jefes //
        async getJefes(){
            this.isBusy = true
            await axios.get('/api/admin/grupos/users/getJefes')
            .then(res => {
                this.jefes = res.data.data
                this.isBusy = false
            })
            .catch(err => {
                this.isBusy = false
                if(err.response.status !== 401){
                    this.$swal({
                        icon: 'error',
                        title: '¡Error!',
                        text: 'Ha ocurrido un error al cargar los jefes',
                    });
                }
            })
        },

        async getJefesToAdd(){
            this.isBusy = true
            await axios.get('/api/admin/grupos/users/getJefesToAdd')
            .then(res => {
                this.jefesToAdd = res.data.data
                this.isBusy = false
            })
            .catch(err => {
                this.isBusy = false
                if(err.response.status !== 401){
                    this.$swal({
                        icon: 'error',
                        title: '¡Error!',
                        text: 'Ha ocurrido un error al cargar los jefes',
                    });
                }
            })
        },

        // usuarios //
        async getUsers(){
            this.spinUser = true
            await axios.get('/api/admin/grupos/users/getUsers')
            .then(res => {
                this.spinUser = false
                this.users = res.data.data
            })
            .catch(err => {
                this.spinUser = false
                if(err.response.status !== 401){
                    this.$swal({
                        icon: 'error',
                        title: '¡Error!',
                        text: 'Ha ocurrido un error al cargar los usuarios',
                    });
                }
            })
        },

        async getUsersToAdd(){
            this.spinUserToAdd = true
            this.isBusy = true
            this.spinUserToAdd = true

            if(this.modalCrearGrupo){
                await axios.get('/api/admin/grupos/users/getUsersToAdd/'+ this.modalCrear.jefe_id)
            .then(res => {
                this.spinUserToAdd = false
                this.isBusy = false
                this.spinUserToAdd = false

                this.usersToAdd = res.data.data
                this.modalCrear = {
                jefe_id: this.modalCrear.jefe_id,
                usersToAdd: this.usersToAdd,
                }
            })
            .catch(err => {
                console.log(err.response)
                this.spinUserToAdd = false
                this.isBusy = false
                this.spinUserToAdd = false

                if(err.response.status !== 401){
                    this.$swal({
                        icon: 'error',
                        title: '¡Error!',
                        text: 'Ha ocurrido un error al cargar los usuarios',
                    });
                }
            })
            } else if(this.modalEditarGrupo){
                await axios.get('/api/admin/grupos/users/getUsersToAdd/'+ this.modalEditar.jefe_id)
            .then(res => {
                this.spinUserToAdd = false
                this.isBusy = false
                this.spinUserToAdd = false

                this.usersToAdd = res.data.data
                this.modalEditar = {
                grupo_id: this.modalEditar.grupo_id,
                jefe_id: this.modalEditar.jefe_id,
                tramitadores: this.modalEditar.tramitadores,
                usersToAdd: this.usersToAdd,
                }
            })
            .catch(err => {
                console.log(err.response)
                this.spinUserToAdd = false
                this.isBusy = false
                this.spinUserToAdd = false

                if(err.response.status !== 401){
                    this.$swal({
                        icon: 'error',
                        title: '¡Error!',
                        text: 'Ha ocurrido un error al cargar los usuarios',
                    });
                }
            })
            }
            
        },
    }
}
</script>

<style>

</style>