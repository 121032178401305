<template>
  <b-modal size="xl"  v-model="show" id="modal-editar" centered title="BootstrapVue" @hidden="cerrarModal()" hide-footer>
      <template #modal-title>
        {{titulo}}
      </template>
      <div class="d-block text-center">
        <b-form id="editUserForm" class="pl-3 pr-3 form-input" @submit.prevent="submit">
        
         <form-aduanas
          :form="form"
          :user="user"
         >
         </form-aduanas>
        </b-form>
      </div>
    </b-modal>
</template>

<script>
import FormAduanas from '../formulario/FormAduanas.vue';
import FormUsuario from '../formulario/FormUsuario.vue';
import InputRut from '../formulario/InputRut.vue';
export default {
  components: { InputRut, FormUsuario, FormAduanas },
    props: {
      show: Boolean,
      cerrarModal: Function,
        titulo: String,
        form: Object,
        submit: Function,
        user:Object,
        
    },
    methods:{
        
    }
}
</script>

<style>

</style>