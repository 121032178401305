<template>
<b-row>
    <b-col cols="12 informacion">
        <b-row v-if="despacho.tipo.tipo_condicion && estados.tipo_condicion">
            <b-col cols="4" class="text-left dato">
                <p>Tipo Condición</p>
            </b-col>
            <b-col 
                cols="4" class="text-right dato despacho" 
            >
                <p v-if="despacho.tipo.tipo_condicion">{{despacho.tipo.tipo_condicion}}</p> 
                <p v-else>-</p> 
                
            </b-col>
            <b-col
                cols="4" class="text-right dato despacho" 
            >
                <b-button
                v-if="pantalla == true"
                    class="btn-principal btn-small"
                    @click="editar('tramitador_aduanero','tipo_condicion')"
                >
                    Editar <inline-svg  class="icono-titulo" src="../../assets/images/Editar.svg" title="My Image"/>
                </b-button>
                <b-button
                v-if="pantalla == false"
                    @click="editar('tramitador_aduanero','tipo_condicion')"
                    class="btn-principal-chico"
                >
                    <inline-svg  class="icono-titulo" src="../../assets/images/EditarAzul.svg" title="My Image"/>
                </b-button>
            </b-col>
            
        </b-row>
        <b-row v-else>
            <b-col 
                cols="12" class="text-right dato despacho" 
            >
                <form class="form-input">
                    <b-row>
                        <b-col sm="12" md="12" lg="6">
                            <b-form-group
                                class="text-left"
                                label="Tipo Condicion"
                                label-for="condicion-select"
                            >
                                <select class="form-control border border-secondary"  id="condicion-select" v-model="condicion"  required>
                                    <option value="0" disabled>Selecciona la Condición</option>
                                    <option :key="index" v-for="(tipo_condicion,index) in informacionGeneral.tipo_condicion" :value="tipo_condicion" >{{tipo_condicion}}</option>
                                    
                                </select>
                            </b-form-group>
                        </b-col>
                        <b-col sm="12" md="12" lg="6">
                            <b-button
                                class="btn-extra-primario btn-small"
                                type="button"
                                @click="editar('tramitador_aduanero','tipo_condicion',true,{'tipo_condicion' : condicion})"
                            >
                                Guardar
                            </b-button>
                            <b-button
                                class="btn-extra-secundario btn-small"
                                type="button"
                                @click="editar('tramitador_aduanero','tipo_condicion')"
                            >
                                Cancelar
                            </b-button>
                        </b-col>
                    </b-row>
                </form>
            </b-col>
        </b-row>
        <!--EXTRAPORTUARIO-->
        <b-row v-if="despacho.tipo.extraportuario && estados.extraportuario">

            <b-col cols="4" class="text-left dato">
                <p>Puerto/Extraportuario</p>
            </b-col>
            <b-col cols="4" class="text-right dato despacho" >
                <p v-if="despacho.tipo.extraportuario">{{despacho.tipo.extraportuario.nombre}}</p> 
                <p v-else>-</p> 
            </b-col>
            <b-col cols="4" class="text-right dato despacho" >
                <b-button
                v-if="pantalla == true"
                    @click="editar('tramitador_aduanero','extraportuario')"
                    class="btn-principal btn-small"
                >
                    Editar <inline-svg  class="icono-titulo" src="../../assets/images/Editar.svg" title="My Image"/>
                </b-button>
                <b-button
                v-if="pantalla == false"
                    @click="editar('tramitador_aduanero','extraportuario')"
                    class="btn-principal-chico"
                >
                    <inline-svg  class="icono-titulo" src="../../assets/images/EditarAzul.svg" title="My Image"/>
                </b-button>
            </b-col>
        </b-row>
        <b-row v-else>
            
            <b-col 
                
                cols="12" class="text-right dato despacho" 
            >
                <form class="form-input">
                    <b-row>
                        <b-col sm="12" md="12" lg="6">
                            <b-form-group
                                class="text-left"
                                label="Puerto/Extraportuario"
                                label-for="extraportuario-select"
                            >
                            <select class="form-control border border-secondary"  id="extraportuario-select" v-model="extraportuario"  required>
                                <option value="0" disabled>Selecciona un Puerto/Extraportuario</option>
                                <option :key="index" v-for="(extraportuario, index) in informacionGeneral.extraportuarios" :value="extraportuario.id">{{extraportuario.nombre}}</option>
                                
                            </select>
                            </b-form-group>
                        </b-col>
                        <b-col sm="12" md="12" lg="6">
                            <b-button
                                class="btn-extra-primario btn-small"
                                type="button"
                                @click="editar('tramitador_aduanero','extraportuario', true,{'extraportuario_id': extraportuario })"
                            >
                                Guardar
                            </b-button>
                            <b-button
                                class="btn-extra-secundario btn-small"
                                type="button"
                                @click="editar('tramitador_aduanero','extraportuario')"
                            >
                                Cancelar
                            </b-button>
                        </b-col>
                    </b-row>
                </form>
            </b-col>
        </b-row>
        <b-row v-if="(despacho.aforo == 0 || despacho.aforo == 1 || despacho.aforo == 2) && estados.aforo">
            <b-col cols="4" class="text-left dato">
                <p>Tipo de Aforo</p>
            </b-col>
            <b-col cols="4" class="text-right dato despacho">
                <p v-if="despacho.aforo == 2">Sin Aforo</p> 
                <p v-if="despacho.aforo == 1">Examen Físico</p> 
                <p v-if="despacho.aforo == 0">Examen Documental</p> 
            </b-col>
            <b-col cols="4" class="text-right dato despacho">
                <b-button
                    @click="editar('tramitador_aduanero','aforo')"
                    class="btn-principal btn-small"
                >
                    Editar <inline-svg  class="icono-titulo" src="../../assets/images/Editar.svg" title="My Image"/>
                </b-button>
            </b-col>
        </b-row>
        <b-row v-else>
            <b-col 
                cols="12" class="text-right dato despacho" 
            >
                <form class="form-input">
                    <b-row>
                        <b-col sm="12" md="12" lg="4">
                            <b-form-group
                                class="text-left"
                                label="Tipo de Aforo"
                                label-for="aforo-select"
                            >
                                <select class="form-control border border-secondary"  id="aforo-select" v-model="aforo"  required>
                                    <option value="3" disabled>Selecciona el Tipo de Aforo</option>
                                    <option  value="2" >Sin Aforo</option>
                                    <option  value="1" >Examen Físico</option>
                                    <option  value="0" >Examen Documental</option>
                                    
                                </select>
                            </b-form-group>
                        </b-col>
                        <b-col sm="12" md="12" lg="4">
                            <b-button
                                class="btn-extra-primario btn-small"
                                type="button"
                                @click="editar('tramitador_aduanero','aforo', true,{'aforo': aforo })"
                            >
                                Guardar
                            </b-button>
                            <b-button
                                class="btn-extra-secundario btn-small"
                                type="button"
                                @click="editar('tramitador_aduanero','aforo')"
                            >
                                Cancelar
                            </b-button>
                        </b-col>
                    </b-row>
                </form>
            </b-col>
        </b-row>
    </b-col>

    
    
    
    

</b-row>
</template>

<script>
import InputRut from '../InputRut.vue';
import InputRegion from '../InputRegion.vue';
export default {
    components: { InputRut, InputRegion },
    name:"FormUsuario",

    props: {
        despacho:Object,
        postInformacionGeneral:Function,
        informacionGeneral:Object,
        editar:Function,
        estados:Object

    },
    data(){
        return{
            condicion: 0,
            extraportuario: '',
            aforo:'0',
            pantalla:false,
        }
    },
    mounted(){
        if (screen.width <= 660) this.pantalla = false
        else this.pantalla = true
    },
    methods:{
    }
}
</script>