<template>
  <b-tabs content-class="mt-3" class="tabs-secciones">
    <b-tab title="Informacion General" @click="cambiarSeccion('general')">
      <template #title>
        <div class="title-tab">
          <inline-svg
            class="icono-titulo"
            src="../../assets/images/general.svg"
            title="My Image"
          />
          <p class="titulo text-center">Información General</p>
        </div>
      </template>
      <informacion-general
        v-if="secciones.general"
        :despacho="despacho"
        :postInformacionGeneral="postInformacionGeneral"
        :informacionGeneral="informacionGeneral"
        tipo="presentador_imp"
      >
      </informacion-general>
    </b-tab>
    <b-tab title="Documentación" @click="cambiarSeccion('documentos')">
      <template #title>
        <div class="title-tab">
          <inline-svg
            class="icono-titulo"
            src="../../assets/images/documento.svg"
            title="My Image"
          />
          <p class="titulo text-center">Documentación</p>
        </div>
      </template>
      <!-- <DocumentosPrestador
                    v-if="todosDocumentos.tipos_documentos && secciones.documentos"
                    :agregarDocumento="true"
                        :postDocumento="postDocumento"
                        :updateDocumento="updateDocumento"
                        :documentosTabla="documentosTabla"
                        :documentos="todosDocumentos"
                        :eliminarDocumento="eliminarDocumento"
                        :validate_permission = "true"
                        
                >
                </DocumentosPrestador> -->
      <documentos
        v-if="todosDocumentos.tipos_documentos && secciones.documentos"
        :agregarDocumento="true"
        :postDocumento="postDocumento"
        :updateDocumento="updateDocumento"
        :documentosTabla="documentosTabla"
        :documentos="todosDocumentos"
        :eliminarDocumento="eliminarDocumento"
        :validate_permission="true"

        :eliminarDocumentos ="eliminarDocumentos"
        :showDeleteDocumentos = "showDeleteDocumentos"
        :actualizarDocumentos= "actualizarDocumentos"
        :confirmarDeleteDocumentos= "confirmarDeleteDocumentos"
        :cerrarModal="cerrarModal"
        :metodoThen= "metodoThen"
        :metodoCatch="metodoCatch"
      >
      </documentos>
    </b-tab>
    <b-tab title="CDE" @click="cambiarSeccion('cde')">
      <template #title>
        <div class="title-tab">
          <inline-svg
            class="icono-titulo"
            src="../../assets/images/documento.svg"
            title="My Image"
          />
          <p class="titulo text-center">CDE</p>
        </div>
      </template>
      <admCde v-if="secciones.cde"> </admCde>
    </b-tab>
    <b-tab title="Pagos" @click="cambiarSeccion('pagos')">
      <template #title>
        <div class="title-tab">
          <inline-svg
            class="icono-titulo"
            src="../../assets/images/dollar.svg"
            title="My Image"
          />
          <p class="titulo text-center">Pagos</p>
        </div>
      </template>
      <pagos
        v-if="secciones.pagos"
        :despacho="despacho"
        :agregarPago="true"
        :postPago="postPago"
        :updatePago="updatePago"
        :pagosTabla="pagosTabla"
        :pagos="todosPagos"
        :getComprobante="getComprobante"
        :eliminarPago="eliminarPago"
      >
      </pagos>
    </b-tab>
  </b-tabs>
</template>

<script>
import Documentos from "../../Componentes/Documentos.vue";
import DocumentosPrestador from "../../Componentes/DocumentosPrestador.vue";
import InformacionGeneral from "../../Componentes/InformacionGeneral.vue";
import Pagos from "../../Componentes/Pagos.vue";
import AdmCde from "@/components/Despachos/Componentes/cde/admCde.vue";

export default {
  components: {
    Documentos,
    Pagos,
    InformacionGeneral,
    AdmCde,
    DocumentosPrestador,
  },
  props: {
    despacho: Object,

    submit: Function,

    //DOCUMENTOS
    documentos: Object,
    documentosTabla: Array,
    postDocumento: Function,
    updateDocumento: Function,
    showDeleteDocumentos: Boolean,
    eliminarDocumentos: Function,
    actualizarDocumentos: Function,
    confirmarDeleteDocumentos: Function,
    cerrarModal: Function,
    metodoThen: Function,
    metodoCatch: Function,

    //PAGOS
    pagos: Object,
    pagosTabla: Array,
    postPago: Function,
    updatePago: Function,

    eliminarDocumento: Function,
    eliminarPago: Function,
    getComprobante: Function,

    //Informacion General
    postInformacionGeneral: Function,
    informacionGeneral: Object,
  },
  data() {
    return {
      documentosPorRol: [],
      todosDocumentos: {},

      pagosPorRol: [],
      todosPagos: {},

      //secciones
      secciones: {
        general: true,
        carga: false,
        pagos: false,
        usuarios: false,
        documentos: true,
        cde: false,
      },
    };
  },
  mounted() {
    // this.pagosPorRol = []
    // this.pagos.tipos_pagos.forEach(el =>{
    //     if(el.id == 2) this.pagosPorRol.push(el)
    // });
    // this.todosPagos= {
    //     tipos_pagos: this.pagosPorRol,
    //     tipos_documentos_contable : this.pagos.tipo_documento_contable,
    // }

    this.documentos.tipos_documentos.forEach((el) => {
        this.documentosPorRol.push(el);
    });
    this.todosDocumentos = {
            tipos_documentos: this.documentosPorRol,
            bl : this.documentos.bl,
            documento_transporte: this.documentos.documento_transporte,
            tipos_vistos_buenos: this.documentos.tipos_vistos_buenos
        }
  },
  watch: {
    documentos: function (newValue, oldValue) {
      if (this.documentos) {
        this.documentosPorRol = [];
        this.documentos.tipos_documentos.forEach((el) => {
            this.documentosPorRol.push(el);
        });
        this.todosDocumentos = {
          tipos_documentos: this.documentosPorRol,
        };
      }
    },
  },
  methods: {
    //secciones
    cambiarSeccion(seccion) {
      Object.keys(this.secciones).forEach((key) => {
        if (key == seccion) this.secciones[key] = true;
        else this.secciones[key] = false;
      });
    },
  },
};
</script>

<style>
</style>