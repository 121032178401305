<template>
  <b-container fluid>
      <b-row class="pt-3 pl-3">
          <b-col>
              <h3>error 404</h3>
              <span>La url solicitada no existe</span>
          </b-col>
      </b-row>

  </b-container>
</template>

<script>
export default {
    name: "NotFound"
}
</script>

<style>

</style>