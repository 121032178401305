<template>
    <div class="section-card">
        <div class="header-card">
            <div v-if="despacho == null">
                <p class="title "> {{title}}</p>
            </div>
            <div v-else>
                <p class="title "> {{title + despacho.codigo}}</p>
            </div>
            
            <div v-if="canManage()">
                <div class="d-flex justify-items-center align-items-center" v-if="pantalla == false">
                    <button type="button" v-if="tercerBoton" class="boton-card btn-secundario btn-large w-100" @click="terceraAccionBoton">{{tercerText}}</button>
                    <button type="button" v-if="segundoboton" class="boton-card btn-secundario btn-large w-100" @click="segundoaccionBoton">{{segundotext}}</button>
                    <button type="button" v-permisos="permiso" v-if="boton" class="boton-card btn-principal btn-large " @click="accionBoton">{{text}}</button>
                    <slot name="extraButtons"></slot>
                </div>
            </div>
        </div>
        <div v-if="canManage()">
            <div class="text-center mb-2" v-if="pantalla == true">
                <button type="button" v-if="tercerBoton" class="boton-card btn-secundario btn-large w-100" @click="terceraAccionBoton">{{tercerText}}</button>
                <button type="button" v-if="segundoboton" class="boton-card btn-secundario btn-large w-100" @click="segundoaccionBoton">{{segundotext}}</button>
                <button type="button" v-permisos="permiso" v-if="boton" class="boton-card btn-principal btn-large " @click="accionBoton">{{text}}</button>
                <slot name="extraButtons"></slot>
            </div>
        </div>
        <slot></slot>
    </div>
</template>



<script>
export default {
    name:"Card",
    props: {
        title: String,
        accionBoton: Function,
        boton: Boolean,
        text: String,
        permiso: String,
        despacho: Object,
        segundoaccionBoton: Function,
        segundoboton: Boolean,
        segundotext: String,
        terceraAccionBoton: Function,
        tercerText: String,
        tercerBoton: Boolean,
        canManage:{type: Function, default: ()=>{return true}} 

    },
    data(){
        return{
            pantalla:false
        }
    },
    mounted(){
        if(screen.width > 660) this.pantalla = false
        else this.pantalla = true
    },
}
</script>

<style>

    .section-card{
        background: white;

        padding-top: 20px ;
        padding-bottom: 30px;

        box-shadow: 0px 0px 10px #0000001a
    }
    .section-card .header-card{
        display: flex;
        justify-content: space-between;
    }

    .section-card .header-card .boton-card{
        
        margin-right: 20px;
    }

    .section-card .header-card .title{

        padding-left: 20px;

        text-align: left;

        font-weight: bold;
        font-size: 16px;
        color: #043161 !important;
    }

    @media only screen and (max-width: 600px) {
        .section-card .header-card{
            display: block;
        }
        .section-card .header-card .title {
            padding-left: 0;
            text-align: center;
        }
    }

    



   
        


</style>