<template>
    <form class="form-input" @submit.prevent="submit" id="form-documentos" enctype="multipart/form-data">
        <b-container fluid>
            <b-row>
                <b-col sm="12" md="12" lg="4">
                    <b-form-group
                        :content-cols="12"
                        label="TIPO DE DOCUMENTO"
                    > 
                        <select class="form-control border border-secondary"  id="documento-select"  v-model="tipo_documento" @change="changeTipoDocumento(tipo_documento)"  required>
                            <option value="" disabled>Seleccionar un Documento</option>
                            <option v-for="(tipo,index) in documentos.tipos_documentos" :key="index" :value="tipo.id">{{tipo.nombre}}</option>
                        </select>
                    </b-form-group>
                </b-col>
                <!-- <b-col sm="12" md="12" lg="4">
                    <b-form-group
                        :content-cols="12"
                        label="FECHA RECEPCION"
                        readonly="true"
                    > 
                        <b-input type="datetime-local" v-model="fecha_recepcion" required>
                        </b-input>
                    </b-form-group>
                </b-col> -->
                <b-col sm="12" md="12" lg="4">
                    <b-form-group
                        :content-cols="12"
                        label="ADJUNTAR DOCUMENTO"
                    > 
                        
                        <b-form-file 
                        browse-text="ADJUNTAR"
                            placeholder="Seleccione un archivo"
                            drop-placeholder="Coloque el archivo aquí..."
                            accept="application/pdf, image/jpeg, image/png, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                        v-model="file" ref="file-input" class="mb-2" multiple></b-form-file>


                        <b-button class="btn-archivo" v-b-tooltip.hover :title="file.name" type="button" v-for="(file,index) in files" :key="index" @click="eliminar(file)">

                             
                            <inline-svg v-if="file.name.split('.').at(-1) == 'pdf'" src="../../assets/images/archivo-pdf.svg" />
                            <inline-svg 
                            v-if="file.name.split('.').at(-1) == 'png' || file.name.split('.').at(-1) == 'jpg' || file.name.split('.').at(-1) == 'jpeg'" 
                            src="../../assets/images/archivo-png.svg"/>
                            <inline-svg v-if="file.name.split('.').at(-1) == 'xlsx' || file.name.split('.').at(-1) == 'xls'" src="../../assets/images/732220.svg" />
                        </b-button>
                    </b-form-group>
                </b-col>
                
                <b-col sm="12" md="12" lg="12" v-if="tipoDocumentoTransporte == 'Documento_Transporte'">
                    <b-row>
                        <b-col sm="12" md="12" lg="4" >
                            <b-form-group
                                :content-cols="12"
                                label="TIPO DE DOCUMENTO DE TRANSPORTE"
                            > 
                                <select class="form-control border border-secondary"  id="docuento-select"  v-model="tipo_transporte"  @change="changeTipoDocumentoTransporte(tipo_transporte)" required>
                                    <option value="" disabled>Seleccione el tipo de documento de transporte</option>
                                    <option v-for="(tipoDocTrans,index) in documentos.documento_transporte" :key="index" :value="tipoDocTrans">{{tipoDocTrans}}</option>
                                </select>
                            </b-form-group>
                        </b-col>
                        <b-col sm="12" md="12" lg="4" >
                            <b-form-group
                                :content-cols="12"
                                label="Numero Documento de Transporte"
                            > 
                                <b-form-input max="100"  v-model="numero_documento" required >
                                </b-form-input>
                            </b-form-group>
                        </b-col>
                        
                    </b-row>
                </b-col>
                <b-col sm="12" md="12" lg="12" v-if="tipoDocumentoTransporte == 'Guia_Despacho'">
                    <b-row>
                        <b-col sm="12" md="12" lg="4" >
                            <b-form-group
                                :content-cols="12"
                                label="Numero Guía Despacho"
                            > 
                                <b-form-input v-model="numero_guia_despacho" required >
                                </b-form-input>
                            </b-form-group>
                        </b-col>
                        
                    </b-row>
                </b-col>
                <b-col sm="12" md="12" lg="4" v-if="tipoDocumentoTransporte == 'Vistos_Buenos'">
                    <b-row>
                        <b-col sm="12" md="12" lg="12" >
                            <b-form-group
                                :content-cols="12"
                                label="TIPO DE VB"
                            > 
                                <select class="form-control border border-secondary"  id="docuento-select"  v-model="tipo_vistos_buenos" required>
                                    <option value="" disabled>Selecciona un tipo de vistos buenos</option>
                                    <option v-for="(tipo,index) in documentos.tipos_vistos_buenos" :key="index" :value="tipo">{{tipo}}</option>
                                </select>
                            </b-form-group>
                        </b-col>
                    </b-row>
                </b-col>
                <b-col sm="12" md="12" lg="4" v-if="tipoDocumentoTransporte == 'DIN'">
                    <b-row>
                        <b-col sm="12" md="12" lg="12" >
                            <b-form-group
                                :content-cols="12"
                                label="Fecha Aceptación"
                            > 
                                <b-input type="date" v-model="fecha_aceptacion" required>

                                </b-input>
                            </b-form-group>
                        </b-col>
                    </b-row>
                </b-col>
                <b-col sm="12" md="12" lg="4" v-if="tipoDocumentoTransporte == 'BL Corregido'">
                    <b-row>
                        <b-col sm="12" md="12" lg="12" >
                            <b-form-group
                                :content-cols="12"
                                label="Status BL"
                            > 
                                <select class="form-control border border-secondary"  id="status-select"  v-model="statusBl"  required>
                                    <option value="" disabled>Selecciona el status del BL</option>
                                    <option v-for="(tipo,index) in documentos.bl.status" :key="index" :value="tipo">{{tipo}}</option>
                                </select>
                            </b-form-group>
                        </b-col>
                    </b-row>
                </b-col>
                 <b-col sm="12" md="12" lg="12" v-if="tipoDocumentoTransporte == '1er_Dus'">
                    <b-row>
                        <b-col sm="12" md="12" lg="4" >
                            <b-form-group
                                class="text-left"
                                label="Fecha Presentación"
                            >
                                <b-form-datepicker 
                                    label-next-month="Siguiente mes"
                                    label-next-year="Siguiente año"
                                    label-current-month="Mes actual"
                                    label-prev-year="Año anterior"
                                    label-prev-month="Mes anterior"
                                    label-prev-decade="Decada anterior"
                                    label-next-decade="Decada siguiente"


                                    label-no-date-selected="No hay fecha seleccionada"
                                    label-help="Usa el cursos para navegar por las fechas"
                                v-model="fecha_presentacion_primer_dus" class="mb-2" ></b-form-datepicker>
                            </b-form-group>
                        </b-col>
                        <b-col sm="12" md="12" lg="4" >
                            <b-form-group
                                class="text-left"
                                cols="6"
                                label="Hora de Retiro"
                            >
                                <b-form-timepicker 
                                    label-no-time-selected="No hay hora seleccionada"
                                    label-close-button="Cerrar"
                                    label-hours="Horas"
                                    label-minutes="Minutes"
                                v-model="hora_presentacion_primer_dus" locale="es"></b-form-timepicker>
                            </b-form-group>
                        </b-col>
                        <b-col sm="12" md="12" lg="4" >
                            <b-form-group
                                class="text-left"
                                cols="6"
                                label="Cantidad de días para alerta"
                            >
                                <b-input type="number" v-model="orden_alerta">

                                </b-input>
                            </b-form-group>
                        </b-col>
                        <b-col sm="12" md="12" lg="4" >
                            <b-form-group
                            class="text-left"
                                cols="6"
                                label="Número Primer Dus"
                            > 
                                <b-input type="text" v-model="numero_primer_dus" required>

                                </b-input>
                            </b-form-group>
                        </b-col>
                    </b-row>
                </b-col>
                    <b-col sm="12" md="12" lg="4" v-if="tipoDocumentoTransporte == 'Dus_legalizado'">
                        <b-row>
                            <b-col sm="12" md="12" lg="12" >
                                <b-form-group
                                    class="text-left"
                                    label="Fecha Dus Legalizado"
                                >
                                    <b-form-datepicker 
                                        label-next-month="Siguiente mes"
                                        label-next-year="Siguiente año"
                                        label-current-month="Mes actual"
                                        label-prev-year="Año anterior"
                                        label-prev-month="Mes anterior"
                                        label-prev-decade="Decada anterior"
                                        label-next-decade="Decada siguiente"


                                        label-no-date-selected="No hay fecha seleccionada"
                                        label-help="Usa el cursor para navegar por las fechas"
                                    v-model="fecha_dus_legalizado" class="mb-2" ></b-form-datepicker>
                                </b-form-group>
                            </b-col>
                        </b-row>
                    </b-col>
                    
                     <b-col sm="12" md="12" lg="4" v-if="tipoDocumentoTransporte == 'Otro'">
                        <b-row>
                            <b-col sm="12" md="12" lg="12" >
                                <b-form-group
                                :content-cols="12"
                                label="Nombre Documento"
                            > 
                                <b-input type="text" v-model="nombre_documento" required>

                                </b-input>
                            </b-form-group>
                            </b-col>
                        </b-row>
                    </b-col>
                <b-col cols="12" class="text-right">
                    <b-button
                    class="btn-principal btn-small"
                    type="submit"
                    >
                        Enviar
                    </b-button>
                </b-col>
            </b-row>
        </b-container>
    </form>
</template>

<script>
import axios from 'axios'
export default {
    props:{
        documentos: Object,

        postDocumento: Function,
        cancelar:Function
    },
    data(){
        return{
            msg:'HOLAAA',
            file: null,
            files: [],
            tipo_documento: '',
            fecha_recepcion: '',
            ///BL
            statusBl: '',

            //DIN
            fecha_aceptacion: '',

            //documento transporte
            tipo_transporte: '',
            numero_documento: '',

            //tipos vistos buenos
            tipo_vistos_buenos: 0,

            //tipos vistos buenos
            numero_guia_despacho: '',
            
            //1er dus
            fecha_presentacion_primer_dus: '',
            hora_presentacion_primer_dus: '',
            numero_primer_dus: '',
            orden_alerta:'',

            //dus legalizado
            fecha_dus_legalizado: '',

            //documento otro
            nombre_documento: '',


            tipoDocumentoTransporte: '',
            tipoDocumentoVB: false,

            tipoDocumentoBl: false,
        }
    },
    watch:{
        file:function(e){
            this.$refs['file-input'].files.forEach(el =>{
                this.files.push(el)
            })
            this.$refs['file-input'].reset()
        }
    },
    methods:{
        changeTipoDocumento(nuevoTipo){
            if(nuevoTipo == 1) this.tipoDocumentoTransporte = 'Documento_Transporte';
            else if(nuevoTipo == 2) this.tipoDocumentoTransporte = 'Vistos_Buenos';
            else if(nuevoTipo == 3) this.tipoDocumentoTransporte = 'BL Corregido';
            else if(nuevoTipo == 4) this.tipoDocumentoTransporte = 'DIN';
            else if(nuevoTipo == 10) this.tipoDocumentoTransporte = 'Guia_Despacho';
            else if(nuevoTipo == 17) this.tipoDocumentoTransporte = '1er_Dus';
            else if(nuevoTipo == 18) this.tipoDocumentoTransporte = 'Dus_legalizado';
            else if(nuevoTipo == 20) this.tipoDocumentoTransporte = 'Otro';
            else this.tipoDocumentoTransporte = '';
            
        },
        changeTipoDocumentoTransporte(nuevoTipoDocumentoTransporte){
            if(nuevoTipoDocumentoTransporte== 1) this.tipoDocumentoBl = true;
            else this.tipoDocumentoBl = false;
        },
        clearFiles() {
            console.log(this.$refs['file-input'])
        //this.$refs['file-input'].reset()
        },
        async eliminar(file){
            let variable = await this.$swal({
                    title: "¿Deseas eliminar este documento?",
                    text: "¿Está seguro? ¡No podrás revertir esta acción!",
                    type: "warning",
                    showCancelButton: true,
                    confirmButtonColor: '#3085d6',
                    cancelButtonColor: '#d33',
                    confirmButtonText: 'Si, Borrar!',
                    cancelButtonText: 'No, cancelar!',
                    buttonsStyling: true
                  })
            if(variable.isConfirmed == true){
            let encontro = false;
            this.files = this.files.filter(el => {
                if(!encontro){
                    if (el.name == file.name) {
                        encontro = true;
                    }else{
                        return el

                    }
                }else{
                    return el
                }
            })
            }
            

        },
        async submit(){
            
            let formData = new FormData();
            
            this.id = this.$route.params.despachoId;
            formData.append('despachos_id', this.id);
            formData.append('fecha_recepcion', new Date().toISOString().slice(0, 19).replace('T', ' '));
            formData.append('tipo_documentos_id', this.tipo_documento);

            for( var i = 0; i < this.files.length; i++ ){
                let file = this.files[i];
                formData.append('files['+ i +']', file);
            }

            if(this.tipoDocumentoTransporte == 'Documento_Transporte'){ 
                formData.append('tipo_transporte', this.tipo_transporte);
                formData.append('numero_documento', this.numero_documento);
                this.tipoDocumentoTransporte = 'Documento_Transporte';
            
            }
            else if(this.tipoDocumentoTransporte == 'Vistos_Buenos') {
                formData.append('tipo_vistos_buenos',this.tipo_vistos_buenos)
                this.tipoDocumentoTransporte = 'Vistos_Buenos';
            
            }
            else if(this.tipoDocumentoTransporte == 'BL Corregido') {

                formData.append('status',this.statusBl)

                this.tipoDocumentoTransporte = 'BL Corregido';
            
            }
            else if(this.tipoDocumentoTransporte == 'DIN') {

                formData.append('fecha_aceptacion', this.fecha_aceptacion);


                this.tipoDocumentoTransporte = 'DIN';
                
            }
            else if(this.tipoDocumentoTransporte == 'Guia_Despacho') {

                formData.append('numero_guia_despacho', this.numero_guia_despacho);


                this.tipoDocumentoTransporte = 'Guia_Despacho';
                
            }
            else if(this.tipoDocumentoTransporte == '1er_Dus') {

                formData.append('fecha_presentacion',this.fecha_presentacion_primer_dus)
                formData.append('hora_presentacion',this.hora_presentacion_primer_dus)
                formData.append('numero_dus',this.numero_primer_dus)
                formData.append('orden_alerta',this.orden_alerta)
                //formData('prorroga',this.)
                //formData('usuarios_alerta',this.)
                //formData('fecha_vencimiento',this.)


                this.tipoDocumentoTransporte = '1er_Dus';
                
            }
            else if(this.tipoDocumentoTransporte == 'Dus_legalizado') {

                formData.append('fecha_dus_legalizado',this.fecha_dus_legalizado)


                this.tipoDocumentoTransporte = 'Dus_legalizado';
                
            }else if(this.tipoDocumentoTransporte == 'Otro') {

                formData.append('nombre_documento',this.nombre_documento)


                this.tipoDocumentoTransporte = 'Otro';
                
            }
            
            
            if(this.tipo_documento == 0){ 
                this.$swal({
                    icon: 'warning',
                    title: '',
                    text: 'Se debe seleccionar un tipo de documento',
                });
            }
            if(this.files.length > 0 ){
                this.cancelar()
                this.postDocumento(formData)
                this.tipo_documento = ''
                this.tipoDocumentoTransporte = '';
                this.fecha_recepcion = '';
                this.files = [];
                }
            else{
                this.$swal({
                    icon: 'warning',
                    title: '',
                    text: 'Se deben subir documentos para su comprobación',
                });
            }
            
            
            
        }
    }
}
</script>

<style>

</style>
