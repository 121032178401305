<template>
<div>
  <title-page title="CUENTA"/>
  <b-alert  :show="!user.email_verified_at" variant="warning">
    <h5>Verificar email</h5>
    Para acceder a todas las funcionalidades, porfavor verifica tu correo <b-button @click="sendEmail">ENVIAR CORREO</b-button>
  </b-alert>
  <card title="Cuenta" :agregar="false" v-permisos="'edit.account'">
    
      <!-- form -->
      <b-form id="editUserForm" class=" form-input" @submit.prevent="submit">
        
       
          <form-usuario 
            :form="form"
            :regions="regions"
            :user="user"
            :rutDisponible="rutDisponible"
            :password="password"
            :cambiarPassword="cambiarPassword"
          
          >
          <template v-slot:cambiarPassword>
              <button type="button" class="btn-extra-secundario btn-large" @click="cambiarPassword">Cambiar contraseña</button>
            </template>
          </form-usuario>
        

        
        


        
    </b-form>
  </card>
</div>
</template>

<script>
  import { mapGetters, mapActions } from 'vuex'
  import axios from 'axios';
  import store from '../../store/index';

  import Card from '../../components/ui/Card.vue'
import TitlePage from '../../components/layout/TitlePage.vue';
import InputRut from '../../components/formulario/InputRut.vue';
import FormUsuario from '../../components/formulario/FormUsuario.vue';


export default {
  components: {
    Card,
    TitlePage,
    InputRut,
    FormUsuario
  },
  computed: {
      ...mapGetters({
        authenticated: 'auth/authenticated',
        user: 'auth/user',
      }),
    },

    data () {
      return {
        selected:null,
        form:{
            name: '',
            username: '',
            email: '',
            phone: '',
            rut:'',
            region: '',
            comuna_id: '',
            password: '',
            password_confirmation: '',
        },
        userID: null,
        regions: [],
        comunas: [],
        password: false,

        rutDisponible: false,
      }
    },
    mounted(){
      this.getUser();
      this.getPermissions();
      this.form.name = this.user.name
      this.form.username = this.user.username
      this.form.email = this.user.email
      this.form.email_confirmation = this.user.email
      this.form.phone = this.user.phone
      this.form.address = this.user.address
      this.form.rut = this.user.rut+this.user.dv
      this.form.comuna_id = this.user.comuna_id
      this.rutDisponible = true;
    },


    methods: {
      ...mapActions({
        getUser: 'auth/getUser',
        getPermissions: 'auth/getPermissions',
      }),

      //LLAMADAS API
      async submit () {
        if(this.form.comuna_id == "" ){
          this.form.address = null
        }
        return store.$axios.post('api/me/edit',this.form).then((response) => {
        this.metodoThen();
        
        
        }).catch((error)=>{
          this.metodoCatch(error)
        })
      },

      async sendEmail(){
        await store.$axios.post('/api/email/verification-notification',{})
          .then(res => {
            this.$swal({
            icon: 'success',
            title: '¡Exito!',
            showConfirmButton: false,
            timer: 1000,
            text: '¡El correo se ha enviado de forma exitosa!',
          }).catch;
        })
      },
      

      ///
      
        cambiarPassword(){
          this.password = !this.password;
        },

        metodoCatch(error){
          if(error.response){
            Object.keys(error.response.data.errors).forEach((key) => {
              if(document.getElementById(`${key}`)){
                document.getElementById(`${key}`).innerHTML = error.response.data.errors[key];
              }
            })
          }
        },
        metodoThen(){
          this.getUser();
        },
    }
};
</script>

<style >

</style>