<template>
    <b-modal size="lg"  v-model="show" id="modal-editar" centered title="BootstrapVue" @hidden="cerrarModal()" hide-footer>
        <template #modal-title>
          {{titulo}}
        </template>
        <div class="d-block text-center">
          <b-form id="editUserForm" class="pl-3 pr-3 form-input" @submit.prevent="submit">
          
           <form-proveedor
            :form="form"
            :rutDisponible="true"
            :user="user"
           >
           </form-proveedor>
          </b-form>
        </div>
      </b-modal>
  </template>
  
  <script>
  import FormProveedor from '../formulario/FormProveedor.vue';
  import FormUsuario from '../formulario/FormUsuario.vue';
  import InputRut from '../formulario/InputRut.vue';
  export default {
    components: { InputRut, FormUsuario, FormProveedor },
      name: "ModalFormUsuario",
      props: {
        show: Boolean,
        cerrarModal: Function,
          titulo: String,
          form: Object,
          submit: Function,
          roles: Array,
          user:Object,
          
          password: Boolean,
          cambiarPassword: Function,
          cambiarPasswordCondicion: Boolean
      },
      methods:{
          
      }
  }
  </script>
  
  <style>
  
  </style>