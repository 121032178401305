<template>
<div>


<title-page title="DESPACHOS"/>
  <card title="Documento despacho" 
  :segundoaccionBoton="volverDespacho"
      :segundoboton="true"
      segundotext="Volver"
      :accionBoton="downloadDocumento" :boton="idDescargar != null" text="Descargar"
    >
  <div v-if="documento.tipo">
    <b-container fluid>
      <b-row >   
        <b-col cols="12" class="contenedor-informacion-revisor">
            <b-row>
                <!--DOCUMENTOS GENERALES --> 
                <b-col sm="12" md="12" lg="4" class="informacion-revisor">
                    <b-row>
                        <b-col cols="6" class="text-left dato">
                            <p>Tipo de Documento</p>
                        </b-col>
                        <b-col cols="6" class="text-right dato despacho">
                            <p>{{documento.tipo.nombre}}</p>
                        </b-col>
                    </b-row>
                </b-col>  
                <b-col sm="12" md="12" lg="4"  class="informacion-revisor">
                    <b-row>
                        <b-col cols="6" class="text-left dato">
                            <p>Fecho Recepción</p>
                        </b-col>
                        <b-col cols="6" class="text-right dato despacho">
                            <p>{{moment(documento.fecha_recepcion).format('DD-MM-YYYY')}}</p>
                        </b-col>
                    </b-row>
                </b-col>  
                <b-col sm="12" md="12" lg="4"  class="informacion-revisor">
                    <b-row>
                        <b-col cols="6" class="text-left dato">
                            <p>Estado Validación</p>
                        </b-col>
                        <b-col cols="6" class="text-right dato despacho">
                            <p>{{documento.estado}}</p>
                        </b-col>
                    </b-row>
                </b-col> 
                <!-- Documento de transporte--> 
                 <b-col sm="12" md="12" lg="4" class="informacion-revisor" v-if="documento.tipo_documentos_id == 1">
                    <b-row>
                        <b-col cols="6" class="text-left dato">
                            <p>Tipo Transporte</p>
                        </b-col>
                        <b-col cols="6" class="text-right dato despacho">
                            <p>{{documento.base.tipo_transporte}}</p>
                        </b-col>
                    </b-row>
                </b-col> 
                <b-col sm="12" md="12" lg="4" class="informacion-revisor" v-if="documento.tipo_documentos_id == 1">
                    <b-row>
                        <b-col cols="6" class="text-left dato">
                            <p>Número Documento</p>
                        </b-col>
                        <b-col cols="6" class="text-right dato despacho">
                            <p>{{documento.base.numero_documento}}</p>
                        </b-col>
                    </b-row>
                </b-col> 
                <!-- Documento de Vistos Buenos--> 
                <b-col sm="12" md="12" lg="4" class="informacion-revisor" v-if="documento.tipo_documentos_id == 2">
                    <b-row>
                        <b-col cols="6" class="text-left dato">
                            <p>Tipo de Visto Bueno</p>
                        </b-col>
                        <b-col cols="6" class="text-right dato despacho">
                            <p>{{documento.base.tipo_vistos_buenos}}</p>
                        </b-col>
                    </b-row>
                </b-col> 
                
                <b-col sm="12" md="12" lg="4" class="informacion-revisor" v-if="documento.tipo_documentos_id == 3">
                    <b-row>
                        <b-col cols="6" class="text-left dato">
                            <p>Status Bl</p>
                        </b-col>
                        <b-col cols="6" class="text-right dato despacho">
                            <p>{{documento.base.status}}</p>
                        </b-col>
                    </b-row>
                </b-col> 

                <!-- Documento de Tipo DIN--> 
                <b-col sm="12" md="12" lg="4" class="informacion-revisor" v-if="documento.tipo_documentos_id == 4">
                    <b-row>
                        <b-col cols="6" class="text-left dato">
                            <p>Fecha Aceptación</p>
                        </b-col>
                        <b-col cols="6" class="text-right dato despacho">
                            <p>{{moment(documento.base.fecha_aceptacion).format('DD-MM-YYYY')}}</p>
                        </b-col>
                    </b-row>
                </b-col> 

                <!-- Documento de Tipo GUIA DESPACHO--> 
                <b-col sm="12" md="12" lg="4" class="informacion-revisor" v-if="documento.tipo_documentos_id == 10">
                    <b-row>
                        <b-col cols="6" class="text-left dato">
                            <p>Número Guía Despacho</p>
                        </b-col>
                        <b-col cols="6" class="text-right dato despacho">
                            <p>{{documento.base.numero_guia_despacho}}</p>
                        </b-col>
                    </b-row>
                </b-col> 
                <!-- Documento de Tipo GUIA DESPACHO--> 
                <b-col sm="12" md="12" lg="4" class="informacion-revisor" v-if="documento.tipo_documentos_id == 17">
                    <b-row>
                        <b-col cols="6" class="text-left dato">
                            <p>Fecha Presentación</p>
                        </b-col>
                        <b-col cols="6" class="text-right dato despacho">
                            <p>{{moment(documento.base.fecha_presentacion).format('DD-MM-YYYY')}}</p>
                        </b-col>
                    </b-row>
                </b-col> 
                <b-col sm="12" md="12" lg="4" class="informacion-revisor" v-if="documento.tipo_documentos_id == 17">
                    <b-row>
                        <b-col cols="6" class="text-left dato">
                            <p>Hora Presentación</p>
                        </b-col>
                        <b-col cols="6" class="text-right dato despacho">
                            <p>{{documento.base.hora_presentacion}}</p>
                        </b-col>
                    </b-row>
                </b-col> 
                <b-col sm="12" md="12" lg="4" class="informacion-revisor" v-if="documento.tipo_documentos_id == 17">
                    <b-row>
                        <b-col cols="6" class="text-left dato">
                            <p>Número Dus</p>
                        </b-col>
                        <b-col cols="6" class="text-right dato despacho">
                            <p>{{documento.base.numero_dus}}</p>
                        </b-col>
                    </b-row>
                </b-col> 
                <b-col sm="12" md="12" lg="4" class="informacion-revisor" v-if="documento.tipo_documentos_id == 17">
                    <b-row>
                        <b-col cols="6" class="text-left dato">
                            <p>Fecha Vencimiento</p>
                        </b-col>
                        <b-col cols="6" class="text-right dato despacho">
                            <p>{{moment(documento.base.fecha_vencimiento).format('DD-MM-YYYY')}}</p>
                        </b-col>
                    </b-row>
                </b-col> 
                 <!-- Documento de Tipo DUS LEGALIZADO--> 
                <b-col sm="12" md="12" lg="4" class="informacion-revisor" v-if="documento.tipo_documentos_id == 17">
                    <b-row>
                        <b-col cols="6" class="text-left dato">
                            <p>Estado Dus</p>
                        </b-col>
                        <b-col cols="6" class="text-right dato despacho">
                            <p>{{documento.base.estado}}</p>
                        </b-col>
                    </b-row>
                </b-col> 
                <b-col sm="12" md="12" lg="4" class="informacion-revisor" v-if="documento.tipo_documentos_id == 17">
                    <b-row>
                        <b-col cols="6" class="text-left dato">
                            <p>Fecha Dus Legalizado</p>
                        </b-col>
                        <b-col cols="6" class="text-right dato despacho">
                            <p>{{moment(documento.base.fecha_dus_legalizado).format('DD-MM-YYYY')}}</p>
                        </b-col>
                    </b-row>
                </b-col> 
                <b-col sm="12" md="12" lg="12" class="informacion-revisor" >
                    <b-row>
                        <b-col cols="12" class="text-left dato">
                            <p>Archivos</p>
                        </b-col>
                        <b-col cols="1" class="text-right dato despacho" v-for="archivo in documento.archivos" v-bind:key="archivo.id">
                            
                        <b-button class="btn-archivo-tabla" v-b-tooltip.hover :title="archivo.ruta" style="width:60px; height:100px" @click="abrirDocumento(archivo.id,archivo.ruta)" >
                           <inline-svg v-if="archivo.ruta.split('.').at(-1) == 'pdf'" src="./../../../../assets/images/archivo-pdf.svg"
                                width="40" 
                                height="100"
                            />
                           <inline-svg 
                           v-if="archivo.ruta.split('.').at(-1) == 'png' || archivo.ruta.split('.').at(-1) == 'jpg' || archivo.ruta.split('.').at(-1) == 'jpeg'" 
                           src="../../assets/images/archivo-png.svg"/>
                           <inline-svg v-if="archivo.ruta.split('.').at(-1) == 'xlsx' || archivo.ruta.split('.').at(-1) == 'xls'" src="./../../../../assets/images/732220.svg" width="40" heigth="100" />
                        </b-button>
                        </b-col>
                    </b-row>
                </b-col> 
                <b-col cols="12 text-right mt-4" v-permisos='"revisor.view"'>
                    <b-button  @click="validar('Aceptado')" class="btn-secundario btn-small mr-1">
                        Aceptado  <inline-svg  class="icono-titulo" src="../../assets/images/Editar.svg" title="My Image"/>
                    </b-button>
                    <b-button  @click="validar('Rechazado')" class=" btn-principal btn-small">
                        Rechazado <inline-svg  class="icono-titulo" src="../../assets/images/basura.svg" title="My Image"/>
                    </b-button>
                </b-col> 


            </b-row>
        </b-col>  
      </b-row>
    </b-container>
  </div>
  <div id="visualizacion-documentos-adjuntos-1">
        <div v-if="tipoDeArchivo === ''" style="text-align: center;">
            <h3 id="visor-titulo" style="color: #372368; margin-top: 30px; margin-bottom: 10px;"><strong>{{nombre_documento}}</strong></h3>
        </div>
        <div v-else>
            <h3 id="visor-titulo" style="color: #372368; margin-top: 30px; margin-bottom: 10px;"><strong>{{nombre_documento}}</strong></h3>
            <div class="visor" v-if="tipoDeArchivo === 'png' || tipoDeArchivo === 'pdf'">
                <canvas id="visor-pdf-gen"></canvas>
                <div class="visor-cuerpo">
                    <canvas id="visor-canvas"
                    :width="visorWidth"
                    :height="visorHeight"
                    ></canvas>
                </div>
                <div class="visor-opciones text-center" 
                v-if="tipoDeArchivo == 'pdf'"
                >
                    <b-button 
                    class="btn-principal btn-large"
                    @click="atrasPagina()"
                    v-if="paginaActual > 1"
                    >
                    Atras
                    </b-button>
                    <b-button 
                    class="btn-secundario btn-small">
                    {{paginaActual}}
                    </b-button>
                    <b-button @click="siguientePagina()"
                    class="btn-principal btn-large"
                    v-if="paginaActual < numeroPaginas "
                    >
                    Siguiente
                    </b-button>
                </div>
            </div>
            <div v-else>
                <h3>No se puede visualizar el archivo.</h3>
            </div>
        </div>
    </div>
    </card>
  </div>
</template>

<script>
import moment from 'moment';
import axios from 'axios'
import TitlePage from '../layout/TitlePage.vue'
import Card from '../ui/Card.vue'
import { getDocument } from 'pdfjs-dist';
import Serve from '../ui/prueba/serve.vue'
export default {
  components: {TitlePage, Card, Serve },
  data(){
    return{
      id:'',
      idDocumento:null,
      idDescargar:null,
      idArchivo: null,

      visorImagen: new Image(),
      
      numeroPaginas: 0,
      paginaActual:1,

      visorWidth:1000,
      visorHeight:800,

      tipoDeArchivo: '',
      
      documento: [],
      nombre_documento:'Seleccione un documento'


    }
  },
  created() {
    window.addEventListener("resize", this.redimensionarVentana);
  },
  destroyed() {
    window.removeEventListener("resize", this.redimensionarVentana);
  },
  mounted(){
    this.id = this.$route.params.despachoId;
    this.idDocumento = this.$route.params.documentoId;

    this.getDocumento()

    //this.mostrarDocumento()
    
   
        
    
  },
  methods: {
    abrirDocumento(id,nombre){
        this.idArchivo = id
        this.idDescargar = id
        this.nombre_documento = nombre.split('/')[1]
        this.mostrarDocumento()
    },
    mostrarDocumento(){
        axios.get(`/api/archivo/${this.idArchivo}/descargar`)
    .then(res=>{
      console.log(res)
      if(res.headers['content-type'] == 'application/pdf'){
        this.tipoDeArchivo = 'pdf'
        this.cargarPDF(this.paginaActual)
      }else if(res.headers['content-type'] == 'image/png' || res.headers['content-type'] == 'image/jpeg'){
        this.tipoDeArchivo = 'png'
        this.cargarImagen()
      }else{
        this.tipoDeArchivo = 'otro'
      }
    })
    .catch(err => console.log(err))

    if(screen.width <= 320) {
        this.visorWidth = 280
        this.visorHeight = 640
    }
    else if(screen.width < 600) {
        this.visorWidth = 340
        this.visorHeight = 640
    }else if(screen.width <=768){
        this.visorWidth = 550
        this.visorHeight = 690
    }
    else if (screen.width < 1024) {
        this.visorWidth = 700
        this.visorHeight = 690
    }
    else{
        this.visorWidth = 1000
        this.visorHeight = 890
    }
    
    var init = false; 
    this.visorImagen.onload = () =>{
      var canvas = document.getElementById('visor-canvas');
      var ctx = canvas.getContext('2d');
      canvas.width = this.visorWidth;
      canvas.height = this.visorHeight;
      this.trackTransforms(ctx);

      var firstDraw = true;

      let redraw = () => {

          // Clear the entire canvas
          var p1 = ctx.transformedPoint(0,0);
          var p2 = ctx.transformedPoint(canvas.width,canvas.height);
          ctx.clearRect(p1.x,p1.y,p2.x-p1.x,p2.y-p1.y);

          ctx.save();
          ctx.setTransform(1,0,0,1,0,0);
          ctx.clearRect(0,0,canvas.width,canvas.height);
          ctx.restore();
          if (this.visorImagen.src !== "") {
              if (firstDraw == true) {
                  var ss = canvas.height/this.visorImagen.height;
                  ctx.translate((canvas.width-this.visorImagen.width*ss)/2,0);
                  ctx.scale(ss,ss);
                  firstDraw = false;
              }
              ctx.drawImage(this.visorImagen,0,0);
          }
      }
      redraw();

      if (init == false) {

          var lastX=canvas.width/2, lastY=canvas.height/2;
          var dragStart,dragged;
          canvas.addEventListener('mousedown',function(evt){
              document.body.style.mozUserSelect = document.body.style.webkitUserSelect = document.body.style.userSelect = 'none';
              lastX = evt.offsetX || (evt.pageX - canvas.offsetLeft);
              lastY = evt.offsetY || (evt.pageY - canvas.offsetTop);
              dragStart = ctx.transformedPoint(lastX,lastY);
              dragged = false;
          },false);

          canvas.addEventListener('mousemove',function(evt){
              lastX = evt.offsetX || (evt.pageX - canvas.offsetLeft);
              lastY = evt.offsetY || (evt.pageY - canvas.offsetTop);
              dragged = true;
              if (dragStart){
                  var pt = ctx.transformedPoint(lastX,lastY);
                  ctx.translate(pt.x-dragStart.x,pt.y-dragStart.y);
                  redraw();
              }
          },false);

          canvas.addEventListener('mouseup',function(evt){
              dragStart = null;
              if (!dragged) zoom(evt.shiftKey ? -1 : 1 );
          },false);

          canvas.addEventListener('mouseout',function(evt){
              dragStart = null;
          },false);

          var scaleFactor = 1.05;

          var zoom = function(clicks){
              var pt = ctx.transformedPoint(lastX,lastY);
              ctx.translate(pt.x,pt.y);
              var factor = Math.pow(scaleFactor,clicks);
              ctx.scale(factor,factor);
              ctx.translate(-pt.x,-pt.y);
              redraw();
          }

          var handleScroll = function(evt){
              var delta = evt.wheelDelta ? evt.wheelDelta/40 : evt.detail ? -evt.detail : 0;
              if (delta) zoom(delta);
              return evt.preventDefault() && false;
          };

          canvas.addEventListener('DOMMouseScroll',handleScroll,false);
          canvas.addEventListener('mousewheel',handleScroll,false);
          init = true;
      }

    };
    },
    async getDocumento(){
      await axios.get(`/api/admin/documentos/${this.idDocumento}`)
        .then(res => {
          this.documento = res.data.data
          console.log(this.documento)
        })
    },
    async validar(estado){
        await axios.post(`api/admin/documentos/${this.idDocumento}/validar`,{estado: estado})
        .then(res => {
            this.getDocumento()
        })
        .catch(err => {console.log(err)})
    },
    async downloadDocumento(){
        window.open(process.env.VUE_APP_API_URL +`/api/admin/archivo/${this.idDescargar}/descargar/despacho`);
    },
    volverDespacho(){
      this.$router.replace({ name: 'PerfilDespacho', params:{despachoId: this.id} })
    },
    redimensionarVentana(e) {
      //console.log(e.windows)
    },
    atrasPagina(){
      this.paginaActual = this.paginaActual - 1
      this.cargarPDF(this.paginaActual)
    },
    siguientePagina(){
      this.paginaActual = this.paginaActual + 1
      this.cargarPDF(this.paginaActual)
    },
    cargarPDF(pagina) {
      // Asynchronous download of PDF
      var loadingTask = getDocument(process.env.VUE_APP_API_URL +`/api/archivo/${this.idArchivo}/descargar`);
      loadingTask.promise.then((pdf) => {
        console.log(pdf.numPages)
        this.numeroPaginas = pdf.numPages
        pdf.getPage(pagina).then(page => {

          // Prepare canvas using PDF page dimensions
          var canvas_pdf = document.getElementById('visor-pdf-gen');
          var context = canvas_pdf.getContext('2d');
          var viewport = page.getViewport({scale:6});
          canvas_pdf.width = viewport.width;
          canvas_pdf.height = viewport.height;

          // Render PDF page into canvas context
          var renderContext = {
            canvasContext: context,
            viewport: viewport
          };
          var renderTask = page.render(renderContext);
          renderTask.promise.then(() =>{
            this.visorImagen.src = canvas_pdf.toDataURL('image/png');
          });
        });
      }, function (reason) {
        // PDF loading error
        console.error(reason);
      });
    },
    cargarImagen() {
      this.visorImagen.src = process.env.VUE_APP_API_URL +`/api/archivo/${this.idArchivo}/descargar`; 
    },
trackTransforms(ctx){

    var svg = document.createElementNS("http://www.w3.org/2000/svg",'svg');
    var xform = svg.createSVGMatrix();
    ctx.getTransform = function(){ return xform; };

    var savedTransforms = [];
    var save = ctx.save;
    ctx.save = function(){
        savedTransforms.push(xform.translate(0,0));
        return save.call(ctx);
    };

    var restore = ctx.restore;
    ctx.restore = function(){
        xform = savedTransforms.pop();
        return restore.call(ctx);
    };

    var scale = ctx.scale;
    ctx.scale = function(sx,sy){
        xform = xform.scaleNonUniform(sx,sy);
        return scale.call(ctx,sx,sy);
    };

    var rotate = ctx.rotate;
    ctx.rotate = function(radians){
        xform = xform.rotate(radians*180/Math.PI);
        return rotate.call(ctx,radians);
    };

    var translate = ctx.translate;
    ctx.translate = function(dx,dy){
        xform = xform.translate(dx,dy);
        return translate.call(ctx,dx,dy);
    };

    var transform = ctx.transform;
    ctx.transform = function(a,b,c,d,e,f){
        var m2 = svg.createSVGMatrix();
        m2.a=a; m2.b=b; m2.c=c; m2.d=d; m2.e=e; m2.f=f;
        xform = xform.multiply(m2);
        return transform.call(ctx,a,b,c,d,e,f);
    };

    var setTransform = ctx.setTransform;
    ctx.setTransform = function(a,b,c,d,e,f){
        xform.a = a;
        xform.b = b;
        xform.c = c;
        xform.d = d;
        xform.e = e;
        xform.f = f;
        return setTransform.call(ctx,a,b,c,d,e,f);
    };

    var pt  = svg.createSVGPoint();
    ctx.transformedPoint = function(x,y){
        pt.x=x; pt.y=y;
        return pt.matrixTransform(xform.inverse());
    }
},

visorLimpiar() {
    var canvas= document.getElementById('visor-canvas');
    canvas.width = 800;
    canvas.height = 400;
    var ctx = canvas.getContext('2d');
    var p1 = ctx.transformedPoint(0,0);
    var p2 = ctx.transformedPoint(canvas.width,canvas.height);
    ctx.clearRect(p1.x,p1.y,p2.x-p1.x,p2.y-p1.y);
    this.visorImagen.src = "";
}

  }
}
</script>

<style>
    .gutter-sm > [class*='col-']{
        padding: 0px 5px;
    }
    .visor {
        height: calc(100% - 70px);
    }
    .visor-cuerpo {
       margin: auto;
    width: fit-content;
        height: calc(100% - 64px);
        text-align: center;
        display: block;
        position: relative;
        background: #EEEEEE;
        cursor: all-scroll;
    }
    .visor-cerrar {
        position: absolute;
        right: 10px;
        top: 10px;
        width: 32px;
        height: 32px;
        border-radius: 16px;
        background-color: #ECECEF;
        color: #372268;
        font-size: 15pt;
        cursor: pointer;
    }
    #visor-canvas {
        display: inline-block;
        max-width: 800px;
    }
    #visor-pdf-gen {
        display: none;
    }
    .visor-opciones {
        height: 64px;
        text-align: center;
        padding-top: 15px;
    }
    .visor-opciones span {
        font-size: 18pt;
    }
    .visor-opciones .opcion-adjuntar {
        color: #372368;
    }
    .visor-opciones .opcion-eliminar {
        color: red;
    }
    .visor-opciones .mensaje{
        font-size: 11pt;
    }
    button.btn-link {
        word-wrap: break-word;
        white-space: normal;
        color: white !important;
        text-decoration: none !important;
    }
    button.btn-link:hover {
        text-decoration: none !important;
    }
    button.btn-rounded-2 {
        border-radius: 20px;
    }
    .archivo-protocolo {
        color:#372268;
    }
    .archivo-protocolo.submitted {
        color:#31C5D6;
    }
    .archivo-protocolo.active {
        color:#068800 !important;
    }
    .ver-doc-ref {
        color:#372268;
    }
    .ver-doc-ref.active {
        color:#098000;
    }
</style>